import React, { useEffect } from "react";
import {
  Box,
  Flex,
  FormLabel,
  Heading,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { closeCreateGroup } from "../../../../modules/admin-modules/models/Actions";
import MainModel from "../../../../components/Models/main-model";
import { useForm } from "react-hook-form";
import Select from "react-select";
import { toast } from "react-toastify";
import {
  createGroupRequest,
  editGroupRequest,
} from "../../../../modules/admin-modules/groups/Actions";
import { getAllSubjectsRequest } from "../../../../modules/admin-modules/subjects/Actions";

const AddGroup = ({ isAdd, data, setSelectedData }) => {
  const openGroup = useSelector((state) => state.model.isOpen2);
  const subjects = useSelector((state) => state.subjects.subjects);
  const isLoading = useSelector((state) => state.groups.iaLoading);
  const dispatch = useDispatch();
  useEffect(() => {
    if (openGroup) {
      if (subjects?.length == 0) dispatch(getAllSubjectsRequest());
    }
  }, [openGroup, subjects]);

  const groupsOptions = subjects?.map((data) => {
    return { value: data.id, label: data.name };
  });
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      width: "100%",
      background: "#f5f5f5",
      color: "#690000",
      borderRadius: "16px",
    }),
    option: (styles) => ({ ...styles, color: "#690000" }),
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: !isAdd ? data : {},
    shouldUnregister: true,
  });

  const header = (
    <Box>
      <Heading textAlign="center" color="main_1" fontSize="24px">
        {isAdd ? "إضافة مجموعة" : "تعديل مجموعة"}
      </Heading>
    </Box>
  );
  const onSubmit = (values) => {
    let formData = new FormData();
    const total = { ...data, ...values };
    for (let key in total) {
      formData.append(key, total[key]);
    }
    const action = () => {
      dispatch(closeCreateGroup());
      setSelectedData({});
      reset();
    };

    if (isAdd) {
      if (values.subject_id) {
        dispatch(createGroupRequest(formData, action));
      } else {
        toast.error("اختر الفرع");
      }
    } else {
      formData.append("_method", "PUT");
      dispatch(
        editGroupRequest({
          id: data?.id,
          formData: formData,
          action: action,
        })
      );
    }
  };

  const closeFunc = () => {
    dispatch(closeCreateGroup());
    setSelectedData({});
    reset();
  };
  return (
    <MainModel
      isLoading={isLoading}
      header={header}
      closeFunc={closeFunc}
      openVar={openGroup}
      buttonTitle={" حفظ التغيرات"}
      buttonFunc={handleSubmit(onSubmit)}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={5} dir="rtl" w="100%">
          <Box w="55%">
            <FormLabel>اسم المجموعة : </FormLabel>

            <Input
              type={"text"}
              borderColor={errors?.name?.message ? "red" : "gray.200"}
              placeholder={"ادخل اسم المجموعة"}
              size="md"
              bg="#f5f5f5"
              defaultValue={data?.name}
              _focus={{ outline: "none" }}
              {...register("name", { required: "هذه المعلومات مطلوبة" })}
            />
          </Box>

          <Box w="55%">
            <FormLabel>المادة : </FormLabel>
            <Select
              options={groupsOptions}
              defaultValue={{
                label: data?.subject?.name,
                value: data?.subject?.id,
              }}
              onChange={(data) => setValue("subject_id", data.value)}
              styles={colourStyles}
              placeholder="المادة"
              name="subject_id"
            />
          </Box>
          <Box w="55%">
            <FormLabel>النوع : </FormLabel>
            <Select
              options={[
                { label: "online", value: 1 },
                { label: "center", value: 2 },
              ]}
              defaultValue={{ label: data?.name, value: data?.type }}
              onChange={(data) => setValue("type", data.value)}
              styles={colourStyles}
              placeholder="النوع"
              name="type"
            />
          </Box>
        </Stack>
      </form>
    </MainModel>
  );
};

export default AddGroup;
