import React, { useRef, useState } from "react";
import { MdOutlinePlayCircle } from "react-icons/md";
import { useDispatch } from "react-redux";
import useVdocipher from "../hooks/use-vdocipher";
import VideoStatusUsingAPI from "../status/index";
import { addVideo } from "./func";
import "./style.css";

export default function Player({ message, otp, playbackInfo, data }) {
  const { loadVideo, isAPIReady } = useVdocipher();
  const videoContainerRef = useRef();
  const [videoRef, setVideoRef] = useState(null);
  const [display, setDisplay] = useState("block");
  const dispatch = useDispatch();
  return (
    <div className="hello" style={{ position: "relative" }}>
      <div className="inline">
        <br />
        <button
          style={{
            position: "absolute",
            bottom: "50%",
            left: "45%",
            cursor: "pointer",
            zIndex: "10",
            background: "white",
            color: "#0e1f42",
            borderRadius: "8px",
            padding: "10px",
            margin: "2px",
            display: display,
          }}
          onClick={() =>
            addVideo({
              loadVideo,
              otp,
              playbackInfo,
              videoContainerRef,
              setVideoRef,
              setDisplay,
              dispatch,
              data,
            })
          }
        >
          <MdOutlinePlayCircle style={{ fontSize: "50px" }} />
        </button>
      </div>
      <div className="vdo-container" ref={videoContainerRef}></div>
      <VideoStatusUsingAPI videoRef={videoRef} isAPIReady={isAPIReady} />
    </div>
  );
}
