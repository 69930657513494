import axiosInstance from "../../../helpers/api";

export const getAllAdminSections = (body) => {
  return axiosInstance.get("/admin/sections", body);
};

export const editAdminSections = (formData) => {
  return axiosInstance.post(
    `/admin/sections/${formData.id}`,
    formData.formData
  );
};

export const createAdminSections = (formData) => {
  return axiosInstance.post("/admin/sections", formData);
};

export const deleteAdminSection = (id) => {
  return axiosInstance.delete(`/admin/sections/${id}`);
};
