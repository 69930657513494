import { Flex, Container, VStack, Box, Text, Image } from '@chakra-ui/react'
import React from 'react'
import ViewersCard from '../../../components/student/Cards/viewersCard'
import { RiGroupLine, RiHandCoinLine, RiFileTextLine } from 'react-icons/ri'
const DownloadAppComp = () => {
    return (

        <Container maxW={"container.xl"} my='60px'>
            <Flex alignItems={'center'} justifyContent='space-between'>
                <Flex flexDirection={'column'} >
                    <Text fontSize='32px' my='10px'
                        fontWeight='600' textTransform={'uppercase'} >
                        Download our app
                    </Text>
                    <VStack
                        spacing={4}
                        align='stretch'
                        color='main_1'
                    >
                        <Flex alignItems={'center'}>
                            <RiHandCoinLine />
                            <Text mx='5px'>Easy to Use</Text>
                        </Flex>
                        <Flex alignItems={'center'}>
                            <RiFileTextLine />
                            <Text mx='5px'>All lessons in one place</Text>
                        </Flex>
                        <Flex alignItems={'center'}>
                            <RiGroupLine />
                            <Text mx='5px'>Summaries and Exercises</Text>
                        </Flex>
                    </VStack>
                    <Flex w='200px' mt='20px' flexDirection={{ base: 'column', md: 'row' }}>
                        <Image src={require('../../../assets/googleapp.webp')} />
                        <Image src={require('../../../assets/applestore.webp')} mt={{ base: '10px', lg: '0' }} ml={{ base: '0', lg: '10px' }} />
                    </Flex>
                </Flex>
                <Box h='320px' position={'relative'} display={{ base: 'none', md: 'block' }}>
                    <Image src={require('../../../assets/phone.webp')} h='100%' />
                    <ViewersCard bgColor={'main_2'} width={'320px'}
                        right='-20px'
                        bottom='0'
                        size='sm' />
                </Box>
            </Flex>
        </Container>

    )
}

export default DownloadAppComp