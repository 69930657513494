import { toast } from 'react-toastify';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import * as actions from './Actions';
import * as api from './Api';
import Types from './Types';

function* getAllLessons({ payload }) {
    try {
        yield put(actions.addLessonLoading(true));
        const result = yield call(api.getAllLessons);
        yield put(actions.getAllLessonsSuccess(result.data));
    } catch (error) {
        const { response, message } = error;
        toast.error(error);
        yield put(actions.getAllLessonsFail());
    } finally {
        yield put(actions.addLessonLoading(false));
    }
}



//Edit Tables
function* editLesson({ payload }) {
    try {
        yield put(actions.addLessonLoading(true));
        const result = yield call(api.editLessons, payload);
        yield put(actions.editLessonSuccess(result.data));
        toast.success('Edit Successfully');
        payload?.action && payload?.action()
    } catch (error) {
        yield put(actions.editLessonFail());
    } finally {
        yield put(actions.addLessonLoading(false));
    }
}

// create
function* createLesson(formData) {
    try {
        yield put(actions.addLessonLoading(true));
        const result = yield call(api.createLessons, formData.payload);
        yield put(
            actions.createLessonSuccess({
                data: result.data
            })
        );
        toast.success('Created Successfully');
        formData?.action && formData?.action()
    } catch (error) {
        yield put(actions.createLessonFail());
    } finally {
        yield put(actions.addLessonLoading(false));
    }
}

function* deleteLesson({ payload }) {
    try {
        yield put(actions.addLessonLoading(true));
        const result = yield call(api.deleteLesson, payload);

        yield put(actions.deleteLessonSuccess(payload));

        toast.success('Deleted Successfully');
    } catch (error) {
        const { response, message } = error;
        toast.error(error.response);
        yield put(actions.deleteLessonFail());
    } finally {
        yield put(actions.addLessonLoading(false));
    }
}

export default function* LessonSaga() {
    yield takeLatest(Types.GET_LESSONS_REQUEST, getAllLessons);
    yield takeLatest(Types.EDIT_LESSON_REQUEST, editLesson);
    yield takeLatest(Types.CREATE_LESSON_REQUEST, createLesson);
    yield takeLatest(Types.DELETE_LESSON_REQUEST, deleteLesson);
}
