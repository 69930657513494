import Types from "./Types";

export const createVideoVouchersRequest = (body) => ({
  type: Types.CREATE_VIDEO_VOUCHERS_REQUEST,
});

export const createVideoVouchersSuccess = (data) => ({
  type: Types.CREATE_VIDEO_VOUCHERS_SUCCESS,
  payload: data,
});

export const createVideoVouchersFail = () => ({
  type: Types.CREATE_VIDEO_VOUCHERS_FAIL,
});

export const getAllVideosRequest = (body) => ({
  type: Types.GET_VIDEOS_REQUEST,
  payload: body,
});

export const getAllVideosSuccess = (data) => ({
  type: Types.GET_VIDEOS_SUCCESS,
  payload: data,
});

export const getAllVideosFail = () => ({
  type: Types.GET_VIDEOS_FAIL,
});

export const getPaymentSubjectRequest = (body) => ({
  type: Types.GET_PAYMENT_SUBJECT_REQUEST,
  payload: body,
});

export const getPaymentSubjectSuccess = (data) => ({
  type: Types.GET_PAYMENT_SUBJECT_SUCCESS,
  payload: data,
});

export const getPaymentSubjectFail = () => ({
  type: Types.GET_PAYMENT_SUBJECT_FAIL,
});

export const editVideoRequest = (formData, action) => ({
  type: Types.EDIT_VIDEO_REQUEST,
  payload: formData,
  action: action,
});

export const editVideoSuccess = (data) => ({
  type: Types.EDIT_VIDEO_SUCCESS,
  payload: data,
});

export const editVideoFail = () => ({
  type: Types.EDIT_VIDEO_FAIL,
});

export const suspendVideoRequest = (formData, action) => ({
  type: Types.SUSPEND_VIDEO_REQUEST,
  payload: formData,
  action: action,
});

export const suspendVideoSuccess = (data) => ({
  type: Types.SUSPEND_VIDEO_SUCCESS,
  payload: data,
});

export const suspendVideoFail = () => ({
  type: Types.SUSPEND_VIDEO_FAIL,
});

//LOADING
export const addVideoLoading = (isLoading) => ({
  type: Types.ADD_VIDEOS_LOADING,
  payload: isLoading,
});

// Create Video
export const createVideoRequest = (formData, action) => ({
  type: Types.CREATE_VIDEO_REQUEST,
  payload: formData,
  action: action,
});

export const createVideoSuccess = ({ data }) => ({
  type: Types.CREATE_VIDEO_SUCCESS,
  payload: {
    data,
  },
});

export const createVideoFail = () => ({
  type: Types.CREATE_VIDEO_FAIL,
});

export const deleteVideoRequest = (id) => ({
  type: Types.DELETE_VIDEO_REQUEST,
  payload: id,
});

export const deleteVideoSuccess = (id) => ({
  type: Types.DELETE_VIDEO_SUCCESS,
  payload: id,
});

export const deleteVideoFail = () => ({
  type: Types.DELETE_VIDEO_FAIL,
});

export const addVideoFilesRequest = (formData, action) => ({
  type: Types.ADD_VIDEO_FILES_REQUEST,
  payload: formData,
  action: action,
});

export const addVideoFilesSuccess = () => ({
  type: Types.ADD_VIDEO_FILES_SUCCESS,
});

export const addVideoFilesFail = () => ({
  type: Types.ADD_VIDEO_FILES_FAIL,
});

export const deleteVideoFilesRequest = (id) => ({
  type: Types.DELETE_VIDEO_FILES_REQUEST,
  payload: id,
});

export const deleteVideoFilesSuccess = () => ({
  type: Types.DELETE_VIDEO_FILES_SUCCESS,
});

export const deleteVideoFilesFail = () => ({
  type: Types.DELETE_VIDEO_FILES_FAIL,
});
