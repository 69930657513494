import {
  Box,
  Button,
  Flex,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { RiFileEditLine, RiForbid2Line, RiQrScan2Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import CTable from "../../../components/Table/table";
import Select from "react-select";
import {
  attendanceReportRequest,
  studentsPerGroupRequest,
} from "../../../modules/admin-modules/reports/Actions";
import { getAllLecturesRequest } from "../../../modules/admin-modules/lectures/Actions";
import Layout from "../../../components/dashboard/Layout";
import LogoutMenu from "../../../components/dashboard/Layout/logout-menu";
import dayjs from "dayjs";
import { CSVLink } from "react-csv";
import { getAllGroupsRequest } from "../../../modules/admin-modules/groups/Actions";

const GroupsReport = ({ setAdd, setSelectedData, searchTerm }) => {
  const dispatch = useDispatch();
  const [groupID, setGroupID] = useState("");

  useEffect(() => {
    if (groupID) dispatch(studentsPerGroupRequest(groupID));
  }, [groupID]);

  useEffect(() => {
    dispatch(getAllGroupsRequest());
  }, []);
  const groups = useSelector((state) => state.groups.groups);
  const groupsOptions = groups?.map((data) => {
    return { value: data.id, label: data.name };
  });

  const studentPerGroup = useSelector((state) => state.reports.studentPerGroup);

  const [offset, setPage] = useState(0);

  const [size, setPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [selected, setSelected] = useState({
    id: "",
  });
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      width: "100%",
      background: "#f5f5f5",
      color: "#690000",
      borderRadius: "16px",
    }),
    option: (styles) => ({ ...styles, color: "#690000" }),
  };

  const viewData = (data) => {
    setSelectedData(data);
    setSelected(data);
  };
  const headers = [
    { label: "name", key: "name" },
    { label: "group_name", key: "group_name" },
    { label: "phone", key: "phone" },
  ];
  const Data = studentPerGroup?.map((data, index) => {
    return {
      ...data,
      index: `#${index + 1}`,
      id: data?.id,
      name: data?.name,
      group_name: data?.group?.name,
      phone: data?.phone,
    };
  });
  const columns = [
    {
      Header: "رقم الطالب",
      accessor: "index",
    },
    {
      Header: "اسم الطالب",
      accessor: "name",
    },
    {
      Header: " المجموعة",
      accessor: "group_name",
    },

    {
      Header: " رقم  الطالب",
      accessor: "phone",
    },
  ];

  return (
    <Layout>
      <div style={{ minHeight: "400px" }}>
        <Flex
          w="100%"
          my="30px"
          justifyContent="space-between"
          alignItems="center"
        >
          <Text fontSize="24px" fontWeight="600" color="main_1">
            تقرير المجموعات
          </Text>

          <Flex w="60%" justifyContent="space-between" alignItems="center">
            <Box w="60%">
              <Select
                options={groupsOptions}
                isMulti={false}
                onChange={(data) => {
                  setGroupID(data?.value);
                }}
                styles={colourStyles}
                placeholder="Select Group"
              />
            </Box>
            <Box
              mx="10px"
              border="1px solid gray"
              borderRadius="8px"
              padding="10px"
            >
              <CSVLink
                headers={headers}
                data={Data?.length != 0 ? Data : []}
                filename={"attendence-report.csv"}
                className="btn btn-primary"
                target="_blank"
              >
                Download
              </CSVLink>
            </Box>
            <LogoutMenu />
          </Flex>
        </Flex>

        <CTable
          selectedData={viewData}
          Columns={columns}
          Data={Data}
          path={"openModel"}
          Actions
          Title={``}
          subTitle=""
          btnTitle=""
          placeHolder=""
          noSearchBar={true}
          noFilter={true}
          footerBtnTitle
          filterList
          addButton
          addButtonTitle=""
          setPage={setPage}
          setPerPage={setPerPage}
          currentpage={pageNumber}
          setPageNumber={setPageNumber}
          noSecondSearchBar={true}
          isLoading={false}
          perPage={size}
          totalPage={Math.ceil(10 / 50)}
        />
      </div>
    </Layout>
  );
};

export default GroupsReport;
