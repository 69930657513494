import React from "react";
import { Box, Heading, VStack } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { closeTagModel } from "../../../../modules/admin-modules/models/Actions";
import MainModel from "../../../../components/Models/main-model";
import { useForm } from "react-hook-form";
import MainInput from "../../../../components/Form/input";
import {
  createTagRequest,
  editTagRequest,
} from "../../../../modules/admin-modules/tags/Actions";

const AddTag = ({ isAdd, data, setSelectedData }) => {
  const openTag = useSelector((state) => state.model.isOpen24);
  const isLoading = useSelector((state) => state.tags.isLoading);

  const dispatch = useDispatch();
  console.log(data);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: data ? data : {},
    shouldUnregister: true,
  });

  const header = (
    <Box>
      <Heading textAlign="center" color="main_1" fontSize="24px">
        {isAdd ? "   اضافة علامة " : "تعديل علامة"}
      </Heading>
    </Box>
  );
  const onSubmit = (values) => {
    let formData = new FormData();
    formData.append("name", values?.name);

    const action = () => {
      dispatch(closeTagModel());
      setSelectedData({});
      reset();
    };
    if (isAdd) dispatch(createTagRequest(formData, action));
    else {
      formData.append("_method", "PUT");

      dispatch(
        editTagRequest({
          id: data?.id,
          formData: formData,
          action: action,
        })
      );
    }
  };

  const closeFunc = () => {
    dispatch(closeTagModel());
    setSelectedData({});
    reset();
  };
  return (
    <MainModel
      isLoading={isLoading}
      header={header}
      closeFunc={closeFunc}
      openVar={openTag}
      buttonTitle={" حفظ التغيرات"}
      buttonFunc={handleSubmit(onSubmit)}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack dir="rtl" w="100%" alignItems="flex-start" spacing={4}>
          <MainInput
            label="اسم العلامة"
            defaultValues={data?.name}
            error={errors?.name?.message}
            placeholder=" اكتب اسم العلامة"
            register={register}
            name="name"
          />
        </VStack>
      </form>
    </MainModel>
  );
};

export default AddTag;
