import { toast } from "react-toastify";
import { call, fork, put, takeLatest } from "redux-saga/effects";
import * as actions from "./Actions";
import * as api from "./Api";
import Types from "./Types";

function* getAllTags({ payload }) {
  try {
    yield put(actions.addTagLoading(true));
    const result = yield call(api.getAllTags);
    yield put(actions.getAllTagsSuccess(result.data));
  } catch (error) {
    const { response, message } = error;
    toast.error(error);
    yield put(actions.getAllTagsFail());
  } finally {
    yield put(actions.addTagLoading(false));
  }
}

//Edit Tables
function* editTag({ payload }) {
  console.log(payload);
  try {
    yield put(actions.addTagLoading(true));
    const result = yield call(api.editTags, payload);
    yield put(actions.editTagSuccess(result.data));
    toast.success("Edit Successfully");
    payload?.action && payload?.action();
  } catch (error) {
    yield put(actions.editTagFail());
  } finally {
    yield put(actions.addTagLoading(false));
  }
}
function* enableTag({ payload }) {
  try {
    yield put(actions.addTagLoading(true));
    const result = yield call(api.enableTags, payload);
    yield put(actions.enableTagSuccess(payload));
    toast.success("Edit Successfully");
    payload?.action && payload?.action();
  } catch (error) {
    yield put(actions.enableTagFail());
  } finally {
    yield put(actions.addTagLoading(false));
  }
}
// create
function* createTag(formData) {
  try {
    yield put(actions.addTagLoading(true));
    const result = yield call(api.createTags, formData.payload);
    yield put(
      actions.createTagSuccess({
        data: result.data,
      })
    );
    toast.success("Created Successfully");
    formData?.action && formData?.action();
  } catch (error) {
    yield put(actions.createTagFail());
  } finally {
    yield put(actions.addTagLoading(false));
  }
}

function* deleteTag({ payload }) {
  try {
    yield put(actions.addTagLoading(true));
    const result = yield call(api.deleteTag, payload);

    yield put(actions.deleteTagSuccess(payload));

    toast.success("Deleted Successfully");
  } catch (error) {
    const { response, message } = error;
    toast.error(error.response);
    yield put(actions.deleteTagFail());
  } finally {
    yield put(actions.addTagLoading(false));
  }
}

export default function* TagSaga() {
  yield takeLatest(Types.GET_TAGS_REQUEST, getAllTags);
  yield takeLatest(Types.EDIT_TAG_REQUEST, editTag);
  yield takeLatest(Types.ENABLE_TAG_REQUEST, enableTag);

  yield takeLatest(Types.CREATE_TAG_REQUEST, createTag);
  yield takeLatest(Types.DELETE_TAG_REQUEST, deleteTag);
}
