import { Button, Flex, Icon, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react'
import React from 'react'
import { RiLogoutBoxRLine, RiUserFill } from 'react-icons/ri'
import { useDispatch } from 'react-redux'
import { logoutUserAction } from '../../../modules/auth/Actions'
// import { logoutUserAction } from '../../modules/auth/Actions'

const LogoutMenu = () => {
    const dispatch = useDispatch()
    return (
        <Menu closeOnSelect={false}>
            <MenuButton
                bg='transparent'
                borderRadius='26px'
                as={Button}
                p='0'
                _hover={{ bg: 'transparent' }}
                _focus={{ outline: 'none', bg: 'transparent' }}
                _active={{ outline: 'none', bg: 'transparent' }}>
                <Flex
                    borderRadius='16px'
                    border='1px solid'
                    borderColor='main_1'
                    w='52px'
                    h='42px'
                    bg='white'
                    alignItems='flex-end'
                    justifyContent='center'>
                    <Icon as={RiUserFill} color='main_1' fontSize='30px' />

                </Flex>
            </MenuButton>
            <MenuList>

                <MenuItem p="7px" mt="10px" onClick={() => dispatch(logoutUserAction())}>
                    <Flex>
                        <Icon as={RiLogoutBoxRLine} fontSize="20px" />
                        <Text mx="5px" >
                            تسجيل خروج
                        </Text>
                    </Flex>
                </MenuItem>
            </MenuList>
        </Menu >
    )
}

export default LogoutMenu