import MainModel from "../../../components/Models/main-model";
import {
    Box,
    Flex,
    FormLabel,
    Heading,
    Stack,
    Textarea,
    Button,
    VStack,
    HStack,
} from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { closeRefuseHomeworkModel } from "../../../modules/admin-modules/models/Actions";
import { useState } from "react";
import { commentStuentHomeworkRequest } from "../../../modules/admin-modules/reports/Actions";
import { getAllHomeworksRequest } from "../../../modules/admin-modules/homework/Actions";
import { deleteStuentHomeworkRequest } from "../../../modules/admin-modules/reports/Actions";
const HomeworkRefuseComment = ({ data, homeworkId }) => {
    const baseUrl = "https://api.enoughphysics.com/public/homework/";

    const openHomeworkComment = useSelector((state) => state.model.isOpen32);
    const [comment, setComment] = useState("");
    const dispatch = useDispatch();
    const header = (
        <Box>
            <Heading textAlign="center" color="main_1" fontSize="24px"></Heading>
        </Box>
    );
    const handelAddComment = async () => {
        await dispatch(
            commentStuentHomeworkRequest({
                id: data?.id,
                body: {
                    homework_id: homeworkId,
                    student_id: data?.student?.id,
                    evaluation: comment,
                },
            })
        );
        setTimeout(() => {
            dispatch(deleteStuentHomeworkRequest(data?.student?.id, data?.homework?.id));
        }, 1500);
        dispatch(closeRefuseHomeworkModel());
    };
    const closeFunc = () => {
        dispatch(closeRefuseHomeworkModel());
    };

    const setEvalation = (evalation) => () => {
        setComment(evalation);
        // set the text area value to the evalation
        document.getElementById("homework").value = evalation;
    };
    return (
        <MainModel
            isLoading={false}
            header={header}
            closeFunc={closeFunc}
            openVar={openHomeworkComment}
            buttonTitle={"حفظ التغيرات"}
            buttonFunc={handelAddComment}
        >
            <VStack dir="rtl">
                <HStack w="100%">
                    <FormLabel> الطالب : {data?.student?.name} </FormLabel>
                    <FormLabel> الواجب : {data?.homework?.name} </FormLabel>
                    <Button
                        as="a"
                        href={`https://docs.google.com/gview?url=${baseUrl}${data?.file}`}
                        target="_blank"
                    >
                        الحل
                    </Button>
                </HStack>

                <HStack w="100%">
                    <Button onClick={setEvalation("Your homework is refused due to missing steps, please complete your steps and upload it again")}>
                        الخطوات ناقصة
                    </Button>
                    <Button onClick={setEvalation("Your homework is refused because there are many wrong questions, please review your HW using the HW Video and upload it again")}>
                        الحل غير صحيح
                    </Button>
                    <Button onClick={setEvalation("Your homework is refused due to missing questions, please complete your HW and upload it again")}>
                        الحل غير مكتمل
                    </Button>
                    <Button onClick={setEvalation("Your homework is refused because you uploaded the wrong HW PDF, please upload the correct HW PDF")}>
                        الملف الخاطئ
                    </Button>
                </HStack>
                <Box w="100%">
                    <FormLabel> الملاحظات : </FormLabel>
                    <Textarea
                        bg="#f5f5f5"
                        placeholder="اكتب الملاحظات"
                        onChange={(e) => setComment(e.target.value)}
                        name="homework"
                        id="homework"
                    ></Textarea>
                </Box>
            </VStack>
        </MainModel>
    );
};

export default HomeworkRefuseComment;
