import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserDataRequest } from '../../modules/user/Actions'


const StudentWrapper = ({ children, roles }) => {
    const dispatch = useDispatch()
    const data = useSelector((state) => state.user.userData)
    const token = window.localStorage.getItem('token')


    useEffect(() => {
        if (!token || (data?.role && !roles.includes(data?.role[0].name))) {
            window.localStorage.clear()

            window.location.href = '/login'
        }

    }, [roles, data, token])
    return (
        <div >{children}</div>
    )
}

export default StudentWrapper
