import React, { useState } from "react";
import { Container, Grid, Box } from "@chakra-ui/react";
import RegisterCard from "../../../components/student/Cards/registerCard";

const RegisterComp = () => {
  const [registerData, setRegisterData] = useState(
    require("../../../staticData/register.json")
  );
  return (
    <Box bg='main_2' py='40px' id='register'>
      <Container maxW={"container.xl"}>
        <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }} gap="50px">
          {registerData?.map((data, index) => {
            return (
              <RegisterCard
                key={index}
                imageUrl={data?.imageUrl}
                background={data?.background}
                title={data?.title}
                step={data?.step}
                description={data?.description}
              />
            );
          })}
        </Grid>
      </Container>
    </Box>
  );
};

export default RegisterComp;
