import axiosInstance from "../../../helpers/api";

export const createVideoVouchers = () => {
  return axiosInstance.post("/admin/video_vouchers");
};

export const getAllVideos = (body) => {
  return axiosInstance.get("/admin/videos", body);
};

export const getPaymentSubjects = (body) => {
  return axiosInstance.get("/admin/payment_subject", body);
};

export const editVideos = (formData) => {
  return axiosInstance.post(`/admin/videos/${formData.id}`, formData.formData);
};

export const suspendVideos = (formData) => {
  return axiosInstance.post(
    `/admin/videos/suspend/${formData.id}`,
    formData.formData
  );
};

export const createVideo = (formData) => {
  return axiosInstance.post("/admin/videos", formData);
};

export const deleteVideo = (id) => {
  return axiosInstance.delete(`/admin/videos/${id}`);
};

export const deleteVideoFile = (id) => {
  return axiosInstance.delete(`/admin/video_files/${id}`);
};

export const addVideoFiles = (formData) => {
  return axiosInstance.post(`/admin/video_files`, formData);
};
