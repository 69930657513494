export default {
  //get all VIDEOs

  GET_ALL_EXAMS_REQUEST: "GET_ALL_EXAMS_REQUEST",
  GET_ALL_EXAMS_SUCCESS: "GET_ALL_EXAMS_SUCCESS",
  GET_ALL_EXAMS_FAIL: "GET_ALL_EXAMS_FAIL",

  GET_EXAMS_BY_CHAPTER_ID_REQUEST: "GET_EXAMS_BY_CHAPTER_ID_REQUEST",
  GET_EXAMS_BY_CHAPTER_ID_SUCCESS: "GET_EXAMS_BY_CHAPTER_ID_SUCCESS",
  GET_EXAMS_BY_CHAPTER_ID_FAIL: "GET_EXAMS_BY_CHAPTER_ID_FAIL",

  GET_EXAMS_BY_ID_REQUEST: "GET_EXAMS_BY_ID_REQUEST",
  GET_EXAMS_BY_ID_SUCCESS: "GET_EXAMS_BY_ID_SUCCESS",
  GET_EXAMS_BY_ID_FAIL: "GET_EXAMS_BY_ID_FAIL",

  SEND_ANSWERS_REQUEST: "SEND_ANSWERS_REQUEST",
  SEND_ANSWERS_SUCCESS: "SEND_ANSWERS_SUCCESS",
  SEND_ANSWERS_FAIL: "SEND_ANSWERS_FAIL",

  GET_EXAM_QUESTIONS_REQUEST: "GET_EXAM_QUESTIONS_REQUEST",
  GET_EXAM_QUESTIONS_SUCCESS: "GET_EXAM_QUESTIONS_SUCCESS",
  GET_EXAM_QUESTIONS_FAIL: "GET_EXAM_QUESTIONS_FAIL",

  GET_EXAM_CORRECTION_REQUEST: "GET_EXAM_CORRECTION_REQUEST",
  GET_EXAM_CORRECTION_SUCCESS: "GET_EXAM_CORRECTION_SUCCESS",
  GET_EXAM_CORRECTION_FAIL: "GET_EXAM_CORRECTION_FAIL",

  EXAM_REDO_REQUEST: "EXAM_REDO_REQUEST",
  EXAM_REDO_SUCCESS: "EXAM_REDO_SUCCESS",
  EXAM_REDO_FAIL: "EXAM_REDO_FAIL",

  GET_CHAPTER_EXAM_REQUEST: "GET_CHAPTER_EXAM_REQUEST",
  GET_CHAPTER_EXAM_SUCCESS: "GET_CHAPTER_EXAM_SUCCESS",
  GET_CHAPTER_EXAM_FAIL: "GET_CHAPTER_EXAM_FAIL",

  GET_CHAPTER_EXAM_DETAILS_REQUEST: "GET_CHAPTER_EXAM_DETAILS_REQUEST",
  GET_CHAPTER_EXAM_DETAILS_SUCCESS: "GET_CHAPTER_EXAM_DETAILS_SUCCESS",
  GET_CHAPTER_EXAM_DETAILS_FAIL: "GET_CHAPTER_EXAM_DETAILS_FAIL",

  SEND_CHAPTER_EXAM_ANSWERS_REQUEST: "SEND_CHAPTER_EXAM_ANSWERS_REQUEST",
  SEND_CHAPTER_EXAM_ANSWERS_SUCCESS: "SEND_CHAPTER_EXAM_ANSWERS_SUCCESS",
  SEND_CHAPTER_EXAM_ANSWERS_FAIL: "SEND_CHAPTER_EXAM_ANSWERS_FAIL",
  //loading
  ADD_EXAMS_LOADING: "ADD_EXAMS_LOADING",
  SEND_ANSWERS_LOADING: "SEND_ANSWERS_LOADING",
};
