import Types from "./Types";

const INITIAL_STATE = {
  videos: [],
  video: {},
  paymentSubjects: [],
  isLoading: false,
  count: "",
};

export default function videos(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.CREATE_VIDEO_VOUCHERS_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.GET_VIDEOS_SUCCESS: {
      return {
        ...state,
        videos: payload.data,
        count: payload.data.length,
      };
    }

    case Types.GET_PAYMENT_SUBJECT_SUCCESS: {
      return {
        ...state,
        paymentSubjects: payload.data,
      };
    }

    case Types.GET_VIDEO_SUCCESS: {
      return {
        ...state,
        video: payload,
      };
    }

    case Types.EDIT_VIDEO_SUCCESS: {
      let data = payload.data;
      const videos = state.videos;
      let idx = 0;
      videos.forEach((table, index) => {
        if (table.id === data.id) {
          idx = index;
          return;
        }
      });

      videos[idx] = data;
      return {
        ...state,
        videos: videos,
      };
    }

    case Types.SUSPEND_VIDEO_SUCCESS: {
      let data = payload.data;
      const videos = state.videos;
      let idx = 0;
      videos.forEach((table, index) => {
        if (table.id === data.id) {
          idx = index;
          return;
        }
      });

      videos[idx] = data;
      return {
        ...state,
        videos: videos,
      };
    }

    // create
    case Types.CREATE_VIDEO_SUCCESS: {
      const data = payload.data.data;
      let newArray = state.videos.slice();
      let index = state.videos.length + 1;
      newArray.splice(index, 0, data);
      return {
        ...state,
        videos: newArray,
      };
    }

    // create
    case Types.ADD_VIDEO_FILES_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.ADD_VIDEOS_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }

    case Types.DELETE_VIDEO_SUCCESS: {
      const newvideos = state.videos.filter((item) => item.id !== payload);
      return {
        ...state,
        videos: newvideos,
      };
    }

    case Types.DELETE_VIDEO_FILES_SUCCESS: {
      return {
        ...state,
      };
    }
    default: {
      return state;
    }
  }
}
