import React, { useEffect } from 'react'
import { Box, Flex, FormLabel, Heading, Icon, Input, Text, Textarea } from '@chakra-ui/react'
import { useDispatch, useSelector } from 'react-redux'
import MainModel from '../../../components/Models/main-model'
import { closeVoucherModel } from '../../../modules/admin-modules/models/Actions'
import { RiCheckboxCircleLine } from 'react-icons/ri'



const AddVoucherSuccess = () => {

    const dispatch = useDispatch()
    const isOpen = useSelector((state) => state.model.isOpen20)


    const header = (
        <Box>
            <Heading textAlign='center' color='main_1' fontSize='24px'>
                Voucher Successfuly added
            </Heading>
        </Box>
    )

    const closeFunc = () => {
        dispatch(closeVoucherModel())
    }
    return (
        <MainModel isLoading={false} header={header} closeFunc={closeFunc} openVar={isOpen} buttonTitle={''} buttonFunc={() => { }} >

            <Flex alignItems='center' justifyContent='center' flexDirection='column'>
                <RiCheckboxCircleLine fontSize='80px' color='green' />
                <Text fontSize='40px'>Please Login Now </Text>
            </Flex>

        </MainModel >
    )
}

export default AddVoucherSuccess