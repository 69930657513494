import { toast } from "react-toastify";
import { call, fork, put, takeLatest } from "redux-saga/effects";
import * as actions from "./Actions";
import * as api from "./Api";
import Types from "./Types";

function* getAllSections({ payload }) {
  try {
    yield put(actions.sectionsLoading(true));
    const result = yield call(api.getAllSections);
    yield put(actions.getAllSectionsSuccess(result.data));
  } catch (error) {
    toast.error(error);
    yield put(actions.getAllSectionsFail());
  } finally {
    yield put(actions.sectionsLoading(false));
  }
}
function* getSection({ payload }) {
  try {
    yield put(actions.sectionsLoading(true));
    const result = yield call(api.getSection, payload);
    yield put(actions.getSectionSuccess(result.data));
  } catch (error) {
    const { response, message } = error;
    toast.error(error);
    //console.log(error, 'error');
    yield put(actions.getSectionFail());
  } finally {
    yield put(actions.sectionsLoading(false));
  }
}
export default function* SectionsSaga() {
  yield takeLatest(Types.GET_SECTIONS_REQUEST, getAllSections);
  yield takeLatest(Types.GET_SECTION_REQUEST, getSection);
}
