import { toast } from "react-toastify";
import { call, fork, put, takeLatest } from "redux-saga/effects";
import * as actions from "./Actions";
import * as api from "./Api";
import Types from "./Types";

function* getAllExams({ payload }) {
  try {
    yield put(actions.addExamLoading(true));
    const result = yield call(api.getAllExams);
    yield put(actions.getAllExamsSuccess(result.data));
  } catch (error) {
    const { response, message } = error;
    toast.error(error);
    yield put(actions.getAllExamsFail());
  } finally {
    yield put(actions.addExamLoading(false));
  }
}

function* getAllExamQuestions({ payload }) {
  try {
    yield put(actions.addExamLoading(true));
    const result = yield call(api.getAllExamQuestions, payload);
    yield put(actions.getAllExamQuestionsSuccess(result.data));
  } catch (error) {
    const { response, message } = error;
    toast.error(error);
    yield put(actions.getAllExamQuestionsFail());
  } finally {
    yield put(actions.addExamLoading(false));
  }
}

function* getAllExamsSuperAdmin({ payload }) {
  try {
    yield put(actions.addExamLoading(true));
    const result = yield call(api.getAllExamsSuperAdmin);
    yield put(actions.getAllExamsSuperAdminSuccess(result.data));
  } catch (error) {
    const { response, message } = error;
    toast.error(error);
    yield put(actions.getAllExamsSuperAdminFail());
  } finally {
    yield put(actions.addExamLoading(false));
  }
}

function* getStudentExams({ payload }) {
  try {
    yield put(actions.addExamLoading(true));
    const result = yield call(api.getStudentExams);
    yield put(actions.getStudentExamsSuccess(result.data));
  } catch (error) {
    const { response, message } = error;
    toast.error(error);
    yield put(actions.getStudentExamsFail());
  } finally {
    yield put(actions.addExamLoading(false));
  }
}

//Edit Tables
function* editExam({ payload }) {
  try {
    yield put(actions.addExamLoading(true));
    const result = yield call(api.editExams, payload);
    yield put(actions.editExamSuccess(result.data));
    toast.success("Edit Successfully");
    payload?.action && payload?.action();
  } catch (error) {
    yield put(actions.editExamFail());
  } finally {
    yield put(actions.addExamLoading(false));
  }
}

// create
function* createExam(formData) {
  try {
    yield put(actions.addExamLoading(true));
    const result = yield call(api.createExams, formData.payload);
    yield put(
      actions.createExamSuccess({
        data: result.data,
      })
    );
    toast.success("Created Successfully");
    formData?.action && formData?.action(result?.data?.data);
  } catch (error) {
    yield put(actions.createExamFail());
    toast.success(error?.data?.message.toString());
  } finally {
    yield put(actions.addExamLoading(false));
  }
}

function* createExamQuestions(formData) {
  try {
    yield put(actions.addExamLoading(true));
    const result = yield call(api.createExamQuestions, formData.payload);
    yield put(
      actions.createExamQuestionsSuccess({
        data: result.data,
      })
    );
    toast.success("Created Successfully");
    formData?.action && formData?.action(result?.data?.data);
  } catch (error) {
    yield put(actions.createExamQuestionsFail());
    toast.success(error?.data?.message.toString());
  } finally {
    yield put(actions.addExamLoading(false));
  }
}
function* deleteExam({ payload }) {
  try {
    yield put(actions.addExamLoading(true));
    const result = yield call(api.deleteExam, payload);

    yield put(actions.deleteExamSuccess(payload));

    toast.success("Deleted Successfully");
  } catch (error) {
    const { response, message } = error;
    toast.error(error.response);
    yield put(actions.deleteExamFail());
  } finally {
    yield put(actions.addExamLoading(false));
  }
}

function* publishExam({ payload }) {
  try {
    yield put(actions.publishExamLoading(true));
    const result = yield call(api.publisExam, payload);
    yield put(actions.publishExamSuccess({ payload, result }));
  } catch (error) {
    const { response, message } = error;
    toast.error(error.response);
    yield put(actions.publishExamFail());
  } finally {
    yield put(actions.publishExamLoading(false));
  }
}

function* restrictExam({ payload }) {
  try {
    yield put(actions.restrictExamLoading(true));
    const result = yield call(api.restrictExam, payload);
    yield put(actions.restrictExamSuccess({ payload, result }));
  } catch (error) {
    const { response, message } = error;
    toast.error(error.response);
    yield put(actions.restrictExamFail());
  } finally {
    yield put(actions.restrictExamLoading(false));
  }
}

function* enableExam({ payload }) {
  try {
    yield put(actions.enableExamLoading(true));
    const result = yield call(api.enableExam, payload);
    yield put(actions.enableExamSuccess({ payload, result }));
  } catch (error) {
    const { response, message } = error;
    toast.error(error.response);
    yield put(actions.enableExamFail());
  } finally {
    yield put(actions.enableExamLoading(false));
  }
}

function* editExamQuestion({ payload }) {
  try {
    yield put(actions.addExamLoading(true));
    const result = yield call(api.editExamQuestion, payload);
    yield put(actions.editExamQuestionSuccess(result.data));
    toast.success("Edit Successfully");
    payload?.action && payload?.action();
  } catch (error) {
    yield put(actions.editExamQuestionFail());
  } finally {
    yield put(actions.addExamLoading(false));
  }
}

function* addBulkCorrection({ payload }) {
    try {
        yield put(actions.addExamLoading(true));
        const result = yield call(api.addBulkCorrection, payload);
        yield put(actions.addBulkCorrectionSuccess(payload));
        toast.success('Students Added Successfully');
    } catch (error) {
        const { response, message } = error;
        toast.error('Something Wrong');
        yield put(actions.addBulkCorrectionFail());
    } finally {
        yield put(actions.addExamLoading(false));
    }
}
export default function* ExamSaga() {
  yield takeLatest(Types.GET_EXAMS_REQUEST, getAllExams);
  yield takeLatest(Types.GET_ALL_EXAM_QUESTIONS_REQUEST, getAllExamQuestions);
  yield takeLatest(Types.GET_EXAMS_SUPER_ADMIN_REQUEST, getAllExamsSuperAdmin);
  yield takeLatest(Types.GET_ADMIN_STUDENT_EXAMS_REQUEST, getStudentExams);
  yield takeLatest(Types.EDIT_EXAM_REQUEST, editExam);
  yield takeLatest(Types.CREATE_EXAM_REQUEST, createExam);
  yield takeLatest(Types.DELETE_EXAM_REQUEST, deleteExam);
  yield takeLatest(Types.PUBLISH_EXAM_REQUEST, publishExam);
  yield takeLatest(Types.RESTRICT_EXAM_REQUEST, restrictExam);
  yield takeLatest(Types.ENABLE_EXAM_REQUEST, enableExam);
  yield takeLatest(Types.EDIT_EXAM_QUESTION_REQUEST, editExamQuestion);
  yield takeLatest(Types.CREATE_EXAM_QUESTIONS_REQUEST, createExamQuestions);
  yield takeLatest(Types.ADD_BULK_CORRECTION_REQUEST, addBulkCorrection);

  
}
