import { Button, IconButton, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { RiCheckLine, RiDeleteBin6Line, RiDeleteBinLine, RiFileEditLine } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux';
import CTable from '../../../components/Table/table';
import { deleteRedoRequestRequest, editRedoRequestRequest } from '../../../modules/admin-modules/redo-requests/Actions';

const RedoRequestTable = ({ setAdd, setSelectedData }) => {
    const redoRequests = useSelector((state) => state.redoRequests.redoRequests)
    const dispatch = useDispatch()
    const isLoading = useSelector((state) => state.redoRequests.isLoading)
    const [offset, setPage] = useState(0);
    const [size, setPerPage] = useState(10);
    const [pageNumber, setPageNumber] = useState(0);
    const [selected, setSelected] = useState({
        id: ''
    })
    const viewData = (data) => {
        setSelectedData(data);
        setSelected(data)
    };

    const Data =
        redoRequests?.map((data) => {
            return {
                ...data,
                id: data?.id,
                exam_name: data?.exam?.name,
                student_name: data?.student?.name,
                chapter_id: data?.chapter?.id,

                // edit: (
                //     <IconButton
                //         variant='outline'
                //         border='0'
                //         color='green.600'
                //         aria-label='Call Sage'
                //         fontSize='20px'
                //         icon={<RiCheckLine />}
                //         onClick={async () => {
                //             let formData = new FormData()
                //             formData.append('_method', 'PUT')
                //             formData.append('video_id', data?.video?.id)

                //             await dispatch(editRedoRequestRequest(data?.id))
                //         }}
                //     />


                delete: (
                    <IconButton
                        variant='outline'
                        border='0'
                        color='gray.600'
                        aria-label='Call Sage'
                        fontSize='20px'
                        icon={<RiDeleteBinLine color='red' />}
                        onClick={() => {

                            dispatch(deleteRedoRequestRequest(data?.id))
                        }}
                    />

                )
            };
        })
    const columns = [
        {
            Header: ' اسم الامتحان',
            accessor: 'exam_name'
        },

        {
            Header: '  اسم الطالب',
            accessor: 'student_name'
        },


        {
            Header: 'حذف',
            accessor: 'delete'
        },

    ];

    return (
        <CTable
            selectedData={viewData}
            Columns={columns}
            Data={Data}
            path={'openModel'}
            Actions
            Title={``}
            subTitle=''
            btnTitle=""
            placeHolder=""
            noSearchBar={true}
            noFilter={true}
            footerBtnTitle
            filterList
            addButton
            addButtonTitle=""
            setPage={setPage}
            setPerPage={setPerPage}
            currentpage={pageNumber}
            setPageNumber={setPageNumber}
            noSecondSearchBar={true}
            isLoading={isLoading}
            perPage={size}
            totalPage={Math.ceil(redoRequests?.length / 10)}

        />
    )
}

export default RedoRequestTable