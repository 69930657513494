import { toast } from "react-toastify";
import { call, fork, put, takeLatest } from "redux-saga/effects";
import * as actions from "./Actions";
import * as api from "./Api";
import Types from "./Types";

function* createVideoVoucher({ payload }) {
  try {
    yield put(actions.addVideoLoading(true));
    const result = yield call(api.createVideoVouchers);
    yield put(actions.createVideoVouchersSuccess(result.data));
  } catch (error) {
    const { response, message } = error;
    yield put(actions.createVideoVouchersFail());
  } finally {
    yield put(actions.addVideoLoading(false));
  }
}

function* getAllVideos({ payload }) {
  try {
    yield put(actions.addVideoLoading(true));
    const result = yield call(api.getAllVideos);
    yield put(actions.getAllVideosSuccess(result.data));
  } catch (error) {
    const { response, message } = error;
    toast.error(error);
    //console.log(error, 'error');
    yield put(actions.getAllVideosFail());
  } finally {
    yield put(actions.addVideoLoading(false));
  }
}

function* getPaymentSubject({ payload }) {
  try {
    yield put(actions.addVideoLoading(true));
    const result = yield call(api.getPaymentSubjects);
    yield put(actions.getPaymentSubjectSuccess(result.data));
  } catch (error) {
    const { response, message } = error;
    toast.error(error);
    //console.log(error, 'error');
    yield put(actions.getPaymentSubjectFail());
  } finally {
    yield put(actions.addVideoLoading(false));
  }
}

//Edit Tables
function* editVideo({ payload }) {
  try {
    yield put(actions.addVideoLoading(true));
    const result = yield call(api.editVideos, payload);
    yield put(actions.editVideoSuccess(result.data));
    toast.success("Edit Successfully");
    payload?.action && payload?.action();
  } catch (error) {
    yield put(actions.editVideoFail());
  } finally {
    yield put(actions.addVideoLoading(false));
  }
}

function* suspendVideo({ payload }) {
  try {
    yield put(actions.addVideoLoading(true));
    const result = yield call(api.suspendVideos, payload);
    yield put(actions.suspendVideoSuccess(result.data));
  } catch (error) {
    yield put(actions.suspendVideoFail());
  } finally {
    yield put(actions.addVideoLoading(false));
  }
}

// create
function* createVideo(formData) {
  try {
    yield put(actions.addVideoLoading(true));
    const result = yield call(api.createVideo, formData.payload);
    yield put(
      actions.createVideoSuccess({
        data: result.data,
      })
    );
    toast.success("Created Successfully");
    formData?.action && formData?.action(1);
  } catch (error) {
    yield put(actions.createVideoFail());
  } finally {
    yield put(actions.addVideoLoading(false));
  }
}

function* addVideoFiles(formData) {
  try {
    yield put(actions.addVideoLoading(true));
    const result = yield call(api.addVideoFiles, formData.payload);
    yield put(
      actions.addVideoFilesSuccess({
        data: result.data,
      })
    );
    toast.success("Created Successfully");
    formData?.action && formData?.action(result?.data?.data?.id);
  } catch (error) {
    toast.success("Created Successfully");
    yield put(actions.addVideoFilesFail());
  } finally {
    yield put(actions.addVideoLoading(false));
  }
}

function* deleteVideoFile({ payload }) {
  try {
    yield put(actions.addVideoLoading(true));
    const result = yield call(api.deleteVideoFile, payload);

    yield put(actions.deleteVideoFilesSuccess(payload));

    toast.success("Deleted Successfully");
  } catch (error) {
    const { response, message } = error;
    toast.error(error.response);
    yield put(actions.deleteVideoFilesFail());
  } finally {
    yield put(actions.addVideoLoading(false));
  }
}

function* deleteVideo({ payload }) {
  try {
    yield put(actions.addVideoLoading(true));
    const result = yield call(api.deleteVideo, payload);

    yield put(actions.deleteVideoSuccess(payload));

    toast.success("Deleted Successfully");
  } catch (error) {
    const { response, message } = error;
    toast.error(error.response);
    yield put(actions.deleteVideoFail());
  } finally {
    yield put(actions.addVideoLoading(false));
  }
}

export default function* VideoSaga() {
  yield takeLatest(Types.GET_VIDEOS_REQUEST, getAllVideos);
  yield takeLatest(Types.CREATE_VIDEO_VOUCHERS_REQUEST, createVideoVoucher);

  yield takeLatest(Types.EDIT_VIDEO_REQUEST, editVideo);
  yield takeLatest(Types.SUSPEND_VIDEO_REQUEST, suspendVideo);
  yield takeLatest(Types.CREATE_VIDEO_REQUEST, createVideo);
  yield takeLatest(Types.ADD_VIDEO_FILES_REQUEST, addVideoFiles);
  yield takeLatest(Types.DELETE_VIDEO_FILES_REQUEST, deleteVideoFile);
  yield takeLatest(Types.DELETE_VIDEO_REQUEST, deleteVideo);
  yield takeLatest(Types.GET_PAYMENT_SUBJECT_REQUEST, getPaymentSubject);
}
