import Types from './Types';


export const getAllRequestsRequest = (body) => ({
    type: Types.GET_REQUESTS_REQUEST,
    payload: body
});

export const getAllRequestsSuccess = (data) => ({
    type: Types.GET_REQUESTS_SUCCESS,
    payload: data
});

export const getAllRequestsFail = () => ({
    type: Types.GET_REQUESTS_FAIL
});


export const editRequestRequest = (formData, action) => ({
    type: Types.EDIT_REQUEST_REQUEST,
    payload: formData,
    action: action
});

export const editRequestSuccess = (data) => ({
    type: Types.EDIT_REQUEST_SUCCESS,
    payload: data
});

export const editRequestFail = () => ({
    type: Types.EDIT_REQUEST_FAIL
});


export const acceptRequestWithoutVoucherRequest = (id, action) => ({
    type: Types.ACCEPT_REQUEST_WITHOUT_VOUCHER_REQUEST,
    payload: id,
    action: action
});

export const acceptRequestWithoutVoucherSuccess = (data) => ({
    type: Types.ACCEPT_REQUEST_WITHOUT_VOUCHER_SUCCESS,
    payload: data
});

export const acceptRequestWithoutVoucherFail = () => ({
    type: Types.ACCEPT_REQUEST_WITHOUT_VOUCHER_FAIL
});

//LOADING
export const addRequestLoading = (isLoading) => ({
    type: Types.ADD_REQUESTS_LOADING,
    payload: isLoading
});



export const deleteRequestRequest = (id) => ({
    type: Types.DELETE_REQUEST_REQUEST,
    payload: id
});

export const deleteRequestSuccess = (id) => ({
    type: Types.DELETE_REQUEST_SUCCESS,
    payload: id
});

export const deleteRequestFail = () => ({
    type: Types.DELETE_REQUEST_FAIL
});
