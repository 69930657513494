import { IconButton, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react'
import { MdWarningAmber } from 'react-icons/md';
import { RiDeleteBinLine, RiFileEditLine } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux';
import CTable from '../../../components/Table/table'
import { deleteEventRequest } from '../../../modules/admin-modules/events/Actions';
import { openCreateEvent } from '../../../modules/admin-modules/models/Actions';

const EventsTable = ({ setAdd, setSelectedData }) => {
    const events = useSelector((state) => state.events.events)
    const dispatch = useDispatch()
    const isLoading = useSelector((state) => state.events.isLoading)
    const [offset, setPage] = useState(0);
    const [size, setPerPage] = useState(10);
    const [pageNumber, setPageNumber] = useState(0);
    const [selected, setSelected] = useState({
        id: ''
    })
    const viewData = (data) => {
        setSelectedData(data);
        setSelected(data)
    };
    const Data =
        events?.map((data) => {
            return {
                ...data,
                id: data?.id,
                title: data?.title,
                date_val: (<Text>{dayjs(data?.date).format('D , MMM ,YYYY')}</Text>),
                edit: (
                    <IconButton
                        variant='outline'
                        border='0'
                        color='gray.600'
                        aria-label='Call Sage'
                        fontSize='20px'
                        icon={<RiFileEditLine />}
                        onClick={() => {
                            setAdd(false);
                            dispatch(openCreateEvent())
                        }}
                    />
                ),
                delete: (<IconButton
                    variant='outline'
                    border='0'
                    color='gray.600'
                    aria-label='Call Sage'
                    fontSize='20px'
                    icon={<RiDeleteBinLine color='red' />}
                    onClick={() => dispatch(deleteEventRequest(data?.id))}
                />)
            };
        })
    const columns = [
        {
            Header: ' الحدث',
            accessor: 'title'
        },
        {
            Header: 'الصف',
            accessor: 'price'
        },
        {
            Header: '  التاريخ',
            accessor: 'date_val'
        },


        {
            Header: 'تعديل',
            accessor: 'edit'
        },
        {
            Header: 'حذف',
            accessor: 'delete'
        }
    ];


    return (
        <CTable
            selectedData={viewData}
            Columns={columns}
            Data={Data}
            path={'openModel'}
            Actions
            Title={``}
            subTitle=''
            btnTitle=""
            placeHolder=""
            noSearchBar={true}
            noFilter={true}
            footerBtnTitle
            filterList
            addButton
            addButtonTitle=""
            setPage={setPage}
            setPerPage={setPerPage}
            currentpage={pageNumber}
            setPageNumber={setPageNumber}
            noSecondSearchBar={true}
            isLoading={isLoading}
            perPage={size}
            totalPage={Math.ceil(events?.length / 10)}

        />
    )
}

export default EventsTable