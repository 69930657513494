import React, { useState } from "react";
import { useSelector } from "react-redux";
import Layout from "../../../components/dashboard/Layout";
import AddStudent from "../../../containers/admin/students/models/add-student";
import ShowPassword from "../../../containers/admin/students/models/show-password";
import StudentReport from "../../../containers/admin/students/models/student-report";
import UserQRCode from "../../../containers/admin/students/models/user-qr-code";
import SearchBar from "../../../containers/admin/students/searchbar";
import StudentsTable from "../../../containers/admin/students/table";
import StudentLessonsReport from "../../../containers/admin/students/models/student-lessons-report";

const Students = () => {
  const model = useSelector((state) => state.model.isOpen18);
  const lessonsModel = useSelector((state) => state.model.isOpen31);

  const [addedStudent, setAddedStudent] = useState({});
  const [isAdd, setAdd] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [searchTerm, setSearchTerm] = useState("");

  return (
    <Layout>
      <SearchBar
        setAdd={setAdd}
        setSelectedData={setSelectedData}
        setSearchTerm={setSearchTerm}
      />
      <StudentsTable
        setAdd={setAdd}
        setSelectedData={setSelectedData}
        searchTerm={searchTerm}
      />
      <AddStudent
        isAdd={isAdd}
        data={selectedData}
        setAddedStudent={setAddedStudent}
      />
      <UserQRCode data={selectedData} setSelectedData={setSelectedData} />
      <ShowPassword data={addedStudent} />
      {model && <StudentReport student_id={selectedData?.id} />}
      {lessonsModel && <StudentLessonsReport student_id={selectedData?.id} />}
    </Layout>
  );
};

export default Students;
