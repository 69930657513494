import { Flex, HStack, Table, TableContainer, Tbody, Td, Text, Th, Thead, Tr, VStack } from '@chakra-ui/react'
import React from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import Layout from '../../../components/dashboard/Layout'
import LogoutMenu from '../../../components/dashboard/Layout/logout-menu'
import Loading from '../../../components/UI/loading'
import { getStudentExamsRequest } from '../../../modules/admin-modules/exams/Actions'

const StudentExamsList = () => {
    const dispatch = useDispatch()
    const exams = useSelector((state) => state.exams.studentExams)
    const isLoading = useSelector((state) => state.exams.isLoading)

    useEffect(() => {
        dispatch(getStudentExamsRequest())
    }, [])
    return (
        <Layout>
            {
                isLoading ? <Loading /> :
                    <VStack p='20px' spacing={'40px'}>
                        <Flex alignItems='center' w='100%' justifyContent='space-between'>
                            <Text fontSize='24px'
                                fontWeight='600' color='main_1'>
                                الاختبارات
                            </Text>

                            <HStack spacing='7px' >



                                <LogoutMenu />
                            </HStack>


                        </Flex>

                        <TableContainer w='100%'>
                            <Table >
                                <Thead bg='main_1_light' color='white'>
                                    <Tr>
                                        <Th># رقم الاختبار</Th>
                                        <Th>اسم الاختبار</Th>
                                        <Th>عدد الاسئلة</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {
                                        exams?.map((exam, idx) => {
                                            return <Tr key={idx}>
                                                <Td>{idx + 1}</Td>
                                                <Td>{exam?.name}</Td>
                                                <Td>{exam?.question_number}</Td>
                                            </Tr>
                                        })
                                    }
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </VStack>
            }

        </Layout>

    )
}

export default StudentExamsList