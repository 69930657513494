import { Box, Button, Flex, HStack, InputGroup, Input, InputLeftElement, MenuItem, MenuList, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import { RiAddFill, RiFileExcel2Line, RiPrinterLine, RiSearch2Fill } from 'react-icons/ri'
import { useDispatch } from 'react-redux'
import Select from 'react-select'
import LogoutMenu from '../../../components/dashboard/Layout/logout-menu'
import { openCreateLectureModel, openCreateLsson } from '../../../modules/admin-modules/models/Actions'

const SearchBar = ({ setAdd, setSelectedData }) => {
    const dispatch = useDispatch()
    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ]
    const colourStyles = {
        control: (styles) => ({ ...styles, width: '100%', background: 'white', color: '#690000', borderRadius: '16px' }),
        option: (styles) => ({ ...styles, color: '#690000' }),

    };
    return (
        <VStack w='100%' my='30px'>

            <Flex alignItems='center' w='100%' justifyContent='space-between'>
                <Text fontSize='24px'
                    fontWeight='600' color='main_1'>
                    الحصص
                </Text>

                <Flex alignItems='center'  >
                    <Button borderRadius='16px' mx='7px' leftIcon={<RiAddFill />} color='white' bg='main_1' _focus={{ outline: 'none' }} onClick={() => {
                        setAdd(true)
                        dispatch(openCreateLectureModel())
                    }
                    }>
                        إضافة حصة
                    </Button>

                    <LogoutMenu />
                </Flex>


            </Flex>
            <Text w='100%' fontSize='20px' textAlign='right'>عدد الحصص:</Text>


            <HStack w='100%' justifyContent='space-between' bg='#f5f5f5' mt='40px !important' p='10px' mb='-30px !important'>
                <HStack spacing='5px'>

                    <InputGroup w='400px'>
                        <InputLeftElement
                            pointerEvents='none'
                            children={<RiSearch2Fill color='main_1' fontSize='20px' />}
                        />
                        <Input type='text' placeholder='عنوان الحصة' borderRadius='16px' bg='white' />
                    </InputGroup>
                </HStack>


            </HStack>

        </VStack>



    )
}

export default SearchBar