import { ArrowUpDownIcon } from "@chakra-ui/icons";
import { Box, Divider, IconButton, Text, VStack } from "@chakra-ui/react";
import React, { useState } from "react";

const IndexList = ({
  examQuestions,
  setSelectedQuestionIdx,
  selectedQuestionIdx,
}) => {
  const [openList, setOpenList] = useState(true);
  console.log(examQuestions);
  return (
    <Box position="relative" w={{ base: "95%", md: "20%" }}>
      <IconButton
        icon={<ArrowUpDownIcon />}
        position="absolute"
        right="-20px"
        onClick={() => setOpenList(!openList)}
      />
      <Box p={4} bg="gray.300" fontWeight="700">
        <Text>Questions List</Text>
      </Box>
      <VStack
        border="1px solid #eeee"
        display={openList ? "none" : "flex"}
        height="500px"
        overflowY="scroll"
      >
        {examQuestions?.map((data, index) => {
          return (
            <>
              <VStack
                cursor="pointer"
                w="100%"
                p="5px"
                onClick={() => setSelectedQuestionIdx(index)}
                color={
                  selectedQuestionIdx == index
                    ? "darkblue"
                    : examQuestions?.[index]?.student_answers
                    ? "darkblue"
                    : "black"
                }
                fontWeight={selectedQuestionIdx == index ? "700" : "500"}
              >
                <Text>Question number {index + 1}</Text>
              </VStack>
              <Divider w="100%" />
            </>
          );
        })}
      </VStack>
    </Box>
  );
};

export default IndexList;
