export default {
    //get all REQUESTs
    GET_REQUESTS_REQUEST: 'GET_REQUESTS_REQUEST',
    GET_REQUESTS_SUCCESS: 'GET_REQUESTS_SUCCESS',
    GET_REQUESTS_FAIL: 'GET_REQUESTS_FAIL',

    //edit REQUESTs
    EDIT_REQUEST_REQUEST: 'EDIT_REQUEST_REQUEST',
    EDIT_REQUEST_SUCCESS: 'EDIT_REQUEST_SUCCESS',
    EDIT_REQUEST_FAIL: 'EDIT_REQUEST_FAIL',

    ACCEPT_REQUEST_WITHOUT_VOUCHER_REQUEST: 'ACCEPT_REQUEST_WITHOUT_VOUCHER_REQUEST',
    ACCEPT_REQUEST_WITHOUT_VOUCHER_SUCCESS: 'ACCEPT_REQUEST_WITHOUT_VOUCHER_SUCCESS',
    ACCEPT_REQUEST_WITHOUT_VOUCHER_FAIL: 'ACCEPT_REQUEST_WITHOUT_VOUCHER_FAIL',

    DELETE_REQUEST_REQUEST: 'DELETE_REQUEST_REQUEST',
    DELETE_REQUEST_SUCCESS: 'DELETE_REQUEST_SUCCESS',
    DELETE_REQUEST_FAIL: 'DELETE_REQUEST_FAIL',

    GET_REQUEST_REQUEST: 'GET_REQUEST_REQUEST',
    GET_REQUEST_SUCCESS: 'GET_REQUEST_SUCCESS',
    GET_REQUEST_FAIL: 'GET_REQUEST_FAIL',

    //loading
    ADD_REQUESTS_LOADING: 'ADD_REQUESTS_LOADING'
};
