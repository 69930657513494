import useTranslation from "../../../assets/lang";
import {
  Flex,
  Box,
  Image,
  Text,
  Spinner,
  HStack,
  SkeletonText,
  Icon,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import React, { useEffect } from "react";
import Logo from "../../../assets/colorLogo.webp";
// import menu from '../../../assets/icons/menu.svg'
import student from "../../../assets/icons/student.svg";
import groups from "../../../assets/icons/groups.svg";
import list from "../../../assets/icons/list.svg";
import videos from "../../../assets/icons/videos.svg";
import events from "../../../assets/icons/events.svg";
import exams from "../../../assets/icons/exams.svg";
import questions from "../../../assets/icons/questions.svg";
import { GoRequestChanges } from "react-icons/go";
import { useSelector } from "react-redux";
import { useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { RiFile2Fill, RiPrinterFill } from "react-icons/ri";
const SuperAdminSidenavbar = () => {
  const userData = useSelector((state) => state.user.userData);
  const isLoading = useSelector((state) => state.user.isLoading);

  let navTabs = [
    { label: "الطلاب", link: "/dashboard/students", icon: student },
    { label: "المجموعات", link: "/dashboard/groups", icon: groups },
    { label: "المحتوى", link: "/dashboard/lessons", icon: list },
    { label: "امتحانات الطلاب", link: "/dashboard/student-exams", icon: exams },
    { label: "الفيديوهات", link: "/dashboard/videos", icon: videos },
    { label: "الأحداث", link: "/dashboard/events", icon: events },
    { label: "الفصول", link: "/dashboard/chapters", icon: list },
    { label: "الحصص", link: "/dashboard/lectures", icon: events },
    { label: "الامتحانات", link: "/dashboard/exams", icon: exams },
    {
      label: "بنك الاسئلة",
      link: "/dashboard/questions-bank",
      icon: questions,
    },
    {
      label: "طلبات اعادة الاختبارات ",
      link: "/dashboard/exam-redo-requests",
      icon: questions,
    },
  ];

  return (
    <Flex
      w={{ base: "10%", lg: "20%" }}
      h="100vh"
      flexDirection={"column"}
      zIndex="100"
      position="fixed"
      bg="#f5f5f5"
      px={{ base: "0", lg: "20px" }}
      borderLeft="1px solid #45424226"
      alignItems={{ base: "center", lg: "flex-start" }}
      className="scroll"
    >
      <Image
        alignSelf="center"
        my="20px"
        w="50%"
        loading="lazy"
        src={Logo}
        alt="Enough"
        onClick={() => window.location.replace("/")}
      />

      {isLoading ? (
        <HStack w="100%" bg="transparent" h="100%">
          <SkeletonText
            startColor="gray.100"
            endColor="gray.600"
            w="80%"
            mt="4"
            noOfLines={7}
            spacing="20"
          />
        </HStack>
      ) : (
        <Accordion defaultIndex={[0]} allowMultiple w="100%">
          <AccordionItem w="100%" border="0">
            <h2>
              <AccordionButton
                justifyContent="space-between"
                w="100%"
                fontSize="22px"
                color="main_1"
                _expanded={{ color: "white", bg: "main_1" }}
                _focus={{ outline: "none" }}
              >
                <Box display={{ base: "none", lg: "block" }}>
                  محتوي المعلمين
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              {navTabs?.map((data, index) => {
                return (
                  <Link to={data.link} key={index} style={{ width: "90%" }}>
                    <Flex
                      w="100%"
                      color="black"
                      alignItems="center"
                      justifyContent={{ base: "center", lg: "flex-start" }}
                      mt="10px"
                      p={{ base: "0", lg: "25px" }}
                      cursor={"pointer"}
                      h={{ base: "60px", lg: "" }}
                      bg={
                        window.location.pathname == data.link
                          ? "#e4e4e4"
                          : "transparent"
                      }
                      borderRadius="15px"
                      fontWeight={
                        window.location.pathname == data.link ? "600" : "500"
                      }
                      _hover={{
                        bg: "#e4e4e4",
                        fontWeight: "600",
                      }}
                    >
                      <Image src={data?.icon} w="30px" />
                      <Text
                        fontSize="20px"
                        mx="20px"
                        mt="2px"
                        display={{ base: "none", lg: "block" }}
                        color="main_1"
                      >
                        {data.label}
                      </Text>
                    </Flex>
                  </Link>
                );
              })}
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem w="100%" border="0" mt="60px">
            <h2>
              <AccordionButton
                justifyContent="space-between"
                w="100%"
                color="main_1"
                fontSize="22px"
                _expanded={{ color: "white", bg: "main_1" }}
                _focus={{ outline: "none" }}
              >
                <Box display={{ base: "none", lg: "block" }}>التقارير</Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Link to={"/dashboard/groups-report"} style={{ width: "90%" }}>
                <Flex
                  w="100%"
                  color="black"
                  alignItems="center"
                  justifyContent={{ base: "center", lg: "flex-start" }}
                  mt="10px"
                  p={{ base: "0", lg: "25px" }}
                  cursor={"pointer"}
                  h={{ base: "60px", lg: "" }}
                  bg={
                    window.location.pathname == "/dashboard/groups-report"
                      ? "#e4e4e4"
                      : "transparent"
                  }
                  borderRadius="15px"
                  fontWeight={
                    window.location.pathname == "/dashboard/groups-report"
                      ? "600"
                      : "500"
                  }
                  _hover={{
                    bg: "#e4e4e4",
                    fontWeight: "600",
                  }}
                >
                  <Icon as={RiFile2Fill} fontSize="22px" color="main_1" />

                  <Text
                    fontSize="14px"
                    mx="20px"
                    mt="2px"
                    display={{ base: "none", lg: "block" }}
                    color="main_1"
                  >
                    تقرير المجموعات{" "}
                  </Text>
                </Flex>
              </Link>

              <Link
                to={"/dashboard/attendence-report"}
                style={{ width: "90%" }}
              >
                <Flex
                  w="100%"
                  color="black"
                  alignItems="center"
                  justifyContent={{ base: "center", lg: "flex-start" }}
                  mt="10px"
                  p={{ base: "0", lg: "25px" }}
                  cursor={"pointer"}
                  h={{ base: "60px", lg: "" }}
                  bg={
                    window.location.pathname == "/dashboard/attendence-report"
                      ? "#e4e4e4"
                      : "transparent"
                  }
                  borderRadius="15px"
                  fontWeight={
                    window.location.pathname == "/dashboard/attendence-report"
                      ? "600"
                      : "500"
                  }
                  _hover={{
                    bg: "#e4e4e4",
                    fontWeight: "600",
                  }}
                >
                  <Icon as={RiFile2Fill} fontSize="22px" color="main_1" />

                  <Text
                    fontSize="14px"
                    mx="20px"
                    mt="2px"
                    display={{ base: "none", lg: "block" }}
                    color="main_1"
                  >
                    الحضور
                  </Text>
                </Flex>
              </Link>
              <Link
                to={"/dashboard/exam-attendence-report"}
                style={{ width: "90%" }}
              >
                <Flex
                  w="100%"
                  color="black"
                  alignItems="center"
                  justifyContent={{ base: "center", lg: "flex-start" }}
                  mt="10px"
                  p={{ base: "0", lg: "25px" }}
                  cursor={"pointer"}
                  h={{ base: "60px", lg: "" }}
                  bg={
                    window.location.pathname ==
                    "/dashboard/exam-attendence-report"
                      ? "#e4e4e4"
                      : "transparent"
                  }
                  borderRadius="15px"
                  fontWeight={
                    window.location.pathname ==
                    "/dashboard/exam-attendence-report"
                      ? "600"
                      : "500"
                  }
                  _hover={{
                    bg: "#e4e4e4",
                    fontWeight: "600",
                  }}
                >
                  <Icon as={RiFile2Fill} fontSize="22px" color="main_1" />

                  <Text
                    fontSize="14px"
                    mx="20px"
                    mt="2px"
                    display={{ base: "none", lg: "block" }}
                    color="main_1"
                  >
                    غياب الامتحانات
                  </Text>
                </Flex>
              </Link>

              <Link to={"/dashboard/exam-report"} style={{ width: "90%" }}>
                <Flex
                  w="100%"
                  color="black"
                  alignItems="center"
                  justifyContent={{ base: "center", lg: "flex-start" }}
                  mt="10px"
                  p={{ base: "0", lg: "25px" }}
                  cursor={"pointer"}
                  h={{ base: "60px", lg: "" }}
                  bg={
                    window.location.pathname == "/dashboard/exam-report"
                      ? "#e4e4e4"
                      : "transparent"
                  }
                  borderRadius="15px"
                  fontWeight={
                    window.location.pathname == "/dashboard/exam-report"
                      ? "600"
                      : "500"
                  }
                  _hover={{
                    bg: "#e4e4e4",
                    fontWeight: "600",
                  }}
                >
                  <Icon as={RiFile2Fill} fontSize="22px" color="main_1" />

                  <Text
                    fontSize="14px"
                    mx="20px"
                    mt="2px"
                    display={{ base: "none", lg: "block" }}
                    color="main_1"
                  >
                    تقرير الامتحانات
                  </Text>
                </Flex>
              </Link>

              <Link to={"/dashboard/videos-report"} style={{ width: "90%" }}>
                <Flex
                  w="100%"
                  color="black"
                  alignItems="center"
                  justifyContent={{ base: "center", lg: "flex-start" }}
                  mt="10px"
                  p={{ base: "0", lg: "25px" }}
                  cursor={"pointer"}
                  h={{ base: "60px", lg: "" }}
                  bg={
                    window.location.pathname == "/dashboard/videos-report"
                      ? "#e4e4e4"
                      : "transparent"
                  }
                  borderRadius="15px"
                  fontWeight={
                    window.location.pathname == "/dashboard/videos-report"
                      ? "600"
                      : "500"
                  }
                  _hover={{
                    bg: "#e4e4e4",
                    fontWeight: "600",
                  }}
                >
                  <Icon as={RiFile2Fill} fontSize="22px" color="main_1" />

                  <Text
                    fontSize="14px"
                    mx="20px"
                    mt="2px"
                    display={{ base: "none", lg: "block" }}
                    color="main_1"
                  >
                    تقرير مشاهدات الفيديوهات
                  </Text>
                </Flex>
              </Link>
              <Link
                to={"/dashboard/print-all-questions"}
                style={{ width: "90%" }}
              >
                <Flex
                  w="100%"
                  color="black"
                  alignItems="center"
                  justifyContent={{ base: "center", lg: "flex-start" }}
                  mt="10px"
                  p={{ base: "0", lg: "25px" }}
                  cursor={"pointer"}
                  h={{ base: "60px", lg: "" }}
                  bg={
                    window.location.pathname == "/dashboard/print-all-questions"
                      ? "#e4e4e4"
                      : "transparent"
                  }
                  borderRadius="15px"
                  fontWeight={
                    window.location.pathname == "/dashboard/print-all-questions"
                      ? "600"
                      : "500"
                  }
                  _hover={{
                    bg: "#e4e4e4",
                    fontWeight: "600",
                  }}
                >
                  <Icon as={RiPrinterFill} fontSize="22px" color="main_1" />

                  <Text
                    fontSize="14px"
                    mx="20px"
                    mt="2px"
                    display={{ base: "none", lg: "block" }}
                    color="main_1"
                  >
                    تقرير طباعة الاسئلة
                  </Text>
                </Flex>
              </Link>
              <Link to={"/dashboard/unpaid-report"} style={{ width: "90%" }}>
                <Flex
                  w="100%"
                  color="black"
                  alignItems="center"
                  justifyContent={{ base: "center", lg: "flex-start" }}
                  mt="10px"
                  p={{ base: "0", lg: "25px" }}
                  cursor={"pointer"}
                  h={{ base: "60px", lg: "" }}
                  bg={
                    window.location.pathname == "/dashboard/unpaid-report"
                      ? "#e4e4e4"
                      : "transparent"
                  }
                  borderRadius="15px"
                  fontWeight={
                    window.location.pathname == "/dashboard/unpaid-report"
                      ? "600"
                      : "500"
                  }
                  _hover={{
                    bg: "#e4e4e4",
                    fontWeight: "600",
                  }}
                >
                  <Icon as={RiFile2Fill} fontSize="22px" color="main_1" />

                  <Text
                    fontSize="14px"
                    mx="20px"
                    mt="2px"
                    display={{ base: "none", lg: "block" }}
                    color="main_1"
                  >
                    تقرير عدم الدفع
                  </Text>
                </Flex>
              </Link>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      )}
    </Flex>
  );
};

export default SuperAdminSidenavbar;
