import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { Box, HStack, Stack, Text, VStack } from "@chakra-ui/react";

ChartJS.register(ArcElement, Tooltip, Legend);

const Performance = ({
  totalQuestions = 0,
  rightAnswers = 0,
  percentage = 0,
  totalExams = 0,
}) => {
  const data = {
    labels: ["total right answers", "total exams questions"],
    datasets: [
      {
        label: "# of Votes",
        data: [rightAnswers, totalQuestions],
        backgroundColor: [
          "rgba(255, 99, 132, 0.2)",
          "rgba(54, 162, 235, 0.2)",
          "rgba(255, 159, 64, 0.2)",
        ],
        borderColor: ["transparent"],
      },
    ],
  };
  return (
    <VStack h="100%">
      <Stack flexDirection={{ base: "column", md: "row" }} gap={2}>
        {totalExams && (
          <Box boxShadow="sm" p="6" rounded="md" bg="main_1_light">
            <Text color="main_1">Total Exams</Text>
            <Text color="main_1" fontWeight="600" fontSize="22px">
              {totalExams}{" "}
            </Text>
          </Box>
        )}
        <Box boxShadow="sm" p="6" rounded="md" bg="main_1_light">
          <Text color="main_1">Total Percentage</Text>
          <Text color="main_1" fontWeight="600" fontSize="22px">
            {percentage} %
          </Text>
        </Box>

        <Box boxShadow="sm" p="6" rounded="md" bg="main_1_light">
          <Text color="main_1">Total Questions</Text>
          <Text color="main_1" fontWeight="600" fontSize="22px">
            {rightAnswers} / {totalQuestions}
          </Text>
        </Box>
      </Stack>
      <Doughnut data={data} style={{ maxHeight: "300px", maxWidth: "400px" }} />
    </VStack>
  );
};

export default Performance;
