import React, { useState } from 'react'
import ServiceCard from '../../../components/student/Cards/serviceCard'
import { Container, Grid, Text } from '@chakra-ui/react'
const Services = () => {
    const [servicesData, setServicesData] = useState(require('../../../staticData/services.json'))
    return (
        <Container maxW={'container.lg'} ml={{ base: '', md: '25%', lg: '15%', xl: '25%' }} id='services' w={{ base: '10%', md: '100%' }}
            mt={{ base: '0', lg: '-300px' }}>

            <Text fontSize='32px'
                color='main_2'
                textAlign='center'
                w='75%'
                display={{ base: 'none', lg: 'block' }}
                fontWeight='700' textTransform={'uppercase'} >
                Services
            </Text>

            <Text fontSize='22px'
                textAlign='center'
                w='75%'
                py='10px'
                color='white'
                mb='150px'
                display={{ base: 'none', lg: 'block' }}

            >
                Discover more study resources throughour unique platform
            </Text>
            <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)' }} gap='8'>
                {
                    servicesData?.map((data, index) => {
                        return <ServiceCard
                            key={index}
                            imageUrl={data?.imageUrl}
                            title={data?.title}
                            description={data?.description} />
                    })
                }
            </Grid>
        </Container>



    )
}

export default Services