import { Box, Button, Heading, Text, VStack } from "@chakra-ui/react";
import QRCode from "qrcode.react";
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import MainModel from "../../../../components/Models/main-model";
import { closeUserQRCodeModel } from "../../../../modules/admin-modules/models/Actions";

const UserQRCode = ({ data }) => {
  const dispatch = useDispatch();
  const header = (
    <Box>
      <Heading textAlign="center" color="main_1" fontSize="24px">
        QR Code
      </Heading>
    </Box>
  );
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `qr-code ${data?.name}`,
  });
  const openUserData = useSelector((state) => state.model.isOpen14);

  const closeFunc = () => {
    dispatch(closeUserQRCodeModel());
  };
  return (
    <MainModel
      header={header}
      closeFunc={closeFunc}
      openVar={openUserData}
      buttonTitle={null}
      buttonFunc={null}
    >
      <VStack>
        <VStack ref={componentRef} my={3}>
          <Text my={4} textAlign="center" width="200px" fontWeight="bold">
            {data?.subject?.name}
          </Text>
          <Text my={4} textAlign="center" width="200px" fontWeight="bold">
            {data?.name}
          </Text>
          <Text my={4} textAlign="center" width="200px" fontWeight="bold">
            {data?.user_name}
          </Text>
          <QRCode
            size={200}
            value={`https://enoughphysics.com/scan-code/${data?.id}/attendence`}
          />
        </VStack>

        <Button
          mx="10px"
          border="1px solid gray"
          borderRadius="8px"
          padding="10px"
          width="30%"
          onClick={handlePrint}
        >
          print
        </Button>
      </VStack>
    </MainModel>
  );
};

export default UserQRCode;
