import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Layout from '../../../components/dashboard/Layout'
import SearchBar from '../../../containers/admin/redo-requests/searchbar';
import RedoRequestTable from '../../../containers/admin/redo-requests/table';
import { editRedoRequestRequest, getAllRedoRequestsRequest } from '../../../modules/admin-modules/redo-requests/Actions';
const RedoRequests = () => {
    const dispatch = useDispatch();
    const model = useSelector((state) => state.model);
    const [isAdd, setAdd] = useState(false)
    const [selectedData, setSelectedData] = useState({})
    const redoRequests = useSelector((state) => state.redoRequests.redoRequests)

    useEffect(() => {
        dispatch(getAllRedoRequestsRequest())
    }, [])

    const handelAcceptAll = () => {
        let formData = new FormData()
        redoRequests?.map((request, index) => {
            formData.append(`requests[${index}]`, request?.id)
        })
        dispatch(editRedoRequestRequest(formData))

    }
    return (
        <Layout>
            <SearchBar setAdd={setAdd} setSelectedData={setSelectedData} handelAcceptAll={handelAcceptAll} />
            <RedoRequestTable setAdd={setAdd} setSelectedData={setSelectedData} />

        </Layout>
    )
}

export default RedoRequests