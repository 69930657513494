export default {
    //get all_REDO REQUESTs
    GET_REDO_REQUESTS_REQUEST: 'GET_REDO_REQUESTS_REQUEST',
    GET_REDO_REQUESTS_SUCCESS: 'GET_REDO_REQUESTS_SUCCESS',
    GET_REDO_REQUESTS_FAIL: 'GET_REDO_REQUESTS_FAIL',

    //edit_REDO REQUESTs
    EDIT_REDO_REQUEST_REQUEST: 'EDIT_REDO_REQUEST_REQUEST',
    EDIT_REDO_REQUEST_SUCCESS: 'EDIT_REDO_REQUEST_SUCCESS',
    EDIT_REDO_REQUEST_FAIL: 'EDIT_REDO_REQUEST_FAIL',


    DELETE_REDO_REQUEST_REQUEST: 'DELETE_REDO_REQUEST_REQUEST',
    DELETE_REDO_REQUEST_SUCCESS: 'DELETE_REDO_REQUEST_SUCCESS',
    DELETE_REDO_REQUEST_FAIL: 'DELETE_REDO_REQUEST_FAIL',

    GET_REDO_REQUEST_REQUEST: 'GET_REDO_REQUEST_REQUEST',
    GET_REDO_REQUEST_SUCCESS: 'GET_REDO_REQUEST_SUCCESS',
    GET_REDO_REQUEST_FAIL: 'GET_REDO_REQUEST_FAIL',

    //loading
    ADD_REDO_REQUESTS_LOADING: 'ADD_REDO_REQUESTS_LOADING'
};
