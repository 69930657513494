import { Box, Flex, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CTable from '../../../components/Table/table';
import Select from 'react-select';
import { examAttendaceRequest } from '../../../modules/admin-modules/reports/Actions';
import Layout from '../../../components/dashboard/Layout';
import { getAllExamsRequest } from '../../../modules/admin-modules/exams/Actions';
import { getAllGroupsRequest } from '../../../modules/admin-modules/groups/Actions';
import LogoutMenu from '../../../components/dashboard/Layout/logout-menu';
import { CSVLink } from 'react-csv';

const ExamAttendanceReport = ({ setAdd, setSelectedData, searchTerm }) => {
    const dispatch = useDispatch();
    const [examId, setExamId] = useState('');
    const [groupId, setGroupId] = useState(['all']);

    useEffect(() => {
        if (examId) {
            dispatch(examAttendaceRequest(examId));
        }
    }, [examId]);

    useEffect(() => {
        dispatch(getAllExamsRequest());
    }, []);

    useEffect(() => {
        dispatch(getAllGroupsRequest());
    }, []);

    const exams = useSelector((state) => state.exams.exams);
    const examsOptions = exams?.map((data) => {
        return { value: data.id, label: data.name };
    });

    const examAttendance = useSelector((state) => state.reports.examAttendance);
    const isLoading = useSelector((state) => state.reports.isLoading);
    const groups = useSelector((state) => state.groups.groups);
    
    const groupOptions = [{ value: 'all', label: 'All' }];
    groups?.map((group) => {
        groupOptions.push({ value: group.id, label: group.name });
    });

    const [offset, setPage] = useState(0);
    const [size, setPerPage] = useState(10);
    const [pageNumber, setPageNumber] = useState(0);
    const [selected, setSelected] = useState({
        id: ''
    });

    const colourStyles = {
        control: (styles) => ({ ...styles, width: '100%', background: '#f5f5f5', color: '#690000', borderRadius: '16px' }),
        option: (styles) => ({ ...styles, color: '#690000' }),
    };

    const viewData = (data) => {
        setSelectedData(data);
        setSelected(data);
    };

    const headers = [
        { label: 'name', key: 'name' },
        { label: 'phone', key: 'phone' },
        { label: 'parent_phone', key: 'parent_phone' },
    ];

    // Filter the data based on the selected groupId
    const filteredData = examAttendance?.filter((data) => {
        if (groupId.includes('all')) {
            return data;
        }
        else{
            return groupId.includes(data?.student_group_id);
        }
    }
    );

    const Data = filteredData?.map((data, index) => {
        return {
            ...data,
            index: `#${index + 1}`,
            id: data?.id,
            name: data?.name,
            parent_phone: data?.parent_phone,
            phone: data?.phone,
            groupId: data?.student_group_id,
        };
    });

    const columns = [
        {
            Header: 'رقم الطالب',
            accessor: 'index'
        },
        {
            Header: 'اسم الطالب',
            accessor: 'name'
        },
        {
            Header: ' رقم هاتف ولي الامر',
            accessor: 'parent_phone'
        },
        {
            Header: ' رقم هاتف الطالب',
            accessor: 'phone'
        }
    ];

    return (
        <Layout>
            <div style={{ minHeight: '400px' }}>
                <Flex w='100%' my='30px' justifyContent='space-between' alignItems='center' position='relative' zIndex='10000000'>
                    <Text fontSize='24px' fontWeight='600' color='main_1'>
                        تقرير غياب الامتحانات
                    </Text>
                    <Flex w='40%' justifyContent='space-between' alignItems='center'>
                        <Box w='75%' pl={2}>
                            <Select options={examsOptions} isMulti={false} onChange={(data) => {
                                setExamId(data?.value);
                            }}
                                styles={colourStyles}
                                placeholder='Select Exam'
                            />
                        </Box>
                        <Box w='75%'>
                            <Select options={groupOptions} isMulti={true} onChange={(data) => {
                                setGroupId(data?.map((group) => group?.value));
                            }}
                                styles={colourStyles}
                                placeholder='Select Groups' 
                            />
                        </Box>
                        <Box mx='10px' border='1px solid gray' borderRadius='8px' padding='10px'>
                            <CSVLink
                                headers={headers}
                                data={Data?.length !== 0 ? Data : []}
                                filename={"exam-report.csv"}
                                className="btn btn-primary"
                                target="_blank"
                            >
                                Download
                            </CSVLink>
                        </Box>
                        <LogoutMenu />
                    </Flex>
                </Flex>

                <CTable
                    selectedData={viewData}
                    Columns={columns}
                    Data={Data}
                    path={'openModel'}
                    Actions
                    Title={``}
                    subTitle=''
                    btnTitle=""
                    placeHolder=""
                    noSearchBar={true}
                    noFilter={true}
                    footerBtnTitle
                    filterList
                    addButton
                    addButtonTitle=""
                    setPage={setPage}
                    setPerPage={setPerPage}
                    currentpage={pageNumber}
                    setPageNumber={setPageNumber}
                    noSecondSearchBar={true}
                    isLoading={isLoading}
                    perPage={size}
                    totalPage={Math.ceil(10 / 50)}
                />
            </div>
        </Layout>
    );
};

export default ExamAttendanceReport;
