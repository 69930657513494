import axiosInstance from "../../../helpers/api";

export const getAllTeacherAssistants = (body) => {
    return axiosInstance.get('/admin/teacher_assistants', body);
};

export const editTeacherAssistants = (formData) => {

    return axiosInstance.put(`/admin/teacher_assistants/${formData.id}`, formData.formData);
};


export const createTeacherAssistants = (formData) => {
    return axiosInstance.post('/admin/teacher_assistants', formData);
};

export const deleteTeacherAssistant = (id) => {
    return axiosInstance.delete(`/admin/teacher_assistants/${id}`);
};
