import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Input,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { useEffect } from "react";
import {
  RiAddCircleLine,
  RiDeleteBin6Line,
  RiIndeterminateCircleLine,
} from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import MainModel from "../../../../components/Models/main-model";
import { closeAddVideoFielsModel } from "../../../../modules/admin-modules/models/Actions";
import {
  addVideoFilesRequest,
  deleteVideoFilesRequest,
} from "../../../../modules/admin-modules/videos/Actions";

const AddVideoFiels = ({ isAdd, data }) => {
  const [selected, setSelected] = useState([{ name: "upload file", file: "" }]);
  const [fieldsCount, setFieldsCount] = useState(1);
  const openVideo = useSelector((state) => state.model.isOpen10);
  const dispatch = useDispatch();
  const closeFunc = () => {
    dispatch(closeAddVideoFielsModel());
  };

  const header = (
    <Box>
      <Heading textAlign="center" color="main_1" fontSize="24px">
        {isAdd ? "اضافة فيديو " : "تعديل فيديو"}
      </Heading>
    </Box>
  );
  const handelFiles = async (values, index) => {
    let formData = new FormData();
    formData.append("file", values);
    formData.append("video_id", data?.id);
    const action = (val) => {
      const newData = selected;
      newData[index] = { ...newData[index], id: val };
      setSelected(() => newData);
    };

    await dispatch(addVideoFilesRequest(formData, action));
  };

  useEffect(() => {
    if (data?.files?.length != 0) {
      setFieldsCount(data?.files?.length);

      const newData = data?.files?.map((val, idx) => {
        return { id: val?.id, name: `file_${idx + 1}`, file: val.file };
      });
      setSelected(newData);
    } else {
      setFieldsCount(1);
      setSelected([{ name: "upload file", file: "" }]);
    }
  }, [data, isAdd]);
  return (
    <MainModel
      header={header}
      closeFunc={closeFunc}
      openVar={openVideo}
      buttonTitle={null}
      buttonFunc={handelFiles}
    >
      {[...Array(fieldsCount)].map((val, index) => {
        return (
          <>
            <Flex
              my="5px"
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Button
                bg="main_1"
                color="white"
                borderRadius="8px"
                textAlign="center"
                position="relative"
                w="70%"
              >
                <Text>
                  {selected?.length == index + 1 && selected[index]
                    ? selected[index]?.name
                    : " اختر ملف ليتم رفعه"}
                </Text>
                <Input
                  type="file"
                  placeholder="اختر ملف ليتم رفعه"
                  opacity="0"
                  position="absolute"
                  w="100%"
                  h="100%"
                  onChange={(e) => {
                    let newData = selected;
                    let newIndex = index;
                    newData[newIndex] = {
                      file: e?.target?.files[0],
                      name: e?.target?.files[0]?.name,
                    };

                    setSelected(newData);
                    setSelected((s) => [...newData]);
                  }}
                />
              </Button>

              <Button onClick={() => handelFiles(selected[index].file, index)}>
                upload
              </Button>
              <Icon
                as={RiAddCircleLine}
                mx={"10px"}
                color="main_2"
                fontSize={"18px"}
                cursor="pointer"
                onClick={() => setFieldsCount(fieldsCount + 1)}
              />

              <Icon
                as={RiIndeterminateCircleLine}
                mx={"10px"}
                color="main_2"
                fontSize={"22px"}
                cursor="pointer"
                onClick={() => {
                  setFieldsCount(fieldsCount - 1);
                  let newData = selected;
                  const filteredData = newData.filter(
                    (val, idx) => idx != index
                  );
                  setSelected(filteredData);
                }}
              />
              {selected?.length >= index + 1 && selected[index]?.id && (
                <Icon
                  as={RiDeleteBin6Line}
                  mx={"10px"}
                  color="main_2"
                  fontSize={"18px"}
                  cursor="pointer"
                  onClick={() => {
                    dispatch(deleteVideoFilesRequest(selected[index]?.id));
                    setFieldsCount(fieldsCount - 1);
                    let newData = selected;
                    const filteredData = newData.filter(
                      (val, idx) => idx != index
                    );
                    setSelected(filteredData);
                  }}
                />
              )}
            </Flex>
          </>
        );
      })}
    </MainModel>
  );
};

export default AddVideoFiels;
