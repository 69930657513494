import axiosInstance from "../../../helpers/api";

export const getAllLessons = (body) => {
    return axiosInstance.get('/admin/lessons', body);
};

export const editLessons = (formData) => {
    return axiosInstance.post(`/admin/lessons/${formData.id}`, formData.formData);
};


export const createLessons = (formData) => {
    return axiosInstance.post('/admin/lessons', formData);
};

export const deleteLesson = (id) => {
    return axiosInstance.delete(`/admin/lessons/${id}`);
};
