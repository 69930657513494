export default {
    //get all STREAMINGs
    GET_STREAMINGS_REQUEST: 'GET_STREAMINGS_REQUEST',
    GET_STREAMINGS_SUCCESS: 'GET_STREAMINGS_SUCCESS',
    GET_STREAMINGS_FAIL: 'GET_STREAMINGS_FAIL',

    //edit STREAMINGs
    EDIT_STREAMING_REQUEST: 'EDIT_STREAMING_REQUEST',
    EDIT_STREAMING_SUCCESS: 'EDIT_STREAMING_SUCCESS',
    EDIT_STREAMING_FAIL: 'EDIT_STREAMING_FAIL',

    //create STREAMING
    CREATE_STREAMING_REQUEST: 'CREATE_STREAMING_REQUEST',
    CREATE_STREAMING_SUCCESS: 'CREATE_STREAMING_SUCCESS',
    CREATE_STREAMING_FAIL: 'CREATE_STREAMING_FAIL',

    DELETE_STREAMING_REQUEST: 'DELETE_STREAMING_REQUEST',
    DELETE_STREAMING_SUCCESS: 'DELETE_STREAMING_SUCCESS',
    DELETE_STREAMING_FAIL: 'DELETE_STREAMING_FAIL',

    GET_STREAMING_REQUEST: 'GET_STREAMING_REQUEST',
    GET_STREAMING_SUCCESS: 'GET_STREAMING_SUCCESS',
    GET_STREAMING_FAIL: 'GET_STREAMING_FAIL',

    //loading
    ADD_STREAMINGS_LOADING: 'ADD_STREAMINGS_LOADING'
};
