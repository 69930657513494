import { toast } from "react-toastify";
import { call, fork, put, takeLatest } from "redux-saga/effects";
import * as actions from "./Actions";
import * as api from "./Api";
import Types from "./Types";

function* getAllChapters({ payload }) {
  try {
    yield put(actions.addChapterLoading(true));
    const result = yield call(api.getAllChapters);
    yield put(actions.getAllChaptersSuccess(result.data));
  } catch (error) {
    const { response, message } = error;
    toast.error(error);
    yield put(actions.getAllChaptersFail());
  } finally {
    yield put(actions.addChapterLoading(false));
  }
}

//Edit Tables
function* editChapter({ payload }) {
  try {
    yield put(actions.addChapterLoading(true));
    const result = yield call(api.editChapters, payload);
    yield put(actions.editChapterSuccess(result.data));
    toast.success("Edit Successfully");
    payload?.action && payload?.action();
  } catch (error) {
    yield put(actions.editChapterFail());
  } finally {
    yield put(actions.addChapterLoading(false));
  }
}

function* restrictChapter({ payload }) {
  try {
    yield put(actions.addChapterLoading(true));
    const result = yield call(api.restrictChapters, payload);
    yield put(actions.restrictChapterSuccess(result.data));
    toast.success("Done Successfully");
    payload?.action && payload?.action();
  } catch (error) {
    yield put(actions.restrictChapterFail());
  } finally {
    yield put(actions.addChapterLoading(false));
  }
}

function* asqueraChapter({ payload }) {
  try {
    yield put(actions.addChapterLoading(true));
    const result = yield call(api.asquaraChapters, payload);
    yield put(actions.asqueraChapterSuccess(result.data));
    toast.success("Done Successfully");
    payload?.action && payload?.action();
  } catch (error) {
    yield put(actions.asqueraChapterFail());
  } finally {
    yield put(actions.addChapterLoading(false));
  }
}

function* activeChapter({ payload }) {
  try {
    yield put(actions.addChapterLoading(true));
    const result = yield call(api.activeChapters, payload);
    yield put(actions.activeChapterSuccess(result.data));
    toast.success("Done Successfully");
    payload?.action && payload?.action();
  } catch (error) {
    yield put(actions.activeChapterFail());
  } finally {
    yield put(actions.addChapterLoading(false));
  }
}

// create
function* createChapter(formData) {
  try {
    yield put(actions.addChapterLoading(true));
    const result = yield call(api.createChapters, formData.payload);
    yield put(
      actions.createChapterSuccess({
        data: result.data,
      })
    );
    toast.success("Created Successfully");
    formData?.action && formData?.action();
  } catch (error) {
    yield put(actions.createChapterFail());
    toast.success(error.data.message.toString());
  } finally {
    yield put(actions.addChapterLoading(false));
  }
}

function* deleteChapter({ payload }) {
  try {
    yield put(actions.addChapterLoading(true));
    const result = yield call(api.deleteChapter, payload);

    yield put(actions.deleteChapterSuccess(payload));

    toast.success("Deleted Successfully");
  } catch (error) {
    const { response, message } = error;
    toast.error(error.response);
    yield put(actions.deleteChapterFail());
  } finally {
    yield put(actions.addChapterLoading(false));
  }
}

export default function* ChapterSaga() {
  yield takeLatest(Types.GET_CHAPTERS_REQUEST, getAllChapters);
  yield takeLatest(Types.EDIT_CHAPTER_REQUEST, editChapter);
  yield takeLatest(Types.RESTRICT_CHAPTER_REQUEST, restrictChapter);
  yield takeLatest(Types.ACTIVE_CHAPTER_REQUEST, activeChapter);
  yield takeLatest(Types.ASQUARA_CHAPTER_REQUEST, asqueraChapter);

  yield takeLatest(Types.CREATE_CHAPTER_REQUEST, createChapter);
  yield takeLatest(Types.DELETE_CHAPTER_REQUEST, deleteChapter);
}
