import {
  Container,
  Heading,
  HStack,
  VStack,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text,
  Square,
  Icon,
  Breadcrumb,
  BreadcrumbItem,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { RiAttachment2 } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import Layout from "../../../components/student/Layout/layout";
import { getLessonsByChapterIdRequest } from "../../../modules/students-modules/lessons/Actions";

const StudentModelAnswer = () => {
  const baseUrl = "https://enoughphysics.com/enoughapi";
  const [selectedData, setSelectedData] = useState({});
  const queryString = require("query-string");

  const { id } = useParams();
  const parsed = queryString.parse(window.location.search);
  const dispatch = useDispatch();
  const chapterLessons = useSelector(
    (state) => state.chapterLessons.chapterLessons
  );
  const isLoading = useSelector((state) => state.chapterLessons.isLoading);

  useEffect(() => {
    if (id) dispatch(getLessonsByChapterIdRequest(id));
  }, [id]);

  useEffect(() => {
    setSelectedData(chapterLessons[0]);
  }, [chapterLessons]);

  return (
    <Layout color="main_1" bg="white" isLoading={isLoading}>
      <Container maxW="container.2xl" h="100%" my="100px" mb="165px" w="100%">
        <VStack spacing={8} h="100%" w="100%" alignItems="flex-start">
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/model-answers/chapters">
                <Text>Model Answers</Text>
              </Link>
            </BreadcrumbItem>

            <BreadcrumbItem isCurrentPage>
              <Text>{selectedData?.title}</Text>
            </BreadcrumbItem>
          </Breadcrumb>

          <Heading as="h3" color="main_1" textAlign="center" w="100%">
            {parsed?.name}
          </Heading>

          <HStack
            flexDirection={{ base: "column", md: "row" }}
            w="100%"
            justifyContent="space-between"
            alignItems="flex-start"
          >
            {/* left side */}
            <VStack w={{ base: "100%", md: "31%" }}>
              <Accordion defaultIndex={[0]} allowMultiple w="100%">
                <AccordionItem border="0">
                  <h2>
                    <AccordionButton
                      bg="main_1_light"
                      color="main_1"
                      borderRadius="8px"
                      _focus={{ outline: "none" }}
                      _hover={{ bg: "main_1_light" }}
                    >
                      <Box flex="1" textAlign="left" fontSize="24px">
                        All Model Answers
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel py="20px" h="100%">
                    <VStack spacing="15px" className="scroll" h="400px" p="5px">
                      {chapterLessons?.map((lesson, idx) => {
                        return (
                          <HStack
                            cursor={"pointer"}
                            boxShadow={
                              lesson?.id == selectedData?.id
                                ? "2px 3px 10px #80808045"
                                : "none"
                            }
                            bg="main_1_light"
                            color="main_1"
                            borderRadius="8px"
                            p="10px"
                            w="100%"
                            key={idx}
                            onClick={() => setSelectedData(lesson)}
                          >
                            <Icon as={RiAttachment2} fontSize="18px" />
                            <Text>
                              Model answer of lesson 1,2 and solved lesson
                            </Text>
                          </HStack>
                        );
                      })}
                    </VStack>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </VStack>

            {/* right side */}
            <VStack w={{ base: "100%", md: "66%" }} h="100%" spacing="40px">
              <iframe
                onContextMenu={(e) => e.preventDefault()}
                style={{ userSelect: "none" }}
                title="subject"
                width="100%"
                height={"600"}
                sandbox="allow-scripts allow-same-origin"
                src={`https://docs.google.com/gview?url=${baseUrl}/${selectedData?.file}&embedded=true`}
                frameBorder="4"
              ></iframe>
            </VStack>
          </HStack>
        </VStack>
      </Container>
    </Layout>
  );
};

export default StudentModelAnswer;
