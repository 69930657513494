export default {
    //get all VIDEOs
    CREATE_STUDENT_EXAMS_REQUEST: 'CREATE_STUDENT_EXAMS_REQUEST',
    CREATE_STUDENT_EXAMS_SUCCESS: 'CREATE_STUDENT_EXAMS_SUCCESS',
    CREATE_STUDENT_EXAMS_FAIL: 'CREATE_STUDENT_EXAMS_FAIL',

    GET_STUDENT_EXAMS_REQUEST: 'GET_STUDENT_EXAMS_REQUEST',
    GET_STUDENT_EXAMS_SUCCESS: 'GET_STUDENT_EXAMS_SUCCESS',
    GET_STUDENT_EXAMS_FAIL: 'GET_STUDENT_EXAMS_FAIL',


    GET_STUDENT_EXAM_QUESTIONS_REQUEST: 'GET_STUDENT_EXAM_QUESTIONS_REQUEST',
    GET_STUDENT_EXAM_QUESTIONS_SUCCESS: 'GET_STUDENT_EXAM_QUESTIONS_SUCCESS',
    GET_STUDENT_EXAM_QUESTIONS_FAIL: 'GET_STUDENT_EXAM_QUESTIONS_FAIL',


    //loading
    ADD_STUDENT_EXAMS_LOADING: 'ADD_STUDENT_EXAMS_LOADING',
};
