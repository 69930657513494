import { Box, FormLabel, Heading, Stack } from "@chakra-ui/react";
import dayjs from "dayjs";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import MainInput from "../../../../components/Form/input";
import MainModel from "../../../../components/Models/main-model";
import { getAllGroupsRequest } from "../../../../modules/admin-modules/groups/Actions";
import {
  closeCreateStudent,
  openShowStudentDataModel,
} from "../../../../modules/admin-modules/models/Actions";
import { getPaymentExceptionRequest } from "../../../../modules/admin-modules/payment/Actions";
import {
  createStudentRequest,
  editStudentRequest,
} from "../../../../modules/admin-modules/students/Actions";
// import ShowPassword from '../models/show-password'

const AddStudent = ({ isAdd, data, setSelectedData, setAddedStudent }) => {
  const openStudent = useSelector((state) => state.model.isOpen1);
  const groups = useSelector((state) => state.groups.groups);
  const isLoading = useSelector((state) => state.students.isLoading);
  const paymentException = useSelector(
    (state) => state.payments.paymentException
  );

  const dispatch = useDispatch();

  const groupsOptions = groups?.map((data) => {
    return { value: data.id, label: data.name };
  });
  const paymentExceptionOptions = paymentException?.map((data) => {
    return { value: data.id, label: data.name };
  });
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      width: "100%",
      background: "#f5f5f5",
      color: "#690000",
      borderRadius: "16px",
      borderColor: errors?.chapter_id?.message ? "red" : "gray.200",
    }),
    option: (styles) => ({ ...styles, color: "#690000" }),
  };

  useEffect(() => {
    if (openStudent) {
      dispatch(getAllGroupsRequest());
      dispatch(getPaymentExceptionRequest());
    }
  }, [openStudent]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: data ? data : {},
    shouldUnregister: true,
  });

  const header = (
    <Box>
      <Heading textAlign="center" color="main_1" fontSize="24px">
        {isAdd ? " إضافة الطالب" : "تعديل  الطالب"}
      </Heading>
    </Box>
  );
  const onSubmit = (values) => {
    const editGroup = values?.student_group_id
      ? values?.student_group_id
      : data?.group?.id;
    console.log(editGroup);
    const newDate = values?.reservation_date
      ? dayjs(values?.reservation_date).format("YYYY-MM-DD")
      : dayjs(data?.created_at).format("YYYY-MM-DD");

    const action = (data) => {
      dispatch(closeCreateStudent());
      setAddedStudent(data);
      dispatch(openShowStudentDataModel());
      setSelectedData({});
      reset();
    };
    let formData = new FormData();
    for (let key in { ...values }) {
      formData.append(key, values[key]);
    }
    formData.append("reservation_date", newDate);
    formData.append("student_group_id", `${editGroup}`);

    if (isAdd) dispatch(createStudentRequest(formData, action));
    else {
      formData.append("_method", "PUT");

      dispatch(
        editStudentRequest({
          id: data?.id,
          formData: formData,
          action: () => {
            dispatch(closeCreateStudent());
          },
        })
      );
    }
  };

  const closeFunc = () => {
    dispatch(closeCreateStudent());
    setSelectedData && setSelectedData({});
    reset();
  };
  return (
    <>
      <MainModel
        header={header}
        closeFunc={closeFunc}
        openVar={openStudent}
        buttonTitle={isAdd ? " إضافة الطالب" : "تعديل  الطالب"}
        isLoading={isLoading}
        buttonFunc={handleSubmit(onSubmit)}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={5} dir="rtl" w="100%">
            <Box w="55%">
              <MainInput
                defaultValues={data?.name}
                label="اسم الطالب"
                error={errors?.name?.message}
                placeholder="ادخل اسم الطالب"
                register={register}
                name="name"
              />
            </Box>
            <Box w="55%">
              <MainInput
                defaultValues={data?.phone}
                label="رقم الموبايل"
                error={errors?.phone?.message}
                placeholder="اكتب رقم الموبايل"
                register={register}
                name="phone"
              />
            </Box>
            <Box w="55%">
              <MainInput
                defaultValues={data?.parent_phone}
                label="رقم موبايل ولي الأمر"
                error={errors?.parent_phone?.message}
                placeholder="اكتب رقم موبايل ولي الأمر"
                register={register}
                name="parent_phone"
              />
            </Box>
            <Box w="55%">
              <MainInput
                defaultValues={data?.assistant_phone}
                label="رقم موبايل المساعد"
                error={errors?.assistant_phone?.message}
                placeholder="اكتب رقم موبايل المساعد"
                register={register}
                name="assistant_phone"
                required={false}
              />
            </Box>
            <Box w="55%">
              <MainInput
                defaultValues={data?.school}
                label=" المدرسة "
                error={errors?.school?.message}
                placeholder="اكتب المدرسة"
                register={register}
                name="school"
              />
            </Box>
            <Box w="55%">
              <MainInput
                type="datetime-local"
                label="تاريخ الحجز "
                error={errors?.reservation_date?.message}
                placeholder="اكتب تاريخ الحجز"
                register={register}
                defaultValues={dayjs(data?.reservation_date).format(
                  "YYYY-MM-DDThh:mm"
                )}
                name="reservation_date"
              />
            </Box>

            <Box w="55%">
              <FormLabel>المجموعة : </FormLabel>
              <Select
                defaultValue={{
                  label: data?.group?.name,
                  value: data?.group?.id,
                }}
                options={groupsOptions}
                onChange={(data) => {
                  setValue("student_group_id", data.value);
                }}
                styles={colourStyles}
                placeholder="الفصول"
              />
            </Box>
            <Box w="55%">
              <FormLabel>النوع : </FormLabel>

              <Stack
                direction="row"
                role="group"
                aria-labelledby="my-radio-group"
              >
                <label>
                  <input
                    {...register("gender", { required: true })}
                    type="radio"
                    value="Female"
                    defaultChecked={data?.gender == "Female" ? true : false}
                  />
                  طالبة
                </label>
                <label>
                  <input
                    {...register("gender", { required: true })}
                    type="radio"
                    value="Male"
                    defaultChecked={data?.gender == "Male" ? true : false}
                  />
                  طالب
                </label>
              </Stack>
            </Box>
            <Box w="55%">
              <FormLabel>طرق الدفع : </FormLabel>
              <Select
                defaultValue={{
                  label: data?.payment_exception?.name,
                  value: data?.payment_exception?.id,
                }}
                options={paymentExceptionOptions}
                onChange={(data) => {
                  setValue("payment_exception_id", data.value);
                }}
                styles={colourStyles}
                placeholder="طرق الدفع"
              />
            </Box>
          </Stack>
        </form>
      </MainModel>

      {/* <ShowPassword data={studentData} /> */}
    </>
  );
};

export default AddStudent;
