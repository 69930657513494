import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../../components/dashboard/Layout";
import GroupsTable from "../../../containers/admin/groups/table";
import SearchBar from "../../../containers/admin/groups/searchbar";
import { getAllGroupsRequest } from "../../../modules/admin-modules/groups/Actions";
import AddGroup from "../../../containers/admin/groups/models/add-group";
const Groups = () => {
  const dispatch = useDispatch();
  const model = useSelector((state) => state.model);
  const [isAdd, setAdd] = useState(false);
  const [selectedData, setSelectedData] = useState({});

  useEffect(() => {
    dispatch(getAllGroupsRequest());
  }, []);

  return (
    <Layout>
      <SearchBar setAdd={setAdd} setSelectedData={setSelectedData} />
      <GroupsTable setAdd={setAdd} setSelectedData={setSelectedData} />
      <AddGroup
        isAdd={isAdd}
        data={selectedData}
        setSelectedData={setSelectedData}
      />
    </Layout>
  );
};

export default Groups;
