import { Button, Flex, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { RiAddFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import LogoutMenu from "../../../components/dashboard/Layout/logout-menu";
import { openTagModel } from "../../../modules/admin-modules/models/Actions";

const SearchBar = ({ setAdd, setSelectedData }) => {
  const dispatch = useDispatch();
  const payments = useSelector((state) => state.tags.count);

  return (
    <VStack w="100%" my="30px">
      <Flex alignItems="center" w="100%" justifyContent="space-between">
        <Text fontSize="24px" fontWeight="600" color="main_1">
          العلامات
        </Text>

        <Flex alignItems="center">
          <Button
            borderRadius="16px"
            leftIcon={<RiAddFill />}
            mx="8px"
            color="white"
            bg="main_1"
            _focus={{ outline: "none" }}
            onClick={() => {
              setAdd(true);
              dispatch(openTagModel());
            }}
          >
            إضافة علامة
          </Button>

          <LogoutMenu />
        </Flex>
      </Flex>
      <Text w="100%" fontSize="20px" textAlign="right">
        {" "}
        عدد العلامات:{payments}
      </Text>
    </VStack>
  );
};

export default SearchBar;
