import Types from "./Types";

export const getAllSectionsRequest = (body) => ({
  type: Types.GET_SECTIONS_REQUEST,
  payload: body,
});

export const getAllSectionsSuccess = (data) => ({
  type: Types.GET_SECTIONS_SUCCESS,
  payload: data,
});

export const getAllSectionsFail = () => ({
  type: Types.GET_SECTIONS_FAIL,
});

export const getSectionRequest = (id) => ({
  type: Types.GET_SECTION_REQUEST,
  payload: id,
});

export const getSectionSuccess = (data) => ({
  type: Types.GET_SECTION_SUCCESS,
  payload: data,
});

export const getSectionFail = () => ({
  type: Types.GET_SECTION_FAIL,
});
//LOADING
export const sectionsLoading = (isLoading) => ({
  type: Types.SECTIONS_LOADING,
  payload: isLoading,
});
