export default {
  //get all VIDEOs
  GET_SECTIONS_REQUEST: "GET_SECTIONS_REQUEST",
  GET_SECTIONS_SUCCESS: "GET_SECTIONS_SUCCESS",
  GET_SECTIONS_FAIL: "GET_SECTIONS_FAIL",

  GET_SECTION_REQUEST: "GET_SECTION_REQUEST",
  GET_SECTION_SUCCESS: "GET_SECTION_SUCCESS",
  GET_SECTION_FAIL: "GET_SECTION_FAIL",
  //loading
  SECTIONS_LOADING: "SECTIONS_LOADING",
};
