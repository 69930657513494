import React from "react";
import {
  RiGlobalLine,
  RiLogoutBoxRLine,
  RiMenuLine,
  RiNotification4Line,
  RiUserLine,
} from "react-icons/ri";
import {
  Menu,
  MenuButton,
  MenuList,
  IconButton,
  MenuItem,
  VStack,
  Button,
  Icon,
  Text,
  Flex,
  Image,
  HStack,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { logoutUserAction } from "../../../modules/auth/Actions";
import bookmark from "../../../assets/icons/bookmark.svg";
import { useDispatch, useSelector } from "react-redux";
import { openUserDataModel } from "../../../modules/admin-modules/models/Actions";
import student from "../../../assets/icons/student.svg";

const MobileNavbar = () => {
  const userData = useSelector((state) => state.user.userData);

  const token = window.localStorage.getItem("token");
  const dispatch = useDispatch();
  return (
    <Menu>
      <MenuButton
        display={{ base: "flex", md: "none" }}
        alignItems="center"
        as={IconButton}
        aria-label="Options"
        icon={<RiMenuLine />}
        variant="outline"
        color="main_1"
        bg="white"
      />
      <MenuList>
        <Link to={"/home"}>
          <MenuItem color="main_1">Home</MenuItem>
        </Link>
        {/* <Link to={'/model-answers/chapters'}>
                    <MenuItem color='main_1'>
                        Model Answer
                    </MenuItem>
                </Link> */}
        <Link to={"/videos/sections"}>
          <MenuItem color="main_1">Videos</MenuItem>
        </Link>
        <Link to={"/exams/sections"}>
          <MenuItem color="main_1">Exams</MenuItem>
        </Link>
        <Link to={"/student/exams"}>
          <MenuItem color="main_1">Student Exams</MenuItem>
        </Link>

        <Link to={"/homeworks/sections"}>
          <MenuItem color="main_1">Homework</MenuItem>
        </Link>
        <Link to={"/study-with-enough/chapters"}>
          <MenuItem color="main_1">Study with enough</MenuItem>
        </Link>
        <Link to={"/questions"}>
          <MenuItem color="main_1">Missed questions</MenuItem>
        </Link>
        {token ? (
          <VStack display={{ base: "flex", md: "none" }} p="5px">
            <IconButton
              my="10px"
              w="100%"
              borderColor="main_1"
              aria-label="Send email"
              bg="white"
              icon={<RiNotification4Line color="main_1" fontSize="20px" />}
            />
            <Menu>
              <MenuButton
                w="100%"
                _focus={{ outline: "none" }}
                as={IconButton}
                borderColor="main_1"
                aria-label="Options"
                icon={<RiUserLine color="main_1" fontSize="20px" />}
                variant="outline"
              />
              <MenuList>
                <Link to="/questions">
                  <MenuItem p="7px" mt="10px">
                    <HStack spacing="7px">
                      <Image src={bookmark} minW="20px" />
                      <Text mx="5px">إشارات مرجعية</Text>
                    </HStack>
                  </MenuItem>
                </Link>
                <MenuItem
                  p="7px"
                  mt="10px"
                  onClick={() => dispatch(openUserDataModel())}
                >
                  <Flex>
                    <Image src={student} minW="20px" />
                    <Text mx="5px">بيانات الطالب</Text>
                  </Flex>
                </MenuItem>
                <MenuItem
                  p="7px"
                  mt="10px"
                  onClick={() => dispatch(logoutUserAction())}
                >
                  <Flex>
                    <Icon as={RiLogoutBoxRLine} fontSize="20px" />
                    <Text mx="5px">تسجيل خروج</Text>
                  </Flex>
                </MenuItem>
              </MenuList>
            </Menu>
          </VStack>
        ) : (
          <VStack display={{ base: "flex", md: "none" }} p="5px">
            <Button bg="main_1" color="white" mx="10px" my="10px" w="100%">
              <Link to="#register">register</Link>
            </Button>
            <Button bg="main_2" w="100%">
              <Link to="/login">login</Link>
            </Button>
          </VStack>
        )}
      </MenuList>
    </Menu>
  );
};

export default MobileNavbar;
