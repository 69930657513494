import Types from './Types';

const INITIAL_STATE = {
    bookmark: [

    ],
    missed: [

    ],
    isLoading: false,
    count: ''
};

export default function questions(state = INITIAL_STATE, action) {
    const { type, payload } = action;
    switch (type) {
        case Types.GET_BOOKMARKED_QUESTIONS_SUCCESS: {
            return {
                ...state,
                bookmark: payload.data,
                count: payload.data.length
            };
        }

        case Types.GET_MISSED_QUESTIONS_SUCCESS: {
            return {
                ...state,
                missed: payload.data,
                count: payload.data.length
            };
        }




        case Types.ADD_QUESTIONS_LOADING: {
            return {
                ...state,
                isLoading: payload
            };
        }




        default: {
            return state;
        }
    }
}
