import { VStack, HStack, Flex, Button, Text, Grid, Skeleton } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { RiDeleteBin7Line, RiDeleteBinLine, RiEditBoxLine, RiFileEditLine } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux';
import { deleteGroupRequest } from '../../../modules/admin-modules/groups/Actions';
import { openCreateGroup, openCreateStudent } from '../../../modules/admin-modules/models/Actions';

const GroupsTable = ({ setAdd, setSelectedData }) => {
    const groups = useSelector((state) => state.groups.groups)
    const dispatch = useDispatch()
    const isLoading = useSelector((state) => state.groups.iaLoading)




    return (
        <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap={6}>
            {isLoading ? <Skeleton>
                <div>contents wrapped</div>
                <div>won't be visible</div>
            </Skeleton> : groups.map((group, index) => {
                return (
                    <VStack

                        key={index}
                        spacing={4}
                        align='stretch'
                        border='1px solid'
                        borderColor='main_1'
                        borderRadius='8px'
                        p='10px'
                        fontSize='18px'
                    >
                        <HStack
                            justifyContent='space-between'
                        >
                            <Flex>
                                <Text> مجموعة : </Text>
                                <Text opacity='0.6'>{group?.name}</Text>
                            </Flex>

                            <Button leftIcon={<RiEditBoxLine />} bg='#f5f5f5' variant='outline' onClick={() => {
                                setSelectedData({ ...group, 'subject_id': group?.subject?.id })

                                setAdd(false);
                                dispatch(openCreateGroup())
                            }}>
                                تعديل
                            </Button>


                        </HStack>
                        <HStack
                            justifyContent='space-between'
                        >
                            <Flex>
                                <Text> الصف : </Text>
                                <Text opacity='0.6'>{group?.grade?.name_ar}</Text>
                            </Flex>

                            <Button leftIcon={<RiDeleteBinLine color='red' />} bg='#e4e4e4' variant='outline' onClick={() => dispatch(deleteGroupRequest(group?.id))}>
                                حذف
                            </Button>

                        </HStack>
                    </VStack>
                )
            })
            }
        </Grid >
    )
}

export default GroupsTable