export default {
  //get all PLAYLISTs
  GET_PLAYLISTS_REQUEST: "GET_PLAYLISTS_REQUEST",
  GET_PLAYLISTS_SUCCESS: "GET_PLAYLISTS_SUCCESS",
  GET_PLAYLISTS_FAIL: "GET_PLAYLISTS_FAIL",

  //edit PLAYLISTs
  EDIT_PLAYLIST_REQUEST: "EDIT_PLAYLIST_REQUEST",
  EDIT_PLAYLIST_SUCCESS: "EDIT_PLAYLIST_SUCCESS",
  EDIT_PLAYLIST_FAIL: "EDIT_PLAYLIST_FAIL",

  //create PLAYLIST
  CREATE_PLAYLIST_REQUEST: "CREATE_PLAYLIST_REQUEST",
  CREATE_PLAYLIST_SUCCESS: "CREATE_PLAYLIST_SUCCESS",
  CREATE_PLAYLIST_FAIL: "CREATE_PLAYLIST_FAIL",

  DELETE_PLAYLIST_REQUEST: "DELETE_PLAYLIST_REQUEST",
  DELETE_PLAYLIST_SUCCESS: "DELETE_PLAYLIST_SUCCESS",
  DELETE_PLAYLIST_FAIL: "DELETE_PLAYLIST_FAIL",

  GET_PLAYLIST_REQUEST: "GET_PLAYLIST_REQUEST",
  GET_PLAYLIST_SUCCESS: "GET_PLAYLIST_SUCCESS",
  GET_PLAYLIST_FAIL: "GET_PLAYLIST_FAIL",

  //loading
  ADD_PLAYLISTS_LOADING: "ADD_PLAYLISTS_LOADING",
};
