import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../../components/dashboard/Layout";
import AddQuestion from "../../../containers/admin/questions-bank/models/add-question";
import SearchBar from "../../../containers/admin/questions-bank/searchbar";
import QuestionsBankTable from "../../../containers/admin/questions-bank/table";
import { getAllQuestionsBankRequest } from "../../../modules/admin-modules/questions-bank/Actions";
const QuestionsBank = () => {
  const dispatch = useDispatch();
  const model = useSelector((state) => state.model);
  const [isAdd, setAdd] = useState(false);
  const [chapterId, setChapterId] = useState(false);

  const [selectedData, setSelectedData] = useState({});

  useEffect(() => {
    if (chapterId) dispatch(getAllQuestionsBankRequest(chapterId));
  }, [chapterId]);

  return (
    <Layout>
      <SearchBar
        setAdd={setAdd}
        setSelectedData={setSelectedData}
        setChapterId={setChapterId}
      />
      <QuestionsBankTable setAdd={setAdd} setSelectedData={setSelectedData} />
      <AddQuestion
        isAdd={isAdd}
        data={selectedData}
        setSelectedData={setSelectedData}
      />
    </Layout>
  );
};

export default QuestionsBank;
