import { toast } from "react-toastify";
import { call, put, takeLatest } from "redux-saga/effects";
import * as actions from "./Actions";
import * as api from "./Api";
import Types from "./Types";

function* getAllHomeworks({ payload }) {
  try {
    yield put(actions.addHomeworkLoading(true));
    const result = yield call(api.getAllHomeworks);
    yield put(actions.getAllHomeworksSuccess(result.data));
  } catch (error) {
    const { response, message } = error;
    toast.error(error);
    yield put(actions.getAllHomeworksFail());
  } finally {
    yield put(actions.addHomeworkLoading(false));
  }
}
function* getStudentHomeworkQuiz({ payload }) {
  try {
    yield put(actions.addHomeworkLoading(true));
    const result = yield call(api.getStudentHomeworkQuiz);
    yield put(actions.getStudentHomeworkQuizSuccess(result.data));
  } catch (error) {
    const { response, message } = error;
    toast.error(error);
    yield put(actions.getStudentHomeworkQuizFail());
  } finally {
    yield put(actions.addHomeworkLoading(false));
  }
}
//Edit
function* editHomework({ payload }) {
  try {
    yield put(actions.addHomeworkLoading(true));
    const result = yield call(api.editHomeworks, payload);
    yield put(actions.editHomeworkSuccess(result.data));
    toast.success("Edit Successfully");
    payload?.action && payload?.action();
  } catch (error) {
    yield put(actions.editHomeworkFail());
  } finally {
    yield put(actions.addHomeworkLoading(false));
  }
}

// create
function* createHomework(formData) {
  try {
    yield put(actions.addHomeworkLoading(true));
    const result = yield call(api.createHomeworks, formData.payload);
    yield put(
      actions.createHomeworkSuccess({
        data: result.data,
      })
    );
    toast.success("Created Successfully");
    formData?.action && formData?.action();
  } catch (error) {
    yield put(actions.createHomeworkFail());
  } finally {
    yield put(actions.addHomeworkLoading(false));
  }
}

function* deleteHomework({ payload }) {
  try {
    yield put(actions.addHomeworkLoading(true));
    const result = yield call(api.deleteHomework, payload);

    yield put(actions.deleteHomeworkSuccess(payload));

    toast.success("Deleted Successfully");
  } catch (error) {
    const { response, message } = error;
    toast.error(error.response);
    yield put(actions.deleteHomeworkFail());
  } finally {
    yield put(actions.addHomeworkLoading(false));
  }
}

function* filterHomework({ payload }) {
  try {
    yield put(actions.addHomeworkLoading(true));
    const result = yield call(api.filterHomework, payload);

    yield put(actions.filterHomeworkSuccess(payload));

    toast.success("Deleted Successfully");
  } catch (error) {
    const { response, message } = error;
    toast.error(error.response);
    yield put(actions.filterHomeworkFail());
  } finally {
    yield put(actions.addHomeworkLoading(false));
  }
}

export default function* HomeworkSaga() {
  yield takeLatest(Types.GET_HOMEWORKS_REQUEST, getAllHomeworks);
  yield takeLatest(Types.EDIT_HOMEWORK_REQUEST, editHomework);
  yield takeLatest(Types.CREATE_HOMEWORK_REQUEST, createHomework);
  yield takeLatest(Types.DELETE_HOMEWORK_REQUEST, deleteHomework);
  yield takeLatest(Types.FILTER_HOMEWORK_REQUEST, filterHomework);
  yield takeLatest(
    Types.GET_ADMIN_STUDENT_HOMEWORK_QUIZ_REQUEST,
    getStudentHomeworkQuiz
  );
}
