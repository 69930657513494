import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../../components/dashboard/Layout";
import AddVideo from "../../../containers/admin/videos/models/add-video";
import AddVideoFiels from "../../../containers/admin/videos/models/add-video-fiels";
import SearchBar from "../../../containers/admin/videos/searchbar";
import VideosTable from "../../../containers/admin/videos/table";
import { getAllVideosRequest } from "../../../modules/admin-modules/videos/Actions";
const Videos = () => {
  const dispatch = useDispatch();
  const model = useSelector((state) => state.model);
  const [isAdd, setAdd] = useState(false);
  const [selectedData, setSelectedData] = useState({});

  useEffect(() => {
    dispatch(getAllVideosRequest());
  }, []);

  return (
    <Layout>
      <SearchBar setAdd={setAdd} setSelectedData={setSelectedData} />
      <VideosTable setAdd={setAdd} setSelectedData={setSelectedData} />
      <AddVideo
        isAdd={isAdd}
        data={selectedData}
        setSelectedData={setSelectedData}
      />
      <AddVideoFiels isAdd={isAdd} data={selectedData} />
    </Layout>
  );
};

export default Videos;
