import Types from './Types';


export const getAllTeacherAssistantsRequest = (body) => ({
    type: Types.GET_TEACHER_ASSISTANTS_REQUEST,
    payload: body
});

export const getAllTeacherAssistantsSuccess = (data) => ({
    type: Types.GET_TEACHER_ASSISTANTS_SUCCESS,
    payload: data
});

export const getAllTeacherAssistantsFail = () => ({
    type: Types.GET_TEACHER_ASSISTANTS_FAIL
});


export const editTeacherAssistantRequest = (formData, action) => ({
    type: Types.EDIT_TEACHER_ASSISTANT_REQUEST,
    payload: formData,
    action: action
});

export const editTeacherAssistantSuccess = (data) => ({
    type: Types.EDIT_TEACHER_ASSISTANT_SUCCESS,
    payload: data
});

export const editTeacherAssistantFail = () => ({
    type: Types.EDIT_TEACHER_ASSISTANT_FAIL
});

//LOADING
export const addTeacherAssistantLoading = (isLoading) => ({
    type: Types.ADD_TEACHER_ASSISTANTS_LOADING,
    payload: isLoading
});

// Create TeacherAssistant
export const createTeacherAssistantRequest = (formData, action) => ({
    type: Types.CREATE_TEACHER_ASSISTANT_REQUEST,
    payload: formData,
    action: action
});

export const createTeacherAssistantSuccess = ({ data }) => ({
    type: Types.CREATE_TEACHER_ASSISTANT_SUCCESS,
    payload: {
        data
    }
});

export const createTeacherAssistantFail = () => ({
    type: Types.CREATE_TEACHER_ASSISTANT_FAIL
});

export const deleteTeacherAssistantRequest = (id) => ({
    type: Types.DELETE_TEACHER_ASSISTANT_REQUEST,
    payload: id
});

export const deleteTeacherAssistantSuccess = (id) => ({
    type: Types.DELETE_TEACHER_ASSISTANT_SUCCESS,
    payload: id
});

export const deleteTeacherAssistantFail = () => ({
    type: Types.DELETE_TEACHER_ASSISTANT_FAIL
});
