import Types from './Types';

const INITIAL_STATE = {
    studentChapters: [],
    chapters: [],
    isLoading: false,
    count: ''
};

export default function studentChapters(state = INITIAL_STATE, action) {
    const { type, payload } = action;
    switch (type) {
        case Types.GET_STUDENT_CHAPTERS_SUCCESS: {
            return {
                ...state,
                studentChapters: payload.data,
                count: payload.data.length
            };
        }
        case Types.GET_ALL_STUDENT_CHAPTERS_SUCCESS: {
            return {
                ...state,
                chapters: payload.data,
                count: payload.data.length
            };
        }

        case Types.ADD_STUDENT_CHAPTERS_LOADING: {
            return {
                ...state,
                isLoading: payload
            };
        }

        case Types.DELETE_VIDEO_SUCCESS: {
            const newvideos = state.videos.filter((item) => item.id !== payload);
            return {
                ...state,
                videos: newvideos
            };
        }

        default: {
            return state;
        }
    }
}
