import { toast } from "react-toastify";
import { call, put, takeLatest } from "redux-saga/effects";
import * as actions from "./Actions";
import * as api from "./Api";
import Types from "./Types";

function* getHomeworksByChapterID({ payload }) {
  try {
    yield put(actions.addHomeworksLoading(true));
    const result = yield call(api.getHomeworsByChapterID, payload);
    yield put(actions.getHomeworksByChapterIdSuccess(result.data));
  } catch (error) {
    const { response, message } = error;
    toast.error(error);
    //console.log(error, "error");
    yield put(actions.getHomeworksByChapterIdFail());
  } finally {
    yield put(actions.addHomeworksLoading(false));
  }
}

function* getHomeworkQuizByChapterID({ payload }) {
  try {
    yield put(actions.addHomeworksLoading(true));
    const result = yield call(api.getHomeworQuizByChapterID, payload);
    yield put(actions.getHomeworkQuizByChapterIdSuccess(result.data));
  } catch (error) {
    const { response, message } = error;
    toast.error(error);
    //console.log(error, "error");
    yield put(actions.getHomeworkQuizByChapterIdFail());
  } finally {
    yield put(actions.addHomeworksLoading(false));
  }
}

function* getHomeworkByID({ payload }) {
  try {
    yield put(actions.addHomeworksLoading(true));
    const result = yield call(api.getHomeworkByID, payload);
    yield put(actions.getHomeworkByIdSuccess(result.data));
  } catch (error) {
    const { response, message } = error;
    toast.error(error);
    //console.log(error, "error");
    yield put(actions.getHomeworkByIdFail());
  } finally {
    yield put(actions.addHomeworksLoading(false));
  }
}

//Edit Tables
function* getHomeworkOtp({ payload }) {
  try {
    yield put(actions.addHomeworksLoading(true));
    const result = yield call(api.getOtp, payload);
    yield put(actions.getHomeworkOtpSuccess(result.data));
    payload?.action && payload?.action();
  } catch (error) {
    yield put(actions.getHomeworkOtpFail());
  } finally {
    yield put(actions.addHomeworksLoading(false));
  }
}

// create
function* updateSeenCount({ payload }) {
  try {
    yield put(actions.HomeworkSeenLoading(true));
    const result = yield call(api.updateSeenCount, payload);
    yield put(actions.updateSeenCountSuccess({ payload, result }));
  } catch (error) {
    yield put(actions.updateSeenCountFail());
  } finally {
    yield put(actions.HomeworkSeenLoading(false));
  }
}
function* submitHomework({ payload }) {
  //console.log(payload);
  try {
    yield put(actions.addHomeworksLoading(true));
    const result = yield call(api.solveHomework, payload);
    payload?.action && payload?.action();
    result?.data?.message
      ? toast.error(result?.data?.message)
      : toast.success("successfully uploaded ");
    yield put(actions.solveHomeworkSuccess(result));
  } catch (error) {
    //console.log(error.response?.data?.message);
    error.response?.data?.message?.file?.map((data) => {
      toast.error(data);
    });
    yield put(actions.solveHomeworkFail());
  } finally {
    yield put(actions.addHomeworksLoading(false));
  }
}

function* addVoucher({ payload }) {
  try {
    yield put(actions.HomeworkVoucherLoading(true));
    const result = yield call(api.addVoucher, payload?.formData?.formData);
    // yield put(
    //     actions.addVoucherSuccess(payload?.formData?.Homework_id)
    // );

    toast.success("طلبك  مقبول");
    window.location.reload();
  } catch (error) {
    toast.error("طلبك  مرفوض");
    yield put(actions.addVoucherFail());
  } finally {
    yield put(actions.HomeworkVoucherLoading(false));
  }
}

function* moreHomeworkSeen({ payload }) {
  try {
    // yield put(actions.HomeworkSeenLoading(true));
    const result = yield call(api.moreHomeworkViews, payload);
    yield put(actions.moreHomeworkViewsSuccess({ payload, result }));
    toast.success("طلبك قيد المراجعة");
  } catch (error) {
    yield put(actions.moreHomeworkViewsFail());
  } finally {
    // yield put(actions.HomeworkSeenLoading(false));
  }
}
export default function* chapterHomeworkSaga() {
  yield takeLatest(
    Types.GET_HOMEWORKS_BY_CHAPTER_ID_REQUEST,
    getHomeworksByChapterID
  );
  yield takeLatest(
    Types.GET_HOMEWORK_QUIZ_BY_CHAPTER_ID_REQUEST,
    getHomeworkQuizByChapterID
  );

  yield takeLatest(Types.GET_HOMEWORK_BY_ID_REQUEST, getHomeworkByID);
  yield takeLatest(Types.SOLVE_HOMEWORK_REQUEST, submitHomework);
  yield takeLatest(Types.MORE_HOMEWORK_VIEWS_REQUEST, moreHomeworkSeen);
  yield takeLatest(Types.ADD_VOUCHER_REQUEST, addVoucher);
}
