import { Text } from '@chakra-ui/react'
import React from 'react'
import { Link } from 'react-router-dom'
const NavLinks = () => {
    const routes = [,
        { label: 'Services', link: 'services' },
        { label: 'Summaries', link: 'summaries' },
        { label: 'Resources', link: 'resources' },
        { label: 'Reviews', link: 'reviews' },
        { label: 'About us', link: 'aboutus' },
        { label: 'Contact us', link: 'contactus' }
    ]
    return (
        <React.Fragment>
            <Text _hover={{ color: 'main_2' }} fontSize='14px'>
                <Link to={`/`}>
                    Home
                </Link>
            </Text>
            {
                routes?.map((data) => {
                    return <Text _hover={{ color: 'main_2' }} fontSize='14px'>
                        <a href={`#${data.link}`}>
                            {data.label}
                        </a>
                    </Text>
                })
            }


        </React.Fragment>
    )
}

export default NavLinks