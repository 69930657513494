import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PublicLayout from "../components/public/Layout/layout";
import Header from "../components/student/Header/index";
import AboutUs from "../containers/student/home/aboutUs";
import ContsctUs from "../containers/student/home/contsctUs";
import DownloadAppComp from "../containers/student/home/downloadAppComp";
import LatestCourses from "../containers/student/home/latestCourses";
import News from "../containers/student/home/newsComp";
import RecordesComp from "../containers/student/home/recordes";
import RegisterComp from "../containers/student/home/registerComp";
import Services from "../containers/student/home/services";
import { getUserDataRequest } from "../modules/user/Actions";

const Home = () => {
  const dispatch = useDispatch();
  const token = window.localStorage.getItem("token");
  const data = useSelector((state) => state.user.userData);

  useEffect(() => {
    if (token) {
      dispatch(getUserDataRequest());
    }
  }, [token]);

  useEffect(() => {
    if (data?.role?.length > 0) {
      if (data?.role[0].name == "Student") {
        window.location.href = "/home";
      } else {
        window.location.href = "/dashboard/students";
      }
    }
  }, [data]);
  return (
    <PublicLayout color="white" bg="main_1">
      <Header />
      <Services />
      <LatestCourses />
      <RegisterComp />
      <RecordesComp />
      {/* <SummeryComp /> */}
      {/* <StudentComp /> */}
      {/* <ReviewsComp /> */}
      <DownloadAppComp />
      <AboutUs />
      <ContsctUs />
      <News />
    </PublicLayout>
  );
};

export default Home;
