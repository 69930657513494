export default {
    //get all LESSONs
    GET_LESSONS_REQUEST: 'GET_LESSONS_REQUEST',
    GET_LESSONS_SUCCESS: 'GET_LESSONS_SUCCESS',
    GET_LESSONS_FAIL: 'GET_LESSONS_FAIL',

    //edit LESSONs
    EDIT_LESSON_REQUEST: 'EDIT_LESSON_REQUEST',
    EDIT_LESSON_SUCCESS: 'EDIT_LESSON_SUCCESS',
    EDIT_LESSON_FAIL: 'EDIT_LESSON_FAIL',

    //create LESSON
    CREATE_LESSON_REQUEST: 'CREATE_LESSON_REQUEST',
    CREATE_LESSON_SUCCESS: 'CREATE_LESSON_SUCCESS',
    CREATE_LESSON_FAIL: 'CREATE_LESSON_FAIL',

    DELETE_LESSON_REQUEST: 'DELETE_LESSON_REQUEST',
    DELETE_LESSON_SUCCESS: 'DELETE_LESSON_SUCCESS',
    DELETE_LESSON_FAIL: 'DELETE_LESSON_FAIL',

    GET_LESSON_REQUEST: 'GET_LESSON_REQUEST',
    GET_LESSON_SUCCESS: 'GET_LESSON_SUCCESS',
    GET_LESSON_FAIL: 'GET_LESSON_FAIL',

    //loading
    ADD_LESSONS_LOADING: 'ADD_LESSONS_LOADING'
};
