import Types from './Types';

const INITIAL_STATE = {
    lectures: [],
    lecture: {},
    isLoading: false,
    count: ''
};

export default function lectures(state = INITIAL_STATE, action) {
    const { type, payload } = action;
    switch (type) {
        case Types.GET_LECTURES_SUCCESS: {
            return {
                ...state,
                lectures: payload.data,
                count: payload.data.length
            };
        }

        case Types.GET_LECTURE_SUCCESS: {
            return {
                ...state,
                lecture: payload
            };
        }

        case Types.EDIT_LECTURE_SUCCESS: {
            let data = payload.data;
            const lectures = state.lectures;
            let idx = 0;
            lectures.forEach((table, index) => {
                if (table.id === data.id) {
                    idx = index;
                    return;
                }
            });

            lectures[idx] = data;
            return {
                ...state,
                lectures: lectures
            };
        }

        // create
        case Types.CREATE_LECTURE_SUCCESS: {
            const data = payload.data.data;
            let newArray = state.lectures.slice();
            let index = state.lectures.length + 1;
            newArray.splice(index, 0, data);
            return {
                ...state,
                lectures: newArray
            };
        }
        case Types.ADD_LECTURES_LOADING: {
            return {
                ...state,
                isLoading: payload
            };
        }

        case Types.DELETE_LECTURE_SUCCESS: {
            const newlectures = state.lectures.filter((item) => item.id !== payload);
            return {
                ...state,
                lectures: newlectures
            };
        }
        default: {
            return state;
        }
    }
}
