import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../../components/dashboard/Layout";
import SearchBar from "../../../containers/admin/teachers/searchbar";
import StudentsTable from "../../../containers/admin/teachers/table";
import { loginUserAction } from "../../../modules/auth/Actions";
import { getAllTeachersRequest } from "../../../modules/admin-modules/teachers/Actions";
import AddTeacher from "../../../containers/admin/teachers/models/add-teacher";
const Teachers = () => {
  const dispatch = useDispatch();
  const model = useSelector((state) => state.model);
  const [isAdd, setAdd] = useState(false);
  const [selectedData, setSelectedData] = useState({});

  useEffect(() => {
    dispatch(getAllTeachersRequest());
  }, []);

  return (
    <Layout>
      <SearchBar setAdd={setAdd} setSelectedData={setSelectedData} />
      <StudentsTable setAdd={setAdd} setSelectedData={setSelectedData} />
      <AddTeacher
        isAdd={isAdd}
        data={selectedData}
        setSelectedData={setSelectedData}
      />
    </Layout>
  );
};

export default Teachers;
