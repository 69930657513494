import { toast } from "react-toastify";
import { call, fork, put, takeLatest } from "redux-saga/effects";
import * as actions from "./Actions";
import * as api from "./Api";
import Types from "./Types";

function* getLessonsByChapterID({ payload }) {
  try {
    yield put(actions.addLessonsLoading(true));
    const result = yield call(api.getLessonsByChapterID, payload);
    yield put(actions.getLessonsByChapterIdSuccess(result.data));
  } catch (error) {
    const { response, message } = error;
    toast.error(error);
    //console.log(error, 'error');
    yield put(actions.getLessonsByChapterIdFail());
  } finally {
    yield put(actions.addLessonsLoading(false));
  }
}

export default function* chapterLessonSaga() {
  yield takeLatest(
    Types.GET_LESSONS_BY_CHAPTER_ID_REQUEST,
    getLessonsByChapterID
  );
}
