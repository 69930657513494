import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field, Formik, Form } from "formik";
import {
  Button,
  FormLabel,
  Heading,
  Text,
  Flex,
  Box,
  Icon,
  Divider,
} from "@chakra-ui/react";
import { loginUserAction } from "../../modules/auth/Actions";
import Cookies from "js-cookie";
import { BiLock, BiEnvelope, BiShow, BiLowVision } from "react-icons/bi";
import VousherLogin from "./vousherLogin";
import PublicLayout from "../public/Layout/layout";
const LoginForm = (props) => {
  const token = Cookies.get("token");
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.auth.isLoading);
  const [inputType, setInputType] = useState("password");
  const validateUserName = (value) => {
    let error;
    if (!value) {
      error = "Required";
    }
    return error;
  };
  const validatePassword = (value) => {
    let error;
    if (!value) {
      error = "Required";
    }
    return error;
  };
  return (
    <PublicLayout color="main_1" bg="white" register={false}>
      <Flex
        bg="whiteAlpha.700"
        flexDirection="column"
        alignItems="center"
        mt="150px"
        w="100%"
        h="100%"
      >
        <Formik
          initialValues={{
            phone: "",
            password: "",
          }}
          onSubmit={(values) => {
            let formData = new FormData();
            formData.append("phone", values.phone);
            formData.append("password", values.password);

            dispatch(loginUserAction(formData));
          }}
        >
          {({ values, errors, touched }) => (
            <Form style={{ width: "50%" }}>
              <Flex flexDirection={"column"} w="100%">
                <Heading textAlign={"center"} mb="10px">
                  Login
                </Heading>
                <Box w="100%">
                  <Flex alignItems={"center"}>
                    <FormLabel
                      htmlFor="phone"
                      className="form-label "
                      color={"main_1"}
                      fontSize="26px"
                    >
                      Phone
                    </FormLabel>
                  </Flex>

                  <Field
                    style={{
                      width: "100%",
                      padding: "20px",
                      objectFit: "contain",
                      borderRadius: "10px",
                      boxShadow: "inset 0 0 16px 0 rgba(0, 0, 0, 0.16)",
                      border: "solid 1px #010038",
                      backgroundColor: "#fff",
                    }}
                    name="phone"
                    validate={validateUserName}
                  />
                  {errors.phone && touched.phone && (
                    <Text color="red">{errors.phone}</Text>
                  )}
                </Box>
                <Box mt="35px" position={"relative"} w="100%">
                  <Flex alignItems={"center"}>
                    <FormLabel
                      htmlFor="password"
                      className="form-label "
                      color={"main_1"}
                      fontSize="26px"
                    >
                      Password
                    </FormLabel>
                  </Flex>

                  <Field
                    style={{
                      padding: "20px",
                      objectFit: "contain",
                      borderRadius: "10px",
                      boxShadow: "inset 0 0 16px 0 rgba(0, 0, 0, 0.16)",
                      border: "solid 1px #010038",
                      backgroundColor: "#fff",
                      width: "100%",
                    }}
                    name="password"
                    validate={validatePassword}
                    type={inputType}
                  />

                  <Icon
                    position={"absolute"}
                    right="10px"
                    top="60px"
                    fontSize="33px"
                    color="main_1"
                    as={inputType == "password" ? BiShow : BiLowVision}
                    onClick={() =>
                      inputType == "password"
                        ? setInputType("text")
                        : setInputType("password")
                    }
                  />
                  {errors.password && touched.password && (
                    <Text color="red" w="180px" textAlign="justify">
                      {errors.password}
                    </Text>
                  )}
                </Box>
                <Button
                  mt="40px"
                  type="submit"
                  w="250px"
                  p="22px"
                  alignSelf={"center"}
                  isLoading={isLoading}
                  bg="main_1"
                  color="white"
                >
                  Login
                </Button>
              </Flex>
            </Form>
          )}
        </Formik>
        <Flex
          my="20px"
          alignItems={"baseline"}
          justifyContent="center"
          color="gray.400"
        >
          {" "}
          <Divider w="500px" mx="10px" /> OR <Divider w="500px" mx="10px" />
        </Flex>
        <VousherLogin />
      </Flex>
    </PublicLayout>
  );
};

export default LoginForm;
