import {
  Box,
  Button,
  Flex,
  FormLabel,
  Heading,
  HStack,
  Input,
  Text,
  Textarea,
} from "@chakra-ui/react";
import React from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import MainModel from "../../../components/Models/main-model";
import { closeSolveHomeworkModel } from "../../../modules/admin-modules/models/Actions";
import { solveHomeworkRequest } from "../../../modules/students-modules/homework/Actions";

const SolveHomework = ({ data, setSelectedData }) => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.model.isOpen23);
  const isLoading = useSelector((state) => state.questionsBank.isLoading);
  console.log(data, "data");
  const {
    getValues,
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: data,
    shouldUnregister: true,
  });
  const fileName = watch("file");
  const header = (
    <Box>
      <Heading
        textAlign="center"
        color="main_1"
        fontSize="24px"
        textTransform="capitalize"
      >
        {data?.name}
      </Heading>
    </Box>
  );
  console.log(data);
  const onSubmit = async (values) => {
    const action = () => {
      dispatch(closeSolveHomeworkModel());
      setSelectedData({});
      reset();
    };

    let formData = new FormData();
    formData.append("file", values?.file);
    formData.append("comment", values?.comment);
    formData.append("homework_id", data?.id);
    dispatch(
      solveHomeworkRequest({
        formData: formData,
        action: action,
      })
    );
  };
  const closeFunc = () => {
    dispatch(closeSolveHomeworkModel());
    setSelectedData({});
    reset();
  };

  return (
    <MainModel
      isLoading={isLoading}
      header={header}
      closeFunc={closeFunc}
      openVar={isOpen}
      buttonTitle={" Save "}
      buttonFunc={handleSubmit(onSubmit)}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex w="100%" flexWrap="wrap" justifyContent="space-between" gap="6">
          <Box w="100%">
            <FormLabel> You must add your information here:</FormLabel>
            <Textarea
              name="comment"
              placeholder="comment"
              defaultValue={data?.comment}
              error={errors?.comment?.message}
              {...register("comment", {
                required: "يجب كتابة البيانات ",
              })}
            />
            <Text color="red">{errors?.comment?.message}</Text>
          </Box>

          <HStack w="100%" my="20px" justifyContent="space-between">
            <HStack w="50%">
              <FormLabel> Answer file </FormLabel>
              <Button
                bg="main_1"
                color="white"
                borderRadius="8px"
                textAlign="center"
                position="relative"
              >
                <Text>{fileName?.name ? fileName?.name : "Choose file"}</Text>
                <Input
                  onChange={(e) => {
                    setValue("file", e.target.files[0]);
                  }}
                  accept=".pdf"
                  type="file"
                  placeholder="Choose file"
                  opacity="0"
                  position="absolute"
                  w="100%"
                  h="100%"
                />
              </Button>
            </HStack>
          </HStack>
          <Text color="red">-size limit 10MB - pdf files only</Text>
        </Flex>
      </form>
    </MainModel>
  );
};

export default SolveHomework;
