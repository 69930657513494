import { IconButton, Switch } from "@chakra-ui/react";
import React, { useState } from "react";
import { RiDeleteBinLine, RiFileEditLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import CTable from "../../../components/Table/table";
import {
  activeChapterRequest,
  asqueraChapterRequest,
  deleteChapterRequest,
  restrictChapterRequest,
} from "../../../modules/admin-modules/chapters/Actions";
import { openCreateChapterModel } from "../../../modules/admin-modules/models/Actions";

const ChaptersTable = ({ setAdd, setSelectedData }) => {
  const chapters = useSelector((state) => state.chapters.chapters);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.chapters.isLoading);
  const [offset, setPage] = useState(0);
  const [size, setPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [selected, setSelected] = useState({
    id: "",
  });
  const viewData = (data) => {
    setSelectedData(data);
    setSelected(data);
  };
  const Data = chapters?.map((data) => {
    return {
      ...data,
      id: data?.id,
      name: data?.name,
      restrict: (
        <Switch
          onChange={() => {
            let formData = new FormData();
            formData.append("restrict", data?.restrict == 0 ? 0 : 1);
            formData.append("_method", "PUT");
            dispatch(
              restrictChapterRequest({
                formData: formData,
                id: data?.id,
              })
            );
          }}
          isChecked={data?.restrict == 0 ? false : true}
        />
      ),
      asquera: (
        <Switch
          onChange={() => {
            let formData = new FormData();
            formData.append("is_asquera", data?.is_asquera == 0 ? 1 : 0);
            formData.append("_method", "PUT");
            dispatch(
              asqueraChapterRequest({
                formData: formData,
                id: data?.id,
              })
            );
          }}
          isChecked={data?.is_asquera == 0 ? false : true}
        />
      ),

      active: (
        <Switch
          onChange={() => {
            let formData = new FormData();
            formData.append("active", data?.active == 0 ? 0 : 1);
            formData.append("_method", "PUT");
            dispatch(
              activeChapterRequest({
                formData: formData,
                id: data?.id,
              })
            );
          }}
          isChecked={data?.active == 0 ? false : true}
        />
      ),
      edit: (
        <IconButton
          variant="outline"
          border="0"
          color="gray.600"
          aria-label="Call Sage"
          fontSize="20px"
          icon={<RiFileEditLine />}
          onClick={() => {
            setAdd(false);
            dispatch(openCreateChapterModel());
          }}
        />
      ),
      delete: (
        <IconButton
          variant="outline"
          border="0"
          color="gray.600"
          aria-label="Call Sage"
          fontSize="20px"
          icon={<RiDeleteBinLine color="red" />}
          onClick={() => dispatch(deleteChapterRequest(data?.id))}
        />
      ),
    };
  });
  const columns = [
    {
      Header: " الفصل",
      accessor: "name",
    },
    {
      Header: " Active",
      accessor: "active",
    },
    {
      Header: " Restrict",
      accessor: "restrict",
    },
    {
      Header: " is Asquera",
      accessor: "asquera",
    },

    {
      Header: "تعديل",
      accessor: "edit",
    },
    {
      Header: "حذف",
      accessor: "delete",
    },
  ];

  return (
    <CTable
      selectedData={viewData}
      Columns={columns}
      Data={Data}
      path={"openModel"}
      Actions
      Title={``}
      subTitle=""
      btnTitle=""
      placeHolder=""
      noSearchBar={true}
      noFilter={true}
      footerBtnTitle
      filterList
      addButton
      addButtonTitle=""
      setPage={setPage}
      setPerPage={setPerPage}
      currentpage={pageNumber}
      setPageNumber={setPageNumber}
      noSecondSearchBar={true}
      isLoading={isLoading}
      perPage={size}
      totalPage={Math.ceil(chapters?.length / 10)}
    />
  );
};

export default ChaptersTable;
