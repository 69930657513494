import { IconButton } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { RiDeleteBinLine, RiFileEditLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import CTable from "../../../components/Table/table";

import { openAddSectionkModel } from "../../../modules/admin-modules/models/Actions";
import { deleteAdminSectionRequest } from "../../../modules/admin-modules/sections/Actions";

const SectionsTable = ({ setAdd, setSelectedData }) => {
  const sections = useSelector((state) => state.adminSections.sections);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.adminSections.isLoading);
  const [offset, setPage] = useState(0);
  const [size, setPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [selected, setSelected] = useState({
    id: "",
  });
  const viewData = (data) => {
    setSelectedData(data);
    setSelected(data);
  };
  const Data = sections?.map((data) => {
    return {
      ...data,
      id: data?.id,
      name: data?.name,

      edit: (
        <IconButton
          variant="outline"
          border="0"
          color="gray.600"
          aria-label="Call Sage"
          fontSize="20px"
          icon={<RiFileEditLine />}
          onClick={() => {
            setAdd(false);
            dispatch(openAddSectionkModel());
          }}
        />
      ),
      delete: (
        <IconButton
          variant="outline"
          border="0"
          color="gray.600"
          aria-label="Call Sage"
          fontSize="20px"
          icon={<RiDeleteBinLine color="red" />}
          onClick={() => dispatch(deleteAdminSectionRequest(data?.id))}
        />
      ),
    };
  });
  const columns = [
    {
      Header: " ألقسم",
      accessor: "name",
    },

    {
      Header: "تعديل",
      accessor: "edit",
    },
    // {
    //   Header: "حذف",
    //   accessor: "delete",
    // },
  ];

  return (
    <CTable
      selectedData={viewData}
      Columns={columns}
      Data={Data}
      path={"openModel"}
      Actions
      Title={``}
      subTitle=""
      btnTitle=""
      placeHolder=""
      noSearchBar={true}
      noFilter={true}
      footerBtnTitle
      filterList
      addButton
      addButtonTitle=""
      setPage={setPage}
      setPerPage={setPerPage}
      currentpage={pageNumber}
      setPageNumber={setPageNumber}
      noSecondSearchBar={true}
      isLoading={isLoading}
      perPage={size}
      totalPage={Math.ceil(sections?.length / 10)}
    />
  );
};

export default SectionsTable;
