import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../../components/dashboard/Layout";
import { getAllPlaylistsRequest } from "../../../modules/admin-modules/playlists/Actions";
import AddPlaylist from "../../../containers/admin/playlist/models/add-playlist";
import PlaylistsTable from "../../../containers/admin/playlist/table";
import SearchBar from "../../../containers/admin/playlist/searchbar";

const Playlists = () => {
  const dispatch = useDispatch();
  const [isAdd, setAdd] = useState(false);
  const [selectedData, setSelectedData] = useState({});

  useEffect(() => {
    dispatch(getAllPlaylistsRequest());
  }, []);

  return (
    <Layout>
      <SearchBar setAdd={setAdd} setSelectedData={setSelectedData} />
      <PlaylistsTable setAdd={setAdd} setSelectedData={setSelectedData} />
      <AddPlaylist
        isAdd={isAdd}
        data={selectedData}
        setSelectedData={setSelectedData}
      />
    </Layout>
  );
};

export default Playlists;
