import axiosInstance from "../../../helpers/api";

export const getAllHomeworks = (body) => {
  return axiosInstance.get("/admin/homeworks", body);
};

export const getStudentHomeworkQuiz = () => {
  return axiosInstance.get("/admin/exams/homework_quiz");
};

export const editHomeworks = (formData) => {
  return axiosInstance.post(
    `/admin/homeworks/${formData.id}`,
    formData.formData
  );
};

export const createHomeworks = (formData) => {
  return axiosInstance.post("/admin/homeworks", formData);
};

export const deleteHomework = (id) => {
  return axiosInstance.delete(`/admin/homeworks/${id}`);
};

export const filterHomework = (id) => {
  return axiosInstance.get(`/admin/homeworks/group/${id}`);
};
