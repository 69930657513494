import {
  Box,
  Button,
  Flex,
  HStack,
  InputGroup,
  Input,
  InputLeftElement,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { RiAddFill, RiSearch2Fill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import LogoutMenu from "../../../components/dashboard/Layout/logout-menu";
import {
  openAddSectionkModel,
  openCreateChapterModel,
} from "../../../modules/admin-modules/models/Actions";

const SearchBar = ({ setAdd }) => {
  const dispatch = useDispatch();
  const count = useSelector((state) => state.adminSections.count);

  return (
    <VStack w="100%" my="30px">
      <Flex alignItems="center" w="100%" justifyContent="space-between">
        <Text fontSize="24px" fontWeight="600" color="main_1">
          أقسام
        </Text>

        <Flex alignItems="center">
          <Button
            borderRadius="16px"
            leftIcon={<RiAddFill />}
            mx="8px"
            color="white"
            bg="main_1"
            _focus={{ outline: "none" }}
            onClick={() => {
              setAdd(true);
              dispatch(openAddSectionkModel());
            }}
          >
            إضافة أقسام
          </Button>

          <LogoutMenu />
        </Flex>
      </Flex>
      <Text w="100%" fontSize="20px" textAlign="right">
        {" "}
        عدد أقسام: {count}
      </Text>
    </VStack>
  );
};

export default SearchBar;
