import { Box, Button, Flex, Input, Text } from "@chakra-ui/react";
import React from "react";

const News = () => {
  return (
    <Flex
      position="relative"
      zIndex="20"
      bg="main_2"
      flexDirection={"column"}
      alignItems="center"
      p="10px"
      borderRadius={"35px"}
      mt="50px"
      w="70%"
      mx="auto"
      mb={{ base: "20px", lg: "-50px" }}
    >
      <Text fontSize="32px" fontWeight="600" textTransform={"uppercase"}>
        Newsletter
      </Text>
      <Text
        fontSize={{ base: "14", lg: "22px" }}
        textAlign="center"
        w="60%"
        py="20px"
      >
        put your email for more news about our website's new courses{" "}
      </Text>

      <Flex
        alignItems="center"
        pb="60px"
        pt="20px"
        px="30px"
        flexDirection={{ base: "column", lg: "row" }}
      >
        <Flex
          flexDirection={"column"}
          justifyContent={"space-between"}
          w={{ base: "100%", lg: "600px" }}
        >
          <Box w="100%">
            <Input
              borderRadius={{ base: "0", lg: "10px" }}
              borderTopEndRadius={"0 !important"}
              borderBottomEndRadius={"0 !important"}
              p="22px"
              style={{
                width: "100%",
                padding: "20px",
                objectFit: "contain",
                boxShadow: "inset 0 0 16px 0 rgba(0, 0, 0, 0.16)",
                border: "solid 1px #010038",
                backgroundColor: "#fff",
              }}
              name="email"
            />
          </Box>
        </Flex>
        <Button
          type="submit"
          w={{ base: "100%", lg: "30%" }}
          p="22px"
          alignSelf={"center"}
          borderRadius={{ base: "0", lg: "5px" }}
          mt={{ base: "10px", lg: "0" }}
          borderTopStartRadius={"0 !important"}
          borderBottomStartRadius={"0 !important"}
          bg="main_1"
          color="white"
        >
          Subscribe
        </Button>
      </Flex>
    </Flex>
  );
};

export default News;
