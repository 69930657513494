import Types from './Types';


export const getAllSubjectsRequest = (body) => ({
    type: Types.GET_SUBJECTS_REQUEST,
    payload: body
});

export const getAllSubjectsSuccess = (data) => ({
    type: Types.GET_SUBJECTS_SUCCESS,
    payload: data
});

export const getAllSubjectsFail = () => ({
    type: Types.GET_SUBJECTS_FAIL
});


export const editSubjectRequest = (formData, action) => ({
    type: Types.EDIT_SUBJECT_REQUEST,
    payload: formData,
    action: action
});

export const editSubjectSuccess = (data) => ({
    type: Types.EDIT_SUBJECT_SUCCESS,
    payload: data
});

export const editSubjectFail = () => ({
    type: Types.EDIT_SUBJECT_FAIL
});

//LOADING
export const addSubjectLoading = (isLoading) => ({
    type: Types.ADD_SUBJECTS_LOADING,
    payload: isLoading
});

// Create Subject
export const createSubjectRequest = (formData, action) => ({
    type: Types.CREATE_SUBJECT_REQUEST,
    payload: { ...formData },
    action: action
});

export const createSubjectSuccess = ({ data }) => ({
    type: Types.CREATE_SUBJECT_SUCCESS,
    payload: {
        data
    }
});

export const createSubjectFail = () => ({
    type: Types.CREATE_SUBJECT_FAIL
});

export const deleteSubjectRequest = (id) => ({
    type: Types.DELETE_SUBJECT_REQUEST,
    payload: id
});

export const deleteSubjectSuccess = (id) => ({
    type: Types.DELETE_SUBJECT_SUCCESS,
    payload: id
});

export const deleteSubjectFail = () => ({
    type: Types.DELETE_SUBJECT_FAIL
});
