import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

const DashboardWrapper = ({ children, roles }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.user.userData);
  const token = window.localStorage.getItem("token");

  useEffect(() => {
    if (!token || (data?.role && !roles.includes(data?.role[0].name))) {
      window.localStorage.clear();
      window.location.href = "/login";
    }
  }, [roles, data, token]);
  return <div>{children}</div>;
};

export default DashboardWrapper;
