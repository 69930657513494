import { toast } from "react-toastify";
import { call, fork, put, takeLatest } from "redux-saga/effects";
import * as actions from "./Actions";
import * as api from "./Api";
import Types from "./Types";

function* getAllSystemGroups({ payload }) {
  try {
    yield put(actions.addGroupLoading(true));
    const result = yield call(api.getAllSystemGroups);
    yield put(actions.getAllSystemGroupsSuccess(result.data));
  } catch (error) {
    const { response, message } = error;
    toast.error(error);
    yield put(actions.getAllSystemGroupsFail());
  } finally {
    yield put(actions.addGroupLoading(false));
  }
}

function* getAllGroups({ payload }) {
  try {
    yield put(actions.addGroupLoading(true));
    const result = yield call(api.getAllGroups);
    yield put(actions.getAllGroupsSuccess(result.data));
  } catch (error) {
    const { response, message } = error;
    toast.error(error);
    yield put(actions.getAllGroupsFail());
  } finally {
    yield put(actions.addGroupLoading(false));
  }
}

function* getPerformanceGroups({ payload }) {
  try {
    yield put(actions.addGroupLoading(true));
    const result = yield call(api.getPerformanceGroups);
    yield put(actions.getPerformanceGroupsSuccess(result.data));
  } catch (error) {
    const { response, message } = error;
    toast.error(error);
    yield put(actions.getPerformanceGroupsFail());
  } finally {
    yield put(actions.addGroupLoading(false));
  }
}

//Edit Tables
function* editGroup({ payload }) {
  try {
    yield put(actions.addGroupLoading(true));
    const result = yield call(api.editGroups, payload);
    yield put(actions.editGroupSuccess(result.data));
    toast.success("Edit Successfully");
    payload?.action && payload?.action();
  } catch (error) {
    yield put(actions.editGroupFail());
  } finally {
    yield put(actions.addGroupLoading(false));
  }
}

// create
function* createGroup(formData) {
  try {
    yield put(actions.addGroupLoading(true));
    const result = yield call(api.createGroups, formData.payload);
    yield put(
      actions.createGroupSuccess({
        data: result.data,
      })
    );
    toast.success("Created Successfully");
    formData?.action && formData?.action();
  } catch (error) {
    yield put(actions.createGroupFail());
  } finally {
    yield put(actions.addGroupLoading(false));
  }
}

function* deleteGroup({ payload }) {
  try {
    yield put(actions.addGroupLoading(true));
    const result = yield call(api.deleteGroup, payload);

    yield put(actions.deleteGroupSuccess(payload));

    toast.success("Deleted Successfully");
  } catch (error) {
    const { response, message } = error;
    toast.error(error.response);
    yield put(actions.deleteGroupFail());
  } finally {
    yield put(actions.addGroupLoading(false));
  }
}

export default function* GroupSaga() {
  yield takeLatest(Types.GET_SYSTEM_GROUPS_REQUEST, getAllSystemGroups);

  yield takeLatest(Types.GET_GROUPS_REQUEST, getAllGroups);
  yield takeLatest(Types.GET_PERFORMANCE_GROUPS_REQUEST, getPerformanceGroups);
  yield takeLatest(Types.EDIT_GROUP_REQUEST, editGroup);
  yield takeLatest(Types.CREATE_GROUP_REQUEST, createGroup);
  yield takeLatest(Types.DELETE_GROUP_REQUEST, deleteGroup);
}
