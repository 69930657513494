import {
  Avatar,
  HStack,
  Icon,
  Image,
  StackDivider,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import bg from "../../../assets/course.webp";
const ChapterCard = ({
  chapter,
  onClick,
  content,
  list,
  type,
  icon,
  image,
  subTitle = "Browes",
}) => {
  return (
    <VStack
      bg="#ededed"
      borderRadius="8px"
      alignItems="flex-start"
      p="10px"
      spacing="20px"
      w="100%"
      cursor="pointer"
    >
      {/* title data */}
      {type == "Exams" && (
        <HStack>
          <Avatar
            name={chapter?.name}
            src={bg}
            borderRadius="8px"
            size="md"
            background="transparent"
          />

          <VStack alignItems="flex-start">
            <Text fontWeight="500">{chapter?.name}</Text>
            {content != 0 && (
              <Text opacity={"0.8"}>
                contain {content} {type}{" "}
              </Text>
            )}
          </VStack>
        </HStack>
      )}
      {/* content list */}
      <VStack alignItems="flex-start" w="100%">
        {content != 0 && (
          <Text fontSize="14px" fontWeight="400">
            {type}
          </Text>
        )}
        <VStack
          divider={<StackDivider borderColor="gray.200" />}
          spacing={2}
          align="stretch"
          w="100%"
        >
          {/* item */}
          {list?.map((video, idx) => {
            if (type == "videos") {
              if (video?.publish == "1" && video?.type == 1)
                return (
                  <HStack
                    justifyContent="space-between"
                    key={idx}
                    onClick={() => onClick(chapter?.id, video?.id)}
                  >
                    <HStack alignItems="center">
                      {icon && <Icon as={icon} fontSize="20px" />}
                      {image && (
                        <Image src={image} width="20px" height="20px" />
                      )}

                      <Text color="main_1" fontSize="14px" mt="-2px">
                        {video?.title}
                      </Text>
                    </HStack>
                    <Text fontSize="12px">{subTitle}</Text>
                  </HStack>
                );
            } else if (type == "Model answer") {
              return (
                <HStack
                  justifyContent="space-between"
                  key={idx}
                  onClick={() => onClick(chapter?.id, video?.id)}
                >
                  <HStack alignItems="center">
                    {icon && <Icon as={icon} fontSize="18px" />}
                    {image && <Image src={image} width="18px" />}

                    <Text color="main_1" fontSize="14px" mt="-2px">
                      {video?.title}
                    </Text>
                  </HStack>
                  <Text fontSize="12px">{subTitle}</Text>
                </HStack>
              );
            }
          })}
        </VStack>
      </VStack>
    </VStack>
  );
};

export default ChapterCard;
