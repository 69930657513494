import React, { useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Heading,
  Input,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  closeCreateLesson,
  closeCreateStudent,
} from "../../../../modules/admin-modules/models/Actions";
import MainModel from "../../../../components/Models/main-model";
import { useForm } from "react-hook-form";
import MainInput from "../../../../components/Form/input";
import Select from "react-select";
import {
  createLessonRequest,
  editLessonRequest,
} from "../../../../modules/admin-modules/lessons/Actions";
import { toast } from "react-toastify";
import { useState } from "react";
import { getAllChaptersRequest } from "../../../../modules/admin-modules/chapters/Actions";
import { getAllGroupsRequest } from "../../../../modules/admin-modules/groups/Actions";
const AddLesson = ({ isAdd, data, setSelectedData }) => {
  const [selected, setSelected] = useState("");
  const openLesson = useSelector((state) => state.model.isOpen3);
  const chapters = useSelector((state) => state.chapters.chapters);
  const groups = useSelector((state) => state.groups.groups);
  const isLoading = useSelector((state) => state.chapters.isLoading);

  const groupsOptions = groups?.map((data) => {
    return { value: data.id, label: data.name };
  });

  const chaptersOptions = chapters?.map((data) => {
    return { value: data.id, label: data.name };
  });
  const dispatch = useDispatch();
  useEffect(() => {
    if (openLesson) {
      dispatch(getAllChaptersRequest());
      dispatch(getAllGroupsRequest());
    }
  }, [openLesson]);

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      width: "100%",
      background: "#f5f5f5",
      color: "#690000",
      borderRadius: "16px",
    }),
    option: (styles) => ({ ...styles, color: "#690000" }),
  };
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
    getValues,
  } = useForm({
    defaultValues: data ? data : {},
    shouldUnregister: true,
  });

  const header = (
    <Box>
      <Heading textAlign="center" color="main_1" fontSize="24px">
        {isAdd ? " إضافة المحتوي" : "تعديل  المحتوي"}
      </Heading>
    </Box>
  );
  const onSubmit = async (values) => {
    let formData = await new FormData();
    for (let key in values) {
      if (key == "groups") {
        values[key]?.map((val, idx) => formData.append(`groups[${idx}]`, val));
      } else {
        formData.append(key, values[key]);
      }
    }
    const action = () => {
      dispatch(closeCreateLesson());
      setSelectedData({});
      reset();
    };
    if (isAdd) {
      await dispatch(createLessonRequest(formData, action));
    } else {
      const totel = { ...data, ...values };
      for (let key in totel) {
        if (key == "groups") {
          totel[key]?.map((val, idx) =>
            formData.append(`groups[${idx}]`, val?.id ? val?.id : val)
          );
        } else {
          formData.append(key, totel[key]);
        }
      }
      formData.append("_method", "PUT");

      dispatch(
        editLessonRequest({
          id: data?.id,
          formData: formData,
          action: action,
        })
      );
    }
  };

  const closeFunc = () => {
    dispatch(closeCreateLesson());
    setSelectedData({});
    reset();
  };
  return (
    <MainModel
      isLoading={isLoading}
      header={header}
      closeFunc={closeFunc}
      openVar={openLesson}
      buttonTitle={" حفظ التغيرات"}
      buttonFunc={handleSubmit(onSubmit)}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex
          dir="rtl"
          w="100%"
          flexWrap="wrap"
          justifyContent="space-between"
          gap="4"
        >
          <Box w="45%">
            <MainInput
              label="اسم المحتوي"
              defaultValues={data.title}
              error={errors?.title?.message}
              placeholder="ادخل اسم المحتوي"
              register={register}
              name="title"
            />
          </Box>
          <Box w="45%">
            <FormLabel> نبذة عن المحتوي : </FormLabel>
            <Textarea
              bg="#f5f5f5"
              defaultValue={data?.over_view}
              placeholder="اكتب نبذة مبسطة عن المحتوى"
              {...register("over_view")}
              name="over_view"
            ></Textarea>
          </Box>
          <Box w="45%">
            <FormLabel>رفع المحتوى:</FormLabel>
            <Button
              bg="main_1"
              color="white"
              borderRadius="8px"
              textAlign="center"
              position="relative"
              w="100%"
            >
              <Text>{selected ? selected : "اختر ملف ليتم رفعه"} </Text>
              <Input
                onChange={(e) => {
                  setValue("file", e.target.files[0]);
                  setSelected((s) => e.target.files[0].name);
                }}
                type="file"
                placeholder="اختر ملف ليتم رفعه"
                opacity="0"
                position="absolute"
                w="100%"
                h="100%"
              />
            </Button>
          </Box>

          <Box w="45%">
            <Box w="100%">
              <FormLabel>الفصول : </FormLabel>
              <Select
                options={chaptersOptions}
                onChange={(data) => setValue("chapter_id", data.value)}
                styles={colourStyles}
                placeholder="الصف"
                defaultValue={{
                  label: data?.chapter?.name,
                  value: data?.chapter?.id,
                }}
              />
            </Box>
          </Box>
          <Box w="100%">
            <Box w="45%">
              <FormLabel>المجموعات : </FormLabel>
              <Select
                options={groupsOptions}
                isMulti={true}
                onChange={(data) => {
                  const selectedGroups = data.map((val) => val.value);
                  setValue("groups", selectedGroups);
                }}
                styles={colourStyles}
                placeholder="الفصول"
                defaultValue={data?.groups?.map((val) => {
                  return { label: val?.name, value: val?.id };
                })}
              />
            </Box>
          </Box>
        </Flex>
      </form>
    </MainModel>
  );
};

export default AddLesson;
