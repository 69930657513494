import React, { useEffect, useState } from "react";
import Layout from "../../../components/dashboard/Layout";
import SearchBar from "../../../containers/admin/lessons/searchbar";
import LessonsTable from "../../../containers/admin/lessons/table";
import { getAllLessonsRequest } from "../../../modules/admin-modules/lessons/Actions";
import { useDispatch } from "react-redux";
import AddLesson from "../../../containers/admin/lessons/models/add-lesson";
const Lessons = () => {
  const [isAdd, setAdd] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllLessonsRequest());
  }, []);

  return (
    <Layout>
      <SearchBar setAdd={setAdd} setSelectedData={setSelectedData} />
      <LessonsTable setAdd={setAdd} setSelectedData={setSelectedData} />
      <AddLesson
        isAdd={isAdd}
        data={selectedData}
        setSelectedData={setSelectedData}
      />
    </Layout>
  );
};

export default Lessons;
