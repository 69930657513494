import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Switch,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import { RiDeleteBinLine, RiFileEditLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import CTable from "../../../components/Table/table";
import {
  openAddVideoFielsModel,
  openCreateVideos,
} from "../../../modules/admin-modules/models/Actions";
import {
  deleteVideoRequest,
  suspendVideoRequest,
} from "../../../modules/admin-modules/videos/Actions";

const VideosTable = ({ setAdd, setSelectedData }) => {
  const videos = useSelector((state) => state.videos.videos);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.videos.isLoading);
  const [, setPage] = useState(0);
  const [size, setPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [, setSelected] = useState({
    id: "",
  });
  const viewData = (data) => {
    setSelectedData(data);
    setSelected(data);
  };

  const Data = videos?.map((data) => {
    return {
      ...data,
      id: data?.id,
      title: data?.title,
      chapter_id: data?.chapter?.id,
      chapter_name: data?.chapter?.name,
      suspend: (
        <Switch
          isChecked={data?.suspended == 1 ? true : false}
          onChange={() => {
            let formData = new FormData();
            formData.append("_method", "PUT");
            formData.append("suspended", data?.suspended == 1 ? 0 : 1);
            dispatch(
              suspendVideoRequest({
                formData: formData,
                id: data?.id,
              })
            );
          }}
        />
      ),
      publish_val: (
        <Text color="main_1" w="70px">
          {data?.publish == 0 ? "غير منشور " : " منشور"}
        </Text>
      ),
      add_fiels: (
        <Button
          bg="main_1"
          color="white"
          onClick={async () => {
            await setSelectedData(() => data);
            dispatch(openAddVideoFielsModel());
          }}
        >
          اضافة ملفات
        </Button>
      ),
      edit: (
        <IconButton
          variant="outline"
          border="0"
          color="gray.600"
          aria-label="Call Sage"
          fontSize="20px"
          icon={<RiFileEditLine />}
          onClick={async () => {
            await setSelectedData(() => data);

            await setSelected(() => data);
            await setAdd(false);

            await dispatch(openCreateVideos());
          }}
        />
      ),
      delete: (
        <IconButton
          variant="outline"
          border="0"
          color="gray.600"
          aria-label="Call Sage"
          fontSize="20px"
          icon={<RiDeleteBinLine color="red" />}
          onClick={() => {
            dispatch(deleteVideoRequest(data?.id));
          }}
        />
      ),
    };
  });
  const columns = [
    {
      Header: " عنوان الفيديو",
      accessor: "title",
    },
    {
      Header: "ID",
      accessor: "id",
    },
    {
      Header: "  الفصل التابع له",
      accessor: "chapter_name",
    },
    {
      Header: "  اضافة ملف",
      accessor: "add_fiels",
    },
    {
      Header: "النشر",
      accessor: "publish_val",
    },
    {
      Header: "suspend",
      accessor: "suspend",
    },

    {
      Header: "تعديل",
      accessor: "edit",
    },
    {
      Header: "حذف",
      accessor: "delete",
    },
  ];

  const actions = (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Options"
        icon={<RiFileEditLine />}
        size="sm"
        fontSize="20px"
        variant="outline"
        border="none"
      />
      <MenuList>
        <MenuItem
          onClick={() => {
            setAdd(false);
            dispatch(openCreateVideos());
          }}
        >
          تعديل
        </MenuItem>
        <MenuItem
          onClick={() =>
            // dispatch(deleteClubRequest(selected?.id))
            {
              //console.log('test')
            }
          }
        >
          حذف
        </MenuItem>
      </MenuList>
    </Menu>
  );
  return (
    <CTable
      selectedData={viewData}
      Columns={columns}
      Data={Data}
      path={"openModel"}
      Actions={actions}
      Title={``}
      subTitle=""
      btnTitle=""
      placeHolder=""
      noSearchBar={true}
      noFilter={true}
      footerBtnTitle
      filterList
      addButton
      addButtonTitle=""
      setPage={setPage}
      setPerPage={setPerPage}
      currentpage={pageNumber}
      setPageNumber={setPageNumber}
      noSecondSearchBar={true}
      isLoading={isLoading}
      perPage={size}
      totalPage={Math.ceil(videos?.length / 10)}
    />
  );
};

export default VideosTable;
