import React from 'react'
import { RiGlobalLine, RiMenuLine, RiNotification4Line, RiUserLine } from 'react-icons/ri'
import { Menu, MenuButton, MenuList, IconButton, MenuItem, VStack, Button } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
const MobileNavbar = () => {

    const routes = [{ label: 'Home', link: '/' },
    { label: 'Services', link: '#services' },
    { label: 'Summaries', link: '#summaries' },
    { label: 'Resources', link: '#resources' },
    { label: 'Reviews', link: '#reviews' },
    { label: 'About us', link: '#aboutus' },
    { label: 'Contact us', link: '#contactus' }
    ]
    return (
        <Menu>
            <MenuButton
                display={{ base: 'flex', lg: 'none' }}
                alignItems='center'
                as={IconButton}
                aria-label='Options'
                icon={<RiMenuLine />}
                variant='outline'
                color='main_1'
                bg='white'
            />
            <MenuList>
                {
                    routes.map((data) => {
                        return <a href={`${data.link}`}>
                            <MenuItem color='main_1'>
                                {data.label}
                            </MenuItem>
                        </a>
                    })
                }


                <VStack p='5px'>
                    <Button bg='main_1' color='white' mx='10px' my='10px' w='100%' textTransform={' capitalize'}>
                        <Link to='#register' style={{ width: '100%' }} >register</Link>
                    </Button>
                    <Link to='/login' style={{ width: '100%' }}>
                        <Button bg='main_2' w='100%' textTransform={'capitalize'}>
                            login
                        </Button>
                    </Link>
                </VStack>

            </MenuList>
        </Menu >
    )
}

export default MobileNavbar