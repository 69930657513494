import { toast } from 'react-toastify';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import * as actions from './Actions';
import * as api from './Api';
import Types from './Types';

function* getAllRedoRequests({ payload }) {
    try {
        yield put(actions.addRedoRequestLoading(true));
        const result = yield call(api.getAllRequests);
        yield put(actions.getAllRedoRequestsSuccess(result.data));
    } catch (error) {
        const { response, message } = error;
        toast.error(error);
        yield put(actions.getAllRedoRequestsFail());
    } finally {
        yield put(actions.addRedoRequestLoading(false));
    }
}



//Edit Tables
function* editRedoRequest({ payload }) {
    try {
        yield put(actions.addRedoRequestLoading(true));
        const result = yield call(api.editRequests, payload);
        yield put(actions.editRedoRequestSuccess(payload));
        toast.success('Accept Successfully');
        payload?.action && payload?.action()
    } catch (error) {
        yield put(actions.editRedoRequestFail());
    } finally {
        yield put(actions.addRedoRequestLoading(false));
    }
}


function* deleteRedoRequest({ payload }) {
    try {
        yield put(actions.addRedoRequestLoading(true));
        const result = yield call(api.deleteRequest, payload);

        yield put(actions.deleteRedoRequestSuccess(payload));

        toast.success('Deleted Successfully');
    } catch (error) {
        const { response, message } = error;
        toast.error(error.response);
        yield put(actions.deleteRedoRequestFail());
    } finally {
        yield put(actions.addRedoRequestLoading(false));
    }
}

export default function* redoRequestsSaga() {
    yield takeLatest(Types.GET_REDO_REQUESTS_REQUEST, getAllRedoRequests);
    yield takeLatest(Types.EDIT_REDO_REQUEST_REQUEST, editRedoRequest);
    yield takeLatest(Types.DELETE_REDO_REQUEST_REQUEST, deleteRedoRequest);
}
