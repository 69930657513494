import { Flex, Image, Box, Button, Heading, Text, chakra, Icon, Container } from '@chakra-ui/react'
import React, { useState } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri'
const Header = () => {
    const [currentSlide, setCurrentSlide] = useState(0)
    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
            <Button
                className={className}
                top={{ base: '12.4vh', md: '9.4vh' }}
                style={{ ...style, left: '0', left: '50px' }}
                _before={{ opacity: '0', display: 'none' }}
                onClick={onClick}
                bg='#f7d723 !important'
                display={{ base: 'none !important', lg: 'flex !important' }}
                alignItems='center'
                w='fit-content !important'
                justifyContent={'center'}
                fontSize='27px'
                p='0'
                h='27px'
                _hover={{ opacity: '0.5' }}
            >
                <Icon as={RiArrowRightSLine} color='main_1' />
            </Button>
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <Button
                className={className}
                top={{ base: '47vh', md: '45vh' }}
                style={{ ...style, left: '0' }}
                _before={{ opacity: '0', display: 'none' }}
                onClick={onClick}
                bg='#f7d723 !important'
                display={{ base: 'none !important', lg: 'flex !important' }}
                alignItems='center'
                w='fit-content !important'
                justifyContent={'center'}
                fontSize='27px'
                p='0'
                h='27px'
                _hover={{ opacity: '0.5' }}
            >
                <Icon as={RiArrowLeftSLine} color='main_1' />
            </Button>
        );
    }
    const settings = {

        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        afterChange: current => setCurrentSlide(current),
        appendDots: dots => (
            <Flex w='fit-content !important' alignItems={'center'} justifyContent='space-between' style={{ bottom: '-10px' }}>
                {dots}
            </Flex>
        ),
        customPaging: i => (
            <Box
                bg='main_2'
                h='5px'
                opacity={currentSlide == i ? '1' : '0.5'}
                pr={currentSlide == i ? '25px' : ''}
                borderRadius={'5px'}
            >

            </Box >
        )
    };
    return (
        <Flex justifyContent='space-between' bg='main_1' color='white' h='1000px' id='home'>
            <Container maxW={'container.xl'}>
                <Flex alignItems={'center'} justifyContent='space-between' bg='main_1' color='white' h='800px'>
                    <Box w='485px' mx={{ base: '0', md: '34px' }}>
                        <Slider {...settings}>
                            <Flex w='100px'>
                                <Heading textTransform={'capitalize'}>
                                    studying <chakra.span color='main_2'> Physics</chakra.span>
                                </Heading>
                                <Heading textTransform={'capitalize'} mt='10px'>
                                    never been easier
                                </Heading>
                                <Text mt='10px' textTransform={'capitalize'}>
                                    Discover more study resources through
                                </Text>
                                <Text textTransform={'capitalize'}>
                                    our unique platform
                                </Text>
                                <Flex mt='35px' color='main_1'>
                                    <Button bg='main_2'>Download our App</Button>
                                    <Button mx='10px'>Go to Summaries</Button>
                                </Flex>
                            </Flex>
                            {/* <div>
                                <h3>2</h3>
                            </div>
                            <div>
                                <h3>3</h3>
                            </div> */}
                        </Slider>

                    </Box>

                    <Box position='relative' w='400px' display={{ base: 'none', lg: 'block' }} className='header'>
                        <Image src={require('../../../assets/book.webp')} w='100%' />
                        <Image src={require('../../../assets/book-effect.webp')} opacity='0.4' position='absolute' w='100%' className='header-img'
                            h={{ base: '214px', lg: '460px' }}
                            top={{
                                base: '19px',
                                lg: '43px'
                            }}
                            left={{ base: '-10px', lg: '-34px' }} />
                    </Box>
                </Flex>
            </Container>

        </Flex>
    )
}

export default Header