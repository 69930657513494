import Types from "./Types";

const INITIAL_STATE = {
  playlists: [],
  playlist: {},
  isLoading: false,
  count: "",
};

export default function playlists(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_PLAYLISTS_SUCCESS: {
      return {
        ...state,
        playlists: payload.data,
        count: payload.data.length,
      };
    }

    case Types.GET_PLAYLIST_SUCCESS: {
      return {
        ...state,
        playlist: payload,
      };
    }

    case Types.EDIT_PLAYLIST_SUCCESS: {
      let data = payload.data;
      const playlists = state.playlists;
      let idx = 0;
      playlists.forEach((table, index) => {
        if (table.id === data.id) {
          idx = index;
          return;
        }
      });

      playlists[idx] = data;
      return {
        ...state,
        playlists: playlists,
      };
    }

    // create
    case Types.CREATE_PLAYLIST_SUCCESS: {
      const data = payload.data.data;
      let newArray = state.playlists.slice();
      let index = state.playlists.length + 1;
      newArray.splice(index, 0, data);
      return {
        ...state,
        playlists: newArray,
      };
    }
    case Types.ADD_PLAYLISTS_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }

    case Types.DELETE_PLAYLIST_SUCCESS: {
      const newplaylists = state.playlists.filter(
        (item) => item.id !== payload
      );
      return {
        ...state,
        playlists: newplaylists,
      };
    }
    default: {
      return state;
    }
  }
}
