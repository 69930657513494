import { toast } from "react-toastify";
import { call, fork, put, takeLatest } from "redux-saga/effects";
import * as actions from "./Actions";
import * as api from "./Api";
import Types from "./Types";

function* getBookmarkedQuestions({ payload }) {
  try {
    yield put(actions.addQuestionsLoading(true));
    const result = yield call(api.getBookmarkedQuestions, payload);
    yield put(actions.getBookmarkedQuestionsSuccess(result.data));
  } catch (error) {
    const { response, message } = error;
    toast.error(error);
    //console.log(error, 'error');
    yield put(actions.getBookmarkedQuestionsFail());
  } finally {
    yield put(actions.addQuestionsLoading(false));
  }
}

function* getMissedQuestions({ payload }) {
  try {
    yield put(actions.addQuestionsLoading(true));
    const result = yield call(api.getMissedQuestions, payload);
    yield put(actions.getMissedQuestionsSuccess(result.data));
  } catch (error) {
    const { response, message } = error;
    toast.error(error);
    //console.log(error, 'error');
    yield put(actions.getMissedQuestionsFail());
  } finally {
    yield put(actions.addQuestionsLoading(false));
  }
}

export default function* questionsSaga() {
  yield takeLatest(Types.GET_MISSED_QUESTIONS_REQUEST, getMissedQuestions);
  yield takeLatest(
    Types.GET_BOOKMARKED_QUESTIONS_REQUEST,
    getBookmarkedQuestions
  );
}
