import React from "react";
import {
  Box,
  FormLabel,
  Heading,
  Radio,
  RadioGroup,
  Stack,
  VStack,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { closeCreateStreaming } from "../../../../modules/admin-modules/models/Actions";
import MainModel from "../../../../components/Models/main-model";
import { useForm } from "react-hook-form";
import MainInput from "../../../../components/Form/input";
import {
  createStreamingRequest,
  editStreamingRequest,
} from "../../../../modules/admin-modules/streaming/Actions";

const AddStreaming = ({ isAdd, data, setSelectedData }) => {
  const openStreaming = useSelector((state) => state.model.isOpen4);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.streaming.isLoading);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: data ? data : {},
    shouldUnregister: true,
  });

  const header = (
    <Box>
      <Heading textAlign="center" color="main_1" fontSize="24px">
        {isAdd ? " اضافة بث مباشر " : "تعديل بث مباشر"}
      </Heading>
    </Box>
  );
  const onSubmit = (data) => {
    let formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    const action = () => {
      dispatch(closeCreateStreaming());
      setSelectedData({});
      reset();
    };
    if (isAdd) dispatch(createStreamingRequest(formData, action));
    else {
      dispatch(
        editStreamingRequest({
          id: data?.id,
          formData: formData,
          action: action,
        })
      );
    }
  };

  const closeFunc = () => {
    dispatch(closeCreateStreaming());
    setSelectedData({});
    reset();
  };
  return (
    <MainModel
      isLoading={isLoading}
      header={header}
      closeFunc={closeFunc}
      openVar={openStreaming}
      buttonTitle={" حفظ التغيرات"}
      buttonFunc={handleSubmit(onSubmit)}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack dir="rtl" w="100%" alignItems="flex-start" spacing={4}>
          <Box w="45%">
            <MainInput
              label=" عنوان البث"
              error={errors?.title?.message}
              placeholder=" اكتب عنوان البث المباشر "
              register={register}
              name="title"
            />
          </Box>
          <Box w="45%">
            <MainInput
              label=" رابط البث"
              error={errors?.url?.message}
              placeholder="اكتب رابط البث المباشر"
              register={register}
              name="url"
            />
          </Box>

          <Box w="45%">
            <FormLabel>نوع البث : </FormLabel>
            <RadioGroup
              borderColor={errors?.type?.message ? "red" : "gray.200"}
              {...register("type", { required: "هذه المعلومات مطلوبة" })}
              onChange={(data) => setValue("registered", data)}
            >
              <Stack direction="row">
                <Radio value="0" colorScheme="red">
                  {" "}
                  Zoom
                </Radio>
                <Radio value="1" colorScheme="red">
                  Youtube
                </Radio>
              </Stack>
            </RadioGroup>
          </Box>

          <Box w="45%">
            <FormLabel>البث المباشر موجة الى : </FormLabel>
            <RadioGroup
              borderColor={errors?.publish?.message ? "red" : "gray.200"}
              {...register("registered", { required: "هذه المعلومات مطلوبة" })}
              onChange={(data) => setValue("publish", data)}
            >
              <Stack direction="row">
                <Radio value="0" colorScheme="red">
                  {" "}
                  Streaming
                </Radio>
                <Radio value="1" colorScheme="red">
                  Extra Lessons
                </Radio>
              </Stack>
            </RadioGroup>
          </Box>

          <Box w="45%">
            <FormLabel>الصف : </FormLabel>
            <RadioGroup
              borderColor={errors?.subject_id?.message ? "red" : "gray.200"}
              {...register("subject_id", { required: "هذه المعلومات مطلوبة" })}
              onChange={(data) => setValue("registered", data)}
            >
              <Stack direction="row">
                <Radio value="0" colorScheme="red">
                  {" "}
                  الأول الثانوي
                </Radio>
                <Radio value="1" colorScheme="red">
                  الثاني الثانوي
                </Radio>
                <Radio value="1" colorScheme="red">
                  الثالث الثانوي
                </Radio>
              </Stack>
            </RadioGroup>
          </Box>
        </VStack>
      </form>
    </MainModel>
  );
};

export default AddStreaming;
