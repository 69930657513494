import React, { useEffect } from "react";
import {
  Box,
  Flex,
  FormLabel,
  Heading,
  HStack,
  Icon,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  closeCreateEven,
  closeCreateEvent,
} from "../../../../modules/admin-modules/models/Actions";
import MainModel from "../../../../components/Models/main-model";
import { useForm } from "react-hook-form";
import MainInput from "../../../../components/Form/input";
import Select from "react-select";
import {
  createEventRequest,
  editEventRequest,
} from "../../../../modules/admin-modules/events/Actions";
import { getAllSubjectsRequest } from "../../../../modules/admin-modules/subjects/Actions";

const AddEvent = ({ isAdd, data, setSelectedData }) => {
  const openEvent = useSelector((state) => state.model.isOpen6);
  const subjects = useSelector((state) => state.subjects.subjects);
  const isLoading = useSelector((state) => state.events.isLoading);

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      width: "100%",
      background: "#f5f5f5",
      color: "#690000",
      borderRadius: "16px",
    }),
    option: (styles) => ({ ...styles, color: "#690000" }),
  };

  const subjectsOptions = subjects?.map((data) => {
    return { value: data.id, label: data.name };
  });

  const dispatch = useDispatch();
  useEffect(() => {
    if (openEvent) {
      dispatch(getAllSubjectsRequest());
    }
  }, [openEvent]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: data ? data : {},
    shouldUnregister: true,
  });

  const header = (
    <Box>
      <Heading textAlign="center" color="main_1" fontSize="24px">
        {isAdd ? "   اضافة حدث " : "تعديل حدث"}
      </Heading>
    </Box>
  );
  const onSubmit = (data) => {
    let formData = new FormData();
    for (let key in data) {
      formData.append(key, data[key]);
    }
    const action = () => {
      dispatch(closeCreateEvent());
      setSelectedData({});
      reset();
    };
    if (isAdd) dispatch(createEventRequest(formData, action));
    else {
      dispatch(
        editEventRequest({
          id: data?.id,
          formData: formData,
          action: action,
        })
      );
    }
  };

  const closeFunc = () => {
    dispatch(closeCreateEvent());
    setSelectedData({});
    reset();
  };
  return (
    <MainModel
      isLoading={isLoading}
      header={header}
      closeFunc={closeFunc}
      openVar={openEvent}
      buttonTitle={" حفظ التغيرات"}
      buttonFunc={handleSubmit(onSubmit)}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack dir="rtl" w="100%" alignItems="flex-start" spacing={4}>
          <Box w="45%">
            <MainInput
              label="  عنوان الحدث"
              error={errors?.title?.message}
              placeholder=" اكتب عنوان الحدث"
              register={register}
              name="title"
            />
          </Box>

          <Box w="45%">
            <Box w="100%">
              <FormLabel>المادة : </FormLabel>
              <Select
                options={subjectsOptions}
                onChange={(data) => setValue("subject_id", data.value)}
                styles={colourStyles}
                placeholder="المادة"
                // defaultValue={{ label: '', value: data.chapter_id }}
              />
            </Box>
          </Box>
          <Box w="45%">
            <MainInput
              type="datetime-local"
              label="التاريخ"
              error={errors?.date?.message}
              placeholder=" اكتب عنوان الحدث"
              register={register}
              name="date"
            />
          </Box>
        </VStack>
      </form>
    </MainModel>
  );
};

export default AddEvent;
