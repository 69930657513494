import {
  Button,
  Flex,
  Heading,
  Icon,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
} from "@chakra-ui/react";
import React, { useState } from "react";
import {
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiPlayCircleLine,
} from "react-icons/ri";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
const LatestCourses = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <Button
        className={className}
        style={{ ...style, left: "0", left: "50px", top: "9.4vh" }}
        _before={{ opacity: "0", display: "none" }}
        onClick={onClick}
        bg="#f7d723 !important"
        display={"flex"}
        alignItems="center"
        w="fit-content !important"
        justifyContent={"center"}
        fontSize="27px"
        p="0"
        h="27px"
        _hover={{ opacity: "0.5" }}
      >
        <Icon as={RiArrowRightSLine} color="main_1" />
      </Button>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <Button
        className={className}
        style={{ ...style, left: "0", top: "45vh" }}
        _before={{ opacity: "0", display: "none" }}
        onClick={onClick}
        bg="#f7d723 !important"
        display={"flex"}
        alignItems="center"
        w="fit-content !important"
        justifyContent={"center"}
        fontSize="27px"
        p="0"
        h="27px"
        _hover={{ opacity: "0.5" }}
      >
        <Icon as={RiArrowLeftSLine} color="main_1" />
      </Button>
    );
  }
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,

    afterChange: (current) => setCurrentSlide(current),
    appendDots: (dots) => (
      <Flex
        w="200px !important"
        alignItems={"center"}
        justifyContent="space-between"
        bottom="-30px"
        left={{ base: "30vw", md: "40vw" }}
        // style={{ bottom: '-30px', left: '40vw' }}
      >
        {dots}
      </Flex>
    ),
    customPaging: (i) => (
      <Flex
        bg="main_1"
        h="5px"
        w="40px"
        opacity={currentSlide == i ? "1" : "0.5"}
        pr={currentSlide == i ? "25px" : ""}
        borderRadius={"5px"}
      ></Flex>
    ),
  };
  return (
    <Flex
      alignItems={"center"}
      justifyContent="center"
      color="main_1"
      flexDirection={"column"}
    >
      <Text
        fontSize="32px"
        textAlign={{ base: "center", md: "center", lg: "left" }}
        fontWeight="600"
        textTransform={"uppercase"}
      >
        Latest Courses
      </Text>
      <Text fontSize="32px" textAlign={{ base: "center", lg: "left" }}>
        Discover more study resources throughour unique platform
      </Text>
      <Tabs
        variant="soft-rounded"
        color="main_1"
        my="15px"
        w={{ base: "100%", md: "90%" }}
        alignSelf={"flex-start"}
        display="flex"
        flexDirection={"column"}
      >
        <TabList alignSelf={"center"}>
          <Tab
            _selected={{ bg: "main_2" }}
            bg="#cacaca"
            color="white"
            mx="5px"
            w="150px"
            borderRadius={"7px"}
          >
            Tab 1
          </Tab>
          <Tab
            _selected={{ bg: "main_2" }}
            bg="#cacaca"
            color="white"
            mx="5px"
            w="150px"
            borderRadius={"7px"}
          >
            Tab 2
          </Tab>
        </TabList>
        <TabPanels alignSelf={"center"}>
          <TabPanel>
            <Slider {...settings}>
              {[...Array(3)].map(() => {
                return (
                  <Flex
                    display={"flex !important"}
                    flexDirection={{ base: "column", md: "row" }}
                    alignItems="center"
                    justifyContent={"space-between"}
                    pl={{ base: "10px", md: "120px" }}
                  >
                    <Flex
                      flexDirection={"column"}
                      w={{ base: "100%", md: "45%" }}
                    >
                      <Heading textTransform={"capitalize"}>
                        Physics 1 - Lesson name
                      </Heading>
                      <Heading
                        textTransform={"capitalize"}
                        mt="10px"
                        fontSize={"28px"}
                      >
                        Mr . Mohammed Ezzat
                      </Heading>
                      {/* <Text mt="10px" textTransform={"capitalize"}>
                        Lorem ipsum dolor sit amet consectetur, adipisicing
                        elit. Eaque, rerum facilis quaerat mollitia quo
                        molestiae earum tempore nesciunt laboriosam eveniet,
                        velit illum? Sequi soluta numquam, atque iure eligendi
                        eveniet necessitatibus?
                      </Text> */}
                    </Flex>
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      bg="#e4e4e4"
                      h="300px"
                      w={{ base: "100%", md: "45%" }}
                      borderRadius={"16px"}
                    >
                      <Icon
                        as={RiPlayCircleLine}
                        color="main_1"
                        fontSize="55px"
                      />
                    </Flex>
                  </Flex>
                );
              })}
            </Slider>
          </TabPanel>
          <TabPanel>
            <Slider {...settings}>
              {[...Array(3)].map(() => {
                return (
                  <Flex
                    display={"flex !important"}
                    flexDirection={{ base: "column", md: "row" }}
                    alignItems="center"
                    justifyContent={"space-between"}
                    pl={{ base: "10px", md: "120px" }}
                  >
                    <Flex
                      flexDirection={"column"}
                      w={{ base: "100%", md: "45%" }}
                    >
                      <Heading textTransform={"capitalize"}>
                        Physics 1 - Lesson name
                      </Heading>
                      <Heading
                        textTransform={"capitalize"}
                        mt="10px"
                        fontSize={"28px"}
                      >
                        Mr . Mohammed Ezzat
                      </Heading>
                      {/* <Text mt="10px" textTransform={"capitalize"}>
                        Lorem ipsum dolor sit amet consectetur, adipisicing
                        elit. Eaque, rerum facilis quaerat mollitia quo
                        molestiae earum tempore nesciunt laboriosam eveniet,
                        velit illum? Sequi soluta numquam, atque iure eligendi
                        eveniet necessitatibus?
                      </Text> */}
                    </Flex>
                    <Flex
                      alignItems="center"
                      justifyContent="center"
                      bg="#e4e4e4"
                      h="300px"
                      w={{ base: "100%", md: "45%" }}
                      borderRadius={"16px"}
                    >
                      <Icon
                        as={RiPlayCircleLine}
                        color="main_1"
                        fontSize="55px"
                      />
                    </Flex>
                  </Flex>
                );
              })}
            </Slider>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};

export default LatestCourses;
