import Types from './Types';


export const getAllStudentChaptersRequest = (body) => ({
    type: Types.GET_STUDENT_CHAPTERS_REQUEST,
    payload: body
});

export const getAllStudentChaptersSuccess = (data) => ({
    type: Types.GET_STUDENT_CHAPTERS_SUCCESS,
    payload: data
});

export const getAllStudentChaptersFail = () => ({
    type: Types.GET_STUDENT_CHAPTERS_FAIL
});

export const getStudentChaptersRequest = (body) => ({
    type: Types.GET_ALL_STUDENT_CHAPTERS_REQUEST,
    payload: body
});

export const getStudentChaptersSuccess = (data) => ({
    type: Types.GET_ALL_STUDENT_CHAPTERS_SUCCESS,
    payload: data
});

export const getStudentChaptersFail = () => ({
    type: Types.GET_ALL_STUDENT_CHAPTERS_FAIL
});




//LOADING
export const addStudentChapterLoading = (isLoading) => ({
    type: Types.ADD_STUDENT_CHAPTERS_LOADING,
    payload: isLoading
});

