import { toast } from 'react-toastify';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import * as actions from './Actions';
import * as api from './Api';
import Types from './Types';

function* getAllEvents({ payload }) {
    try {
        yield put(actions.addEventLoading(true));
        const result = yield call(api.getAllEvents);
        yield put(actions.getAllEventsSuccess(result.data));
    } catch (error) {
        const { response, message } = error;
        toast.error(error);
        yield put(actions.getAllEventsFail());
    } finally {
        yield put(actions.addEventLoading(false));
    }
}



//Edit Tables
function* editEvent(data) {
    try {
        yield put(actions.addEventLoading(true));
        const result = yield call(api.editEvents, data.payload);
        yield put(actions.editEventSuccess(result.data));
        toast.success('Edit Successfully');
        data?.action && data?.action()
    } catch (error) {
        yield put(actions.editEventFail());
    } finally {
        yield put(actions.addEventLoading(false));
    }
}

// create
function* createEvent(formData) {
    try {
        yield put(actions.addEventLoading(true));
        const result = yield call(api.createEvents, formData.payload);
        yield put(
            actions.createEventSuccess({
                data: result.data
            })
        );
        toast.success('Created Successfully');
        formData?.action && formData?.action()
    } catch (error) {
        yield put(actions.createEventFail());
        toast.success(error.data.message.toString());

    } finally {
        yield put(actions.addEventLoading(false));
    }
}

function* deleteEvent({ payload }) {
    try {
        yield put(actions.addEventLoading(true));
        const result = yield call(api.deleteEvent, payload);

        yield put(actions.deleteEventSuccess(payload));

        toast.success('Deleted Successfully');
    } catch (error) {
        const { response, message } = error;
        toast.error(error.response);
        yield put(actions.deleteEventFail());
    } finally {
        yield put(actions.addEventLoading(false));
    }
}

export default function* EventSaga() {
    yield takeLatest(Types.GET_EVENTS_REQUEST, getAllEvents);
    yield takeLatest(Types.EDIT_EVENT_REQUEST, editEvent);
    yield takeLatest(Types.CREATE_EVENT_REQUEST, createEvent);
    yield takeLatest(Types.DELETE_EVENT_REQUEST, deleteEvent);
}
