import React, { useEffect } from "react";
import {
  Box,
  Flex,
  FormLabel,
  Heading,
  Icon,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { closeCreateTeacher } from "../../../../modules/admin-modules/models/Actions";
import MainModel from "../../../../components/Models/main-model";
import { useForm } from "react-hook-form";
import MainInput from "../../../../components/Form/input";
import {
  createTeacherRequest,
  editTeacherRequest,
} from "../../../../modules/admin-modules/teachers/Actions";
import { getAllGradesRequest } from "../../../../modules/admin-modules/grades/Actions";
import { getAllSubjectsRequest } from "../../../../modules/admin-modules/subjects/Actions";

const AddTeacher = ({ isAdd, data, setSelectedData }) => {
  const openTeacher = useSelector((state) => state.model.isOpen7);
  const dispatch = useDispatch();
  const subjects = useSelector((state) => state.subjects.subjects);
  const grades = useSelector((state) => state.grades.grades);
  const isLoading = useSelector((state) => state.teachers.isLoading);

  const gradesOptions = grades?.map((data) => {
    return { value: data.id, label: data.name };
  });

  const subjectsOptions = subjects?.map((data) => {
    return { value: data.id, label: data.name };
  });

  useEffect(() => {
    if (openTeacher) {
      dispatch(getAllGradesRequest());
      dispatch(getAllSubjectsRequest());
    }
  }, [openTeacher]);
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      width: "100%",
      background: "#f5f5f5",
      color: "#690000",
      borderRadius: "16px",
    }),
    option: (styles) => ({ ...styles, color: "#690000" }),
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: data ? data : {},
    shouldUnregister: true,
  });

  const header = (
    <Box>
      <Heading textAlign="center" color="main_1" fontSize="24px">
        {isAdd ? " إضافة معلم" : "تعديل  معلم"}
      </Heading>
    </Box>
  );
  const onSubmit = (data) => {
    const action = () => {
      dispatch(closeCreateTeacher);
      setSelectedData({});
      reset();
    };
    if (isAdd) {
      let formData = new FormData();
      for (let key in data) {
        formData.append(key, data[key]);
      }
      dispatch(createTeacherRequest(formData, action));
    } else {
      let formData = new FormData();
      for (let key in data) {
        formData.append(key, data[key]);
      }
      dispatch(
        editTeacherRequest({
          id: data?.id,
          formData: formData,
        })
      );
    }
  };

  const closeFunc = () => {
    dispatch(closeCreateTeacher());
    setSelectedData({});
    reset();
  };
  return (
    <MainModel
      isLoading={isLoading}
      header={header}
      closeFunc={closeFunc}
      openVar={openTeacher}
      buttonTitle={isAdd ? " إضافة معلم" : "تعديل  معلم"}
      buttonFunc={handleSubmit(onSubmit)}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={5} dir="rtl" w="100%">
          <Box w="55%">
            <MainInput
              label="اسم المعلم"
              error={errors?.name?.message}
              placeholder="ادخل اسم المعلم"
              register={register}
              name="name"
            />
          </Box>
          <Box w="55%">
            <MainInput
              label="رقم الموبايل"
              error={errors?.phone?.message}
              placeholder="اكتب رقم الموبايل"
              register={register}
              name="phone"
            />
          </Box>
          <Box w="55%">
            <MainInput
              label="كلمة المرور"
              error={errors?.password?.message}
              placeholder="اكتب كلمة المرور"
              register={register}
              name="password"
            />
          </Box>
          <Box w="55%">
            <MainInput
              label="اسم المستخدم"
              error={errors?.user_name?.message}
              placeholder="اكتب اسم المستخدم"
              register={register}
              name="user_name"
            />
          </Box>
          <Box w="55%">
            <MainInput
              label=" البريد الالكتروني"
              error={errors?.email?.message}
              placeholder="اكتب البريد الالكتروني"
              register={register}
              name="email"
            />
          </Box>
          <Box w="55%">
            <FormLabel>المادة : </FormLabel>
            <Select
              options={subjectsOptions}
              styles={colourStyles}
              placeholder="المادة"
              onChange={(data) => setValue("subject_id", data.value)}
            />
          </Box>
          <Box w="55%">
            <FormLabel>الصف : </FormLabel>
            <Select
              options={gradesOptions}
              defaultValue={{
                label: data?.chapter?.name,
                value: data?.chapter?.id,
              }}
              onChange={(data) => setValue("grade_id", data.value)}
              styles={colourStyles}
              placeholder="الصف"
            />
          </Box>

          <Box w="55%">
            <FormLabel>النوع : </FormLabel>
            <Stack
              direction="row"
              role="group"
              aria-labelledby="my-radio-group"
            >
              <label>
                <input
                  {...register("gender", { required: true })}
                  type="radio"
                  value="Female"
                />
                مدرس
              </label>
              <label>
                <input
                  {...register("gender", { required: true })}
                  type="radio"
                  value="Male"
                />
                مدرسة
              </label>
            </Stack>{" "}
          </Box>
        </Stack>
      </form>
    </MainModel>
  );
};

export default AddTeacher;
