import { Box, Flex, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import CTable from '../../../components/Table/table'
import Select from 'react-select'
import { examAttendaceRequest, examReportRequest } from '../../../modules/admin-modules/reports/Actions';
import Layout from '../../../components/dashboard/Layout';
import { getAllExamsRequest } from '../../../modules/admin-modules/exams/Actions';
import { getAllGroupsRequest } from '../../../modules/admin-modules/groups/Actions';
import LogoutMenu from '../../../components/dashboard/Layout/logout-menu';
import { CSVLink } from "react-csv";


const ExamReport = ({ setAdd, setSelectedData, searchTerm }) => {
    const dispatch = useDispatch()
    const [examId, setExamId] = useState('')
    const [groupId, setGroupId] = useState(['all'])

    useEffect(() => {
        if (examId) dispatch(examReportRequest(examId))
    }, [examId])

    useEffect(() => {
        dispatch(getAllExamsRequest());
        dispatch(getAllGroupsRequest());
    }, [])
    const exams = useSelector((state) => state.exams.exams)
    const examsOptions = exams?.map((data) => {
        return { value: data.id, label: data.name }
    })

    const groups = useSelector((state) => state.groups.groups)
    const groupOptions = [{ value: 'all', label: 'All' }]
    groups?.map((group) => {
        groupOptions.push({ value: group.id, label: group.name })
    });

    const examReport = useSelector((state) => state.reports.examReport);
    const iaLoading = useSelector((state) => state.reports.iaLoading);

    const [offset, setPage] = useState(0);

    const [size, setPerPage] = useState(10);
    const [pageNumber, setPageNumber] = useState(0);
    const [selected, setSelected] = useState({
        id: ''
    })
    const colourStyles = {
        control: (styles) => ({ ...styles, width: '100%', background: '#f5f5f5', color: '#690000', borderRadius: '16px' }),
        option: (styles) => ({ ...styles, color: '#690000' }),

    };

    const viewData = (data) => {
        setSelectedData(data);
        setSelected(data)
    };
    const headers = [
        { label: 'name', key: 'name' },
        { label: 'group_name', key: 'group_name' },
        { label: 'phone', key: 'phone' },
        { label: 'parent_phone', key: 'parent_pone' },
        { label: 'examResult', key: 'examResult' },


    ];

    const filteredData = examReport?.filter((data) => {
        if(groupId.includes('all')) return data
        else return groupId.includes(data?.group.id)
    });

    const Data =
        filteredData?.map((data, index) => {
            console.log(data)
            return {
                ...data,
                index: `#${index + 1}`,
                id: data?.id,
                name: data?.name,
                group_name: data?.group?.name,
                phone: data?.phone,
                groupId: data?.group.id,
                examResult: `${data?.exam?.find((val) => val?.id == examId)?.pivot?.right_answers} / ${data?.exam?.find((val) => val?.id == examId)?.question_number}`

            };
        })
    const columns = [
        {
            Header: 'رقم الطالب',
            accessor: 'index'
        },
        {
            Header: 'اسم الطالب',
            accessor: 'name'
        },
        {
            Header: ' المجموعة',
            accessor: 'group_name'
        },
        {
            Header: ' الدرجة',
            accessor: 'examResult'
        },

        {
            Header: ' رقم ولي الامر ',
            accessor: 'parent_pone'
        },
        {
            Header: ' رقم  الطالب',
            accessor: 'phone'
        },



    ];


    return (
        <Layout >
            <div style={{ minHeight: '400px' }}>

                <Flex w='100%' my='30px' justifyContent='space-between' alignItems='center' position='relative' zIndex='10000000'>

                    <Text fontSize='24px'
                        fontWeight='600' color='main_1'>
                        تقرير  الامتحانات
                    </Text>
                    <Flex w='50%' justifyContent='space-between' alignItems='center' >

                        <Box w='75%' pl={2}>
                            <Select options={examsOptions} isMulti={false} onChange={(data) => {
                                setExamId(data?.value)
                            }}
                                styles={colourStyles}
                                placeholder='Select Exam'
                            />
                        </Box>
                        <Box w='75%'>
                            <Select options={groupOptions} isMulti={true} onChange={(data) => {
                                setGroupId(data?.map((group) => group?.value))
                            }}
                                styles={colourStyles}
                                placeholder='Select Group'
                            />
                        </Box>
                        <Box mx='10px' border='1px solid gray' borderRadius='8px' padding='10px'>
                            <CSVLink
                                headers={headers}
                                data={Data?.length != 0 ? Data : []}
                                filename={"exam-report.csv"}
                                className="btn btn-primary"
                                target="_blank"
                            >
                                Download
                            </CSVLink>
                        </Box>
                        <LogoutMenu />
                    </Flex>
                </Flex>

                <CTable
                    selectedData={viewData}
                    Columns={columns}
                    Data={Data}
                    path={'openModel'}
                    Actions
                    Title={``}
                    subTitle=''
                    btnTitle=""
                    placeHolder=""
                    noSearchBar={true}
                    noFilter={true}
                    footerBtnTitle
                    filterList
                    addButton
                    addButtonTitle=""
                    setPage={setPage}
                    setPerPage={setPerPage}
                    currentpage={pageNumber}
                    setPageNumber={setPageNumber}
                    noSecondSearchBar={true}
                    isLoading={iaLoading}
                    perPage={size}
                    totalPage={Math.ceil(10 / 50)}

                />
            </div>
        </Layout>

    )
}

export default ExamReport