import { Box, Button, HStack, Text, VStack } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import Layout from '../../../components/dashboard/Layout'
import { getAllExamsRequest } from '../../../modules/admin-modules/exams/Actions'
import { getAllGroupsRequest } from '../../../modules/admin-modules/groups/Actions'
import { getAllLecturesRequest } from '../../../modules/admin-modules/lectures/Actions'
import { attendanceReportRequest, examAttendaceRequest, examReportRequest, studentsPerGroupRequest, videoReportRequest } from '../../../modules/admin-modules/reports/Actions'
import { getAllVideosRequest } from '../../../modules/admin-modules/videos/Actions'

const DownloadableReport = () => {
    const [videoId, setVideoId] = useState('')
    const [lecId, setLecId] = useState('')
    const [examId, setExamId] = useState('')
    const [groupId, setGroupId] = useState('')

    const dispatch = useDispatch()
    const videos = useSelector((state) => state.videos.videos)
    const lectures = useSelector((state) => state.lectures.lectures)
    const exams = useSelector((state) => state.exams.exams)
    const groups = useSelector((state) => state.groups.groups)

    const videosOptions = videos?.map((data) => {
        return { value: data.id, label: data.title }
    })
    const lecOptions = lectures?.map((data) => {
        return { value: data.id, label: data.title }
    })
    const examsOptions = exams?.map((data) => {
        return { value: data.id, label: data.name }
    })
    const groupsOptions = groups?.map((data) => {
        return { value: data.id, label: data.name }
    })
    const colourStyles = {
        control: (styles) => ({ ...styles, width: '100%', background: '#f5f5f5', color: '#690000', borderRadius: '16px' }),
        option: (styles) => ({ ...styles, color: '#690000' }),

    };
    useEffect(() => {
        dispatch(getAllVideosRequest())
        dispatch(getAllLecturesRequest())
        dispatch(getAllExamsRequest())

        dispatch(getAllGroupsRequest())

    }, [])
    return (
        <Layout>
            <VStack h='100vh' my='30px'>
                <HStack w='100%'>
                    <VStack w='50%' border='1px solid gray' borderRadius={'8px'} alignItems='flex-end' p='20px' spacing={'4'}>
                        <Text fontSize='20px' color='main_1'>Video Report</Text>

                        <Box w='100%'>
                            <Select options={videosOptions} isMulti={false} onChange={(data) => {
                                setVideoId(data?.value)
                            }}
                                styles={colourStyles}
                                placeholder='Select video'
                            />
                        </Box>

                        <a href={`https://api.enoughphysics.com/api/admin/reports/video/${videoId}`} style={{ alignSelf: 'center' }}>

                            <Button bg='main_1' color='white' alignSelf='center'
                                onClick={() => dispatch(videoReportRequest(videoId))}>
                                Download Report
                            </Button>
                        </a>
                    </VStack>

                    <VStack w='50%' border='1px solid gray' borderRadius={'8px'} alignItems='flex-end' p='20px' spacing={'4'}>
                        <Text fontSize='20px' color='main_1'>Attendance Report</Text>

                        <Box w='100%'>
                            <Select options={lecOptions} isMulti={false} onChange={(data) => {
                                setLecId(data?.value)
                            }}
                                styles={colourStyles}
                                placeholder='Select lecture'
                            />
                        </Box>

                        <a href={`https://api.enoughphysics.com/api/admin/reports/attend/${lecId}`} style={{ alignSelf: 'center' }}>
                            <Button bg='main_1' color='white' alignSelf='center'
                            >
                                Download Report
                            </Button>                        </a>


                    </VStack>

                </HStack>

                <HStack w='100%'>
                    <VStack w='50%' border='1px solid gray' borderRadius={'8px'} alignItems='flex-end' p='20px' spacing={'4'}>
                        <Text fontSize='20px' color='main_1'>Exam Report</Text>

                        <Box w='100%'>
                            <Select options={examsOptions} isMulti={false} onChange={(data) => {
                                setExamId(data?.value)
                            }}
                                styles={colourStyles}
                                placeholder='Select Exam'
                            />
                        </Box>

                        <a href={`https://api.enoughphysics.com/api/admin/reports/exam/${examId}`} style={{ alignSelf: 'center' }} >

                            <Button bg='main_1' color='white' alignSelf='center'
                                onClick={() => dispatch(examReportRequest(examId))}>
                                Download Report
                            </Button>
                        </a>
                    </VStack>

                    <VStack w='50%' border='1px solid gray' borderRadius={'8px'} alignItems='flex-end' p='20px' spacing={'4'}>
                        <Text fontSize='20px' color='main_1'>Exam Attendence Report</Text>

                        <Box w='100%'>
                            <Select options={examsOptions} isMulti={false} onChange={(data) => {
                                setExamId(data?.value)
                            }}
                                styles={colourStyles}
                                placeholder='Select Exam'
                            />
                        </Box>

                        <a href={`https://api.enoughphysics.com/api/admin/reports/exam/${examId}`} style={{ alignSelf: 'center' }}>

                            <Button bg='main_1' color='white' alignSelf='center'
                                onClick={() => dispatch(examAttendaceRequest(examId))}>
                                Download Report
                            </Button>
                        </a>
                    </VStack>

                </HStack>
                <HStack w='100%'>
                    <VStack w='50%' border='1px solid gray' borderRadius={'8px'} alignItems='flex-end' p='20px' spacing={'4'}>
                        <Text fontSize='20px' color='main_1'>Students per group Report</Text>

                        <Box w='100%'>
                            <Select options={groupsOptions} isMulti={false} onChange={(data) => {
                                setGroupId(data?.value)
                            }}
                                styles={colourStyles}
                                placeholder='Select Group'
                            />
                        </Box>

                        <a href={`https://api.enoughphysics.com/api/admin/reports/exam/${groupId}`} style={{ alignSelf: 'center' }}>

                            <Button bg='main_1' color='white' alignSelf='center'
                                onClick={() => dispatch(studentsPerGroupRequest(groupId))}>
                                Download Report
                            </Button>
                        </a>

                    </VStack>



                </HStack>
            </VStack>

        </Layout>
    )
}

export default DownloadableReport