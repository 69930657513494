import React, { useState } from "react";
import { Container, Grid, Box } from "@chakra-ui/react";
import RecordesCard from "../../../components/student/Cards/recordesCard";

const RecordesComp = () => {
  const [recordesData, setRecordesData] = useState(
    require("../../../staticData/recordes.json")
  );
  return (
    <Box bg='main_1' py='40px'>
      <Container maxW={"container.xl"}>
        <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)", lg: 'repeat(5, 1fr)' }} gap="50px">
          {recordesData?.map((data, index) => {
            return (
              <RecordesCard
                key={index}
                title={data?.title}
                number={data?.number}

              />
            );
          })}
        </Grid>
      </Container>
    </Box>
  );
};

export default RecordesComp;
