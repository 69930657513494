import { Box, Stack } from "@chakra-ui/react";
import React from "react";
import Footer from "./footer";
import Navbar from "./navbar";
import StudentWrapper from "../../Auth/StudentWrapper";
import UserData from "../../../containers/student/models/user-data";
import Loading from "../../UI/loading";
const Layout = ({ children, color, bg, isLoading = false }) => {
  return (
    <StudentWrapper roles={["Student"]}>
      <Stack
        h="100%"
        minH="100vh"
        w="100%"
        className="student"
        bgColor="white"
        justifyContent="space-between"
      >
        <Box h="7%">
          <Navbar color={color} bg={bg} />
        </Box>
        <Box mt="10px">{isLoading ? <Loading /> : children}</Box>
        <Footer />
      </Stack>
      <UserData />
    </StudentWrapper>
  );
};

export default Layout;
