export default {
    //get all QUESTIONS_BANKs
    GET_QUESTIONS_BANK_REQUEST: 'GET_QUESTIONS_BANK_REQUEST',
    GET_QUESTIONS_BANK_SUCCESS: 'GET_QUESTIONS_BANK_SUCCESS',
    GET_QUESTIONS_BANK_FAIL: 'GET_QUESTIONS_BANK_FAIL',

    //edit QUESTIONS_BANKs
    EDIT_QUESTION_BANK_REQUEST: 'EDIT_QUESTION_BANK_REQUEST',
    EDIT_QUESTION_BANK_SUCCESS: 'EDIT_QUESTION_BANK_SUCCESS',
    EDIT_QUESTION_BANK_FAIL: 'EDIT_QUESTION_BANK_FAIL',

    //create QUESTION_BANK
    CREATE_QUESTION_BANK_REQUEST: 'CREATE_QUESTION_BANK_REQUEST',
    CREATE_QUESTION_BANK_SUCCESS: 'CREATE_QUESTION_BANK_SUCCESS',
    CREATE_QUESTION_BANK_FAIL: 'CREATE_QUESTION_BANK_FAIL',

    DELETE_QUESTION_BANK_REQUEST: 'DELETE_QUESTION_BANK_REQUEST',
    DELETE_QUESTION_BANK_SUCCESS: 'DELETE_QUESTION_BANK_SUCCESS',
    DELETE_QUESTION_BANK_FAIL: 'DELETE_QUESTION_BANK_FAIL',

    BOOKMARK_QUESTION_BANK_REQUEST: 'BOOKMARK_QUESTION_BANK_REQUEST',
    BOOKMARK_QUESTION_BANK_SUCCESS: 'BOOKMARK_QUESTION_BANK_SUCCESS',
    BOOKMARK_QUESTION_BANK_FAIL: 'BOOKMARK_QUESTION_BANK_FAIL',

    GET_QUESTION_BANK_REQUEST: 'GET_QUESTION_BANK_REQUEST',
    GET_QUESTION_BANK_SUCCESS: 'GET_QUESTION_BANK_SUCCESS',
    GET_QUESTION_BANK_FAIL: 'GET_QUESTION_BANK_FAIL',

    //loading
    ADD_QUESTION_BANK_LOADING: 'ADD_QUESTION_BANK_LOADING',
    BOOKMARK_QUESTION_BANK_LOADING: 'BOOKMARK_QUESTION_BANK_LOADING',
    SUSPEND_QUESTION_BANK_LOADING: 'SUSPEND_QUESTION_BANK_LOADING'


};
