import axiosInstance from "../../helpers/api";

export const Login = (formData) => {
  return axiosInstance.post(`/login`, formData);
};
export const Logout = () => {
  return axiosInstance.get(`/logout`);
};

export const userVoucher = (formData) => {
  //console.log(formData);
  return axiosInstance.post(`/students/users_payments`, formData?.formData);
};
