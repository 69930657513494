import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { videoDataRequest } from "../../../modules/students-modules/videos/Actions";
import "./style.css";
/**
 * Component consuming the VdocipherAPI
 */
export default function VideoStatusUsingAPI({ videoRef, isAPIReady }) {
  const [status, setStatus] = useState("NA");
  const [player, setPlayer] = useState(null);
  const [currentTime, setCurrentTime] = useState(0);
  let [searchParams, setSearchParams] = useSearchParams();

  const [totalPalyed, setTotalPlayed] = useState(null);
  const [totalCoverd, setTotalCoverd] = useState(null);

  const userData = useSelector((state) => state.user.userData);
  const videoData = useSelector((state) => state.chapterVideos.videoData);
  const dispatch = useDispatch();

  const [start, setStart] = useState(0);

  useEffect(() => {
    if (!isAPIReady) return;
    if (!videoRef) {
      // @todo detach from the API
      //console.log("No");
      setPlayer(null);
      return;
    }
    const player = new window.VdoPlayer(videoRef);
    window.player = player;
    setPlayer(player);
    player.video.addEventListener("play", () => setStatus("Playing"));
    player.video.addEventListener("pause", () => setStatus("pause"));
    player.video.addEventListener("canplay", () => setStatus("Ready"));
    player.video.addEventListener("timeupdate", () => {
      setCurrentTime(player.video.currentTime);
    });

    window.player = player;
  }, [isAPIReady, videoRef]);

  useEffect(() => {
    if (player) {
      player.api.getTotalPlayed().then((data) => {
        let diff = data - start;
        setStart(data);
        setTotalPlayed(diff);
      });

      setTotalCoverd(player.video.currentTime);
    }
  }, [player, status]);

  useEffect(() => {
    if (totalPalyed && totalCoverd) {
      dispatch(
        videoDataRequest({
          // TODO: add variables to get the response of the request ENG: Radwa
          total_played: totalPalyed,
          total_covered: totalCoverd,
          video_id: searchParams.get("video"),
          user_id: userData?.id,
        })
      );
    }
  }, [totalCoverd, totalPalyed]);

  useEffect(() => {
    if (videoData.views_count > 3) {
      window.location.reload();
    }
  }, [videoData]);

  return <></>;
}
