import { Box, Button, Flex, HStack, Text, VStack } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { RiAddFill } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import LogoutMenu from '../../../components/dashboard/Layout/logout-menu'
import { getAllChaptersRequest } from '../../../modules/admin-modules/chapters/Actions'
import { openCreateQuestionModel } from '../../../modules/admin-modules/models/Actions'

const SearchBar = ({ setAdd, setSelectedData, setChapterId }) => {
    const dispatch = useDispatch()
    const count = useSelector((state) => state.questionsBank.count);
    const chapters = useSelector((state) => state.chapters.chapters)

    const chaptersOptions = chapters?.map((data) => {
        return { value: data.id, label: data.name }

    })
    const colourStyles = {
        control: (styles) => ({ ...styles, width: '100%', background: 'white', color: '#690000', borderRadius: '16px' }),
        option: (styles) => ({ ...styles, color: '#690000' }),

    };

    useEffect(() => {
        if (chapters?.length == 0) dispatch(getAllChaptersRequest())
    }, [chapters])
    return (
        <VStack w='100%' my='30px'>

            <Flex alignItems='center' w='100%' justifyContent='space-between'>
                <Text fontSize='24px'
                    fontWeight='600' color='main_1'>
                    بنك الاسئلة
                </Text>

                <Flex alignItems='center'  >
                    <Button borderRadius='16px' mx='5px' leftIcon={<RiAddFill />} color='white' bg='main_1' _focus={{ outline: 'none' }} onClick={() => {
                        setAdd(true)
                        dispatch(openCreateQuestionModel())
                    }
                    }>
                        إضافة سؤال
                    </Button>


                    <LogoutMenu />
                </Flex>


            </Flex>

            <Text w='100%' fontSize='20px' textAlign='right'>عدد الاسئلة  :{count} </Text>

            <HStack w='100%' justifyContent='flex-end' bg='#f5f5f5' mt='40px !important' p='10px' mb='-30px !important' alignItems='center'>


                <Box w='50%'>
                    <Select options={chaptersOptions} onChange={(data) => setChapterId(data?.value)} styles={colourStyles} placeholder='الفصل' />
                </Box>
            </HStack>

        </VStack >



    )
}

export default SearchBar