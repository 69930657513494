import { Button, HStack, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { useNavigate } from "react-router-dom";

const ExamCard = ({ exam }) => {
  const navigate = useNavigate();

  return (
    <HStack
      my={2}
      bg="#ededed"
      borderRadius="8px"
      alignItems="center"
      p="10px"
      gap="20px"
      w="100%"
    >
      <VStack color="main_1" w="100%">
        {/* header */}
        <HStack justifyContent="space-between" w="100%">
          <HStack justifyContent="space-between" w="80%" alignItems="start">
            <Text fontWeight="700" fontSize="26px" color="main_1">
              {exam?.name}
            </Text>
          </HStack>
          <VStack>
            <HStack>
              <Text fontWeight="500">Status</Text>
              <Text fontWeight="700" fontSize="18px" color="red">
                Pending
              </Text>
            </HStack>
            <HStack gap={2} w="100%">
              <Text fontWeight="500">Score</Text>
              <Text fontWeight="700" fontSize="18px" color="main_1">
                {exam?.score ?? 0}/{exam?.total_count}
              </Text>
            </HStack>
          </VStack>
        </HStack>
        <HStack w="100%">
          <VStack alignItems="start" w="100%">
            <HStack gap={2} w="100%">
              <Text fontWeight="500" w="145px">
                Solved questions
              </Text>
              <Text fontWeight="700" fontSize="18px" color="main_1">
                {exam?.answered_count}
              </Text>
            </HStack>
            <HStack gap={2} w="100%">
              <Text fontWeight="500" w="145px">
                Remaining
              </Text>
              <Text fontWeight="700" fontSize="18px" color="main_1">
                {exam?.remain_count}
              </Text>
            </HStack>
            {exam?.total_count && (
              <HStack gap={2} w="100%">
                <Text fontWeight="500" w="145px">
                  target
                </Text>
                <Text fontWeight="700" fontSize="18px" color="main_1">
                  {exam?.total_count}
                </Text>
              </HStack>
            )}
            {exam?.timer == 1 && (
              <HStack justifyContent="space-between" w="100%">
                <Text fontWeight="500" w="145px">
                  Exam time:
                </Text>
                <Text fontWeight="700" fontSize="18px" color="main_1">
                  {exam?.time} min{" "}
                </Text>
              </HStack>
            )}
          </VStack>
        </HStack>

        <Button
          alignSelf="end"
          w={{ base: "100%", md: "30%" }}
          bg="main_1"
          color="white"
          isDisabled={exam?.enable == 0 ? false : true}
          onClick={() => navigate(`/study/${exam?.id}`)}
        >
          Go practice
        </Button>
      </VStack>
    </HStack>
  );
};

export default ExamCard;
