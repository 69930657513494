import axiosInstance from "../../../helpers/api";

export const getVideosByChapterID = (id) => {
  return axiosInstance.get(`/students/videos/${id}`);
};

export const getVideoByID = (id) => {
  return axiosInstance.get(`/students/videos/show/${id}`);
};

export const getOtp = (id) => {
  return axiosInstance.get(`/students/videos/otp/${id}`);
};
export const setOtpUserData = (body) => {
  return axiosInstance.post(
    `https://dev.vdocipher.com/api/videos/${body?.id}/otp`,
    body
  );
};
export const updateSeenCount = (body) => {
  return axiosInstance.put(`/students/user_videos/${body?.video_id}`);
};

export const createSeenCount = (body) => {
  return axiosInstance.post(`/students/user_videos`, body);
};
export const addVoucher = (body) => {
  return axiosInstance.post(`/students/video_requests/submitVoucher`, body);
};
export const moreVideoViews = (body) => {
  return axiosInstance.post(`/students/video_requests`, body);
};

export const videoData = (body) => {
  return axiosInstance.post(`/students/user_videos/add_watched_time`, body);
};

export const setVideoSeenCount = (id) => {
  return axiosInstance.put(`/students/user_videos/set_play/${id}`);
};
