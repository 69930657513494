import {
  Button,
  Flex,
  Text,
  VStack,
  InputGroup,
  InputLeftElement,
  Input,
  HStack,
} from "@chakra-ui/react";
import React from "react";
import { RiAddFill, RiSearch2Fill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import LogoutMenu from "../../../components/dashboard/Layout/logout-menu";
import { openCreatePaymentModel } from "../../../modules/admin-modules/models/Actions";

const SearchBar = ({ setAdd, setSearchTerm }) => {
  const dispatch = useDispatch();
  const payments = useSelector((state) => state.payments.count);

  return (
    <VStack w="100%" my="30px">
      <Flex alignItems="center" w="100%" justifyContent="space-between">
        <Text fontSize="24px" fontWeight="600" color="main_1">
          الحضور والدفع وتعويضات الحصص{" "}
        </Text>

        <Flex alignItems="center">
          <LogoutMenu />
        </Flex>
      </Flex>

      <HStack
        w="100%"
        justifyContent="space-between"
        mt="40px !important"
        p="10px"
        mb="-30px !important"
      >
        <InputGroup w="400px">
          <InputLeftElement
            pointerEvents="none"
            children={<RiSearch2Fill color="main_1" fontSize="20px" />}
          />
          <Input
            type="text"
            placeholder="ابحث باسم الطالب أو رقم الموبايل"
            borderRadius="16px"
            bg="#f5f5f5"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </InputGroup>
      </HStack>
    </VStack>
  );
};

export default SearchBar;
