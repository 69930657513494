export default {
    //get all VIDEOs
    GET_MISSED_QUESTIONS_REQUEST: 'GET_MISSED_QUESTIONS_REQUEST',
    GET_MISSED_QUESTIONS_SUCCESS: 'GET_MISSED_QUESTIONS_SUCCESS',
    GET_MISSED_QUESTIONS_FAIL: 'GET_MISSED_QUESTIONS_FAIL',

    GET_BOOKMARKED_QUESTIONS_REQUEST: 'GET_BOOKMARKED_QUESTIONS_REQUEST',
    GET_BOOKMARKED_QUESTIONS_SUCCESS: 'GET_BOOKMARKED_QUESTIONS_SUCCESS',
    GET_BOOKMARKED_QUESTIONS_FAIL: 'GET_BOOKMARKED_QUESTIONS_FAIL',



    //loading
    ADD_QUESTIONS_LOADING: 'ADD_QUESTIONS_LOADING'
};
