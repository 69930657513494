import {
  Box,
  Container,
  Flex,
  HStack,
  Image,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../components/student/Layout/layout";

import QRCode from "qrcode.react";
import bg from "../../assets/ezzat.jpeg";
import Performance from "../../containers/student/performance/index";
import { getAllStudentChaptersRequest } from "../../modules/students-modules/chapters/Actions";
import {
  getAnswersAnalyticsRequest,
  getTopPerformanceAllGroupspRequest,
  getTopPerformancePerGroupRequest,
} from "../../modules/students-modules/performance/Actions";

const StudentHome = () => {
  const baseUrl = "https://enoughphysics.com/enoughapi";
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);
  const chapters = useSelector(
    (state) => state.studentChapters.studentChapters
  );

  const perGroup = useSelector((state) => state.performance.perGroup);
  const answers = useSelector((state) => state.performance.answers);

  useEffect(() => {
    dispatch(getTopPerformanceAllGroupspRequest());
    dispatch(getTopPerformancePerGroupRequest());
    dispatch(getAnswersAnalyticsRequest());
  }, []);
  useEffect(() => {
    dispatch(getAllStudentChaptersRequest());
  }, []);

  return (
    <Layout color="main_1" bg="white">
      <Box
        bg="main_1"
        color="white"
        w="100%"
        p="10px"
        my="100px"
        mb="35px"
        textAlign="center"
        fontSize="22px"
      >
        {` Welcome ${userData?.name} To Enough in Physics, the most innovative portal for physics Students `}{" "}
      </Box>
      <Container maxW="container.2xl" h="100%" w="100%">
        <Stack
          flexDir={{ base: "column", lg: "row" }}
          alignItems="flex-start"
          justifyContent=" space-between"
          borderRadius="8px"
          p="10px"
        >
          {/* data */}

          <Flex
            w={{ base: "100%", lg: "50%" }}
            borderRadius="8px"
            boxShadow="0px 1px 5px 0px #80808066"
            p="10px"
            flexDir="column"
            alignItems="center"
          >
            <HStack alignItems="flex-start" gap={4}>
              <Image src={bg} h="100%" w="25%" />
              <VStack alignItems="flex-start" mx="7px">
                <Text color="main_1" fontSize="14px" fontWeight="600">
                  Mr. Mohammed Ezzat
                </Text>
                <Text color="main_1">{userData?.subject?.name}</Text>
                <Text color="main_1">{userData?.grade?.name}</Text>
                <Text color="main_1">{userData?.group?.name}</Text>
                <QRCode
                  size={200}
                  value={`https://enoughphysics.com/scan-code/${userData?.id}/attendence`}
                />
              </VStack>
            </HStack>
          </Flex>
          <VStack
            w={{ base: "100%", lg: "47%" }}
            borderRadius="8px"
            p="10px"
            boxShadow="0px 1px 5px 0px #80808066"
          >
            <Text
              color="main_1"
              fontSize="20px"
              fontWeight="600"
              alignSelf="flex-start"
            >
              Live Video
            </Text>

            <Box
              bg="#EDEDED"
              borderRadius="8px"
              alignItems="center"
              p="25px"
              w="100%"
            >
              <Text
                textAlign="center"
                color="main_1"
                fontSize="18px"
                fontWeight="600"
              >
                There isn't any available live videos
              </Text>
            </Box>

            <Text
              bg="main_1"
              color="white"
              textAlign="center"
              p="7px"
              borderRadius="8px"
            >
              Live Video Page
            </Text>
          </VStack>
        </Stack>

        <Stack
          flexDir={{ base: "column", lg: "row" }}
          w="100%"
          borderRadius="8px"
          alignItems="flex-start"
          justifyContent="space-between"
          p="10px"
          spacing={2}
        >
          <Box
            w={{ base: "100%", lg: "60%" }}
            borderRadius="8px"
            boxShadow="0px 1px 5px 0px #80808066"
            p="10px"
          >
            <Text color="main_1" fontSize="20px" fontWeight="600" mb="15px">
              Performance across all exams
            </Text>
            <Performance
              percentage={answers["percentage"]}
              totalQuestions={answers["total_exams_questions"]}
              rightAnswers={answers["total right answers"]}
              totalExams={answers["exams_count"]}
            />
          </Box>

          <Box
            w={{ base: "100%", lg: "35%" }}
            borderRadius="8px"
            boxShadow="0px 1px 5px 0px #80808066"
            p="10px"
            h="100%"
          >
            <Text color="main_1" fontSize="20px" fontWeight="600" mb="15px">
              Performance per group
            </Text>
            <Performance
              percentage={perGroup["percentage"]}
              totalQuestions={perGroup["total_exams_questions"]}
              rightAnswers={perGroup["total right answers"]}
            />
          </Box>
        </Stack>
        <Stack
          flexDir={{ base: "column", lg: "row" }}
          justifyContent="space-between"
          borderRadius="8px"
          p="10px"
        >
          {/* latest videos */}

          <Box w={{ base: "100%", lg: "60%" }} mt="0 !important">
            <Box
              w="100%"
              borderRadius="8px"
              boxShadow="0px 1px 5px 0px #80808066"
              p="10px"
            >
              <Text color="main_1" fontSize="20px" fontWeight="600" mb="15px">
                Printable Files
              </Text>

              <Box
                bg="#EDEDED"
                borderRadius="8px"
                alignItems="center"
                p="25px"
                w="100%"
              >
                <Text
                  textAlign="center"
                  color="main_1"
                  fontSize="18px"
                  fontWeight="600"
                >
                  There isn't any available printable files
                </Text>
              </Box>
            </Box>
            <Box
              w="100%"
              borderRadius="8px"
              boxShadow="0px 1px 5px 0px #80808066"
              p="10px"
              mt="20px"
            >
              <Text color="main_1" fontSize="20px" fontWeight="600" mb="15px">
                Latest Updates
              </Text>

              <Box
                bg="#EDEDED"
                borderRadius="8px"
                alignItems="center"
                p="25px"
                w="100%"
              >
                <Text
                  textAlign="center"
                  color="main_1"
                  fontSize="18px"
                  fontWeight="600"
                >
                  There isn't any available updates
                </Text>
              </Box>
            </Box>
          </Box>
        </Stack>
        <Box w="100%" my="30px" borderRadius="8px" p="10px">
          <Text color="main_1" fontSize="20px" fontWeight="600">
            Chapters
          </Text>

          <Text color="main_1" fontSize="16px">
            Chapters
          </Text>

          <HStack py="15px" className="scrollX">
            {chapters.map((slide) => {
              return (
                <VStack
                  justifyContent="space-between"
                  p="5px"
                  bg="white"
                  borderRadius="8px"
                  boxShadow="0px 1px 5px 0px #80808066"
                  minW={{ base: "100%", md: "30%" }}
                  h="200px"
                >
                  <Image src={`${baseUrl}/${slide?.logo}`} h="60%" />
                  <Text
                    bg="main_1"
                    color="white"
                    w="100%"
                    textAlign="center"
                    p="7px"
                    borderRadius="8px"
                  >
                    {slide?.name}
                  </Text>
                </VStack>
              );
            })}
          </HStack>
        </Box>
      </Container>
    </Layout>
  );
};

export default StudentHome;
