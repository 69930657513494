import { VStack } from "@chakra-ui/react";
import React from "react";
import CountdownTimer from "../../../components/timer-hook/count-down-timer";

const Timer = ({ time, handelSendAnswers }) => {
  const THREE_DAYS_IN_MS = Number(time) * 1000 * 60;
  const startTime = window.localStorage.getItem("startTime");
  const NOW_IN_MS = new Date(startTime)?.getTime();
  const targetDate = THREE_DAYS_IN_MS + NOW_IN_MS;
  return (
    <VStack
      fontSize="28px"
      mt={{ base: "0", lg: "-55px !important" }}
      alignItems="center"
      justifyContent="center"
      w="150px"
      h="90px"
      border="1px solid"
      color="main_1"
      borderColor="main_1"
      borderRadius="8px"
      alignSelf="flex-end"
    >
      <CountdownTimer
        targetDate={targetDate}
        handelSendAnswers={handelSendAnswers}
      />
    </VStack>
  );
};

export default Timer;
