import { Button, Flex, Input, Stack, Text, Textarea } from "@chakra-ui/react";
import React from "react";

const ContsctUs = () => {
  return (
    <Flex
      flexDirection={"column"}
      my="30px"
      alignItems={"center"}
      justifyContent="center"
      id="contactus"
    >
      <Text
        fontSize="32px"
        fontWeight="600"
        my="10px"
        textTransform={"uppercase"}
      >
        contact us
      </Text>

      <Stack spacing={3} w={{ base: "90%", md: "50%" }}>
        <Input placeholder="Email" size="md" />
        <Textarea
          placeholder="Your Comment"
          size="lg"
          resize="vertical"
          minH={"200px"}
        />
      </Stack>
      <Button bg="main_1" color="white" my="15px" w="200px">
        Send Message
      </Button>
    </Flex>
  );
};

export default ContsctUs;
