export default {
    //get all EVENTs
    GET_EVENTS_REQUEST: 'GET_EVENTS_REQUEST',
    GET_EVENTS_SUCCESS: 'GET_EVENTS_SUCCESS',
    GET_EVENTS_FAIL: 'GET_EVENTS_FAIL',

    //edit EVENTs
    EDIT_EVENT_REQUEST: 'EDIT_EVENT_REQUEST',
    EDIT_EVENT_SUCCESS: 'EDIT_EVENT_SUCCESS',
    EDIT_EVENT_FAIL: 'EDIT_EVENT_FAIL',

    //create EVENT
    CREATE_EVENT_REQUEST: 'CREATE_EVENT_REQUEST',
    CREATE_EVENT_SUCCESS: 'CREATE_EVENT_SUCCESS',
    CREATE_EVENT_FAIL: 'CREATE_EVENT_FAIL',

    DELETE_EVENT_REQUEST: 'DELETE_EVENT_REQUEST',
    DELETE_EVENT_SUCCESS: 'DELETE_EVENT_SUCCESS',
    DELETE_EVENT_FAIL: 'DELETE_EVENT_FAIL',

    GET_EVENT_REQUEST: 'GET_EVENT_REQUEST',
    GET_EVENT_SUCCESS: 'GET_EVENT_SUCCESS',
    GET_EVENT_FAIL: 'GET_EVENT_FAIL',

    //loading
    ADD_EVENTS_LOADING: 'ADD_EVENTS_LOADING'
};
