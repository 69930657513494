import { toast } from 'react-toastify';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import * as actions from './Actions';
import * as api from './Api';
import Types from './Types';

function* getAllQuestionsBank({ payload }) {
    try {
        yield put(actions.addQuestionBankLoading(true));
        const result = yield call(api.getAllQuestionsBank, payload);
        yield put(actions.getAllQuestionsBankSuccess(result.data));
    } catch (error) {
        const { response, message } = error;
        toast.error(error);
        yield put(actions.getAllQuestionsBankFail());
    } finally {
        yield put(actions.addQuestionBankLoading(false));
    }
}



//Edit Tables
function* editQuestionBank({ payload }) {
    try {
        yield put(actions.addQuestionBankLoading(true));
        const result = yield call(api.editQuestionBank, payload);
        yield put(actions.editQuestionBankSuccess(result.data));
        toast.success('Edit Successfully');
        payload?.action && payload?.action()
    } catch (error) {
        yield put(actions.editQuestionBankFail());
    } finally {
        yield put(actions.addQuestionBankLoading(false));
    }
}

// create
function* createQuestionBank(formData) {
    try {
        yield put(actions.addQuestionBankLoading(true));
        const result = yield call(api.createQuestionBank, formData.payload);
        yield put(
            actions.createQuestionBankSuccess({
                data: result.data
            })
        );
        toast.success('Created Successfully');
        formData?.action && formData?.action()
    } catch (error) {
        yield put(actions.createQuestionBankFail());
    } finally {
        yield put(actions.addQuestionBankLoading(false));
    }
}

function* deleteQuestionBank({ payload }) {
    try {
        yield put(actions.suspendQuestionBankLoading(true));
        const result = yield call(api.deleteQuestionBank, payload);

        yield put(actions.deleteQuestionBankSuccess({ payload, result }));

        toast.success('Suspended Successfully');
    } catch (error) {
        const { response, message } = error;
        toast.error(error.response);
        yield put(actions.deleteQuestionBankFail());
    } finally {
        yield put(actions.suspendQuestionBankLoading(false));
    }
}

function* bookmarkQuestionBank({ payload }) {
    try {
        yield put(actions.bookmarkQuestionBankLoading(true));
        const result = yield call(api.bookmarkQuestionBank, payload);

        yield put(actions.bookmarkQuestionBankSuccess({ payload, result }));

        toast.success('Bookmark Successfully');
    } catch (error) {
        const { response, message } = error;
        toast.error(error.response);
        yield put(actions.bookmarkQuestionBankFail());
    } finally {
        yield put(actions.bookmarkQuestionBankLoading(false));
    }
}
export default function* QuestionBankSaga() {
    yield takeLatest(Types.GET_QUESTIONS_BANK_REQUEST, getAllQuestionsBank);
    yield takeLatest(Types.EDIT_QUESTION_BANK_REQUEST, editQuestionBank);
    yield takeLatest(Types.CREATE_QUESTION_BANK_REQUEST, createQuestionBank);
    yield takeLatest(Types.DELETE_QUESTION_BANK_REQUEST, deleteQuestionBank);
    yield takeLatest(Types.BOOKMARK_QUESTION_BANK_REQUEST, bookmarkQuestionBank);

}
