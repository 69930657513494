import { Button, IconButton, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { RiFileEditLine } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux';
import CTable from '../../../components/Table/table'
import { openCreateLectureModel, openCreateLsson } from '../../../modules/admin-modules/models/Actions';
import dayjs from 'dayjs'
const LecturesTable = ({ setAdd, setSelectedData }) => {
    const lectures = useSelector((state) => state.lectures.lectures)
    const dispatch = useDispatch()
    const isLoading = useSelector((state) => state.lectures.isLoading)
    const [offset, setPage] = useState(0);
    const [size, setPerPage] = useState(10);
    const [pageNumber, setPageNumber] = useState(0);
    const [selected, setSelected] = useState({
        id: ''
    })
    const viewData = (data) => {
        setSelectedData(data);
        setSelected(data)
    };
    const Data =
        lectures?.map((data) => {
            return {
                ...data,
                id: data?.id,
                title: data?.title,
                date: (<Text>{dayjs(data?.date).format('D , MMM ,YYYY')}</Text>),

                edit: (
                    <IconButton
                        variant='outline'
                        border='0'
                        color='gray.600'
                        aria-label='Call Sage'
                        fontSize='20px'
                        icon={<RiFileEditLine />}
                        onClick={() => {
                            setAdd(false);
                            dispatch(openCreateLectureModel())
                        }}
                    />

                )
            };
        })
    const columns = [
        {
            Header: 'عنوان الدرس',
            accessor: 'title'
        },
        {
            Header: 'ميعاد الحصة',
            accessor: 'date'
        },


        {
            Header: 'تعديل',
            accessor: 'edit'
        }
    ];


    return (
        <CTable
            selectedData={viewData}
            Columns={columns}
            Data={Data}
            path={'openModel'}
            Actions
            Title={``}
            subTitle=''
            btnTitle=""
            placeHolder=""
            noSearchBar={true}
            noFilter={true}
            footerBtnTitle
            filterList
            addButton
            addButtonTitle=""
            setPage={setPage}
            setPerPage={setPerPage}
            currentpage={pageNumber}
            setPageNumber={setPageNumber}
            noSecondSearchBar={true}
            isLoading={isLoading}
            perPage={size}
            totalPage={Math.ceil(lectures?.length / 10)}

        />
    )
}

export default LecturesTable