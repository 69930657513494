import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../../components/dashboard/Layout";
import AddTeacherAssistant from "../../../containers/admin/teacherAssistant/models/add-teacher-assistant";
import SearchBar from "../../../containers/admin/teacherAssistant/searchbar";
import TeachersAssistantTable from "../../../containers/admin/teacherAssistant/table";
import { getAllTeacherAssistantsRequest } from "../../../modules/admin-modules/teachersAssistant/Actions";

const TeachersAssistant = () => {
  const dispatch = useDispatch();
  const model = useSelector((state) => state.model);
  const [isAdd, setAdd] = useState(false);
  const [selectedData, setSelectedData] = useState({});

  useEffect(() => {
    dispatch(getAllTeacherAssistantsRequest());
  }, []);

  return (
    <Layout>
      <SearchBar setAdd={setAdd} setSelectedData={setSelectedData} />
      <TeachersAssistantTable
        setAdd={setAdd}
        setSelectedData={setSelectedData}
      />
      <AddTeacherAssistant
        isAdd={isAdd}
        data={selectedData}
        setSelectedData={setSelectedData}
      />
    </Layout>
  );
};

export default TeachersAssistant;
