import { Box, FormLabel, HStack, Stack, Text, VStack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MainInput from "../../../../components/Form/input";

import { getPaymentSubjectRequest } from "../../../../modules/admin-modules/videos/Actions";

const StepOne = ({
  data,
  register,
  errors,
  isFormOne,
  watchTimer,
  setValue,
  watchType,
}) => {
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      width: "100%",
      background: "#f5f5f5",
      color: "#690000",
      borderRadius: "16px",
    }),
    option: (styles) => ({ ...styles, color: "#690000" }),
  };
  const videos = useSelector((state) => state.videos.videos);
  const paymentSubjects = useSelector((state) => state.videos.paymentSubjects);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getPaymentSubjectRequest());
  }, []);

  const paymentSubjectsOptions = paymentSubjects?.map((data) => {
    return { value: data.id, label: data.name };
  });

  const videosOptions = videos?.map((data) => {
    return { value: data.id, label: data.title };
  });
  return (
    <VStack
      dir="rtl"
      w="100%"
      flexWrap="wrap"
      justifyContent="space-between"
      spacing="50px"
      display={isFormOne ? "flex" : "none"}
    >
      <Box w="100%">
        <MainInput
          label=" عنوان الاختبار"
          defaultValues={data?.name}
          error={errors?.name?.message}
          placeholder=" ادخل عنوان الاختبار "
          register={register}
          name="name"
        />
      </Box>

      <HStack w="100%" alignItems="flex-start">
        <VStack w="50%" alignItems="flex-start">
          <FormLabel>إضافة مؤقت للاختبار : </FormLabel>
          <Stack
            direction="row"
            role="group"
            aria-labelledby="my-radio-group"
            spacing="20px"
          >
            <label>
              <input
                {...register("timer", { required: "هذه المعلومات مطلوبة" })}
                type="radio"
                value="0"
                defaultChecked={data?.timer == 0 ? true : false}
              />
              لا
            </label>
            <label>
              <input
                {...register("timer", { required: "هذه المعلومات مطلوبة" })}
                type="radio"
                value="1"
                defaultChecked={data?.timer == 1 ? true : false}
              />
              نعم
            </label>
            <Text color="red">{errors?.timer?.message}</Text>
          </Stack>
        </VStack>

        {watchTimer == 1 && (
          <HStack w="50%">
            <FormLabel w="25%">زمن مؤقت : </FormLabel>

            <MainInput
              label=""
              required={false}
              defaultValues={data.time}
              placeholder=" ادخل زمن الاختبار "
              register={register}
              name="time"
            />
          </HStack>
        )}
      </HStack>
      <HStack w="100%" alignItems="flex-start">
        <Box w="100%">
          <FormLabel> نشر نتيجة الاختبار : </FormLabel>

          <Stack
            direction="row"
            role="group"
            aria-labelledby="my-radio-group"
            spacing="20px"
          >
            <label>
              <input
                {...register("auto_publish_result", {
                  required: "هذه المعلومات مطلوبة",
                })}
                type="radio"
                value="1"
                defaultChecked={data?.auto_publish_result == 1 ? true : false}
              />
              نشر النتيجة
            </label>
            <label>
              <input
                {...register("auto_publish_result", {
                  required: "هذه المعلومات مطلوبة",
                })}
                type="radio"
                value="0"
                defaultChecked={data?.auto_publish_result == 0 ? true : false}
              />
              عدم نشر النتيجة
            </label>
            <Text color="red">{errors?.auto_publish_result?.message}</Text>
          </Stack>
        </Box>
        <Box w="100%">
          <FormLabel> اهمية الاختبار : </FormLabel>

          <Stack
            direction="row"
            role="group"
            aria-labelledby="my-radio-group"
            spacing="20px"
          >
            <label>
              <input
                {...register("book_mark", {
                  required: "هذه المعلومات مطلوبة",
                })}
                type="radio"
                value="0"
                defaultChecked={data?.book_mark == 0 ? true : false}
              />
              اختبار عادي
            </label>
            <label>
              <input
                {...register("book_mark", {
                  required: "هذه المعلومات مطلوبة",
                })}
                type="radio"
                value="1"
                defaultChecked={data?.book_mark == 1 ? true : false}
              />
              اختبار به اسئلة مهمة
            </label>
            <Text color="red">{errors?.book_mark?.message}</Text>
          </Stack>
        </Box>
      </HStack>

      <Box w="100%">
        <FormLabel> درجة صعوبة الامتحان : </FormLabel>

        <Stack
          direction="row"
          role="group"
          aria-labelledby="my-radio-group"
          spacing="20px"
        >
          <label>
            <input
              {...register("difficulty")}
              type="radio"
              value="0"
              defaultChecked={data?.difficulty == 0 ? true : false}
            />
            all
          </label>
          <label>
            <input
              {...register("difficulty")}
              type="radio"
              value="1"
              defaultChecked={data?.difficulty == 1 ? true : false}
            />
            notes
          </label>
          <label>
            <input
              {...register("difficulty")}
              type="radio"
              value="2"
              defaultChecked={data?.difficulty == 2 ? true : false}
            />
            Studing and remebering
          </label>
          <label>
            <input
              {...register("difficulty")}
              type="radio"
              value="3"
              defaultChecked={data?.difficulty == 3 ? true : false}
            />
            Hard and challenging
          </label>
          <label>
            <input
              {...register("difficulty")}
              type="radio"
              value="4"
              defaultChecked={data?.difficulty == 4 ? true : false}
            />
            Previous exams
          </label>
          <label>
            <input
              {...register("difficulty")}
              type="radio"
              value="5"
              defaultChecked={data?.difficulty == 5 ? true : false}
            />
            Revision
          </label>
        </Stack>
      </Box>
    </VStack>
  );
};

export default StepOne;
