import Types from "./Types";

const INITIAL_STATE = {
  sections: [],
  section: [],
  isLoading: false,
  count: "",
};

export default function sections(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_SECTIONS_SUCCESS: {
      return {
        ...state,
        sections: payload.data,
        count: payload.data.length,
      };
    }
    case Types.GET_SECTION_SUCCESS: {
      return {
        ...state,
        section: payload.data,
        count: payload.data.length,
      };
    }
    case Types.SECTIONS_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }

    default: {
      return state;
    }
  }
}
