export default {


    GET_TOP_PERFORMANCE_PER_GROUP_REQUEST: 'GET_TOP_PERFORMANCE_PER_GROUP_REQUEST',
    GET_TOP_PERFORMANCE_PER_GROUP_SUCCESS: 'GET_TOP_PERFORMANCE_PER_GROUP_SUCCESS',
    GET_TOP_PERFORMANCE_PER_GROUP_FAIL: 'GET_TOP_PERFORMANCE_PER_GROUP_FAIL',

    GET_TOP_PERFORMANCE_ALL_GROUPS_REQUEST: 'GET_TOP_PERFORMANCE_ALL_GROUPS_REQUEST',
    GET_TOP_PERFORMANCE_ALL_GROUPS_SUCCESS: 'GET_TOP_PERFORMANCE_ALL_GROUPS_SUCCESS',
    GET_TOP_PERFORMANCE_ALL_GROUPS_FAIL: 'GET_TOP_PERFORMANCE_ALL_GROUPS_FAIL',

    GET_ANSWERS_ANALYTICS_REQUEST: 'GET_ANSWERS_ANALYTICS_REQUEST',
    GET_ANSWERS_ANALYTICS_SUCCESS: 'GET_ANSWERS_ANALYTICS_SUCCESS',
    GET_ANSWERS_ANALYTICS_FAIL: 'GET_ANSWERS_ANALYTICS_FAIL',

    //loading
    PERFORMANCE_LOADING: 'PERFORMANCE_LOADING'
};
