import axiosInstance from "../../../helpers/api";

export const getAllGrades = (body) => {
    return axiosInstance.get('/admin/grades', body);
};



export const editGrades = (formData) => {

    return axiosInstance.put(`/admin/grades/${formData.id}`, formData.formData);
};


export const createGrades = (formData) => {
    return axiosInstance.post('/admin/grades', formData);
};

export const deleteGrade = (id) => {
    return axiosInstance.delete(`/admin/grades/${id}`);
};
