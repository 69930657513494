import axiosInstance from "../../../helpers/api";

export const getAllQuestionsBank = (id) => {
    return axiosInstance.get(`/admin/bank_questions?chapter_id=${id}`);
};

export const editQuestionBank = (formData) => {
    return axiosInstance.post(`/admin/bank_questions/${formData.id}`, formData.formData);
};


export const createQuestionBank = (formData) => {
    return axiosInstance.post('/admin/bank_questions', formData);
};

export const deleteQuestionBank = (id) => {
    return axiosInstance.delete(`/admin/bank_questions/${id}`);
};

export const bookmarkQuestionBank = (id) => {
    return axiosInstance.get(`/admin/bank_questions/book_mark/${id}`);
};
