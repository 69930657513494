import axiosInstance from "../../../helpers/api";

export const getAllLectures = (body) => {
    return axiosInstance.get('/admin/lectures', body);
};

export const editLectures = (formData) => {
    return axiosInstance.post(`/admin/lectures/${formData.id}`, formData.formData);
};


export const createLectures = (formData) => {
    return axiosInstance.post('/admin/lectures', formData);
};

export const deleteLecture = (id) => {
    return axiosInstance.delete(`/admin/lectures/${id}`);
};
