import Types from "./Types";

export const getMissedQuestionsRequest = (body) => ({
  type: Types.GET_MISSED_QUESTIONS_REQUEST,
  payload: body,
});

export const getMissedQuestionsSuccess = (data) => ({
  type: Types.GET_MISSED_QUESTIONS_SUCCESS,
  payload: data,
});

export const getMissedQuestionsFail = () => ({
  type: Types.GET_MISSED_QUESTIONS_FAIL,
});

export const getBookmarkedQuestionsRequest = (id) => ({
  type: Types.GET_BOOKMARKED_QUESTIONS_REQUEST,
  payload: id,
});

export const getBookmarkedQuestionsSuccess = (data) => ({
  type: Types.GET_BOOKMARKED_QUESTIONS_SUCCESS,
  payload: data,
});

export const getBookmarkedQuestionsFail = () => ({
  type: Types.GET_BOOKMARKED_QUESTIONS_FAIL,
});

//LOADING
export const addQuestionsLoading = (isLoading) => ({
  type: Types.ADD_QUESTIONS_LOADING,
  payload: isLoading,
});
