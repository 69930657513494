import Types from './Types';

const INITIAL_STATE = {
    chapterLessons: [],
    isLoading: false,
    count: ''
};

export default function chapterlessons(state = INITIAL_STATE, action) {
    const { type, payload } = action;
    switch (type) {
        case Types.GET_LESSONS_BY_CHAPTER_ID_SUCCESS: {
            return {
                ...state,
                chapterLessons: payload.data,
                count: payload.data.length
            };
        }






        case Types.ADD_LESSONS_LOADING: {
            return {
                ...state,
                isLoading: payload
            };
        }




        default: {
            return state;
        }
    }
}
