export default {
  //get all PAYMENTs
  GET_PAYMENTS_REQUEST: "GET_PAYMENTS_REQUEST",
  GET_PAYMENTS_SUCCESS: "GET_PAYMENTS_SUCCESS",
  GET_PAYMENTS_FAIL: "GET_PAYMENTS_FAIL",

  //edit PAYMENTs
  EDIT_PAYMENT_REQUEST: "EDIT_PAYMENT_REQUEST",
  EDIT_PAYMENT_SUCCESS: "EDIT_PAYMENT_SUCCESS",
  EDIT_PAYMENT_FAIL: "EDIT_PAYMENT_FAIL",

  //create PAYMENT
  CREATE_PAYMENT_REQUEST: "CREATE_PAYMENT_REQUEST",
  CREATE_PAYMENT_SUCCESS: "CREATE_PAYMENT_SUCCESS",
  CREATE_PAYMENT_FAIL: "CREATE_PAYMENT_FAIL",

  CREATE_VOUCHER_REQUEST: "CREATE_VOUCHER_REQUEST",
  CREATE_VOUCHER_SUCCESS: "CREATE_VOUCHER_SUCCESS",
  CREATE_VOUCHER_FAIL: "CREATE_VOUCHER_FAIL",

  DELETE_PAYMENT_REQUEST: "DELETE_PAYMENT_REQUEST",
  DELETE_PAYMENT_SUCCESS: "DELETE_PAYMENT_SUCCESS",
  DELETE_PAYMENT_FAIL: "DELETE_PAYMENT_FAIL",

  GET_PAYMENT_REQUEST: "GET_PAYMENT_REQUEST",
  GET_PAYMENT_SUCCESS: "GET_PAYMENT_SUCCESS",
  GET_PAYMENT_FAIL: "GET_PAYMENT_FAIL",

  GET_PAYMENT_EXCEPTION_REQUEST: "GET_PAYMENT_EXCEPTION_REQUEST",
  GET_PAYMENT_EXCEPTION_SUCCESS: "GET_PAYMENT_EXCEPTION_SUCCESS",
  GET_PAYMENT_EXCEPTION_FAIL: "GET_PAYMENT_EXCEPTION_FAIL",

  ADD_PAYMENTS_LOADING: "ADD_PAYMENTS_LOADING",
};
