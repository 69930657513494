import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../../components/dashboard/Layout";
import { getAllPaymentsRequest } from "../../../modules/admin-modules/payment/Actions";
import AddTag from "../../../containers/admin/tags/models/add-tag";
import TagsTable from "../../../containers/admin/tags/table";
import SearchBar from "../../../containers/admin/tags/searchbar";
import { getAllTagsRequest } from "../../../modules/admin-modules/tags/Actions";

const Tags = () => {
  const dispatch = useDispatch();
  const [isAdd, setAdd] = useState(false);
  const [selectedData, setSelectedData] = useState({});

  useEffect(() => {
    dispatch(getAllTagsRequest());
  }, []);

  return (
    <Layout>
      {/* <div style={{ padding: "80% 0 0 0", position: "relative" }}>
        <iframe
          src="https://player.vimeo.com/video/855784290?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          style={{
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
          }}
          title="01"
        ></iframe>
      </div> */}
      <SearchBar setAdd={setAdd} setSelectedData={setSelectedData} />
      <TagsTable setAdd={setAdd} setSelectedData={setSelectedData} />
      <AddTag
        isAdd={isAdd}
        data={selectedData}
        setSelectedData={setSelectedData}
      />
    </Layout>
  );
};

export default Tags;
