import { toast } from "react-toastify";
import { call, fork, put, takeLatest } from "redux-saga/effects";
import * as actions from "./Actions";
import * as api from "./Api";
import Types from "./Types";

function* getAllPayments({ payload }) {
  try {
    yield put(actions.addPaymentLoading(true));
    const result = yield call(api.getAllPayments);
    yield put(actions.getAllPaymentsSuccess(result.data));
  } catch (error) {
    const { response, message } = error;
    toast.error(error);
    yield put(actions.getAllPaymentsFail());
  } finally {
    yield put(actions.addPaymentLoading(false));
  }
}

function* getPaymentException({ payload }) {
  try {
    yield put(actions.addPaymentLoading(true));
    const result = yield call(api.getPaymentException);
    yield put(actions.getPaymentExceptionSuccess(result.data));
  } catch (error) {
    const { response, message } = error;
    toast.error(error);
    yield put(actions.getPaymentExceptionFail());
  } finally {
    yield put(actions.addPaymentLoading(false));
  }
}

//Edit Tables
function* editPayment(data) {
  try {
    yield put(actions.addPaymentLoading(true));
    const result = yield call(api.editPayments, data.payload);
    yield put(actions.editPaymentSuccess(result.data));
    toast.success("Edit Successfully");
    data?.action && data?.action();
  } catch (error) {
    yield put(actions.editPaymentFail());
  } finally {
    yield put(actions.addPaymentLoading(false));
  }
}

// create
function* createPayment(formData) {
  try {
    yield put(actions.addPaymentLoading(true));
    const result = yield call(api.createPayments, formData.payload);
    yield put(
      actions.createPaymentSuccess({
        data: result.data,
      })
    );
    toast.success("Created Successfully");
    formData?.action && formData?.action();
  } catch (error) {
    yield put(actions.createPaymentFail());
    toast.success(error.data.message.toString());
  } finally {
    yield put(actions.addPaymentLoading(false));
  }
}

function* deletePayment({ payload }) {
  try {
    yield put(actions.addPaymentLoading(true));
    const result = yield call(api.deletePayment, payload);

    yield put(actions.deletePaymentSuccess(payload));

    toast.success("Deleted Successfully");
  } catch (error) {
    const { response, message } = error;
    toast.error(error.response);
    yield put(actions.deletePaymentFail());
  } finally {
    yield put(actions.addPaymentLoading(false));
  }
}

function* createVoucher({ payload }) {
  try {
    yield put(actions.addPaymentLoading(true));
    const result = yield call(api.createVoucher, payload);
    yield put(actions.createVoucherSuccess(payload));
    toast.success("Done Successfully");
  } catch (error) {
    const { response, message } = error;
    toast.error(error.response);
    yield put(actions.createVoucherFail());
  } finally {
    yield put(actions.addPaymentLoading(false));
  }
}
export default function* PaymentSaga() {
  yield takeLatest(Types.GET_PAYMENTS_REQUEST, getAllPayments);
  yield takeLatest(Types.GET_PAYMENT_EXCEPTION_REQUEST, getPaymentException);

  yield takeLatest(Types.EDIT_PAYMENT_REQUEST, editPayment);
  yield takeLatest(Types.CREATE_PAYMENT_REQUEST, createPayment);
  yield takeLatest(Types.DELETE_PAYMENT_REQUEST, deletePayment);
  yield takeLatest(Types.CREATE_VOUCHER_REQUEST, createVoucher);
}
