import Types from './Types';

const INITIAL_STATE = {
    grades: [],
    grade: {},
    isLoading: false,
    count: ''
};

export default function grades(state = INITIAL_STATE, action) {
    const { type, payload } = action;
    switch (type) {
        case Types.GET_GRADES_SUCCESS: {
            return {
                ...state,
                grades: payload.data,
                count: payload.data.length
            };
        }

        case Types.GET_GRADE_SUCCESS: {
            return {
                ...state,
                grade: payload
            };
        }

        case Types.EDIT_GRADE_SUCCESS: {
            let data = payload.data;
            const grades = state.grades;
            let idx = 0;
            grades.forEach((table, index) => {
                if (table.id === data.id) {
                    idx = index;
                    return;
                }
            });

            grades[idx] = data;
            return {
                ...state,
                grades: grades
            };
        }

        // create
        case Types.CREATE_GRADE_SUCCESS: {
            const data = payload.data.data;
            let newArray = state.grades.slice();
            let index = state.grades.length + 1;
            newArray.splice(index, 0, data);
            return {
                ...state,
                grades: newArray
            };
        }
        case Types.ADD_GRADES_LOADING: {
            return {
                ...state,
                isLoading: payload
            };
        }

        case Types.DELETE_GRADE_SUCCESS: {
            const newgrades = state.grades.filter((item) => item.id !== payload);
            return {
                ...state,
                grades: newgrades
            };
        }
        default: {
            return state;
        }
    }
}
