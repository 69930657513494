import React, { useEffect, useState } from "react";
import Layout from "../../../components/dashboard/Layout";
import { useDispatch } from "react-redux";
import SearchBar from "../../../containers/admin/lectures/searchbar";
import LecturesTable from "../../../containers/admin/lectures/table";
import { getAllLecturesRequest } from "../../../modules/admin-modules/lectures/Actions";
import AddLecture from "../../../containers/admin/lectures/models/add-lecture";
const Lectures = () => {
  const [isAdd, setAdd] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllLecturesRequest());
  }, []);

  return (
    <Layout>
      <SearchBar setAdd={setAdd} setSelectedData={setSelectedData} />
      <LecturesTable setAdd={setAdd} setSelectedData={setSelectedData} />
      <AddLecture
        isAdd={isAdd}
        data={selectedData}
        setSelectedData={setSelectedData}
      />
    </Layout>
  );
};

export default Lectures;
