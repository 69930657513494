import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../../components/dashboard/Layout";
import SearchBar from "../../../containers/admin/exams/searchbar";
import ExamsTable from "../../../containers/admin/exams/table";
import AddExamData from "../../../containers/admin/exams/models/add-exam-data";
import {
  getAllExamsRequest,
  getAllExamsSuperAdminRequest,
} from "../../../modules/admin-modules/exams/Actions";
import Questions from "../../../containers/admin/exams/models/questions";

const Exams = () => {
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.user.userData);

  const [isAdd, setAdd] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [examData, setExamData] = useState({});

  useEffect(() => {
    if (userData?.role?.length > 0) {
      if (userData?.role?.[0]?.name == "Teacher") {
        dispatch(getAllExamsRequest());
      } else {
        dispatch(getAllExamsSuperAdminRequest());
      }
    }
  }, [userData]);
  return (
    <Layout>
      <SearchBar setAdd={setAdd} setSelectedData={setSelectedData} />
      <AddExamData
        isAdd={isAdd}
        data={selectedData}
        setSelectedData={setSelectedData}
        setExamData={setExamData}
      />
      <ExamsTable setAdd={setAdd} setSelectedData={setSelectedData} />
      <Questions
        examData={examData}
        selectedData={selectedData}
        isAdd={isAdd}
      />
    </Layout>
  );
};

export default Exams;
