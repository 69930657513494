import Types from './Types';

const INITIAL_STATE = {
    questionsBank: [],
    questionBank: {},
    isLoading: false,
    suspendLoading: false,
    bookmarkLoading: false,
    count: ''
};

export default function questionsBank(state = INITIAL_STATE, action) {
    const { type, payload } = action;
    switch (type) {
        case Types.GET_QUESTIONS_BANK_SUCCESS: {
            return {
                ...state,
                questionsBank: payload.data,
                count: payload.data.length
            };
        }

        case Types.GET_QUESTION_BANK_SUCCESS: {
            return {
                ...state,
                questionBank: payload
            };
        }

        case Types.EDIT_QUESTION_BANK_SUCCESS: {
            let data = payload.data;
            const questionsBank = state.questionsBank;
            let idx = 0;
            questionsBank.forEach((table, index) => {
                if (table.id === data.id) {
                    idx = index;
                    return;
                }
            });

            questionsBank[idx] = data;
            return {
                ...state,
                questionsBank: questionsBank
            };
        }

        // create
        case Types.CREATE_QUESTION_BANK_SUCCESS: {
            const data = payload.data.data;
            let newArray = state.questionsBank.slice();
            let index = state.questionsBank.length + 1;
            newArray.splice(index, 0, data);
            return {
                ...state,
                questionsBank: newArray
            };
        }
        case Types.ADD_QUESTION_BANK_LOADING: {
            return {
                ...state,
                isLoading: payload
            };
        }

        case Types.DELETE_QUESTION_BANK_SUCCESS: {
            let id = payload.payload;
            let data = payload.result.data.data

            const questionsBank = state.questionsBank;
            let idx = 0;
            questionsBank.forEach((table, index) => {
                if (table.id === id) {
                    idx = index;
                    return;
                }
            });

            questionsBank[idx].deleted_at = data?.deleted_at;
            return {
                ...state,
                questionsBank: questionsBank
            };
        }
        case Types.BOOKMARK_QUESTION_BANK_SUCCESS: {
            let id = payload.payload;
            let data = payload.result.data.data
            const questionsBank = state.questionsBank;
            let idx = 0;
            questionsBank.forEach((table, index) => {
                if (table.id === id) {
                    idx = index;
                    return;
                }
            });

            questionsBank[idx].book_marked = data?.book_marked;
            return {
                ...state,
                questionsBank: questionsBank
            };
        }




        case Types.BOOKMARK_QUESTION_BANK_LOADING: {
            return {
                ...state,
                bookmarkLoading: payload
            };
        }

        case Types.SUSPEND_QUESTION_BANK_LOADING: {
            return {
                ...state,
                suspendLoading: payload
            };
        }
        default: {
            return state;
        }
    }
}
