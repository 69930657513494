import { Button, Flex, Heading, Input, Text, Box } from "@chakra-ui/react";
import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import AddVoucherSuccess from "../../containers/student/models/add-voucher-success";
import { openVoucherModel } from "../../modules/admin-modules/models/Actions";
import { userVoucherAction } from "../../modules/auth/Actions";

const VousherLogin = () => {
  const [voucherData, setVoucherData] = useState();
  const dispatch = useDispatch();
  const handelSendVoucher = () => {
    let formData = new FormData();
    formData.append("voucher", voucherData);

    dispatch(
      userVoucherAction({
        formData: formData,
        action: () => {
          dispatch(openVoucherModel());
        },
      })
    );
  };
  return (
    <Flex
      bg="main_2"
      flexDirection={"column"}
      alignItems="center"
      pt="10px"
      borderRadius={"7px"}
      mt={{ base: "0", md: "30px" }}
      mb="-50px"
    >
      <Heading as={"h6"} pt="15px" textTransform={"uppercase"}>
        voucher
      </Heading>

      <Flex
        alignItems="center"
        pb="60px"
        pt="20px"
        px="30px"
        flexDirection={{ base: "column", md: "row" }}
      >
        <Flex
          flexDirection={"column"}
          justifyContent={"space-between"}
          w={{ base: "100%", md: "600px" }}
        >
          <Text fontSize={"26px"} color="main_1">
            voucher code
          </Text>
          <Box w="100%">
            <Input
              style={{
                width: "100%",
                padding: "20px",
                objectFit: "contain",
                borderRadius: "10px",
                boxShadow: "inset 0 0 16px 0 rgba(0, 0, 0, 0.16)",
                border: "solid 1px #010038",
                backgroundColor: "#fff",
              }}
              onChange={(e) => setVoucherData(e?.target?.value)}
            />
          </Box>
        </Flex>
        <Button
          onClick={handelSendVoucher}
          mt="40px"
          type="submit"
          w="30%"
          p="22px"
          alignSelf={"center"}
          mx="10px"
          bg="main_1"
          color="white"
        >
          Login
        </Button>
      </Flex>
      <AddVoucherSuccess />
    </Flex>
  );
};

export default VousherLogin;
