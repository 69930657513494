import { Button, IconButton, Switch } from "@chakra-ui/react";
import React, { useState } from "react";
import { RiDeleteBinLine, RiFileEditLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CTable from "../../../components/Table/table";
import { openTagModel } from "../../../modules/admin-modules/models/Actions";
import {
  deleteTagRequest,
  enableTagRequest,
} from "../../../modules/admin-modules/tags/Actions";

const TagsTable = ({ setAdd, setSelectedData }) => {
  const tags = useSelector((state) => state.tags.tags);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.tags.isLoading);
  const [offset, setPage] = useState(0);
  const [size, setPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [selected, setSelected] = useState({
    id: "",
  });
  const viewData = (data) => {
    setSelectedData(data);
    setSelected(data);
  };
  const Data = tags?.map((data) => {
    return {
      ...data,
      id: data?.id,
      title: data?.name,

      edit: (
        <IconButton
          variant="outline"
          border="0"
          color="gray.600"
          aria-label="Call Sage"
          fontSize="20px"
          icon={<RiFileEditLine />}
          onClick={() => {
            setAdd(false);
            setSelectedData(data);
            dispatch(openTagModel());
          }}
        />
      ),

      delete: (
        <IconButton
          variant="outline"
          border="0"
          color="gray.600"
          aria-label="Call Sage"
          fontSize="20px"
          icon={<RiDeleteBinLine color="red" />}
          onClick={() => dispatch(deleteTagRequest(data?.id))}
        />
      ),
      enable: (
        <Switch
          isChecked={data?.enable == 1 ? true : false}
          onChange={() => {
            dispatch(
              enableTagRequest({
                formData: { enable: data?.enable == 1 ? 0 : 1 },
                id: data?.id,
              })
            );
          }}
        />
      ),
    };
  });
  const columns = [
    {
      Header: " العلامة",
      accessor: "title",
    },
    {
      Header: "تعديل",
      accessor: "edit",
    },
    {
      Header: "enable",
      accessor: "enable",
    },

    {
      Header: "حذف",
      accessor: "delete",
    },
  ];

  return (
    <CTable
      selectedData={viewData}
      Columns={columns}
      Data={Data}
      path={"openModel"}
      Actions
      Title={``}
      subTitle=""
      btnTitle=""
      placeHolder=""
      noSearchBar={true}
      noFilter={true}
      footerBtnTitle
      filterList
      addButton
      addButtonTitle=""
      setPage={setPage}
      setPerPage={setPerPage}
      currentpage={pageNumber}
      setPageNumber={setPageNumber}
      noSecondSearchBar={true}
      isLoading={isLoading}
      perPage={size}
      totalPage={Math.ceil(tags?.length / 10)}
    />
  );
};

export default TagsTable;
