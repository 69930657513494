import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { RiFileEditLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Layout from "../../../components/dashboard/Layout";
import CTable from "../../../components/Table/table";
import { openCreateStudent } from "../../../modules/admin-modules/models/Actions";
import { createVoucherRequest } from "../../../modules/admin-modules/payment/Actions";
import { getAllStudentsRequest } from "../../../modules/admin-modules/students/Actions";

const StudentsPaymentList = ({ setAdd, setSelectedData, searchTerm }) => {
  const { payment_id } = useParams();
  const students = useSelector((state) => state.students.students);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.students.isLoading);
  const count = useSelector((state) => state.students.count);
  const [offset, setPage] = useState(0);
  const [size, setPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [selected, setSelected] = useState({
    id: "",
  });

  useEffect(() => {
    const timeId = setTimeout(() => {
      dispatch(getAllStudentsRequest(pageNumber + 1, ""));
    }, 200);

    return () => {
      clearTimeout(timeId);
    };
  }, [pageNumber]);

  const viewData = (data) => {
    setSelectedData(data);
    setSelected(data);
  };
  const Data = students?.map((data, index) => {
    return {
      ...data,
      index: `#${index + 1}`,
      id: data?.id,
      student_name: <Text>{data?.name}</Text>,
      pay: (
        <Button
          onClick={() => {
            let formData = new FormData();
            formData.append("user_id", data?.id);
            formData.append("payment_id", payment_id);
            dispatch(createVoucherRequest(formData));
          }}
        >
          دفع
        </Button>
      ),
    };
  });
  const columns = [
    {
      Header: "رقم الطالب",
      accessor: "index",
    },
    {
      Header: "اسم الطالب",
      accessor: "student_name",
    },
    {
      Header: " ",
      accessor: "pay",
    },
  ];

  return (
    <Layout>
      <Box py="10px">
        <CTable
          selectedData={viewData}
          Columns={columns}
          Data={Data}
          path={"openModel"}
          Actions
          Title={``}
          subTitle=""
          btnTitle=""
          placeHolder=""
          noSearchBar={true}
          noFilter={true}
          footerBtnTitle
          filterList
          addButton
          addButtonTitle=""
          setPage={setPage}
          setPerPage={setPerPage}
          currentpage={pageNumber}
          setPageNumber={setPageNumber}
          noSecondSearchBar={true}
          isLoading={isLoading}
          perPage={size}
          totalPage={Math.ceil(count / 50)}
        />
      </Box>
    </Layout>
  );
};

export default StudentsPaymentList;
