export default {
  //get all CHAPTERs
  STUDENTS_PER_GROUP_REQUEST: "STUDENTS_PER_GROUP_REQUEST",
  STUDENTS_PER_GROUP_SUCCESS: "STUDENTS_PER_GROUP_SUCCESS",
  STUDENTS_PER_GROUP_FAIL: "STUDENTS_PER_GROUP_FAIL",

  //edit CHAPTERs
  VIDEO_REPORT_REQUEST: "VIDEO_REPORT_REQUEST",
  VIDEO_REPORT_SUCCESS: "VIDEO_REPORT_SUCCESS",
  VIDEO_REPORT_FAIL: "VIDEO_REPORT_FAIL",

  //create CHAPTER
  ATTENDANCE_REPORT_REQUEST: "ATTENDANCE_REPORT_REQUEST",
  ATTENDANCE_REPORT_SUCCESS: "ATTENDANCE_REPORT_SUCCESS",
  ATTENDANCE_REPORT_FAIL: "ATTENDANCE_REPORT_FAIL",

  EXAM_REPORT_REQUEST: "EXAM_REPORT_REQUEST",
  EXAM_REPORT_SUCCESS: "EXAM_REPORT_SUCCESS",
  EXAM_REPORT_FAIL: "EXAM_REPORT_FAIL",

  UNPAID_REPORT_REQUEST: "UNPAID_REPORT_REQUEST",
  UNPAID_REPORT_SUCCESS: "UNPAID_REPORT_SUCCESS",
  UNPAID_REPORT_FAIL: "UNPAID_REPORT_FAIL",

  PAID_REPORT_REQUEST: "PAID_REPORT_REQUEST",
  PAID_REPORT_SUCCESS: "PAID_REPORT_SUCCESS",
  PAID_REPORT_FAIL: "PAID_REPORT_FAIL",

  EXAM_ATTENDANCE_REQUEST: "EXAM_ATTENDANCE_REQUEST",
  EXAM_ATTENDANCE_SUCCESS: "EXAM_ATTENDANCE_SUCCESS",
  EXAM_ATTENDANCE_FAIL: "EXAM_ATTENDANCE_FAIL",
  REPORT_LOADING: "REPORT_LOADING",

  HOMEWORK_ATTENDENCE_REPORT_REQUEST: "HOMEWORK_ATTENDENCE_REPORT_REQUEST",
  HOMEWORK_ATTENDENCE_REPORT_SUCCESS: "HOMEWORK_ATTENDENCE_REPORT_SUCCESS",
  HOMEWORK_ATTENDENCE_REPORT_FAIL: "HOMEWORK_ATTENDENCE_REPORT_FAIL",

  FILTER_HOMEWORK_ATTENDENCE_REPORT_REQUEST:
    "FILTER_HOMEWORK_ATTENDENCE_REPORT_REQUEST",
  FILTER_HOMEWORK_ATTENDENCE_REPORT_SUCCESS:
    "FILTER_HOMEWORK_ATTENDENCE_REPORT_SUCCESS",
  FILTER_HOMEWORK_ATTENDENCE_REPORT_FAIL:
    "FILTER_HOMEWORK_ATTENDENCE_REPORT_FAIL",

  HOMEWORK_ABSENT_REPORT_REQUEST: "HOMEWORK_ABSENT_REPORT_REQUEST",
  HOMEWORK_ABSENT_REPORT_SUCCESS: "HOMEWORK_ABSENT_REPORT_SUCCESS",
  HOMEWORK_ABSENT_REPORT_FAIL: "HOMEWORK_ABSENT_REPORT_FAIL",

  DELETE_STUDENT_HOMEWORK_REQUEST: "DELETE_STUDENT_HOMEWORK_REQUEST",
  DELETE_STUDENT_HOMEWORK_SUCCESS: "DELETE_STUDENT_HOMEWORK_SUCCESS",
  DELETE_STUDENT_HOMEWORK_FAIL: "DELETE_STUDENT_HOMEWORK_FAIL",

  RESTRICT_STUDENT_HOMEWORK_REQUEST: "RESTRICT_STUDENT_HOMEWORK_REQUEST",
  RESTRICT_STUDENT_HOMEWORK_SUCCESS: "RESTRICT_STUDENT_HOMEWORK_SUCCESS",
  RESTRICT_STUDENT_HOMEWORK_FAIL: "RESTRICT_STUDENT_HOMEWORK_FAIL",
  ACCEPT_STUDENT_HOMEWORK_REQUEST: "ACCEPT_STUDENT_HOMEWORK_REQUEST",
  ACCEPT_STUDENT_HOMEWORK_SUCCESS: "ACCEPT_STUDENT_HOMEWORK_SUCCESS",
  ACCEPT_STUDENT_HOMEWORK_FAIL: "ACCEPT_STUDENT_HOMEWORK_FAIL",

  COMMENT_STUDENT_HOMEWORK_REQUEST: "COMMENT_STUDENT_HOMEWORK_REQUEST",
  COMMENT_STUDENT_HOMEWORK_SUCCESS: "COMMENT_STUDENT_HOMEWORK_SUCCESS",
  COMMENT_STUDENT_HOMEWORK_FAIL: "COMMENT_STUDENT_HOMEWORK_FAIL", //loading
  HOMEWORK_UNCOMMENT_REPORT_REQUEST: "HOMEWORK_UNCOMMENT_REPORT_REQUEST",
  HOMEWORK_UNCOMMENT_REPORT_SUCCESS: "HOMEWORK_UNCOMMENT_REPORT_SUCCESS",
  HOMEWORK_UNCOMMENT_REPORT_FAIL: "HOMEWORK_UNCOMMENT_REPORT_FAIL",
  STUDENT_PERFORMANCE_REQUEST: "STUDENT_PERFORMANCE_REQUEST",
  STUDENT_PERFORMANCE_SUCCESS: "STUDENT_PERFORMANCE_SUCCESS",
  STUDENT_PERFORMANCE_FAIL: "STUDENT_PERFORMANCE_FAIL",

  VIDEO_FILTER_REPORT_REQUEST: "VIDEO_FILTER_REPORT_REQUEST",
  VIDEO_FILTER_REPORT_SUCCESS: "VIDEO_FILTER_REPORT_SUCCESS",
  VIDEO_FILTER_REPORT_FAIL: "VIDEO_FILTER_REPORT_FAIL",
};
