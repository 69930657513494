import Types from './Types';


export const getAllLecturesRequest = (body) => ({
    type: Types.GET_LECTURES_REQUEST,
    payload: body
});

export const getAllLecturesSuccess = (data) => ({
    type: Types.GET_LECTURES_SUCCESS,
    payload: data
});

export const getAllLecturesFail = () => ({
    type: Types.GET_LECTURES_FAIL
});


export const editLectureRequest = (formData) => ({
    type: Types.EDIT_LECTURE_REQUEST,
    payload: formData,
});

export const editLectureSuccess = (data) => ({
    type: Types.EDIT_LECTURE_SUCCESS,
    payload: data
});

export const editLectureFail = () => ({
    type: Types.EDIT_LECTURE_FAIL
});

//LOADING
export const addLectureLoading = (isLoading) => ({
    type: Types.ADD_LECTURES_LOADING,
    payload: isLoading
});

// Create Lecture
export const createLectureRequest = (formData, action) => ({
    type: Types.CREATE_LECTURE_REQUEST,
    payload: formData,
    action: action
});

export const createLectureSuccess = ({ data }) => ({
    type: Types.CREATE_LECTURE_SUCCESS,
    payload: {
        data
    }
});

export const createLectureFail = () => ({
    type: Types.CREATE_LECTURE_FAIL
});

export const deleteLectureRequest = (id) => ({
    type: Types.DELETE_LECTURE_REQUEST,
    payload: id
});

export const deleteLectureSuccess = (id) => ({
    type: Types.DELETE_LECTURE_SUCCESS,
    payload: id
});

export const deleteLectureFail = () => ({
    type: Types.DELETE_LECTURE_FAIL
});
