import { IconButton, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { RiDeleteBin6Line, RiDeleteBinLine, RiFileEditLine } from 'react-icons/ri'
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import CTable from '../../../components/Table/table'
import { openCreateStudent, openCreateTeacher } from '../../../modules/admin-modules/models/Actions';
import { deleteTeacherRequest } from '../../../modules/admin-modules/teachers/Actions';

const TeachersTable = ({ setAdd, setSelectedData }) => {
    const teachers = useSelector((state) => state.teachers.teachers)
    const dispatch = useDispatch()
    const isLoading = false
    const [offset, setPage] = useState(0);
    const [size, setPerPage] = useState(10);
    const [pageNumber, setPageNumber] = useState(0);
    const [selected, setSelected] = useState({
        id: ''
    })
    const viewData = (data) => {
        setSelectedData(data);
        setSelected(data)
    };
    const Data =
        teachers?.map((data) => {
            return {
                id: data?.id,
                title: data?.name,
                price: data?.email,
                edit: (
                    <IconButton
                        variant='outline'
                        border='0'
                        color='gray.600'
                        aria-label='Call Sage'
                        fontSize='20px'
                        icon={<RiFileEditLine />}
                        onClick={() => {

                            setAdd(false);
                            dispatch(openCreateTeacher())
                        }}
                    />

                ),
                delete: (
                    <IconButton
                        variant='outline'
                        border='0'
                        color='gray.600'
                        aria-label='Call Sage'
                        fontSize='20px'
                        icon={<RiDeleteBinLine />}
                        onClick={() => {

                            dispatch(deleteTeacherRequest(data?.id))
                        }}
                    />

                )
            };
        })
    const columns = [
        {
            Header: 'الاسم',
            accessor: 'title'
        },
        {
            Header: 'البريد الالكتروني',
            accessor: 'price'
        },
        // {
        //     Header: 'تعديل',
        //     accessor: 'edit'
        // },
        // {
        //     Header: 'حذف',
        //     accessor: 'delete'
        // },

    ];


    return (
        <CTable
            selectedData={viewData}
            Columns={columns}
            Data={Data}
            path={'openModel'}
            Actions
            Title={``}
            subTitle=''
            btnTitle=""
            placeHolder=""
            noSearchBar={true}
            noFilter={true}
            footerBtnTitle
            filterList
            addButton
            addButtonTitle=""
            setPage={setPage}
            setPerPage={setPerPage}
            currentpage={pageNumber}
            setPageNumber={setPageNumber}
            noSecondSearchBar={true}
            isLoading={isLoading}
            perPage={size}
            totalPage={Math.ceil(teachers?.length / 10)}

        />
    )
}

export default TeachersTable