import {
  Button,
  Flex,
  HStack,
  InputGroup,
  Input,
  InputLeftElement,
  Text,
  VStack,
  Box,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Select from "react-select";

import {
  RiAddFill,
  RiFileExcel2Line,
  RiSearch2Fill,
  RiSendPlaneLine,
} from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import LogoutMenu from "../../../components/dashboard/Layout/logout-menu";
import { openCreateStudent } from "../../../modules/admin-modules/models/Actions";
import {
  addBulkStudentRequest,
  studentsParentReportRequest,
} from "../../../modules/admin-modules/students/Actions";
import { getAllSystemGroupsRequest } from "../../../modules/admin-modules/groups/Actions";

const SearchBar = ({ setAdd, setSelectedData, setSearchTerm }) => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.user.userData);
  const groups = useSelector((state) => state.groups.allGroups);
  const [sendReport, setSendReport] = useState(false);
  const groupsOptions = groups?.map((data) => {
    return { value: data.id, label: data.name };
  });

  useEffect(() => {
    if (sendReport && groups?.length == 0)
      dispatch(getAllSystemGroupsRequest());
  }, [sendReport, groups]);

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      width: "100%",
      background: "#f5f5f5",
      color: "#690000",
      borderRadius: "16px",
    }),
    option: (styles) => ({ ...styles, color: "#690000" }),
  };
  return (
    <VStack w="100%" my="30px">
      <Flex alignItems="center" w="100%" justifyContent="space-between">
        <Text fontSize="24px" fontWeight="600" color="main_1">
          الطلاب
        </Text>

        <Flex alignItems="center">
          <Button
            borderRadius="16px"
            leftIcon={<RiAddFill />}
            color="white"
            bg="main_1"
            _focus={{ outline: "none" }}
            onClick={() => {
              setAdd(true);
              dispatch(openCreateStudent());
            }}
          >
            إضافة طالب
          </Button>
          <Button
            borderColor="main_1"
            border="1px solid"
            mx="10px"
            borderRadius="16px"
            leftIcon={<RiFileExcel2Line />}
            color="main_1"
            bg="white"
            _focus={{ outline: "none" }}
            position="relative"
          >
            <a href="https://api.enoughphysics.com/api/admin/students/export_bulk/1">
              تصدير الى Excel
            </a>
          </Button>
          {userData?.id === 11 && (
            <Button
              borderColor="main_1"
              border="1px solid"
              mx="10px"
              borderRadius="16px"
              leftIcon={<RiSendPlaneLine />}
              color="main_1"
              bg="white"
              _focus={{ outline: "none" }}
              position="relative"
              onClick={() => setSendReport(!sendReport)}
            >
              Students Report
            </Button>
          )}
          <Button
            borderColor="main_1"
            border="1px solid"
            mx="10px"
            borderRadius="16px"
            leftIcon={<RiFileExcel2Line />}
            color="main_1"
            bg="white"
            _focus={{ outline: "none" }}
            position="relative"
          >
            <Text>Add Students in Bulk</Text>
            <Input
              onChange={(e) => {
                let formData = new FormData();
                formData.append("student_bulk", e.target.files[0]);
                dispatch(addBulkStudentRequest(formData));
              }}
              type="file"
              placeholder="اختر ملف ليتم رفعه"
              opacity="0"
              position="absolute"
              w="100%"
              h="100%"
            />
          </Button>

          <LogoutMenu />
        </Flex>
      </Flex>

      {/* <Text w="100%" fontSize="20px" textAlign="right">
        عدد الطلاب :{count}{" "}
      </Text> */}

      <HStack w="100%" mt="40px !important" p="10px" mb="-30px !important">
        <InputGroup w="400px">
          <InputLeftElement
            pointerEvents="none"
            children={<RiSearch2Fill color="main_1" fontSize="20px" />}
          />
          <Input
            type="text"
            placeholder="ابحث باسم الطالب أو رقم الموبايل"
            borderRadius="16px"
            bg="#f5f5f5"
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </InputGroup>
        {sendReport && (
          <Box w="35%">
            <Select
              options={groupsOptions}
              isMulti={false}
              onChange={(data) => {
                dispatch(
                  studentsParentReportRequest({ student_group_id: data?.value })
                );
              }}
              styles={colourStyles}
              placeholder="المجموعات"
            />
          </Box>
        )}
      </HStack>
    </VStack>
  );
};

export default SearchBar;
