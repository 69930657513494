import Types from "./Types";

const INITIAL_STATE = {
  tags: [],
  tag: {},
  isLoading: false,
  count: "",
};

export default function tags(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_TAGS_SUCCESS: {
      return {
        ...state,
        tags: payload.data,
        count: payload.data.length,
      };
    }

    case Types.GET_TAG_SUCCESS: {
      return {
        ...state,
        tag: payload,
      };
    }

    case Types.EDIT_TAG_SUCCESS: {
      let data = payload.data;
      const tags = state.tags;
      let idx = 0;
      tags.forEach((table, index) => {
        if (table.id === data.id) {
          idx = index;
          return;
        }
      });

      tags[idx] = data;
      return {
        ...state,
        tags: tags,
      };
    }
    case Types.ENABLE_TAG_SUCCESS: {
      let data = payload.id;
      const tags = state.tags;
      let idx = 0;
      tags.forEach((table, index) => {
        if (table.id === data) {
          idx = index;
          return;
        }
      });

      tags[idx].enable = payload.formData?.enable == 1 ? true : false;
      return {
        ...state,
        tags: tags,
      };
    }
    // create
    case Types.CREATE_TAG_SUCCESS: {
      const data = payload.data.data;
      return {
        ...state,
        tags: [...state.tags, data],
      };
    }
    case Types.ADD_TAGS_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }

    case Types.DELETE_TAG_SUCCESS: {
      const newtags = state.tags.filter((item) => item.id !== payload);
      return {
        ...state,
        tags: newtags,
      };
    }
    default: {
      return state;
    }
  }
}
