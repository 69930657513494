import { toast } from "react-toastify";
import { call, fork, put, takeLatest } from "redux-saga/effects";
import * as actions from "./Actions";
import * as api from "./Api";
import Types from "./Types";

function* getAllPlaylists({ payload }) {
  try {
    yield put(actions.addPlaylistLoading(true));
    const result = yield call(api.getAllPlaylists);
    yield put(actions.getAllPlaylistsSuccess(result.data));
  } catch (error) {
    const { response, message } = error;
    toast.error(error);
    yield put(actions.getAllPlaylistsFail());
  } finally {
    yield put(actions.addPlaylistLoading(false));
  }
}

//Edit Tables
function* editPlaylist({ payload }) {
  try {
    yield put(actions.addPlaylistLoading(true));
    const result = yield call(api.editPlaylists, payload);
    yield put(actions.editPlaylistSuccess(result.data));
    toast.success("Edit Successfully");
    payload?.action && payload?.action();
  } catch (error) {
    yield put(actions.editPlaylistFail());
  } finally {
    yield put(actions.addPlaylistLoading(false));
  }
}

// create
function* createPlaylist(formData) {
  try {
    yield put(actions.addPlaylistLoading(true));
    const result = yield call(api.createPlaylists, formData.payload);
    yield put(
      actions.createPlaylistSuccess({
        data: result.data,
      })
    );
    toast.success("Created Successfully");
    formData?.action && formData?.action();
  } catch (error) {
    yield put(actions.createPlaylistFail());
  } finally {
    yield put(actions.addPlaylistLoading(false));
  }
}

function* deletePlaylist({ payload }) {
  try {
    yield put(actions.addPlaylistLoading(true));
    const result = yield call(api.deletePlaylist, payload);

    yield put(actions.deletePlaylistSuccess(payload));

    toast.success("Deleted Successfully");
  } catch (error) {
    const { response, message } = error;
    toast.error(error.response);
    yield put(actions.deletePlaylistFail());
  } finally {
    yield put(actions.addPlaylistLoading(false));
  }
}

export default function* PlaylistSaga() {
  yield takeLatest(Types.GET_PLAYLISTS_REQUEST, getAllPlaylists);
  yield takeLatest(Types.EDIT_PLAYLIST_REQUEST, editPlaylist);
  yield takeLatest(Types.CREATE_PLAYLIST_REQUEST, createPlaylist);
  yield takeLatest(Types.DELETE_PLAYLIST_REQUEST, deletePlaylist);
}
