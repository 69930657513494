import {
  Box,
  chakra,
  Flex,
  Image,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useState } from "react";
import "./style.css";
const ExamQuestions = ({ data, answers, setAnswers, selectedQuestionIdx }) => {
  const baseUrl = "https://enoughphysics.com/enoughapi";
  const [loading, setLoading] = useState(false);

  return (
    <VStack
      w="100%"
      spacing="20px"
      h="100%"
      onSelect={(e) => e.preventDefault()}
    >
      {/* question */}
      <Stack
        w="100%"
        flexDirection={{ base: "column", lg: "row" }}
        spacing={{ base: "5%", lg: "0" }}
        alignItems="flex-start"
        p={3}
        border="1px solid black"
        borderRadius="10px"
      >
        <VStack w={"100%"} alignItems={"center"} mr="20px">
          {data?.comprehension && (
            <Box textAlign="center" w="100%">
              <chakra.span
                fontSize="18px"
                fontWeight="600"
                textDecoration="underline"
              >
                Comprehension
              </chakra.span>{" "}
              <Text fontSize="20px">{data?.comprehension}</Text>
            </Box>
          )}
          <Box textAlign="center" w="100%">
            <chakra.span
              fontSize="18px"
              fontWeight="600"
              textDecoration="underline"
            >
              Question
            </chakra.span>{" "}
            <Text onMouseDown={(e) => e.preventDefault()} fontSize="20px">
              <div dangerouslySetInnerHTML={{ __html: data?.question }} />
            </Text>
          </Box>
        </VStack>

        {data?.image && (
          <Image
            src={`${baseUrl}/${data?.image}`}
            w={{ base: "100%", lg: "30%" }}
          />
        )}
      </Stack>

      {/* answers */}

      <Stack
        role="group"
        aria-labelledby="my-radio-group"
        spacing={0}
        alignItems="flex-start"
        direction={{ base: "column", lg: "row" }}
        flexWrap="wrap"
        w="100%"
      >
        {data?.answers?.map((val, index) => {
          return (
            <Flex
              w={{ base: "100%", lg: "45%" }}
              my="30px !important"
              alignItems="center"
              className="container"
            >
              {(!loading || loading) && (
                <label htmlFor={`answer-${index}`}>
                  <input
                    id={`answer-${index}`}
                    type="radio"
                    key={index}
                    value={val?.id}
                    name="answer"
                    onClick={(e) => {
                      setLoading(true);
                      const formData = {
                        question_id: data?.id,
                        answer_id: e?.target?.value,
                      };
                      let newAnswers = answers;
                      answers[selectedQuestionIdx] = formData;
                      setAnswers(() => newAnswers);
                      setTimeout(() => {
                        setLoading(false);
                      }, 300);
                    }}
                    checked={
                      answers[selectedQuestionIdx]?.answer_id == val?.id
                        ? true
                        : false
                    }
                  />
                  <Text mx="5px" fontSize="20px" mt="-5px">
                    {val?.answer}
                  </Text>
                </label>
              )}
            </Flex>
          );
        })}
      </Stack>
    </VStack>
  );
};

export default ExamQuestions;
