import Types from "./Types";

const INITIAL_STATE = {
  sections: [],
  section: {},
  isLoading: false,
  count: "",
};

export default function adminSections(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_ADMIN_SECTIONS_SUCCESS: {
      return {
        ...state,
        sections: payload.data,
        count: payload.data.length,
      };
    }

    case Types.GET_ADMIN_SECTION_SUCCESS: {
      return {
        ...state,
        section: payload,
      };
    }

    case Types.EDIT_ADMIN_SECTION_SUCCESS: {
      let data = payload.data;
      const sections = state.sections;
      let idx = 0;
      sections.forEach((table, index) => {
        if (table.id === data.id) {
          idx = index;
          return;
        }
      });

      sections[idx] = data;
      return {
        ...state,
        sections: sections,
      };
    }

    // create
    case Types.CREATE_ADMIN_SECTION_SUCCESS: {
      const data = payload.data.data;
      let newArray = [...state.sections, data];
      return {
        ...state,
        sections: newArray,
      };
    }
    case Types.ADD_ADMIN_SECTIONS_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }

    case Types.DELETE_ADMIN_SECTION_SUCCESS: {
      const newsections = state.sections.filter((item) => item.id !== payload);
      return {
        ...state,
        sections: newsections,
      };
    }
    default: {
      return state;
    }
  }
}
