import Types from './Types';


export const getAllGradesRequest = (body) => ({
    type: Types.GET_GRADES_REQUEST,
    payload: body
});

export const getAllGradesSuccess = (data) => ({
    type: Types.GET_GRADES_SUCCESS,
    payload: data
});

export const getAllGradesFail = () => ({
    type: Types.GET_GRADES_FAIL
});


export const editGradeRequest = (formData, action) => ({
    type: Types.EDIT_GRADE_REQUEST,
    payload: formData,
    action: action
});

export const editGradeSuccess = (data) => ({
    type: Types.EDIT_GRADE_SUCCESS,
    payload: data
});

export const editGradeFail = () => ({
    type: Types.EDIT_GRADE_FAIL
});

//LOADING
export const addGradeLoading = (isLoading) => ({
    type: Types.ADD_GRADES_LOADING,
    payload: isLoading
});

// Create Grade
export const createGradeRequest = (formData, action) => ({
    type: Types.CREATE_GRADE_REQUEST,
    payload: { ...formData },
    action: action
});

export const createGradeSuccess = ({ data }) => ({
    type: Types.CREATE_GRADE_SUCCESS,
    payload: {
        data
    }
});

export const createGradeFail = () => ({
    type: Types.CREATE_GRADE_FAIL
});

export const deleteGradeRequest = (id) => ({
    type: Types.DELETE_GRADE_REQUEST,
    payload: id
});

export const deleteGradeSuccess = (id) => ({
    type: Types.DELETE_GRADE_SUCCESS,
    payload: id
});

export const deleteGradeFail = () => ({
    type: Types.DELETE_GRADE_FAIL
});
