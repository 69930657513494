export default {
    //get all VIDEOs
    GET_LESSONS_BY_CHAPTER_ID_REQUEST: 'GET_LESSONS_BY_CHAPTER_ID_REQUEST',
    GET_LESSONS_BY_CHAPTER_ID_SUCCESS: 'GET_LESSONS_BY_CHAPTER_ID_SUCCESS',
    GET_LESSONS_BY_CHAPTER_ID_FAIL: 'GET_LESSONS_BY_CHAPTER_ID_FAIL',



    //loading
    ADD_LESSONS_LOADING: 'ADD_LESSONS_LOADING'
};
