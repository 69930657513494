export default {
    //get all LECTUREs
    GET_LECTURES_REQUEST: 'GET_LECTURES_REQUEST',
    GET_LECTURES_SUCCESS: 'GET_LECTURES_SUCCESS',
    GET_LECTURES_FAIL: 'GET_LECTURES_FAIL',

    //edit LECTUREs
    EDIT_LECTURE_REQUEST: 'EDIT_LECTURE_REQUEST',
    EDIT_LECTURE_SUCCESS: 'EDIT_LECTURE_SUCCESS',
    EDIT_LECTURE_FAIL: 'EDIT_LECTURE_FAIL',

    //create LECTURE
    CREATE_LECTURE_REQUEST: 'CREATE_LECTURE_REQUEST',
    CREATE_LECTURE_SUCCESS: 'CREATE_LECTURE_SUCCESS',
    CREATE_LECTURE_FAIL: 'CREATE_LECTURE_FAIL',

    DELETE_LECTURE_REQUEST: 'DELETE_LECTURE_REQUEST',
    DELETE_LECTURE_SUCCESS: 'DELETE_LECTURE_SUCCESS',
    DELETE_LECTURE_FAIL: 'DELETE_LECTURE_FAIL',

    GET_LECTURE_REQUEST: 'GET_LECTURE_REQUEST',
    GET_LECTURE_SUCCESS: 'GET_LECTURE_SUCCESS',
    GET_LECTURE_FAIL: 'GET_LECTURE_FAIL',

    //loading
    ADD_LECTURES_LOADING: 'ADD_LECTURES_LOADING'
};
