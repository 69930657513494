import Types from './Types';


export const getAllStreamingsRequest = (body) => ({
    type: Types.GET_STREAMINGS_REQUEST,
    payload: body
});

export const getAllStreamingsSuccess = (data) => ({
    type: Types.GET_STREAMINGS_SUCCESS,
    payload: data
});

export const getAllStreamingsFail = () => ({
    type: Types.GET_STREAMINGS_FAIL
});


export const editStreamingRequest = (formData, action) => ({
    type: Types.EDIT_STREAMING_REQUEST,
    payload: formData,
    action: action
});

export const editStreamingSuccess = (data) => ({
    type: Types.EDIT_STREAMING_SUCCESS,
    payload: data
});

export const editStreamingFail = () => ({
    type: Types.EDIT_STREAMING_FAIL
});

//LOADING
export const addStreamingLoading = (isLoading) => ({
    type: Types.ADD_STREAMINGS_LOADING,
    payload: isLoading
});

// Create Streaming
export const createStreamingRequest = (formData, action) => ({
    type: Types.CREATE_STREAMING_REQUEST,
    payload: formData,
    action: action
});

export const createStreamingSuccess = ({ data }) => ({
    type: Types.CREATE_STREAMING_SUCCESS,
    payload: {
        data
    }
});

export const createStreamingFail = () => ({
    type: Types.CREATE_STREAMING_FAIL
});

export const deleteStreamingRequest = (id) => ({
    type: Types.DELETE_STREAMING_REQUEST,
    payload: id
});

export const deleteStreamingSuccess = (id) => ({
    type: Types.DELETE_STREAMING_SUCCESS,
    payload: id
});

export const deleteStreamingFail = () => ({
    type: Types.DELETE_STREAMING_FAIL
});
