import React, { useEffect } from "react";
import { Box, Flex, FormLabel, Heading } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { closeCreateLectureModel } from "../../../../modules/admin-modules/models/Actions";
import MainModel from "../../../../components/Models/main-model";
import { useForm } from "react-hook-form";
import MainInput from "../../../../components/Form/input";
import Select from "react-select";
import { getAllChaptersRequest } from "../../../../modules/admin-modules/chapters/Actions";
import { getAllGroupsRequest } from "../../../../modules/admin-modules/groups/Actions";
import {
  createLectureRequest,
  editLectureRequest,
} from "../../../../modules/admin-modules/lectures/Actions";
const AddLecture = ({ isAdd, data, setSelectedData }) => {
  const openLecture = useSelector((state) => state.model.isOpen12);
  const chapters = useSelector((state) => state.chapters.chapters);
  const groups = useSelector((state) => state.groups.groups);
  const isLoading = useSelector((state) => state.lectures.isLoading);

  const groupsOptions = groups?.map((data) => {
    return { value: data.id, label: data.name };
  });

  const chaptersOptions = chapters?.map((data) => {
    return { value: data.id, label: data.name };
  });
  const dispatch = useDispatch();
  useEffect(() => {
    if (openLecture) {
      dispatch(getAllChaptersRequest());
      dispatch(getAllGroupsRequest());
    }
  }, [openLecture]);

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      width: "100%",
      background: "#f5f5f5",
      color: "#690000",
      borderRadius: "16px",
    }),
    option: (styles) => ({ ...styles, color: "#690000" }),
  };

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
    getValues,
  } = useForm({
    defaultValues: data ? data : {},
    shouldUnregister: true,
  });

  const header = (
    <Box>
      <Heading textAlign="center" color="main_1" fontSize="24px">
        {isAdd ? " إضافة المحتوي" : "تعديل  المحتوي"}
      </Heading>
    </Box>
  );
  const onSubmit = async (values) => {
    let formData = await new FormData();
    for (let key in values) {
      if (key == "groups") {
        values[key].map((val, idx) => formData.append(`groups[${idx}]`, val));
      } else {
        formData.append(key, values[key]);
      }
    }
    const action = () => {
      dispatch(closeCreateLectureModel());
      setSelectedData({});
      reset();
    };
    if (isAdd) {
      await dispatch(createLectureRequest(formData, action));
    } else {
      const totel = { ...data, ...values };
      for (let key in totel) {
        if (key == "groups") {
          totel[key].map((val, idx) => formData.append(`groups[${idx}]`, val));
        } else {
          formData.append(key, totel[key]);
        }
      }
      formData.append("_method", "PUT");

      dispatch(
        editLectureRequest({
          id: data?.id,
          formData: formData,
          action: action,
        })
      );
    }
  };

  const closeFunc = () => {
    dispatch(closeCreateLectureModel());
    setSelectedData({});
    reset();
  };
  return (
    <MainModel
      isLoading={isLoading}
      header={header}
      closeFunc={closeFunc}
      openVar={openLecture}
      buttonTitle={" حفظ التغيرات"}
      buttonFunc={handleSubmit(onSubmit)}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex
          dir="rtl"
          w="100%"
          flexWrap="wrap"
          justifyContent="space-between"
          gap="4"
        >
          <Box w="45%">
            <MainInput
              label="اسم المحتوي"
              defaultValues={data.title}
              error={errors?.title?.message}
              placeholder="ادخل اسم المحتوي"
              register={register}
              name="title"
            />
          </Box>

          <Box w="55%">
            <MainInput
              type="datetime-local"
              label="تاريخ الحصة "
              error={errors?.date?.message}
              placeholder="اكتب تاريخ الحجز"
              register={register}
              name="date"
            />
          </Box>

          <Box w="45%">
            <Box w="100%">
              <FormLabel>الفصول : </FormLabel>
              <Select
                options={chaptersOptions}
                onChange={(data) => setValue("chapter_id", data.value)}
                styles={colourStyles}
                placeholder="الصف"
                // defaultValue={{ label: '', value: data.chapter_id }}
              />
            </Box>
          </Box>
          <Box w="100%">
            <Box w="45%">
              <FormLabel>المجموعات : </FormLabel>
              <Select
                options={groupsOptions}
                isMulti={true}
                onChange={(data) => {
                  const selectedGroups = data.map((val) => val.value);
                  setValue("groups", selectedGroups);
                }}
                styles={colourStyles}
                placeholder="الفصول"
                defaultValue={data?.groups?.map((val) => {
                  return { label: val?.name, value: val?.id };
                })}
              />
            </Box>
          </Box>
        </Flex>
      </form>
    </MainModel>
  );
};

export default AddLecture;
