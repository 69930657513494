export default {
    //get all TEACHER_ASSISTANTs
    GET_TEACHER_ASSISTANTS_REQUEST: 'GET_TEACHER_ASSISTANTS_REQUEST',
    GET_TEACHER_ASSISTANTS_SUCCESS: 'GET_TEACHER_ASSISTANTS_SUCCESS',
    GET_TEACHER_ASSISTANTS_FAIL: 'GET_TEACHER_ASSISTANTS_FAIL',

    //edit TEACHER_ASSISTANTs
    EDIT_TEACHER_ASSISTANT_REQUEST: 'EDIT_TEACHER_ASSISTANT_REQUEST',
    EDIT_TEACHER_ASSISTANT_SUCCESS: 'EDIT_TEACHER_ASSISTANT_SUCCESS',
    EDIT_TEACHER_ASSISTANT_FAIL: 'EDIT_TEACHER_ASSISTANT_FAIL',

    //create TEACHER_ASSISTANT
    CREATE_TEACHER_ASSISTANT_REQUEST: 'CREATE_TEACHER_ASSISTANT_REQUEST',
    CREATE_TEACHER_ASSISTANT_SUCCESS: 'CREATE_TEACHER_ASSISTANT_SUCCESS',
    CREATE_TEACHER_ASSISTANT_FAIL: 'CREATE_TEACHER_ASSISTANT_FAIL',

    DELETE_TEACHER_ASSISTANT_REQUEST: 'DELETE_TEACHER_ASSISTANT_REQUEST',
    DELETE_TEACHER_ASSISTANT_SUCCESS: 'DELETE_TEACHER_ASSISTANT_SUCCESS',
    DELETE_TEACHER_ASSISTANT_FAIL: 'DELETE_TEACHER_ASSISTANT_FAIL',

    GET_TEACHER_ASSISTANT_REQUEST: 'GET_TEACHER_ASSISTANT_REQUEST',
    GET_TEACHER_ASSISTANT_SUCCESS: 'GET_TEACHER_ASSISTANT_SUCCESS',
    GET_TEACHER_ASSISTANT_FAIL: 'GET_TEACHER_ASSISTANT_FAIL',

    //loading
    ADD_TEACHER_ASSISTANTS_LOADING: 'ADD_TEACHER_ASSISTANTS_LOADING'
};
