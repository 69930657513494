import { Container, Flex, Image, Text } from "@chakra-ui/react";
import React from "react";

const AboutUs = () => {
  return (
    <Flex
      bg="main_1"
      color="white"
      alignItems={"center"}
      justifyContent="space-between"
      py="20px"
      id="aboutus"
    >
      <Container maxW={"container.xl"}>
        <Flex
          alignItems={"center"}
          justifyContent="space-between"
          flexDirection={{ base: "column", md: "row" }}
          gap={2}
        >
          <Flex w={{ base: "100%", md: "45%" }} alignItems={"center"}>
            <Image src={require("../../../assets/whiteLogo.webp")} w="300px" />
          </Flex>

          <Flex flexDirection={"column"} w={{ base: "100%", md: "45%" }}>
            <Text
              textTransform={"uppercase"}
              fontWeight="bold"
              fontSize={"20px"}
            >
              About Us
            </Text>
            <Text>Master Physics, Anywhere, Anytime!</Text>
          </Flex>
        </Flex>
      </Container>
    </Flex>
  );
};

export default AboutUs;
