import axiosInstance from "../../../helpers/api";

export const getAllTags = (body) => {
  return axiosInstance.get("/admin/question_tags", body);
};

export const editTags = (formData) => {
  return axiosInstance.post(
    `/admin/question_tags/${formData.id}`,
    formData.formData
  );
};

export const createTags = (formData) => {
  return axiosInstance.post("/admin/question_tags", formData);
};

export const deleteTag = (id) => {
  return axiosInstance.delete(`/admin/question_tags/${id}`);
};

export const enableTags = (formData) => {
  return axiosInstance.put(
    `/admin/question_tags/enable/${formData.id}`,
    formData.formData
  );
};
