import Types from './Types';





export const createStudentExamsRequest = (data) => ({
    type: Types.CREATE_STUDENT_EXAMS_REQUEST,
    payload: data
});

export const createStudentExamsSuccess = () => ({
    type: Types.CREATE_STUDENT_EXAMS_SUCCESS,
});

export const createStudentExamsFail = () => ({
    type: Types.CREATE_STUDENT_EXAMS_FAIL
});


export const getStudentExamsRequest = (id) => ({
    type: Types.GET_STUDENT_EXAMS_REQUEST,
    payload: id
});

export const getStudentExamsSuccess = (data) => ({
    type: Types.GET_STUDENT_EXAMS_SUCCESS,
    payload: data
});

export const getStudentExamsFail = () => ({
    type: Types.GET_STUDENT_EXAMS_FAIL
});




export const getStudentExamQuestionsRequest = (id) => ({
    type: Types.GET_STUDENT_EXAM_QUESTIONS_REQUEST,
    payload: id
});

export const getStudentExamQuestionsSuccess = (data) => ({
    type: Types.GET_STUDENT_EXAM_QUESTIONS_SUCCESS,
    payload: data
});

export const getStudentExamQuestionsFail = () => ({
    type: Types.GET_STUDENT_EXAM_QUESTIONS_FAIL
});




//LOADING
export const addStudentExamsLoading = (isLoading) => ({
    type: Types.ADD_STUDENT_EXAMS_LOADING,
    payload: isLoading
});

