import Types from "./Types";

export const getAllExamsRequest = (id) => ({
  type: Types.GET_ALL_EXAMS_REQUEST,
  payload: id,
});

export const getAllExamsSuccess = (data) => ({
  type: Types.GET_ALL_EXAMS_SUCCESS,
  payload: data,
});

export const getAllExamsFail = () => ({
  type: Types.GET_ALL_EXAMS_FAIL,
});

export const getExamsByChapterIdRequest = (id) => ({
  type: Types.GET_EXAMS_BY_CHAPTER_ID_REQUEST,
  payload: id,
});

export const getExamsByChapterIdSuccess = (data) => ({
  type: Types.GET_EXAMS_BY_CHAPTER_ID_SUCCESS,
  payload: data,
});

export const getExamsByIdFail = () => ({
  type: Types.GET_EXAMS_BY_ID_FAIL,
});

export const getExamsByIdRequest = (id) => ({
  type: Types.GET_EXAMS_BY_ID_REQUEST,
  payload: id,
});

export const getExamsByIdSuccess = (data) => ({
  type: Types.GET_EXAMS_BY_ID_SUCCESS,
  payload: data,
});

export const getExamsByChapterIdFail = () => ({
  type: Types.GET_EXAMS_BY_ID_FAIL,
});

export const getExamQuestionsRequest = (id) => ({
  type: Types.GET_EXAM_QUESTIONS_REQUEST,
  payload: id,
});

export const getExamQuestionsSuccess = (data) => ({
  type: Types.GET_EXAM_QUESTIONS_SUCCESS,
  payload: data,
});

export const getExamQuestionsFail = () => ({
  type: Types.GET_EXAM_QUESTIONS_FAIL,
});

export const getExamCorrectionRequest = (id) => ({
  type: Types.GET_EXAM_CORRECTION_REQUEST,
  payload: id,
});

export const getExamCorrectionSuccess = (data) => ({
  type: Types.GET_EXAM_CORRECTION_SUCCESS,
  payload: data,
});

export const getExamCorrectionFail = () => ({
  type: Types.GET_EXAM_CORRECTION_FAIL,
});

export const getChapterExamRequest = (id) => ({
  type: Types.GET_CHAPTER_EXAM_REQUEST,
  payload: id,
});

export const getChapterExamSuccess = (data) => ({
  type: Types.GET_CHAPTER_EXAM_SUCCESS,
  payload: data,
});

export const getChapterExamFail = () => ({
  type: Types.GET_CHAPTER_EXAM_FAIL,
});
export const getChapterExamByIdRequest = (id) => ({
  type: Types.GET_CHAPTER_EXAM_DETAILS_REQUEST,
  payload: id,
});

export const getChapterExamByIdSuccess = (data) => ({
  type: Types.GET_CHAPTER_EXAM_DETAILS_SUCCESS,
  payload: data,
});

export const getChapterExamByIdFail = () => ({
  type: Types.GET_CHAPTER_EXAM_DETAILS_FAIL,
});
//LOADING
export const addExamsLoading = (isLoading) => ({
  type: Types.ADD_EXAMS_LOADING,
  payload: isLoading,
});

export const sendAnswersRequest = (formData, action) => ({
  type: Types.SEND_ANSWERS_REQUEST,
  payload: formData,
  action: action,
});

export const sendAnswersSuccess = ({ data }) => ({
  type: Types.SEND_ANSWERS_SUCCESS,
  payload: {
    data,
  },
});

export const sendAnswersFail = () => ({
  type: Types.SEND_ANSWERS_FAIL,
});
export const sendChapterExamAnswersRequest = (formData, action) => ({
  type: Types.SEND_CHAPTER_EXAM_ANSWERS_REQUEST,
  payload: formData,
  action: action,
});

export const sendChapterExamAnswersSuccess = ({ data }) => ({
  type: Types.SEND_CHAPTER_EXAM_ANSWERS_SUCCESS,
  payload: {
    data,
  },
});

export const sendChapterExamAnswersFail = () => ({
  type: Types.SEND_CHAPTER_EXAM_ANSWERS_FAIL,
});
export const examRedoRequest = (formData, action) => ({
  type: Types.EXAM_REDO_REQUEST,
  payload: formData,
  action: action,
});

export const examRedoSuccess = ({ data }) => ({
  type: Types.EXAM_REDO_SUCCESS,
  payload: {
    data,
  },
});

export const examRedoFail = () => ({
  type: Types.EXAM_REDO_FAIL,
});

//LOADING
export const sendAnswersLoading = (isLoading) => ({
  type: Types.SEND_ANSWERS_LOADING,
  payload: isLoading,
});
