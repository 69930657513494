import Types from './Types';

const INITIAL_STATE = {
    studentExams: [

    ],
    examQuestions: [

    ],

    isLoading: false,
    count: ''
};

export default function studentExams(state = INITIAL_STATE, action) {
    const { type, payload } = action;
    switch (type) {
        case Types.GET_STUDENT_EXAMS_SUCCESS: {
            return {
                ...state,
                studentExams: payload.data,
                count: payload.data.length
            };
        }

        case Types.REATE_STUDENT_EXAMS_SUCCESS: {
            return {
                studentExams: state.studentExams.concat(payload),
                ...state,
            };
        }

        case Types.GET_STUDENT_EXAM_QUESTIONS_SUCCESS: {
            return {
                ...state,
                examQuestions: payload.data,
            };
        }


        case Types.ADD_STUDENT_EXAMS_LOADING: {
            return {
                ...state,
                isLoading: payload
            };
        }


        default: {
            return state;
        }
    }
}
