import {
  Button,
  Container,
  Heading,
  HStack,
  Icon,
  Stack,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { RiArrowDropLeftLine, RiArrowDropRightLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../../components/student/Layout/layout";
import ExamEllipses from "../../../containers/student/exams/exam-ellipses";
import ExamHint from "../../../containers/student/exams/exam-hint";
import ExamSolvedQuestions from "../../../containers/student/exams/exam-solved-questions";
import { openExamHintkModel } from "../../../modules/admin-modules/models/Actions";
import {
  getExamCorrectionRequest,
  getExamsByIdRequest,
} from "../../../modules/students-modules/exams/Actions";
const ExamResult = () => {
  const dispatch = useDispatch();
  const queryString = require("query-string");
  const parsed = queryString.parse(window.location.search);
  const examId = window.location.pathname.split("/")[2];

  const examCorrection = useSelector(
    (state) => state.chapterExams.examCorrection
  );

  const isLoading = useSelector((state) => state.chapterExams.isLoading);

  const [selectedQuestionIdx, setSelectedQuestionIdx] = useState(0);
  const videoData =
    examCorrection?.questions?.[selectedQuestionIdx]?.url?.split("/");
  const splitLength =
    examCorrection?.questions?.[selectedQuestionIdx]?.url?.split("/")?.length;
  const videoId = videoData?.[splitLength - 1];
  useEffect(() => {
    if (examId) {
      dispatch(getExamsByIdRequest(examId));
      dispatch(getExamCorrectionRequest(examId));
    }
  }, [examId]);
  const handelNextQuestion = () => {
    if (selectedQuestionIdx < 0)
      setSelectedQuestionIdx(examCorrection?.questions?.length - 1);
    else if (selectedQuestionIdx >= examCorrection?.questions?.length - 1)
      setSelectedQuestionIdx(0);
    else setSelectedQuestionIdx((s) => s + 1);
  };
  const handelPrevQuestion = () => {
    if (selectedQuestionIdx > examCorrection?.questions?.length - 1)
      setSelectedQuestionIdx(0);
    if (selectedQuestionIdx <= 0)
      setSelectedQuestionIdx(examCorrection?.questions?.length - 1);
    else setSelectedQuestionIdx((s) => s - 1);
  };
  return (
    <Layout color="main_1" bg="white" isLoading={isLoading}>
      <Container
        maxW="container.xl"
        h="100%"
        my="100px"
        mb="165px"
        w="100%"
        overflowX="hidden"
      >
        <Stack flexDir={{ base: "column", md: "row" }} alignItems="end">
          <VStack spacing={"70px"} h="100%" w="100%">
            <Heading
              as="h3"
              color="main_1"
              textAlign="center"
              w="100%"
              textTransform="capitalize"
            >
              {parsed?.name}
            </Heading>

            <HStack>
              {/* questions polts */}
              <Icon
                as={RiArrowDropLeftLine}
                onClick={handelPrevQuestion}
                fontSize="35px"
              />
              <ExamEllipses
                examCorrection={examCorrection}
                questionsCount={examCorrection?.questions?.length}
                setSelectedQuestionIdx={setSelectedQuestionIdx}
                selectedQuestionIdx={selectedQuestionIdx}
              />
              <Icon
                as={RiArrowDropRightLine}
                onClick={handelNextQuestion}
                fontSize="35px"
              />
            </HStack>

            {/* questions */}
            {examCorrection?.questions && (
              <ExamSolvedQuestions
                data={examCorrection?.questions[selectedQuestionIdx]}
                correctionArr={examCorrection?.answers}
                selectedQuestionIdx={selectedQuestionIdx}
              />
            )}

            {/* submit */}
          </VStack>
          <HStack>
            {examCorrection?.questions &&
              (examCorrection?.questions?.[selectedQuestionIdx]
                ?.model_answer_image ||
                examCorrection?.questions?.[selectedQuestionIdx]?.url) && (
                <Button onClick={() => dispatch(openExamHintkModel())}>
                  Hint
                </Button>
              )}
            <Button
              onClick={handelNextQuestion}
              bg="main_1"
              color="white"
              mt="10px"
            >
              {" "}
              Next Question
            </Button>
          </HStack>
        </Stack>
      </Container>
      {/* hint model */}
      <ExamHint
        videoId={videoId}
        model_answer_image={
          examCorrection?.questions?.[selectedQuestionIdx]?.model_answer_image
        }
      />
    </Layout>
  );
};

export default ExamResult;
