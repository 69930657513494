import Types from './Types';

const INITIAL_STATE = {
    redoRequests: [],
    isLoading: false,
    count: ''
};

export default function redoRequests(state = INITIAL_STATE, action) {
    const { type, payload } = action;
    switch (type) {
        case Types.GET_REDO_REQUESTS_SUCCESS: {
            return {
                ...state,
                redoRequests: payload.data,
                count: payload.data.length
            };
        }



        case Types.EDIT_REDO_REQUEST_SUCCESS: {
            const newrequests = state.redoRequests.filter((item) => item.id !== payload);

            return {
                ...state,
                redoRequests: newrequests
            };
        }



        case Types.DELETE_REDO_REQUEST_SUCCESS: {
            const newrequests = state.redoRequests.filter((item) => item.id !== payload);
            return {
                ...state,
                redoRequests: newrequests
            };
        }
        case Types.ADD_REDO_REQUESTS_LOADING: {
            return {
                ...state,
                isLoading: payload
            };
        }

        default: {
            return state;
        }
    }
}
