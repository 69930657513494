import Types from './Types';


export const getAllLessonsRequest = (body) => ({
    type: Types.GET_LESSONS_REQUEST,
    payload: body
});

export const getAllLessonsSuccess = (data) => ({
    type: Types.GET_LESSONS_SUCCESS,
    payload: data
});

export const getAllLessonsFail = () => ({
    type: Types.GET_LESSONS_FAIL
});


export const editLessonRequest = (formData) => ({
    type: Types.EDIT_LESSON_REQUEST,
    payload: formData,
});

export const editLessonSuccess = (data) => ({
    type: Types.EDIT_LESSON_SUCCESS,
    payload: data
});

export const editLessonFail = () => ({
    type: Types.EDIT_LESSON_FAIL
});

//LOADING
export const addLessonLoading = (isLoading) => ({
    type: Types.ADD_LESSONS_LOADING,
    payload: isLoading
});

// Create Lesson
export const createLessonRequest = (formData, action) => ({
    type: Types.CREATE_LESSON_REQUEST,
    payload: formData,
    action: action
});

export const createLessonSuccess = ({ data }) => ({
    type: Types.CREATE_LESSON_SUCCESS,
    payload: {
        data
    }
});

export const createLessonFail = () => ({
    type: Types.CREATE_LESSON_FAIL
});

export const deleteLessonRequest = (id) => ({
    type: Types.DELETE_LESSON_REQUEST,
    payload: id
});

export const deleteLessonSuccess = (id) => ({
    type: Types.DELETE_LESSON_SUCCESS,
    payload: id
});

export const deleteLessonFail = () => ({
    type: Types.DELETE_LESSON_FAIL
});
