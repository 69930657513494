import Types from "./Types";

const INITIAL_STATE = {
  chapterHomeworks: [],
  chapterHomeworkQuiz: [],
  homeworkDetails: {},
  seenCount: 0,
  otp: "",
  playbackInfo: "",
  count: "",
  loading: false,
  seenLoading: false,
  voucherLoading: false,
};

export default function chapterHomeworks(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_HOMEWORKS_BY_CHAPTER_ID_SUCCESS: {
      return {
        ...state,
        chapterHomeworks: payload.data,
        count: payload.data.length,
      };
    }
    case Types.GET_HOMEWORK_QUIZ_BY_CHAPTER_ID_SUCCESS: {
      return {
        ...state,
        chapterHomeworkQuiz: payload.data,
        count: payload.data.length,
      };
    }
    case Types.GET_HOMEWORK_BY_ID_SUCCESS: {
      return {
        ...state,
        homeworkDetails: payload.data,
      };
    }
    case Types.SOLVE_HOMEWORK_SUCCESS: {
      let data = payload.data.data;
      const chapterHomeworks = state.chapterHomeworks;
      let idx = 0;
      chapterHomeworks.forEach((table, index) => {
        if (table.id === data.homework_id) {
          idx = index;
          return;
        }
      });
      chapterHomeworks[idx] = data;
      return {
        ...state,
        chapterHomeworks: chapterHomeworks,
      };
    }
    case Types.UPDATE_SEEN_COUNT_SUCCESS: {
      let id = payload.payload?.video_id;
      let data = payload.result.data.data;
      const chapterHomeworks = state.chapterHomeworks;
      let idx = 0;
      chapterHomeworks.forEach((table, index) => {
        if (table.id === id) {
          idx = index;
          return;
        }
      });
      chapterHomeworks[idx].views_count.views_count = data?.views_count;
      return {
        ...state,
        chapterHomeworks: chapterHomeworks,
      };
    }

    case Types.ADD_VOUCHER_SUCCESS: {
      let id = payload;
      const chapterHomeworks = state.chapterHomeworks;
      let idx = 0;
      chapterHomeworks.forEach((table, index) => {
        if (table.id === id) {
          idx = index;
          return;
        }
      });
      chapterHomeworks[idx].views_count.views_count = 0;
      return {
        ...state,
        chapterHomeworks: chapterHomeworks,
      };
    }

    case Types.GET_HOMEWORK_OTP_SUCCESS: {
      let data = payload;

      return {
        ...state,
        playbackInfo: payload.playbackInfo,
        otp: payload.otp,
      };
    }

    case Types.MORE_HOMEWORK_VIEWS_SUCCESS: {
      let data = payload;
      window.open("https://wa.me/201025830160", "_blank");
      return {
        ...state,
      };
    }

    case Types.ADD_HOMEWORKS_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }

    case Types.VIDEO_SEEN_LOADING: {
      return {
        ...state,
        seenLoading: payload,
      };
    }

    case Types.VIDEO_VOUCHER_LOADING: {
      return {
        ...state,
        voucherLoading: payload,
      };
    }
    default: {
      return state;
    }
  }
}
