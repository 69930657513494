import {
  Box,
  Button,
  Flex,
  HStack,
  InputGroup,
  Input,
  InputLeftElement,
  MenuItem,
  MenuList,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import {
  RiAddFill,
  RiFileExcel2Line,
  RiPrinterLine,
  RiSearch2Fill,
} from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import LogoutMenu from "../../../components/dashboard/Layout/logout-menu";
import { openCreateLsson } from "../../../modules/admin-modules/models/Actions";

const SearchBar = ({ setAdd, setSelectedData }) => {
  const dispatch = useDispatch();
  const count = useSelector((state) => state.lessons.count);

  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      width: "100%",
      background: "white",
      color: "#690000",
      borderRadius: "16px",
    }),
    option: (styles) => ({ ...styles, color: "#690000" }),
  };
  return (
    <VStack w="100%" my="30px">
      <Flex alignItems="center" w="100%" justifyContent="space-between">
        <Text fontSize="24px" fontWeight="600" color="main_1">
          المحتوي{" "}
        </Text>

        <Flex alignItems="center">
          <Button
            borderRadius="16px"
            leftIcon={<RiAddFill />}
            color="white"
            bg="main_1"
            _focus={{ outline: "none" }}
            onClick={() => {
              setAdd(true);
              dispatch(openCreateLsson());
            }}
          >
            إضافة محتوى
          </Button>
          <Button
            borderColor="main_1"
            border="1px solid"
            mx="10px"
            borderRadius="16px"
            leftIcon={<RiPrinterLine />}
            color="main_1"
            bg="white"
            _focus={{ outline: "none" }}
          >
            طباعة المحتوى
          </Button>
          <LogoutMenu />
        </Flex>
      </Flex>
      <Text w="100%" fontSize="20px" textAlign="right">
        عدد الكورسات:{count}
      </Text>

      <HStack
        w="100%"
        justifyContent="space-between"
        bg="#f5f5f5"
        mt="40px !important"
        p="10px"
        mb="-30px !important"
      >
        <HStack spacing="5px">
          <Select
            options={options}
            onChange={(data) => {}}
            styles={colourStyles}
            placeholder="الصف"
          />
          <Select
            options={options}
            onChange={(data) => {}}
            styles={colourStyles}
            placeholder="الفصل "
          />

          <InputGroup w="400px">
            <InputLeftElement
              pointerEvents="none"
              children={<RiSearch2Fill color="main_1" fontSize="20px" />}
            />
            <Input
              type="text"
              placeholder="عنوان الدرس"
              borderRadius="16px"
              bg="white"
            />
          </InputGroup>
        </HStack>

        <HStack spacing="5px">
          <Select
            options={options}
            onChange={(data) => {}}
            styles={colourStyles}
            placeholder="التصنيف"
          />

          <Select
            options={options}
            onChange={(data) => {}}
            styles={colourStyles}
            placeholder="الأحدث"
          />
        </HStack>
      </HStack>
    </VStack>
  );
};

export default SearchBar;
