import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormLabel,
  HStack,
  Input,
  Stack,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import MainInput from "../../../../components/Form/input";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { openQuestionsListModel } from "../../../../modules/admin-modules/models/Actions";

const StepTwo = ({
  watchTotalQustions,
  watchSubType,
  watchQuestionWay,
  data,
  setValue,
  register,
  errors,
  isFormOne,
  watchChapters,
  isAdd,
  watchType,
}) => {
  const dispatch = useDispatch();
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      width: "100%",
      background: "#f5f5f5",
      color: "#690000",
      borderRadius: "16px",
    }),
    option: (styles) => ({ ...styles, color: "#690000" }),
  };

  const [fieldsCount, setFieldsCount] = useState(1);

  const chapters = useSelector((state) => state.chapters.chapters);
  const groups = useSelector((state) => state.groups.groups);
  const performanceGroups = useSelector(
    (state) => state.groups.performanceGroups
  );

  const groupsOptions = groups?.map((data) => {
    return { value: data.id, label: data.name };
  });

  const performanceGroupsOptions =
    performanceGroups?.length > 0
      ? performanceGroups?.map((data) => {
          return { value: data.id, label: data.name };
        })
      : [];

  const chaptersOptions = chapters?.map((data) => {
    return { value: data.id, label: data.name };
  });

  const totalSelectedQuestions = watchChapters?.reduce((accumulator, value) => {
    return Number(accumulator) + Number(value?.no_of_questions);
  }, 0);
  return (
    <VStack
      display={!isFormOne ? "flex" : "none"}
      dir="rtl"
      w="100%"
      flexWrap="wrap"
      justifyContent="space-between"
      spacing="50px"
    >
      <HStack w="100%" gap={5} mt={4}>
        <Box w="45%">
          <FormLabel>المجموعات : </FormLabel>
          <Select
            options={groupsOptions}
            isMulti={true}
            onChange={(data) => {
              const selectedGroups = data.map((val) => val.value);
              setValue("groups", selectedGroups);
            }}
            styles={colourStyles}
            placeholder="المجموعات"
            defaultValue={data?.groups?.map((val) => {
              return { label: val?.name, value: val?.id };
            })}
          />
        </Box>
        <Box w="45%">
          {performanceGroupsOptions?.map((data, index) => {
            return (
              <>
                <FormLabel>{data?.label}</FormLabel>
                <MainInput
                  label=""
                  defaultValues={data.question_number}
                  error={errors?.question_number?.message}
                  placeholder=" ادخل عدد الدرجات "
                  register={register}
                  name="question_number"
                  required={false}
                  onChange={(e) => {
                    setValue(
                      `chapters[${index + 1}][min_target]`,
                      e?.target?.value
                    );
                    setValue(
                      `chapters[${index + 1}][performance_gp_id]`,
                      data?.id
                    );
                  }}
                />
              </>
            );
          })}
        </Box>
      </HStack>
      <Box w="100%">
        <FormLabel> طريقة السؤال : </FormLabel>

        <Stack
          direction="row"
          role="group"
          aria-labelledby="my-radio-group"
          spacing="30px"
        >
          <label>
            <input
              {...register("random", { required: "هذه المعلومات مطلوبة" })}
              type="radio"
              value="0"
              defaultChecked={data?.random == 0 ? true : false}
            />
            أسئلة يختارها المدرس
          </label>
          <label>
            <input
              {...register("random", { required: "هذه المعلومات مطلوبة" })}
              type="radio"
              value="1"
              defaultChecked={data?.random == 1 ? true : false}
            />
            اسئلة عشوائية من بنك الاسئلة
          </label>
          <Text color="red">{errors?.random?.message}</Text>
        </Stack>
      </Box>
      <HStack w="100%" alignItems="flex-start">
        <VStack w="50%" alignItems="flex-start">
          <FormLabel> نوع الاسئلة : </FormLabel>
          <Stack
            direction="row"
            role="group"
            aria-labelledby="my-radio-group"
            spacing="30px"
          >
            <label>
              <input
                {...register("sub_type", {
                  required: "هذه المعلومات مطلوبة",
                })}
                type="radio"
                value="1"
                defaultChecked={data?.sub_type == 1 ? true : false}
              />
              اختبار (من فصل واحد)
            </label>
            <label>
              <input
                {...register("sub_type", {
                  required: "هذه المعلومات مطلوبة",
                })}
                type="radio"
                value="2"
                disabled={watchQuestionWay == 0 ? true : false}
                defaultChecked={data?.sub_type == 2 ? true : false}
              />
              اختبار شامل (من اكتر من فصل)
            </label>
          </Stack>
        </VStack>
        <Box w="50%">
          <FormLabel w="30%">عدد الأسئلة : </FormLabel>
          <MainInput
            label=""
            defaultValues={data.question_number}
            error={errors?.question_number?.message}
            placeholder=" ادخل عدد الأسئلة "
            register={register}
            name="question_number"
            required={false}
          />
        </Box>
        {!isAdd && watchQuestionWay == 0 && (
          <Button w="30%" onClick={() => dispatch(openQuestionsListModel())}>
            أخترالاسئلة
          </Button>
        )}
      </HStack>

      {watchSubType == 1 && (
        <HStack w="100%" gap="30px" alignItems="flex-end">
          <Box w="70%">
            <FormLabel>الفصل : </FormLabel>
            <Select
              options={chaptersOptions}
              isMulti={false}
              onChange={(data) => {
                setValue("chapters[1][chapter_id]", data?.value);
                setValue("chapters[1][no_of_questions]", watchTotalQustions);
              }}
              styles={colourStyles}
              placeholder="الفصل"
              defaultValue={data?.chapters?.map((val) => {
                return { label: val?.name, value: val?.id };
              })}
            />
          </Box>
        </HStack>
      )}
      {watchSubType == 2 && watchTotalQustions && (
        <HStack w="100%" alignItems="flex-start">
          <VStack w="70%" alignItems="flex-start">
            <FormLabel>الفصول : </FormLabel>

            {[...Array(fieldsCount)].map((val, index) => {
              return (
                <HStack w="100%" spacing="40px">
                  <Box w="45%">
                    <Select
                      options={chaptersOptions}
                      isMulti={false}
                      onChange={(data) => {
                        setValue(
                          `chapters[${index + 1}][chapter_id]`,
                          data?.value
                        );
                      }}
                      styles={colourStyles}
                      placeholder="الفصول"
                      defaultValue={data?.chapters?.map((val) => {
                        return { label: val?.name, value: val?.id };
                      })}
                    />
                  </Box>

                  <HStack w="45%">
                    <FormLabel w="60%">عدد الأسئلة : </FormLabel>
                    <Input
                      size="md"
                      defaultValues={data.question_number}
                      error={errors?.question_number?.message}
                      onChange={(e) => {
                        setValue(
                          `chapters[${index + 1}][no_of_questions]`,
                          e.target.value
                        );
                      }}
                      placeholder="   اكتب عدد الاسئلة "
                      name="question_number"
                    />
                  </HStack>
                </HStack>
              );
            })}
          </VStack>
          <Button
            w="30%"
            onClick={() => setFieldsCount((s) => s + 1)}
            isDisabled={
              totalSelectedQuestions >= watchTotalQustions ? true : false
            }
          >
            أضف فرعا آخر
          </Button>
        </HStack>
      )}
    </VStack>
  );
};

export default StepTwo;
