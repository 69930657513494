import Types from './Types';


export const getAllQuestionsBankRequest = (id) => ({
    type: Types.GET_QUESTIONS_BANK_REQUEST,
    payload: id
});

export const getAllQuestionsBankSuccess = (data) => ({
    type: Types.GET_QUESTIONS_BANK_SUCCESS,
    payload: data
});

export const getAllQuestionsBankFail = () => ({
    type: Types.GET_QUESTIONS_BANK_FAIL
});


export const editQuestionBankRequest = (formData) => ({
    type: Types.EDIT_QUESTION_BANK_REQUEST,
    payload: formData,
});

export const editQuestionBankSuccess = (data) => ({
    type: Types.EDIT_QUESTION_BANK_SUCCESS,
    payload: data
});

export const editQuestionBankFail = () => ({
    type: Types.EDIT_QUESTION_BANK_FAIL
});

//LOADING
export const addQuestionBankLoading = (isLoading) => ({
    type: Types.ADD_QUESTION_BANK_LOADING,
    payload: isLoading
});

// Create QuestionBank
export const createQuestionBankRequest = (formData, action) => ({
    type: Types.CREATE_QUESTION_BANK_REQUEST,
    payload: formData,
    action: action
});

export const createQuestionBankSuccess = ({ data }) => ({
    type: Types.CREATE_QUESTION_BANK_SUCCESS,
    payload: {
        data
    }
});

export const createQuestionBankFail = () => ({
    type: Types.CREATE_QUESTION_BANK_FAIL
});

export const deleteQuestionBankRequest = (id) => ({
    type: Types.DELETE_QUESTION_BANK_REQUEST,
    payload: id
});

export const deleteQuestionBankSuccess = (id) => ({
    type: Types.DELETE_QUESTION_BANK_SUCCESS,
    payload: id
});

export const deleteQuestionBankFail = () => ({
    type: Types.DELETE_QUESTION_BANK_FAIL
});

export const bookmarkQuestionBankRequest = (id) => ({
    type: Types.BOOKMARK_QUESTION_BANK_REQUEST,
    payload: id
});

export const bookmarkQuestionBankSuccess = (id) => ({
    type: Types.BOOKMARK_QUESTION_BANK_SUCCESS,
    payload: id
});

export const bookmarkQuestionBankFail = () => ({
    type: Types.BOOKMARK_QUESTION_BANK_FAIL
});

export const suspendQuestionBankLoading = (isLoading) => ({
    type: Types.SUSPEND_QUESTION_BANK_LOADING,
    payload: isLoading
});


export const bookmarkQuestionBankLoading = (isLoading) => ({
    type: Types.BOOKMARK_QUESTION_BANK_LOADING,
    payload: isLoading
});
