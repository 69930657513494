import {
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Container,
  Divider,
  Flex,
  Grid,
  Heading,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams, useSearchParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import exams from "../../../assets/icons/exams.svg";
import Layout from "../../../components/student/Layout/layout";
import ExamMainData from "../../../containers/student/exams/exam-main-data";
import { getStudentChaptersRequest } from "../../../modules/students-modules/chapters/Actions";
import { getExamsByChapterIdRequest } from "../../../modules/students-modules/exams/Actions";
import {
  createStudentExamsRequest,
  getStudentExamsRequest,
} from "../../../modules/students-modules/student-exams/Actions";

const ChapterExams = () => {
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();
  const chapters = useSelector((state) => state.studentChapters.chapters);

  const chapterExams = useSelector((state) => state.chapterExams.chapterExams);
  const studentExams = useSelector((state) => state.studentExams.studentExams);
  const isLoading = useSelector((state) => state.chapterExams.isLoading);

  const [subType, setSubType] = useState("");
  const [chaptersData, setChapters] = useState([]);
  const { id } = useParams();
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      width: "100%",
      background: "#f5f5f5",
      color: "#690000",
      borderRadius: "16px",
    }),
    option: (styles) => ({ ...styles, color: "#690000" }),
  };

  const chaptersOptions = chapters?.map((data) => {
    return { value: data.id, label: data.name };
  });
  console.log(window.location.pathname.includes("student"));
  useEffect(() => {
    if (window.location.pathname.includes("student"))
      dispatch(getStudentExamsRequest());
    else if (!window.location.pathname.includes("student") && id) {
      dispatch(getExamsByChapterIdRequest(id));
    }
  }, [id]);
  useEffect(() => {
    dispatch(getStudentChaptersRequest());
  }, []);

  const handelSolveExamFun = (data) => {
    if (window.location.pathname.includes("student"))
      window.location.href = `/student/exam-guide/${data?.id}?name=${data?.name}`;
    else window.location.href = `/exam-guide/${data?.id}?name=${data?.name}`;
  };

  const handelCheckExamAnswersFun = (data) => {
    window.location.href = `/exam-result/${data?.id}?name=${data?.name}`;
  };

  return (
    <Layout color="main_1" bg="white" isLoading={isLoading}>
      <Container maxW="container.2xl" h="100%" my="100px" mb="165px" w="100%">
        <VStack spacing={8} h="100%" w="100%" alignItems="flex-start">
          {!window.location.pathname.includes("student") && (
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/exams/sections">
                  <Text>Exams</Text>
                </Link>
              </BreadcrumbItem>

              <BreadcrumbItem isCurrentPage>
                <Text>{searchParams.get("name")}</Text>
              </BreadcrumbItem>
            </Breadcrumb>
          )}

          <HStack w={"100%"}>
            <Heading as="h3" color="main_1" textAlign="center" w="100%">
              {window.location.pathname.includes("student")
                ? "Exams"
                : searchParams.get("name")}
            </Heading>

            {window.location.pathname.includes("student") && (
              <Menu closeOnSelect={false}>
                <MenuButton as={Button} bg="main_1" color="white" w="20%">
                  <Flex alignItems="center" justifyContent="space-between">
                    <Text> Create Exam</Text>
                    <Image src={exams} />
                  </Flex>
                </MenuButton>
                <MenuList minWidth="240px">
                  <MenuOptionGroup type="radio">
                    <MenuItemOption
                      value="2"
                      onClick={() => {
                        setSubType(2);
                        let formData = new FormData();
                        formData.append("sub_type", "2");
                        dispatch(createStudentExamsRequest(formData));
                      }}
                    >
                      شامل
                    </MenuItemOption>

                    <MenuItemOption value="1" onClick={() => setSubType(1)}>
                      من احدي الفصول
                    </MenuItemOption>
                    <MenuItemOption value="3" onClick={() => setSubType(3)}>
                      {" "}
                      mini quiz
                    </MenuItemOption>
                  </MenuOptionGroup>
                  <Divider />
                  {subType == 1 && (
                    <MenuList
                      p={4}
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Select
                        options={chaptersOptions}
                        isMulti={true}
                        onChange={(data) => {
                          setChapters(data);
                        }}
                        styles={colourStyles}
                        placeholder="Select lecture"
                      />
                      <Button
                        mt="10px"
                        onClick={async () => {
                          let formData = new FormData();
                          await formData.append("sub_type", "1");
                          await chaptersData?.map((val, idx) =>
                            formData.append(
                              `chapters[${idx}][chapter_id]`,
                              val?.value
                            )
                          );
                          if (chaptersData?.length <= 3) {
                            await chaptersData?.map((val, idx) => {
                              if (chaptersData?.length < 3)
                                formData.append(
                                  `chapters[${idx}][no_of_questions]`,
                                  30 / chaptersData?.length
                                );
                              else
                                formData.append(
                                  `chapters[${idx}][no_of_questions]`,
                                  10
                                );
                            });
                          } else {
                            toast.error("  لا يجب ان تخار اكثر من ثلاث فصول");
                          }

                          dispatch(createStudentExamsRequest(formData));
                        }}
                      >
                        Create
                      </Button>
                    </MenuList>
                  )}
                  {subType == 3 && (
                    <MenuList
                      p={4}
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Select
                        options={chaptersOptions}
                        isMulti={false}
                        onChange={(data) => {
                          setChapters(data);
                        }}
                        styles={colourStyles}
                        placeholder="Select chapter"
                      />
                      <Button
                        mt="10px"
                        onClick={async () => {
                          if (subType == 1) {
                            let formData = new FormData();
                            await formData.append("sub_type", "1");
                            await chaptersData?.map((val, idx) =>
                              formData.append(
                                `chapters[${idx}][chapter_id]`,
                                val?.value
                              )
                            );
                            if (chaptersData?.length <= 3) {
                              await chaptersData?.map((val, idx) => {
                                if (chaptersData?.length < 3)
                                  formData.append(
                                    `chapters[${idx}][no_of_questions]`,
                                    30 / chaptersData?.length
                                  );
                                else
                                  formData.append(
                                    `chapters[${idx}][no_of_questions]`,
                                    10
                                  );
                              });
                            } else {
                              toast.error("  لا يجب ان تخار اكثر من ثلاث فصول");
                            }
                            dispatch(createStudentExamsRequest(formData));
                          }
                          if (subType == 3) {
                            let formData = new FormData();
                            await formData.append("sub_type", "3");
                            formData.append(
                              `chapters[0][chapter_id]`,
                              chaptersData?.value
                            );
                            formData.append(`chapters[0][no_of_questions]`, 10);
                            dispatch(createStudentExamsRequest(formData));
                          }
                        }}
                      >
                        Create
                      </Button>
                    </MenuList>
                  )}
                </MenuList>
              </Menu>
            )}
          </HStack>

          <Grid
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
            gap={6}
            w="100%"
          >
            {(window.location.pathname.includes("student")
              ? studentExams
              : chapterExams
            )?.map((chapter, idx) => {
              return (
                <ExamMainData
                  exam={chapter}
                  state={chapter?.right_answers == null ? "Pending" : "Solved"}
                  solveExamFun={handelSolveExamFun}
                  checkExamAnswersFun={handelCheckExamAnswersFun}
                />
              );
            })}
          </Grid>
        </VStack>
      </Container>
    </Layout>
  );
};

export default ChapterExams;
