import {
  Avatar,
  Container,
  Grid,
  Heading,
  HStack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import bg from "../../../assets/course.webp";
import Layout from "../../../components/student/Layout/layout";
import { getAllSectionsRequest } from "../../../modules/students-modules/sections/Actions";

const ExamsSectionsList = () => {
  const sections = useSelector((state) => state.sections.sections);
  const isLoading = useSelector((state) => state.sections.isLoading);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllSectionsRequest());
  }, []);
  return (
    <Layout color="main_1" bg="white" isLoading={isLoading}>
      <Container maxW="container.2xl" h="100%" my="100px" mb="165px" w="100%">
        <VStack spacing={8} h="100%" w="100%" alignItems="flex-start">
          <Heading as="h3" color="main_1" textAlign="center" w="100%">
            Exams
          </Heading>

          <Grid
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(4, 1fr)" }}
            gap={6}
            w="100%"
          >
            {sections?.map((section, idx) => {
              return (
                <Link
                  to={`/exams/chapters/${section?.id}?name=${section?.name}`}
                  key={idx}
                >
                  <VStack
                    bg="#ededed"
                    borderRadius="8px"
                    alignItems="start"
                    justifyContent="center"
                    p="10px"
                    spacing="20px"
                    h="70px"
                    w="100%"
                    cursor="pointer"
                  >
                    <HStack p="10px">
                      <Avatar
                        name={section?.name}
                        src={bg}
                        borderRadius="8px"
                        size="md"
                        background="transparent"
                      />

                      <VStack alignItems="flex-start">
                        <Text fontWeight="700" fontSize="20px">
                          {section?.name}
                        </Text>
                      </VStack>
                    </HStack>
                  </VStack>
                </Link>
              );
            })}
          </Grid>
        </VStack>
      </Container>
    </Layout>
  );
};

export default ExamsSectionsList;
