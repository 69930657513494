import { IconButton, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { RiDeleteBin6Line, RiDeleteBinLine, RiFileEditLine } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux';
import CTable from '../../../components/Table/table'
import { openCreateStudent, openCreateTeacher, openCreateTeacherAssistant } from '../../../modules/admin-modules/models/Actions';
import { deleteTeacherAssistantRequest } from '../../../modules/admin-modules/teachersAssistant/Actions';

const TeachersAssistantTable = ({ setAdd, setSelectedData }) => {
    const teacherAssistant = useSelector((state) => state.teacherassistants.teacherassistants)
    const dispatch = useDispatch()
    const isLoading = false
    const [offset, setPage] = useState(0);
    const [size, setPerPage] = useState(10);
    const [pageNumber, setPageNumber] = useState(0);
    const [selected, setSelected] = useState({
        id: ''
    })
    const viewData = (data) => {
        setSelectedData(data);
        setSelected(data)
    };
    const Data =
        teacherAssistant?.map((data) => {
            return {
                id: data?.id,
                title: data?.name,
                group: data?.group?.name,
                edit: (
                    <IconButton
                        variant='outline'
                        border='0'
                        color='gray.600'
                        aria-label='Call Sage'
                        fontSize='20px'
                        icon={<RiFileEditLine />}
                        onClick={() => {
                            setAdd(false);
                            dispatch(openCreateTeacherAssistant())
                        }}
                    />

                ),
                delete: (
                    <IconButton
                        variant='outline'
                        border='0'
                        color='gray.600'
                        aria-label='Call Sage'
                        fontSize='20px'
                        icon={<RiDeleteBinLine />}
                        onClick={() => {

                            dispatch(deleteTeacherAssistantRequest(data?.id))
                        }}
                    />

                )
            };
        })
    const columns = [
        {
            Header: 'الاسم',
            accessor: 'title'
        },
        {
            Header: 'المجموعة',
            accessor: 'group'
        },

        // {
        //     Header: 'تعديل',
        //     accessor: 'edit'
        // },
        // {
        //     Header: 'حذف',
        //     accessor: 'delete'
        // }
    ];


    return (
        <CTable
            selectedData={viewData}
            Columns={columns}
            Data={Data}
            path={'openModel'}
            Actions
            Title={``}
            subTitle=''
            btnTitle=""
            placeHolder=""
            noSearchBar={true}
            noFilter={true}
            footerBtnTitle
            filterList
            addButton
            addButtonTitle=""
            setPage={setPage}
            setPerPage={setPerPage}
            currentpage={pageNumber}
            setPageNumber={setPageNumber}
            noSecondSearchBar={true}
            isLoading={isLoading}
            perPage={size}
            totalPage={Math.ceil(teacherAssistant?.length / 10)}

        />
    )
}

export default TeachersAssistantTable