import { toast } from "react-toastify";
import { call, put, takeLatest } from "redux-saga/effects";
import * as actions from "./Actions";
import * as api from "./Api";
import Types from "./Types";

function* getAllStudents(formData) {
  try {
    yield put(actions.addStudentLoading(true));
    const result = yield call(api.getAllStudents, formData);
    yield put(actions.getAllStudentsSuccess(result.data));
  } catch (error) {
    toast.error(error);
    yield put(actions.getAllStudentsFail());
  } finally {
    yield put(actions.addStudentLoading(false));
  }
}

function* clearSession({ payload }) {
  try {
    yield put(actions.addStudentLoading(true));
    const result = yield call(api.clearSession, payload);
    toast.success("session cleared");

    yield put(actions.clearSessionSuccess(result.data));
  } catch (error) {
    toast.error(error);
    yield put(actions.clearSessionFail());
  } finally {
    yield put(actions.addStudentLoading(false));
  }
}

//Edit Tables
function* editStudent({ payload }) {
  try {
    yield put(actions.addStudentLoading(true));
    const result = yield call(api.editStudents, payload);
    yield put(actions.editStudentSuccess(result.data));
    toast.success("Edit Successfully");
    payload?.action && payload?.action();
  } catch (error) {
    yield put(actions.editStudentFail());
  } finally {
    yield put(actions.addStudentLoading(false));
  }
}

// create
function* createStudent(formData) {
  try {
    yield put(actions.addStudentLoading(true));
    const result = yield call(api.createStudents, formData.payload);
    yield put(
      actions.createStudentSuccess({
        data: result.data,
      })
    );
    toast.success("Created Successfully");
    formData?.action && formData?.action(result?.data?.data);
  } catch (error) {
    yield put(actions.createStudentFail());
  } finally {
    yield put(actions.addStudentLoading(false));
  }
}

function* studentReport({ payload }) {
  try {
    yield put(actions.suspendLoading(true));
    const result = yield call(api.getStudentReport, payload);

    yield put(actions.getStudentReportSuccess({ data: result?.data }));
  } catch (error) {
    toast.error(error.response);
    yield put(actions.getStudentReportFail());
  } finally {
    yield put(actions.suspendLoading(false));
  }
}

function* deleteStudent({ payload }) {
  try {
    yield put(actions.suspendLoading(true));
    const result = yield call(api.deleteStudent, payload);

    yield put(actions.deleteStudentSuccess({ payload, result }));

    toast.success("Deleted Successfully");
  } catch (error) {
    toast.error(error.response);
    yield put(actions.deleteStudentFail());
  } finally {
    yield put(actions.suspendLoading(false));
  }
}

function* getStudent({ payload }) {
  try {
    yield put(actions.suspendLoading(true));
    const result = yield call(api.getStudent, payload);

    yield put(actions.getStudentSuccess(result.data));
  } catch (error) {
    toast.error(error.response);
    yield put(actions.getStudentFail());
  } finally {
    yield put(actions.suspendLoading(false));
  }
}

function* restrictStudent({ payload }) {
  try {
    yield put(actions.restrictLoading(true));
    const result = yield call(api.restricStudent, payload);

    yield put(actions.restrictStudentSuccess({ payload, result }));

    toast.success("Restricted Successfully");
  } catch (error) {
    toast.error(error.response);
    yield put(actions.restrictStudentFail());
  } finally {
    yield put(actions.restrictLoading(false));
  }
}

function* addBulkStudent({ payload }) {
  try {
    yield put(actions.addStudentLoading(true));
    yield call(api.addBulkStudent, payload);
    yield put(actions.addBulkStudentSuccess(payload));
    toast.success("Students Added Successfully");
  } catch (error) {
    toast.error("Something Wrong");
    yield put(actions.addBulkStudentFail());
  } finally {
    yield put(actions.addStudentLoading(false));
  }
}

function* studentsParentReport({ payload }) {
  try {
    yield put(actions.addStudentLoading(true));
    yield call(api.studentsParentReports(payload));
    toast.success("Students Report sent Successfully");
  } catch (error) {
    toast.error("Something Wrong");
  } finally {
    yield put(actions.addStudentLoading(false));
  }
}

export default function* StudentSaga() {
  yield takeLatest(Types.GET_STUDENTS_REQUEST, getAllStudents);
  yield takeLatest(Types.EDIT_STUDENT_REQUEST, editStudent);
  yield takeLatest(Types.CREATE_STUDENT_REQUEST, createStudent);
  yield takeLatest(Types.DELETE_STUDENT_REQUEST, deleteStudent);
  yield takeLatest(Types.ADD_BULK_STUDENT_REQUEST, addBulkStudent);
  yield takeLatest(Types.RESTRICT_STUDENT_REQUEST, restrictStudent);
  yield takeLatest(Types.CLEAR_SESSION_REQUEST, clearSession);
  yield takeLatest(Types.GET_STUDENT_REQUEST, getStudent);
  yield takeLatest(Types.GET_STUDENT_REPORT_REQUEST, studentReport);
  yield takeLatest(Types.STUDENTS_PARENT_REPORT_REQUEST, studentsParentReport);
}
