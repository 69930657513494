import { Box, Button, Container, Flex, Grid, HStack, IconButton, Image, Text } from '@chakra-ui/react'
import React, { useState } from 'react'
import MobileNavbar from './mobileNavbar'
import { Link } from "react-router-dom";
import NavLinks from './navLinks';

const Navbar = ({ color, bg, register }) => {
    const token = true
    window.onscroll = function () { scrollFn() };
    function scrollFn() {
        if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
            document.getElementById("navbar").style.backgroundColor = "white";
            document.getElementById("navbar").style.color = "#010038";
            document.getElementById("logo").src = require("../../../assets/colorLogo.webp");
        } else {
            document.getElementById("navbar").style.backgroundColor = "";
            document.getElementById("navbar").style.color = color;
            document.getElementById("logo").src = bg == 'white' ? require('../../../assets/colorLogo.webp') : require("../../../assets/whiteLogo.webp");



        }
    }

    return (
        <Box w='100%' position={'fixed'} zIndex='100' top='0' color={color} bg={bg} id='navbar' py='20px'>
            <Container maxW={'container.xl'} w='100%'>
                <HStack alignItems='center' p='5px' w='100%' justifyContent={'space-between'} textTransform={'capitalize'}>
                    <Box >
                        <Image src={bg == 'white' ? require('../../../assets/colorLogo.webp') : require("../../../assets/whiteLogo.webp")} w='100px' id='logo' />
                    </Box>
                    <HStack spacing='30px' display={{ base: 'none', lg: 'flex' }} fontSize='16px' fontWeight='400' >
                        <NavLinks />
                    </HStack>

                    <Flex display={{ base: 'none', lg: 'flex' }} visibility={register ? 'visible' : 'hidden'} justifyContent='space-between'>
                        <Link to='#register' style={{ width: '40%' }}>
                            <Button color='main_1' bg='white' mx='10px' w='100%' textTransform={'capitalize'}>
                                register
                            </Button>
                        </Link>
                        <Link to='/login' style={{ width: '40%' }}>
                            <Button bg='main_2' w='100%' textTransform={'capitalize'}>
                                login
                            </Button>
                        </Link>
                    </Flex>

                    <Box display={{ base: 'block', lg: 'none' }} >
                        <MobileNavbar />
                    </Box>
                </HStack >
            </Container>
        </Box>

    )
}

export default Navbar