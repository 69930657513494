import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../../components/dashboard/Layout";
import SearchBar from "../../../containers/admin/sections/searchbar";
import SectionsTable from "../../../containers/admin/sections/table";
import AddSection from "../../../containers/admin/sections/models/add-section";
import { getAllAdminSectionsRequest } from "../../../modules/admin-modules/sections/Actions";

const Sections = () => {
  const dispatch = useDispatch();
  const [isAdd, setAdd] = useState(false);
  const [selectedData, setSelectedData] = useState({});

  useEffect(() => {
    dispatch(getAllAdminSectionsRequest());
  }, []);

  return (
    <Layout>
      <SearchBar setAdd={setAdd} setSelectedData={setSelectedData} />
      <SectionsTable setAdd={setAdd} setSelectedData={setSelectedData} />
      <AddSection
        isAdd={isAdd}
        data={selectedData}
        setSelectedData={setSelectedData}
      />
    </Layout>
  );
};

export default Sections;
