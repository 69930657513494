import { Button, Flex, HStack, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { RiAddFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import LogoutMenu from "../../../components/dashboard/Layout/logout-menu";
import { openAddHomeworkExamData, openAddHomeworkModel } from "../../../modules/admin-modules/models/Actions";

const SearchBar = ({ setAdd }) => {
  const dispatch = useDispatch();
  const count = useSelector((state) => state.homework.count);

  const colourStyles = {
    control: (styles) => ({
      ...styles,
      width: "100%",
      background: "white",
      color: "#690000",
      borderRadius: "16px",
    }),
    option: (styles) => ({ ...styles, color: "#690000" }),
  };
  return (
    <VStack w="100%" my="30px">
      <Flex alignItems="center" w="100%" justifyContent="space-between">
        <Text fontSize="24px" fontWeight="600" color="main_1">
          الواجبات
        </Text>

        <HStack spacing="7px">
          <Button
            borderRadius="16px"
            leftIcon={<RiAddFill />}
            color="white"
            bg="main_1"
            _focus={{ outline: "none" }}
            onClick={() => {
              setAdd(true);
              dispatch(openAddHomeworkModel());
            }}
          >
            إضافة واجب
          </Button>
          <Button
            borderRadius="16px"
            leftIcon={<RiAddFill />}
            color="white"
            bg="main_1"
            _focus={{ outline: "none" }}
            onClick={() => {
              setAdd(true);
              dispatch(openAddHomeworkExamData());
            }}
          >
            إضافة Quizz
          </Button>
          <LogoutMenu />
        </HStack>
      </Flex>

      <Text w="100%" fontSize="20px" textAlign="right">
        عدد الواجبات :{count}{" "}
      </Text>
    </VStack>
  );
};

export default SearchBar;
