import { Box, Button, Flex, HStack, InputGroup, Input, InputLeftElement, MenuItem, MenuList, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import { RiAddFill, RiFileExcel2Line, RiSearch2Fill } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux'
import LogoutMenu from '../../../components/dashboard/Layout/logout-menu'
import { openCreateGroup } from '../../../modules/admin-modules/models/Actions'

const SearchBar = ({ setAdd, setSelectedData }) => {
    const dispatch = useDispatch()
    const count = useSelector((state) => state.groups.count)

    return (
        <VStack w='100%' my='30px'>

            <Flex alignItems='center' w='100%' justifyContent='space-between'>
                <Text fontSize='24px'
                    fontWeight='600' color='main_1'>
                    المجموعات
                </Text>

                <Flex alignItems='center'  >
                    <Button mx='7px' borderRadius='16px' leftIcon={<RiAddFill />} color='white' bg='main_1' _focus={{ outline: 'none' }} onClick={() => {
                        setAdd(true)
                        dispatch(openCreateGroup())
                    }
                    }>
                        إضافة مجموعة
                    </Button>
                    <LogoutMenu />
                </Flex>
            </Flex>


            <Text w='100%' fontSize='20px' textAlign='right'>عدد المجموعات :{count}</Text>


        </VStack>



    )
}

export default SearchBar