import Types from "./Types";

export const getAllHomeworksRequest = (body) => ({
  type: Types.GET_HOMEWORKS_REQUEST,
  payload: body,
});

export const getAllHomeworksSuccess = (data) => ({
  type: Types.GET_HOMEWORKS_SUCCESS,
  payload: data,
});

export const getAllHomeworksFail = () => ({
  type: Types.GET_HOMEWORKS_FAIL,
});

export const getAllHomeworksSuperAdminRequest = (body) => ({
  type: Types.GET_HOMEWORKS_SUPER_ADMIN_REQUEST,
  payload: body,
});

export const getAllHomeworksSuperAdminSuccess = (data) => ({
  type: Types.GET_HOMEWORKS_SUPER_ADMIN_SUCCESS,
  payload: data,
});

export const getAllHomeworksSuperAdminFail = () => ({
  type: Types.GET_HOMEWORKS_SUPER_ADMIN_FAIL,
});

export const getStudentHomeworksRequest = (body) => ({
  type: Types.GET_ADMIN_STUDENT_HOMEWORKS_REQUEST,
  payload: body,
});

export const getStudentHomeworksSuccess = (data) => ({
  type: Types.GET_ADMIN_STUDENT_HOMEWORKS_SUCCESS,
  payload: data,
});

export const getStudentHomeworksFail = () => ({
  type: Types.GET_ADMIN_STUDENT_HOMEWORKS_FAIL,
});

export const getStudentHomeworkQuizRequest = (body) => ({
  type: Types.GET_ADMIN_STUDENT_HOMEWORK_QUIZ_REQUEST,
  payload: body,
});

export const getStudentHomeworkQuizSuccess = (data) => ({
  type: Types.GET_ADMIN_STUDENT_HOMEWORK_QUIZ_SUCCESS,
  payload: data,
});

export const getStudentHomeworkQuizFail = () => ({
  type: Types.GET_ADMIN_STUDENT_HOMEWORK_QUIZ_FAIL,
});

export const editHomeworkRequest = (formData, action) => ({
  type: Types.EDIT_HOMEWORK_REQUEST,
  payload: formData,
  action: action,
});

export const editHomeworkSuccess = (data) => ({
  type: Types.EDIT_HOMEWORK_SUCCESS,
  payload: data,
});

export const editHomeworkFail = () => ({
  type: Types.EDIT_HOMEWORK_FAIL,
});

//LOADING
export const addHomeworkLoading = (isLoading) => ({
  type: Types.ADD_HOMEWORKS_LOADING,
  payload: isLoading,
});

// Create Homework
export const createHomeworkRequest = (formData, action) => ({
  type: Types.CREATE_HOMEWORK_REQUEST,
  payload: formData,
  action: action,
});

export const createHomeworkSuccess = ({ data }) => ({
  type: Types.CREATE_HOMEWORK_SUCCESS,
  payload: {
    data,
  },
});

export const createHomeworkFail = () => ({
  type: Types.CREATE_HOMEWORK_FAIL,
});

export const deleteHomeworkRequest = (id) => ({
  type: Types.DELETE_HOMEWORK_REQUEST,
  payload: id,
});

export const deleteHomeworkSuccess = (id) => ({
  type: Types.DELETE_HOMEWORK_SUCCESS,
  payload: id,
});

export const deleteHomeworkFail = () => ({
  type: Types.DELETE_HOMEWORK_FAIL,
});

export const publishHomeworkRequest = (id) => ({
  type: Types.PUBLISH_HOMEWORK_REQUEST,
  payload: id,
});

export const publishHomeworkSuccess = (id) => ({
  type: Types.PUBLISH_HOMEWORK_SUCCESS,
  payload: id,
});

export const publishHomeworkFail = () => ({
  type: Types.PUBLISH_HOMEWORK_FAIL,
});
export const restrictHomeworkRequest = (id) => ({
  type: Types.RESTRICT_HOMEWORK_REQUEST,
  payload: id,
});

export const restrictHomeworkSuccess = (id) => ({
  type: Types.RESTRICT_HOMEWORK_SUCCESS,
  payload: id,
});

export const restrictHomeworkFail = () => ({
  type: Types.RESTRICT_HOMEWORK_FAIL,
});
export const enableHomeworkRequest = (id) => ({
  type: Types.ENABLE_HOMEWORK_REQUEST,
  payload: id,
});

export const enableHomeworkSuccess = (id) => ({
  type: Types.ENABLE_HOMEWORK_SUCCESS,
  payload: id,
});

export const enableHomeworkFail = () => ({
  type: Types.ENABLE_HOMEWORK_FAIL,
});
export const publishHomeworkLoading = (isLoading) => ({
  type: Types.PUBLISH_HOMEWORK_LOADING,
  payload: isLoading,
});
export const enableHomeworkLoading = (isLoading) => ({
  type: Types.ENABLE_HOMEWORK_LOADING,
  payload: isLoading,
});
export const restrictHomeworkLoading = (isLoading) => ({
  type: Types.RESTRICT_HOMEWORK_LOADING,
  payload: isLoading,
});

export const editHomeworkQuestionRequest = (formData, action) => ({
  type: Types.EDIT_HOMEWORK_QUESTION_REQUEST,
  payload: formData,
  action: action,
});

export const editHomeworkQuestionSuccess = (data) => ({
  type: Types.EDIT_HOMEWORK_QUESTION_SUCCESS,
  payload: data,
});

export const editHomeworkQuestionFail = () => ({
  type: Types.EDIT_HOMEWORK_QUESTION_FAIL,
});

export const filterHomeworkRequest = (id) => ({
  type: Types.FILTER_HOMEWORK_REQUEST,
  payload: id,
});

export const filterHomeworkSuccess = (id) => ({
  type: Types.FILTER_HOMEWORK_SUCCESS,
  payload: id,
});

export const filterHomeworkFail = () => ({
  type: Types.FILTER_HOMEWORK_FAIL,
});
