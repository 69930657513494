import { toast } from "react-toastify";
import { call, fork, put, takeLatest } from "redux-saga/effects";
import * as actions from "./Actions";
import * as api from "./Api";
import Types from "./Types";

function* getAllRequests({ payload }) {
  try {
    yield put(actions.addRequestLoading(true));
    const result = yield call(api.getAllRequests);
    yield put(actions.getAllRequestsSuccess(result.data));
  } catch (error) {
    const { response, message } = error;
    toast.error(error);
    //console.log(error, 'error');
    yield put(actions.getAllRequestsFail());
  } finally {
    yield put(actions.addRequestLoading(false));
  }
}

//Edit Tables
function* editRequest({ payload }) {
  try {
    yield put(actions.addRequestLoading(true));
    const result = yield call(api.editRequests, payload);
    yield put(actions.editRequestSuccess(payload.id));
    toast.success("Edit Successfully");
    payload?.action && payload?.action();
  } catch (error) {
    yield put(actions.editRequestFail());
  } finally {
    yield put(actions.addRequestLoading(false));
  }
}

function* acceptRequestWithoutVoucherRequest({ payload }) {
  try {
    yield put(actions.addRequestLoading(true));
    const result = yield call(api.acceptRequestWithoutVoucher, payload);
    yield put(actions.acceptRequestWithoutVoucherSuccess(payload));
    toast.success("Edit Successfully");
  } catch (error) {
    yield put(actions.acceptRequestWithoutVoucherFail());
  } finally {
    yield put(actions.addRequestLoading(false));
  }
}

function* deleteRequest({ payload }) {
  try {
    yield put(actions.addRequestLoading(true));
    const result = yield call(api.deleteRequest, payload);

    yield put(actions.deleteRequestSuccess(payload));

    toast.success("Deleted Successfully");
  } catch (error) {
    const { response, message } = error;
    toast.error(error.response);
    yield put(actions.deleteRequestFail());
  } finally {
    yield put(actions.addRequestLoading(false));
  }
}

export default function* RequestSaga() {
  yield takeLatest(Types.GET_REQUESTS_REQUEST, getAllRequests);
  yield takeLatest(Types.EDIT_REQUEST_REQUEST, editRequest);
  yield takeLatest(Types.DELETE_REQUEST_REQUEST, deleteRequest);
  yield takeLatest(
    Types.ACCEPT_REQUEST_WITHOUT_VOUCHER_REQUEST,
    acceptRequestWithoutVoucherRequest
  );
}
