import Types from './Types';

export const loginUserAction = (formData) => ({
    type: Types.LOGIN_USER,
    payload: formData
});

export const loginUserSuccessAction = (user, token) => ({
    type: Types.LOGIN_USER_SUCCESS,
    payload: { user, token }
});

export const userVoucherAction = (formData) => ({
    type: Types.USER_VOUCHER,
    payload: formData
});

export const userVoucherSuccessAction = (user, token) => ({
    type: Types.USER_VOUCHER_SUCCESS,
    payload: { user, token }
});

export const logoutUserAction = () => ({
    type: Types.LOGOUT_USER
});



export const logoutUserSuccessAction = () => ({
    type: Types.LOGOUT_USER_SUCCESS
});

export const setAuthIsLoadingAction = (isLoading) => ({
    type: Types.SET_AUTH_IS_LOADING,
    payload: isLoading
});

