import { Flex, Grid, Avatar, Text } from '@chakra-ui/react'
import React from 'react'

const ViewersCard = ({ bgColor, bottom,
    width,
    right, size = 'xs' }) => {
    return (
        <Grid templateColumns="repeat(6, 1fr)" color='main_1' borderRadius='13px'
            boxShadow='0 3px 6px 0 rgba(0, 0, 0, 0.16)'
            backgroundColor={bgColor}
            position='absolute'
            padding='10px'
            borderTopRightRadius='0'
            borderBottomRightRadius='0'
            bottom={bottom}
            width={width}
            right={right}>
            <Avatar
                size={size}
                name='Kola Tioluwani'
                src='https://bit.ly/kent-c-dodds'
            />
            <Avatar
                size={size}
                name='Kola Tioluwani'
                src='https://bit.ly/kent-c-dodds'
            />
            <Avatar
                size={size}
                name='Kola Tioluwani'
                src='https://bit.ly/kent-c-dodds'
            />
            <Avatar
                size={size}
                name='Kola Tioluwani'
                src='https://bit.ly/kent-c-dodds'
            />
            <Avatar
                size={size}
                name='Kola Tioluwani'
                src='https://bit.ly/kent-c-dodds'
            />
            <Flex fontSize='8px' flexDirection={'column'}>
                <Text>+500</Text>
                <Text>Downloads</Text>
            </Flex>

        </Grid>
    )
}

export default ViewersCard