import { Flex, Image, Text, Box } from '@chakra-ui/react'
import React from 'react'
import ViewersCard from './viewersCard'

const RecordesCard = ({ title, number }) => {
    return (
        <Flex flexDirection={'column'} alignItems='center' justifyContent={'center'} color='main_2' position={'relative'}>
            <Text fontSize={'50px'}
                fontWeight='900'>{number}</Text>
            <Text color='white' fontSize={'25px'}>
                {title}
            </Text>

        </Flex>


    )
}

export default RecordesCard