export default {
    //get all GRADEs
    GET_GRADES_REQUEST: 'GET_GRADES_REQUEST',
    GET_GRADES_SUCCESS: 'GET_GRADES_SUCCESS',
    GET_GRADES_FAIL: 'GET_GRADES_FAIL',

    //edit GRADEs
    EDIT_GRADE_REQUEST: 'EDIT_GRADE_REQUEST',
    EDIT_GRADE_SUCCESS: 'EDIT_GRADE_SUCCESS',
    EDIT_GRADE_FAIL: 'EDIT_GRADE_FAIL',

    //create GRADE
    CREATE_GRADE_REQUEST: 'CREATE_GRADE_REQUEST',
    CREATE_GRADE_SUCCESS: 'CREATE_GRADE_SUCCESS',
    CREATE_GRADE_FAIL: 'CREATE_GRADE_FAIL',

    DELETE_GRADE_REQUEST: 'DELETE_GRADE_REQUEST',
    DELETE_GRADE_SUCCESS: 'DELETE_GRADE_SUCCESS',
    DELETE_GRADE_FAIL: 'DELETE_GRADE_FAIL',

    GET_GRADE_REQUEST: 'GET_GRADE_REQUEST',
    GET_GRADE_SUCCESS: 'GET_GRADE_SUCCESS',
    GET_GRADE_FAIL: 'GET_GRADE_FAIL',

    //loading
    ADD_GRADES_LOADING: 'ADD_GRADES_LOADING'
};
