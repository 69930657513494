import axiosInstance from "../../../helpers/api";

export const getAllRequests = (body) => {
    return axiosInstance.get('/admin/redo_exams', body);
};

export const editRequests = (data) => {

    return axiosInstance.post(`/admin/redo_exams/accept`, data);
};




export const deleteRequest = (id) => {
    return axiosInstance.get(`/admin/redo_exams/reject/${id}`);
};
