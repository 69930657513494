import {
  Box,
  chakra,
  Flex,
  Image,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";

const ExamSolvedQuestions = ({ data, correctionArr, studentAnswer }) => {
  const baseUrl = "https://enoughphysics.com/enoughapi";
  const filteredAnswers = studentAnswer
    ? studentAnswer
    : correctionArr?.find((val) => val?.question_id == data?.id);
  const answersTypes = {
    wrong: { color: "red", msg: "Your Answer" },
    correct: { color: "green", msg: "Correct Answer" },
    normal: "transparent",
  };

  const handelAnswer = (val) => {
    if (filteredAnswers?.student_answer_id == val?.id && val?.right_answer == 1)
      return "correct";
    else if (
      filteredAnswers?.student_answer_id == val?.id &&
      val?.right_answer == 0
    )
      return "wrong";
    else if (val?.right_answer == 1) return "correct";
    else return "normal";
  };

  return (
    <VStack w="100%" spacing="40px">
      {/* question */}
      <Stack
        w="100%"
        flexDirection={{ base: "column", lg: "row" }}
        spacing={{ base: "5%", lg: "0" }}
        p={3}
        border="1px solid black"
        borderRadius="10px"
      >
        <VStack w={"100%"} alignItems="center" mr="20px">
          {data?.comprehension && (
            <Box textAlign="center" w="100%">
              {" "}
              <chakra.span
                fontSize="18px"
                fontWeight="600"
                textDecoration="underline"
              >
                Comprehension
              </chakra.span>{" "}
              <Text fontSize="20px">{data?.comprehension}</Text>
            </Box>
          )}
          <Box textAlign="center" w="100%">
            <chakra.span
              fontSize="18px"
              fontWeight="600"
              textDecoration="underline"
            >
              Question
            </chakra.span>{" "}
            <Text fontSize="20px">
              <div dangerouslySetInnerHTML={{ __html: data?.question }} />
            </Text>
          </Box>
        </VStack>

        {data?.image && (
          <Image
            src={`${baseUrl}/${data?.image}`}
            // "https://bookment.s3.eu-central-1.amazonaws.com/b2social/0b2a8c1c-f31b-4461-b373-38b3546680f7.jpeg"                    //{`${baseUrl}/${data?.image}`}
            w={{ base: "100%", lg: "30%" }}
          />
        )}
      </Stack>

      {/* answers */}

      <Stack
        role="group"
        aria-labelledby="my-radio-group"
        spacing={"10px"}
        alignItems="flex-start"
        direction={{ base: "column", lg: "row" }}
        flexWrap="wrap"
        w="100%"
      >
        {data?.answers?.map((val, index) => {
          return (
            <Flex
              w={{ base: "100%", lg: "45%" }}
              my="20px !important"
              alignItems="center"
              p="10px"
              borderRadius="8px"
              bg={answersTypes[handelAnswer(val)]?.color}
              color={handelAnswer(val) == "normal" ? "black" : "white"}
            >
              <Text mx="5px" fontSize="20px" mt="-5px">
                {`${val?.answer}   ${
                  answersTypes[handelAnswer(val)]?.msg
                    ? `[${answersTypes[handelAnswer(val)]?.msg}]`
                    : ""
                }`}{" "}
              </Text>
            </Flex>
          );
        })}
      </Stack>
    </VStack>
  );
};

export default ExamSolvedQuestions;
