import React, { useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Heading,
  Input,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { closeUserDataModel } from "../../../modules/admin-modules/models/Actions";
import MainModel from "../../../components/Models/main-model";
import QRCode from "qrcode.react";

import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from "@chakra-ui/react";
const UserData = ({ isAdd, data }) => {
  const userData = useSelector((state) => state.user.userData);
  const allGroups = useSelector((state) => state.performance.allGroups);

  const dispatch = useDispatch();

  const header = (
    <Box>
      <Heading textAlign="center" color="main_1" fontSize="24px">
        بيانات الطالب
      </Heading>
    </Box>
  );

  const openUserData = useSelector((state) => state.model.isOpen13);

  const closeFunc = () => {
    dispatch(closeUserDataModel());
  };
  return (
    <MainModel
      header={header}
      closeFunc={closeFunc}
      openVar={openUserData}
      buttonTitle={null}
      buttonFunc={null}
    >
      <TableContainer>
        <Table variant="striped">
          <Tbody>
            <Tr>
              <Td>User Name</Td>
              <Td>{userData?.name}</Td>
            </Tr>
            <Tr>
              <Td>Subject</Td>
              <Td>{userData?.subject?.name}</Td>
            </Tr>
            <Tr>
              <Td>Grade</Td>
              <Td>{userData?.grade?.name}</Td>
            </Tr>
            <Tr>
              <Td>Group</Td>
              <Td>{userData?.group?.name}</Td>
            </Tr>

            <Tr>
              <Td>Performance status</Td>
              <Td
                color={
                  allGroups?.msg == "not top performance" ? "red" : "green"
                }
              >
                {allGroups?.msg}
              </Td>
            </Tr>

            <Tr>
              <Td>كود الحضور</Td>

              <Td>
                <QRCode
                  size={200}
                  value={`https://enoughphysics.com/scan-code/${userData?.id}/attendence`}
                />
                <Text my={4} textAlign="center" width="200px" fontWeight="bold">
                  {userData?.subject?.name}
                </Text>
                <Text my={4} textAlign="center" width="200px" fontWeight="bold">
                  {userData?.name}
                </Text>
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </MainModel>
  );
};

export default UserData;
