import axiosInstance from "../../../helpers/api";

export const getBookmarkedQuestions = (id) => {
  return axiosInstance.get(`/students/questions/book_marked`);
};

export const getMissedQuestions = (body) => {
  console.log(body);
  return axiosInstance.get(
    `/students/questions/missed_questions/${body?.type}`
  );
};
