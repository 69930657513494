import Types from "./Types";

const INITIAL_STATE = {
  payments: [],
  paymentException: [],
  payment: {},
  isLoading: false,
  count: "",
};

export default function payments(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_PAYMENTS_SUCCESS: {
      return {
        ...state,
        payments: payload.data,
        count: payload.data.length,
      };
    }
    case Types.GET_PAYMENT_EXCEPTION_SUCCESS: {
      return {
        ...state,
        paymentException: payload.data,
        count: payload.data.length,
      };
    }
    case Types.GET_PAYMENT_SUCCESS: {
      return {
        ...state,
        payment: payload,
      };
    }

    case Types.EDIT_PAYMENT_SUCCESS: {
      let data = payload.data;
      const payments = state.payments;
      let idx = 0;
      payments.forEach((table, index) => {
        if (table.id === data.id) {
          idx = index;
          return;
        }
      });

      payments[idx] = data;
      return {
        ...state,
        payments: payments,
      };
    }

    // create
    case Types.CREATE_PAYMENT_SUCCESS: {
      const data = payload.data.data;
      let newArray = state.payments.slice();
      let index = state.payments.length + 1;
      newArray.splice(index, 0, data);
      return {
        ...state,
        payments: newArray,
      };
    }

    case Types.CREATE_VOUCHER_SUCCESS: {
      return {
        ...state,
      };
    }
    case Types.ADD_PAYMENTS_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }

    case Types.DELETE_PAYMENT_SUCCESS: {
      const newpayments = state.payments.filter((item) => item.id !== payload);
      return {
        ...state,
        payments: newpayments,
      };
    }
    default: {
      return state;
    }
  }
}
