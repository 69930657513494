import Types from "./Types";

export const getAllChaptersRequest = (body) => ({
  type: Types.GET_CHAPTERS_REQUEST,
  payload: body,
});

export const getAllChaptersSuccess = (data) => ({
  type: Types.GET_CHAPTERS_SUCCESS,
  payload: data,
});

export const getAllChaptersFail = () => ({
  type: Types.GET_CHAPTERS_FAIL,
});

export const editChapterRequest = (formData, action) => ({
  type: Types.EDIT_CHAPTER_REQUEST,
  payload: formData,
  action: action,
});

export const editChapterSuccess = (data) => ({
  type: Types.EDIT_CHAPTER_SUCCESS,
  payload: data,
});

export const editChapterFail = () => ({
  type: Types.EDIT_CHAPTER_FAIL,
});

export const restrictChapterRequest = (formData, action) => ({
  type: Types.RESTRICT_CHAPTER_REQUEST,
  payload: formData,
  action: action,
});

export const restrictChapterSuccess = (data) => ({
  type: Types.RESTRICT_CHAPTER_SUCCESS,
  payload: data,
});

export const restrictChapterFail = () => ({
  type: Types.RESTRICT_CHAPTER_FAIL,
});
export const asqueraChapterRequest = (formData, action) => ({
  type: Types.ASQUARA_CHAPTER_REQUEST,
  payload: formData,
  action: action,
});

export const asqueraChapterSuccess = (data) => ({
  type: Types.ASQUARA_CHAPTER_SUCCESS,
  payload: data,
});

export const asqueraChapterFail = () => ({
  type: Types.ASQUARA_CHAPTER_FAIL,
});
export const activeChapterRequest = (formData, action) => ({
  type: Types.ACTIVE_CHAPTER_REQUEST,
  payload: formData,
  action: action,
});

export const activeChapterSuccess = (data) => ({
  type: Types.ACTIVE_CHAPTER_SUCCESS,
  payload: data,
});

export const activeChapterFail = () => ({
  type: Types.ACTIVE_CHAPTER_FAIL,
});
//LOADING
export const addChapterLoading = (isLoading) => ({
  type: Types.ADD_CHAPTERS_LOADING,
  payload: isLoading,
});

// Create Chapter
export const createChapterRequest = (formData, action) => ({
  type: Types.CREATE_CHAPTER_REQUEST,
  payload: formData,
  action: action,
});

export const createChapterSuccess = ({ data }) => ({
  type: Types.CREATE_CHAPTER_SUCCESS,
  payload: {
    data,
  },
});

export const createChapterFail = () => ({
  type: Types.CREATE_CHAPTER_FAIL,
});

export const deleteChapterRequest = (id) => ({
  type: Types.DELETE_CHAPTER_REQUEST,
  payload: id,
});

export const deleteChapterSuccess = (id) => ({
  type: Types.DELETE_CHAPTER_SUCCESS,
  payload: id,
});

export const deleteChapterFail = () => ({
  type: Types.DELETE_CHAPTER_FAIL,
});
