import Types from "./Types";
export const getAllSystemGroupsRequest = (body) => ({
  type: Types.GET_SYSTEM_GROUPS_REQUEST,
  payload: body,
});

export const getAllSystemGroupsSuccess = (data) => ({
  type: Types.GET_SYSTEM_GROUPS_SUCCESS,
  payload: data,
});

export const getAllSystemGroupsFail = () => ({
  type: Types.GET_SYSTEM_GROUPS_FAIL,
});

export const getAllGroupsRequest = (body) => ({
  type: Types.GET_GROUPS_REQUEST,
  payload: body,
});

export const getAllGroupsSuccess = (data) => ({
  type: Types.GET_GROUPS_SUCCESS,
  payload: data,
});

export const getAllGroupsFail = () => ({
  type: Types.GET_GROUPS_FAIL,
});
export const getPerformanceGroupsRequest = (body) => ({
  type: Types.GET_PERFORMANCE_GROUPS_REQUEST,
  payload: body,
});

export const getPerformanceGroupsSuccess = (data) => ({
  type: Types.GET_PERFORMANCE_GROUPS_SUCCESS,
  payload: data,
});

export const getPerformanceGroupsFail = () => ({
  type: Types.GET_PERFORMANCE_GROUPS_FAIL,
});

export const editGroupRequest = (formData) => {
  return {
    type: Types.EDIT_GROUP_REQUEST,
    payload: formData,
  };
};

export const editGroupSuccess = (data) => ({
  type: Types.EDIT_GROUP_SUCCESS,
  payload: data,
});

export const editGroupFail = () => ({
  type: Types.EDIT_GROUP_FAIL,
});

//LOADING
export const addGroupLoading = (isLoading) => ({
  type: Types.ADD_GROUPS_LOADING,
  payload: isLoading,
});

// Create Group
export const createGroupRequest = (formData, action) => ({
  type: Types.CREATE_GROUP_REQUEST,
  payload: formData,
  action: action,
});

export const createGroupSuccess = ({ data }) => ({
  type: Types.CREATE_GROUP_SUCCESS,
  payload: {
    data,
  },
});

export const createGroupFail = () => ({
  type: Types.CREATE_GROUP_FAIL,
});

export const deleteGroupRequest = (id) => ({
  type: Types.DELETE_GROUP_REQUEST,
  payload: id,
});

export const deleteGroupSuccess = (id) => ({
  type: Types.DELETE_GROUP_SUCCESS,
  payload: id,
});

export const deleteGroupFail = () => ({
  type: Types.DELETE_GROUP_FAIL,
});
