import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../../components/dashboard/Layout";
import AddPayment from "../../../containers/admin/payment/models/add-payment";
import SearchBar from "../../../containers/admin/payment/searchbar";
import PaymentTable from "../../../containers/admin/payment/table";
import { getAllPaymentsRequest } from "../../../modules/admin-modules/payment/Actions";
import { getAllStudentsRequest } from "../../../modules/admin-modules/students/Actions";

const Payment = () => {
  const dispatch = useDispatch();
  const model = useSelector((state) => state.model);
  const [isAdd, setAdd] = useState(false);
  const [selectedData, setSelectedData] = useState({});
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    const timeId = setTimeout(() => {
      dispatch(getAllStudentsRequest(1, searchTerm));
    }, 200);

    return () => {
      clearTimeout(timeId);
    };
  }, [searchTerm]);

  return (
    <Layout>
      <SearchBar
        setAdd={setAdd}
        setSelectedData={setSelectedData}
        setSearchTerm={setSearchTerm}
      />
      <PaymentTable setAdd={setAdd} setSelectedData={setSelectedData} />
      <AddPayment
        isAdd={isAdd}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
      />
    </Layout>
  );
};

export default Payment;
