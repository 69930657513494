import Types from './Types';


export const getTopPerformancePerGroupRequest = () => ({
    type: Types.GET_TOP_PERFORMANCE_PER_GROUP_REQUEST,
});

export const getTopPerformancePerGroupSuccess = (data) => ({
    type: Types.GET_TOP_PERFORMANCE_PER_GROUP_SUCCESS,
    payload: data
});

export const getTopPerformancePerGroupFail = () => ({
    type: Types.GET_TOP_PERFORMANCE_PER_GROUP_FAIL
});


export const getTopPerformanceAllGroupspRequest = () => ({
    type: Types.GET_TOP_PERFORMANCE_ALL_GROUPS_REQUEST,
});

export const getTopPerformanceAllGroupspSuccess = (data) => ({
    type: Types.GET_TOP_PERFORMANCE_ALL_GROUPS_SUCCESS,
    payload: data
});

export const getTopPerformanceAllGroupspFail = () => ({
    type: Types.GET_TOP_PERFORMANCE_ALL_GROUPS_FAIL
});


export const getAnswersAnalyticsRequest = () => ({
    type: Types.GET_ANSWERS_ANALYTICS_REQUEST,
});

export const getAnswersAnalyticsSuccess = (data) => ({
    type: Types.GET_ANSWERS_ANALYTICS_SUCCESS,
    payload: data
});

export const getAnswersAnalyticsFail = () => ({
    type: Types.GET_ANSWERS_ANALYTICS_FAIL
});




//LOADING
export const performanceLoading = (isLoading) => ({
    type: Types.PERFORMANCE_LOADING,
    payload: isLoading
});

