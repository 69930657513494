export default {
  //get all CHAPTERs
  GET_ADMIN_SECTIONS_REQUEST: "GET_ADMIN_SECTIONS_REQUEST",
  GET_ADMIN_SECTIONS_SUCCESS: "GET_ADMIN_SECTIONS_SUCCESS",
  GET_ADMIN_SECTIONS_FAIL: "GET_ADMIN_SECTIONS_FAIL",

  //edit CHAPTERs
  EDIT_ADMIN_SECTION_REQUEST: "EDIT_ADMIN_SECTION_REQUEST",
  EDIT_ADMIN_SECTION_SUCCESS: "EDIT_ADMIN_SECTION_SUCCESS",
  EDIT_ADMIN_SECTION_FAIL: "EDIT_ADMIN_SECTION_FAIL",

  RESTRICT_ADMIN_SECTION_REQUEST: "RESTRICT_ADMIN_SECTION_REQUEST",
  RESTRICT_ADMIN_SECTION_SUCCESS: "RESTRICT_ADMIN_SECTION_SUCCESS",
  RESTRICT_ADMIN_SECTION_FAIL: "RESTRICT_ADMIN_SECTION_FAIL",

  ASQUARA_ADMIN_SECTION_REQUEST: "ASQUARA_ADMIN_SECTION_REQUEST",
  ASQUARA_ADMIN_SECTION_SUCCESS: "ASQUARA_ADMIN_SECTION_SUCCESS",
  ASQUARA_ADMIN_SECTION_FAIL: "ASQUARA_ADMIN_SECTION_FAIL",
  ACTIVE_ADMIN_SECTION_REQUEST: "ACTIVE_ADMIN_SECTION_REQUEST",
  ACTIVE_ADMIN_SECTION_SUCCESS: "ACTIVE_ADMIN_SECTION_SUCCESS",
  ACTIVE_ADMIN_SECTION_FAIL: "ACTIVE_ADMIN_SECTION_FAIL",

  //create CHAPTER
  CREATE_ADMIN_SECTION_REQUEST: "CREATE_ADMIN_SECTION_REQUEST",
  CREATE_ADMIN_SECTION_SUCCESS: "CREATE_ADMIN_SECTION_SUCCESS",
  CREATE_ADMIN_SECTION_FAIL: "CREATE_ADMIN_SECTION_FAIL",

  DELETE_ADMIN_SECTION_REQUEST: "DELETE_ADMIN_SECTION_REQUEST",
  DELETE_ADMIN_SECTION_SUCCESS: "DELETE_ADMIN_SECTION_SUCCESS",
  DELETE_ADMIN_SECTION_FAIL: "DELETE_ADMIN_SECTION_FAIL",

  GET_ADMIN_SECTION_REQUEST: "GET_ADMIN_SECTION_REQUEST",
  GET_ADMIN_SECTION_SUCCESS: "GET_ADMIN_SECTION_SUCCESS",
  GET_ADMIN_SECTION_FAIL: "GET_ADMIN_SECTION_FAIL",

  //loading
  ADD_ADMIN_SECTIONS_LOADING: "ADD_ADMIN_SECTIONS_LOADING",
};
