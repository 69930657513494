import Types from "./Types";

export const getAllAdminSectionsRequest = (body) => ({
  type: Types.GET_ADMIN_SECTIONS_REQUEST,
  payload: body,
});

export const getAllAdminSectionsSuccess = (data) => ({
  type: Types.GET_ADMIN_SECTIONS_SUCCESS,
  payload: data,
});

export const getAllAdminSectionsFail = () => ({
  type: Types.GET_ADMIN_SECTIONS_FAIL,
});

export const editAdminSectionRequest = (formData, action) => ({
  type: Types.EDIT_ADMIN_SECTION_REQUEST,
  payload: formData,
  action: action,
});

export const editAdminSectionSuccess = (data) => ({
  type: Types.EDIT_ADMIN_SECTION_SUCCESS,
  payload: data,
});

export const editAdminSectionFail = () => ({
  type: Types.EDIT_ADMIN_SECTION_FAIL,
});

//LOADING
export const addAdminSectionLoading = (isLoading) => ({
  type: Types.ADD_ADMIN_SECTIONS_LOADING,
  payload: isLoading,
});

// Create AdminSection
export const createAdminSectionRequest = (formData, action) => ({
  type: Types.CREATE_ADMIN_SECTION_REQUEST,
  payload: formData,
  action: action,
});

export const createAdminSectionSuccess = ({ data }) => ({
  type: Types.CREATE_ADMIN_SECTION_SUCCESS,
  payload: {
    data,
  },
});

export const createAdminSectionFail = () => ({
  type: Types.CREATE_ADMIN_SECTION_FAIL,
});

export const deleteAdminSectionRequest = (id) => ({
  type: Types.DELETE_ADMIN_SECTION_REQUEST,
  payload: id,
});

export const deleteAdminSectionSuccess = (id) => ({
  type: Types.DELETE_ADMIN_SECTION_SUCCESS,
  payload: id,
});

export const deleteAdminSectionFail = () => ({
  type: Types.DELETE_ADMIN_SECTION_FAIL,
});
