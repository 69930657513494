import { toast } from "react-toastify";
import { call, fork, put, takeLatest } from "redux-saga/effects";
import * as actions from "./Actions";
import * as api from "./Api";
import Types from "./Types";

function* getAllAdminSections({ payload }) {
  try {
    yield put(actions.addAdminSectionLoading(true));
    const result = yield call(api.getAllAdminSections);
    yield put(actions.getAllAdminSectionsSuccess(result.data));
  } catch (error) {
    const { response, message } = error;
    toast.error(error);
    yield put(actions.getAllAdminSectionsFail());
  } finally {
    yield put(actions.addAdminSectionLoading(false));
  }
}

//Edit Tables
function* editAdminSection({ payload }) {
  try {
    yield put(actions.addAdminSectionLoading(true));
    const result = yield call(api.editAdminSections, payload);
    yield put(actions.editAdminSectionSuccess(result.data));
    toast.success("Edit Successfully");
    payload?.action && payload?.action();
  } catch (error) {
    yield put(actions.editAdminSectionFail());
  } finally {
    yield put(actions.addAdminSectionLoading(false));
  }
}

// create
function* createAdminSection(formData) {
  try {
    yield put(actions.addAdminSectionLoading(true));
    const result = yield call(api.createAdminSections, formData.payload);
    yield put(
      actions.createAdminSectionSuccess({
        data: result.data,
      })
    );
    toast.success("Created Successfully");
    formData?.action && formData?.action();
  } catch (error) {
    yield put(actions.createAdminSectionFail());
    toast.success(error.data.message.toString());
  } finally {
    yield put(actions.addAdminSectionLoading(false));
  }
}

function* deleteAdminSection({ payload }) {
  try {
    yield put(actions.addAdminSectionLoading(true));
    const result = yield call(api.deleteAdminSection, payload);

    yield put(actions.deleteAdminSectionSuccess(payload));

    toast.success("Deleted Successfully");
  } catch (error) {
    const { response, message } = error;
    toast.error(error.response);
    yield put(actions.deleteAdminSectionFail());
  } finally {
    yield put(actions.addAdminSectionLoading(false));
  }
}

export default function* AdminSectionSaga() {
  yield takeLatest(Types.GET_ADMIN_SECTIONS_REQUEST, getAllAdminSections);
  yield takeLatest(Types.EDIT_ADMIN_SECTION_REQUEST, editAdminSection);
  yield takeLatest(Types.CREATE_ADMIN_SECTION_REQUEST, createAdminSection);
  yield takeLatest(Types.DELETE_ADMIN_SECTION_REQUEST, deleteAdminSection);
}
