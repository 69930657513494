import { toast } from 'react-toastify';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import * as actions from './Actions';
import * as api from './Api';
import Types from './Types';

function* getAllTeacherAssistants({ payload }) {
    try {
        yield put(actions.addTeacherAssistantLoading(true));
        const result = yield call(api.getAllTeacherAssistants);
        yield put(actions.getAllTeacherAssistantsSuccess(result.data));
    } catch (error) {
        const { response, message } = error;
        toast.error(error);
        yield put(actions.getAllTeacherAssistantsFail());
    } finally {
        yield put(actions.addTeacherAssistantLoading(false));
    }
}



//Edit Tables
function* editTeacherAssistant(data) {
    try {
        yield put(actions.addTeacherAssistantLoading(true));
        const result = yield call(api.editTeacherAssistants, data.payload);
        yield put(actions.editTeacherAssistantSuccess(result.data));
        toast.success('Edit Successfully');
        data?.action && data?.action()
    } catch (error) {
        yield put(actions.editTeacherAssistantFail());
    } finally {
        yield put(actions.addTeacherAssistantLoading(false));
    }
}

// create
function* createTeacherAssistant(formData) {
    try {
        yield put(actions.addTeacherAssistantLoading(true));
        const result = yield call(api.createTeacherAssistants, formData.payload);
        yield put(
            actions.createTeacherAssistantSuccess({
                data: result.data
            })
        );
        toast.success('Created Successfully');
        formData?.action && formData?.action()
    } catch (error) {
        yield put(actions.createTeacherAssistantFail());
    } finally {
        yield put(actions.addTeacherAssistantLoading(false));
    }
}

function* deleteTeacherAssistant({ payload }) {
    try {
        yield put(actions.addTeacherAssistantLoading(true));
        const result = yield call(api.deleteTeacherAssistant, payload);

        yield put(actions.deleteTeacherAssistantSuccess(payload));

        toast.success('Deleted Successfully');
    } catch (error) {
        const { response, message } = error;
        toast.error(error.response);
        yield put(actions.deleteTeacherAssistantFail());
    } finally {
        yield put(actions.addTeacherAssistantLoading(false));
    }
}

export default function* TeacherAssistantSaga() {
    yield takeLatest(Types.GET_TEACHER_ASSISTANTS_REQUEST, getAllTeacherAssistants);
    yield takeLatest(Types.EDIT_TEACHER_ASSISTANT_REQUEST, editTeacherAssistant);
    yield takeLatest(Types.CREATE_TEACHER_ASSISTANT_REQUEST, createTeacherAssistant);
    yield takeLatest(Types.DELETE_TEACHER_ASSISTANT_REQUEST, deleteTeacherAssistant);
}
