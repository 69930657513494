import Types from './Types';

const INITIAL_STATE = {
    events: [],
    event: {},
    isLoading: false,
    count: ''
};

export default function events(state = INITIAL_STATE, action) {
    const { type, payload } = action;
    switch (type) {
        case Types.GET_EVENTS_SUCCESS: {
            return {
                ...state,
                events: payload.data,
                count: payload.data.length
            };
        }

        case Types.GET_EVENT_SUCCESS: {
            return {
                ...state,
                event: payload
            };
        }

        case Types.EDIT_EVENT_SUCCESS: {
            let data = payload.data;
            const events = state.events;
            let idx = 0;
            events.forEach((table, index) => {
                if (table.id === data.id) {
                    idx = index;
                    return;
                }
            });

            events[idx] = data;
            return {
                ...state,
                events: events
            };
        }

        // create
        case Types.CREATE_EVENT_SUCCESS: {
            const data = payload.data.data;
            let newArray = state.events.slice();
            let index = state.events.length + 1;
            newArray.splice(index, 0, data);
            return {
                ...state,
                events: newArray
            };
        }
        case Types.ADD_EVENTS_LOADING: {
            return {
                ...state,
                isLoading: payload
            };
        }

        case Types.DELETE_EVENT_SUCCESS: {
            const newevents = state.events.filter((item) => item.id !== payload);
            return {
                ...state,
                events: newevents
            };
        }
        default: {
            return state;
        }
    }
}
