import Types from './Types';


export const getAllRedoRequestsRequest = (body) => ({
    type: Types.GET_REDO_REQUESTS_REQUEST,
    payload: body
});

export const getAllRedoRequestsSuccess = (data) => ({
    type: Types.GET_REDO_REQUESTS_SUCCESS,
    payload: data
});

export const getAllRedoRequestsFail = () => ({
    type: Types.GET_REDO_REQUESTS_FAIL
});


export const editRedoRequestRequest = (id, action) => ({
    type: Types.EDIT_REDO_REQUEST_REQUEST,
    payload: id,
    action: action
});

export const editRedoRequestSuccess = (data) => ({
    type: Types.EDIT_REDO_REQUEST_SUCCESS,
    payload: data
});

export const editRedoRequestFail = () => ({
    type: Types.EDIT_REDO_REQUEST_FAIL
});

//LOADING
export const addRedoRequestLoading = (isLoading) => ({
    type: Types.ADD_REDO_REQUESTS_LOADING,
    payload: isLoading
});



export const deleteRedoRequestRequest = (id) => ({
    type: Types.DELETE_REDO_REQUEST_REQUEST,
    payload: id
});

export const deleteRedoRequestSuccess = (id) => ({
    type: Types.DELETE_REDO_REQUEST_SUCCESS,
    payload: id
});

export const deleteRedoRequestFail = () => ({
    type: Types.DELETE_REDO_REQUEST_FAIL
});
