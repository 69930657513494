import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

const ProtectedRoute = (props) => {
  const { component: Component, ...rest } = props;
  const token = localStorage.getItem("token");
  return (
    <Routes>
      <Route
        {...rest}
        render={(componentProps) =>
          !token && !window.location.pathname.includes("login") ? (
            <Navigate to="/login" replace={true} />
          ) : (
            <Component {...componentProps} />
          )
        }
      />
    </Routes>
  );
};

export default ProtectedRoute;
