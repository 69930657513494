import React, { useEffect } from 'react'
import {
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Box,
    Grid,
    Container,
    Heading,
    VStack
} from '@chakra-ui/react'
import ChapterCard from '../../../components/student/Cards/chapterCard'
import Layout from '../../../components/student/Layout/layout'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getAllStudentChaptersRequest } from '../../../modules/students-modules/chapters/Actions'
import { RiAttachment2 } from 'react-icons/ri'
const ModelAnswersChaptersList = () => {
    const chapters = useSelector((state) => state.studentChapters.studentChapters)
    const isLoading = useSelector((state) => state.studentChapters.isLoading)

    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getAllStudentChaptersRequest())
    }, [])
    return (
        <Layout color='main_1' bg='white' isLoading={isLoading}>
            <Container maxW='container.2xl' h='100%' my='100px' mb='165px' w='100%' >
                <VStack spacing={8} h='100%' w='100%' alignItems='flex-start'>
                    <Heading as='h3' color='main_1' textAlign='center' w='100%'>Model Answers</Heading>
                    <Grid templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }} gap={6} w='100%'>
                        {
                            chapters?.map((chapter, idx) => {
                                return <Link to={`/model-answer/${chapter?.id}?name=${chapter?.name}`} key={idx}>
                                    < ChapterCard chapter={chapter} onClick={() => { }} list={chapter?.lessons} content={chapter?.lessons_count} icon={RiAttachment2} type={'Model answer'} />
                                </Link>
                            })
                        }
                    </Grid>
                </VStack>
            </Container>
        </Layout>
    )
}

export default ModelAnswersChaptersList