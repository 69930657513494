import axiosInstance from "../../../helpers/api";

export const getAllSubjects = (body) => {
    return axiosInstance.get('/admin/subjects', body);
};



export const editSubjects = (formData) => {

    return axiosInstance.put(`/admin/subjects/${formData.id}`, formData.formData);
};


export const createSubjects = (formData) => {
    return axiosInstance.post('/admin/subjects', formData);
};

export const deleteSubject = (id) => {
    return axiosInstance.delete(`/admin/subjects/${id}`);
};
