import React, { useEffect } from "react";
import {
  Box,
  Flex,
  FormLabel,
  Heading,
  Icon,
  Input,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from "@chakra-ui/react";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  closeCreateTeacher,
  closeCreateTeacherAssistant,
} from "../../../../modules/admin-modules/models/Actions";
import MainModel from "../../../../components/Models/main-model";
import { useForm } from "react-hook-form";
import MainInput from "../../../../components/Form/input";
import {
  createTeacherAssistantRequest,
  editTeacherAssistantRequest,
} from "../../../../modules/admin-modules/teachersAssistant/Actions";
import { getAllGroupsRequest } from "../../../../modules/admin-modules/groups/Actions";

const AddTeacherAssistant = ({ isAdd, data, setSelectedData }) => {
  const openTeacherAssistant = useSelector((state) => state.model.isOpen8);
  const dispatch = useDispatch();
  const groups = useSelector((state) => state.groups.groups);
  const isLoading = useSelector((state) => state.teacherassistants.isLoading);

  const groupsOptions = groups?.map((data) => {
    return { value: data.id, label: data.name };
  });
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      width: "100%",
      background: "#f5f5f5",
      color: "#690000",
      borderRadius: "16px",
    }),
    option: (styles) => ({ ...styles, color: "#690000" }),
  };
  useEffect(() => {
    if (openTeacherAssistant) {
      if (groups?.length == 0) dispatch(getAllGroupsRequest());
    }
  }, [openTeacherAssistant]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: data ? data : {},
    shouldUnregister: true,
  });

  const header = (
    <Box>
      <Heading textAlign="center" color="main_1" fontSize="24px">
        {isAdd ? " إضافة معلم مساعد" : "تعديل معلم مساعد"}
      </Heading>
    </Box>
  );
  const onSubmit = (data) => {
    const action = () => {
      dispatch(closeCreateTeacherAssistant());
      setSelectedData({});
      reset();
    };
    if (isAdd) {
      let formData = new FormData();
      for (let key in data) {
        formData.append(key, data[key]);
      }
      dispatch(createTeacherAssistantRequest(formData, action));
    } else {
      let formData = new FormData();
      for (let key in data) {
        formData.append(key, data[key]);
      }
      dispatch(
        editTeacherAssistantRequest({
          id: data?.id,
          formData: formData,
          action: action,
        })
      );
    }
  };

  const closeFunc = () => {
    dispatch(closeCreateTeacherAssistant());
    setSelectedData({});
    reset();
  };
  return (
    <MainModel
      isLoading={isLoading}
      header={header}
      closeFunc={closeFunc}
      openVar={openTeacherAssistant}
      buttonTitle={"اضافة معلم"}
      buttonFunc={handleSubmit(onSubmit)}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={5} dir="rtl" w="100%">
          <Box w="55%">
            <MainInput
              label="اسم المعلم"
              error={errors?.name?.message}
              placeholder="ادخل اسم المعلم"
              register={register}
              name="name"
            />
          </Box>
          <Box w="55%">
            <MainInput
              label="رقم الموبايل"
              error={errors?.phone?.message}
              placeholder="اكتب رقم الموبايل"
              register={register}
              name="phone"
            />
          </Box>
          <Box w="55%">
            <MainInput
              label="كلمة المرور"
              error={errors?.password?.message}
              placeholder="اكتب كلمة المرور"
              register={register}
              name="password"
            />
          </Box>
          <Box w="55%">
            <MainInput
              label="اسم المستخدم"
              error={errors?.user_name?.message}
              placeholder="اكتب اسم المستخدم"
              register={register}
              name="user_name"
            />
          </Box>

          <Box w="55%">
            <FormLabel>المجموعة : </FormLabel>
            <Select
              defaultValue={{
                label: data?.group?.name,
                value: data?.group?.id,
              }}
              options={groupsOptions}
              onChange={(data) => {
                setValue("student_group_id", data.value);
              }}
              styles={colourStyles}
              placeholder="الفصول"
            />
          </Box>

          <Box w="55%">
            <FormLabel>النوع : </FormLabel>
            <Stack
              direction="row"
              role="group"
              aria-labelledby="my-radio-group"
            >
              <label>
                <input
                  {...register("gender", { required: true })}
                  type="radio"
                  value="Female"
                />
                مدرس
              </label>
              <label>
                <input
                  {...register("gender", { required: true })}
                  type="radio"
                  value="Male"
                />
                مدرسة
              </label>
            </Stack>
          </Box>
        </Stack>
      </form>
    </MainModel>
  );
};

export default AddTeacherAssistant;
