import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../../components/dashboard/Layout";
import AddEvent from "../../../containers/admin/events/models/add-events";
import SearchBar from "../../../containers/admin/events/searchbar";
import EventsTable from "../../../containers/admin/events/table";
import { getAllEventsRequest } from "../../../modules/admin-modules/events/Actions";

const Events = () => {
  const dispatch = useDispatch();
  const model = useSelector((state) => state.model);
  const [isAdd, setAdd] = useState(false);
  const [selectedData, setSelectedData] = useState({});

  useEffect(() => {
    dispatch(getAllEventsRequest());
  }, []);

  return (
    <Layout>
      <SearchBar setAdd={setAdd} setSelectedData={setSelectedData} />
      <EventsTable setAdd={setAdd} setSelectedData={setSelectedData} />
      <AddEvent
        isAdd={isAdd}
        data={selectedData}
        setSelectedData={setSelectedData}
      />
    </Layout>
  );
};

export default Events;
