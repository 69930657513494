import Types from './Types';

const INITIAL_STATE = {
    lessons: [],
    lesson: {},
    isLoading: false,
    count: ''
};

export default function lessons(state = INITIAL_STATE, action) {
    const { type, payload } = action;
    switch (type) {
        case Types.GET_LESSONS_SUCCESS: {
            return {
                ...state,
                lessons: payload.data,
                count: payload.data.length
            };
        }

        case Types.GET_LESSON_SUCCESS: {
            return {
                ...state,
                lesson: payload
            };
        }

        case Types.EDIT_LESSON_SUCCESS: {
            let data = payload.data;
            const lessons = state.lessons;
            let idx = 0;
            lessons.forEach((table, index) => {
                if (table.id === data.id) {
                    idx = index;
                    return;
                }
            });

            lessons[idx] = data;
            return {
                ...state,
                lessons: lessons
            };
        }

        // create
        case Types.CREATE_LESSON_SUCCESS: {
            const data = payload.data.data;
            let newArray = state.lessons.slice();
            let index = state.lessons.length + 1;
            newArray.splice(index, 0, data);
            return {
                ...state,
                lessons: newArray
            };
        }
        case Types.ADD_LESSONS_LOADING: {
            return {
                ...state,
                isLoading: payload
            };
        }

        case Types.DELETE_LESSON_SUCCESS: {
            const newlessons = state.lessons.filter((item) => item.id !== payload);
            return {
                ...state,
                lessons: newlessons
            };
        }
        default: {
            return state;
        }
    }
}
