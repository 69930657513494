import axiosInstance from "../../../helpers/api";

export const getAllExams = (id) => {
  return axiosInstance.get(`/students/student_exams`);
};

export const getExamsByChapterID = (id) => {
  return axiosInstance.get(`/students/student_exams/${id}`);
};

export const getChapterExam = () => {
  return axiosInstance.get(`/admin/exams/list/chapter_exams`);
};

export const getChapterExamById = (id) => {
  return axiosInstance.get(
    `/students/student_exams/chapter_exam_questions/${id}`
  );
};
export const getExamsByID = (id) => {
  return axiosInstance.get(`/students/exams/${id}`);
};

export const getExamQuestions = (id) => {
  return axiosInstance.get(`/students/student_exams/get_questions/${id}`);
};

export const getExamCorrection = (id) => {
  return axiosInstance.get(
    `/students/student_exams/get_questions_correction/${id}`
  );
};

export const sendAnswers = (formData) => {
  return axiosInstance.post("/students/student_exams/set_answers", formData);
};

export const sendChapterExamAnswers = (formData) => {
  return axiosInstance.post(
    "/students/student_exams/chapter_exam/set_answer",
    formData
  );
};

export const examRedo = (formData) => {
  return axiosInstance.post("/students/redo_exams", formData);
};
