import Types from "./Types";

export const getAllStudentsRequest = (page, searchTerm) => ({
  type: Types.GET_STUDENTS_REQUEST,
  payload: page,
  searchTerm: searchTerm,
});

export const getAllStudentsSuccess = (data) => ({
  type: Types.GET_STUDENTS_SUCCESS,
  payload: data,
});

export const getAllStudentsFail = () => ({
  type: Types.GET_STUDENTS_FAIL,
});

export const editStudentRequest = (formData, action) => ({
  type: Types.EDIT_STUDENT_REQUEST,
  payload: formData,
  action: action,
});

export const editStudentSuccess = (data) => ({
  type: Types.EDIT_STUDENT_SUCCESS,
  payload: data,
});

export const editStudentFail = () => ({
  type: Types.EDIT_STUDENT_FAIL,
});

//LOADING
export const addStudentLoading = (isLoading) => ({
  type: Types.ADD_STUDENTS_LOADING,
  payload: isLoading,
});

// Create Student
export const createStudentRequest = (formData, action) => ({
  type: Types.CREATE_STUDENT_REQUEST,
  payload: formData,
  action: action,
});

export const createStudentSuccess = ({ data }) => ({
  type: Types.CREATE_STUDENT_SUCCESS,
  payload: {
    data,
  },
});

export const createStudentFail = () => ({
  type: Types.CREATE_STUDENT_FAIL,
});

export const getStudentRequest = (id) => ({
  type: Types.GET_STUDENT_REQUEST,
  payload: id,
});

export const getStudentSuccess = (data) => ({
  type: Types.GET_STUDENT_SUCCESS,
  payload: data,
});

export const getStudentFail = () => ({
  type: Types.GET_STUDENT_FAIL,
});

export const deleteStudentRequest = (id) => ({
  type: Types.DELETE_STUDENT_REQUEST,
  payload: id,
});

export const deleteStudentSuccess = (data) => ({
  type: Types.DELETE_STUDENT_SUCCESS,
  payload: data,
});

export const deleteStudentFail = () => ({
  type: Types.DELETE_STUDENT_FAIL,
});

export const getStudentReportRequest = (id) => ({
  type: Types.GET_STUDENT_REPORT_REQUEST,
  payload: id,
});

export const getStudentReportSuccess = (data) => ({
  type: Types.GET_STUDENT_REPORT_SUCCESS,
  payload: data,
});

export const getStudentReportFail = () => ({
  type: Types.GET_STUDENT_REPORT_FAIL,
});

export const restrictStudentRequest = (data) => ({
  type: Types.RESTRICT_STUDENT_REQUEST,
  payload: data,
});

export const restrictStudentSuccess = (data) => ({
  type: Types.RESTRICT_STUDENT_SUCCESS,
  payload: data,
});

export const restrictStudentFail = () => ({
  type: Types.RESTRICT_STUDENT_FAIL,
});

export const clearSessionRequest = (id) => ({
  type: Types.CLEAR_SESSION_REQUEST,
  payload: id,
});

export const clearSessionSuccess = (data) => ({
  type: Types.CLEAR_SESSION_SUCCESS,
  payload: data,
});

export const clearSessionFail = () => ({
  type: Types.CLEAR_SESSION_FAIL,
});

export const addBulkStudentRequest = (formData) => ({
  type: Types.ADD_BULK_STUDENT_REQUEST,
  payload: formData,
});

export const addBulkStudentSuccess = (id) => ({
  type: Types.ADD_BULK_STUDENT_SUCCESS,
  payload: id,
});

export const addBulkStudentFail = () => ({
  type: Types.ADD_BULK_STUDENT_FAIL,
});

export const exportBulkStudentRequest = (formData) => ({
  type: Types.EXPORT_BULK_STUDENT_REQUEST,
  payload: formData,
});

export const exportBulkStudentSuccess = (id) => ({
  type: Types.EXPORT_BULK_STUDENT_SUCCESS,
  payload: id,
});

export const exportBulkStudentFail = () => ({
  type: Types.EXPORT_BULK_STUDENT_FAIL,
});

export const studentsParentReportRequest = (formData) => ({
  type: Types.STUDENTS_PARENT_REPORT_REQUEST,
  payload: formData,
});

export const studentsParentReportSuccess = (id) => ({
  type: Types.STUDENTS_PARENT_REPORT_SUCCESS,
  payload: id,
});

export const studentsParentReportFail = () => ({
  type: Types.STUDENTS_PARENT_REPORT_FAIL,
});

//LOADING
export const suspendLoading = (isLoading) => ({
  type: Types.SUSPEND_LOADING,
  payload: isLoading,
});

//LOADING
export const restrictLoading = (isLoading) => ({
  type: Types.RESTRICT_LOADING,
  payload: isLoading,
});
