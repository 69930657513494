import { Button, IconButton, Switch } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { RiCheckFill, RiDeleteBinLine, RiFileEditLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import CTable from "../../../components/Table/table";
import {
  deleteExamRequest,
  enableExamRequest,
  publishExamRequest,
  restrictExamRequest,
} from "../../../modules/admin-modules/exams/Actions";
import { openCreateExamDataModel } from "../../../modules/admin-modules/models/Actions";

const ExamsTable = ({ setAdd, setSelectedData }) => {
  const exams = useSelector((state) => state.exams.exams);
  const user = useSelector((state) => state.user.userData);

  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.exams.isLoading);
  const publishLoading = useSelector((state) => state.exams.publishLoading);
  const restrictLoading = useSelector((state) => state.exams.restrictLoading);
  const [userRole, setUserRole] = useState("");

  const [offset, setPage] = useState(0);
  const [size, setPerPage] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [selected, setSelected] = useState({
    id: "",
  });

  useEffect(() => {
    if (user?.role?.length > 0) {
      setUserRole(user?.role[0]?.name);
    }
  }, [user?.role]);
  const viewData = (data) => {
    setSelectedData(data);
    setSelected(data);
  };
  const Data = exams?.map((data) => {
    return {
      ...data,
      id: data?.id,
      title: data?.name,
      publish: (
        <Button
          onClick={() => dispatch(publishExamRequest(data.id))}
          isLoading={data?.id == selected?.id ? publishLoading : false}
          leftIcon={data?.auto_publish_result == 0 ? null : <RiCheckFill />}
          bg={data?.auto_publish_result == 0 ? "main_1" : "#ddffc2"}
          color={data?.auto_publish_result == 0 ? "white" : "black"}
        >
          {data?.auto_publish_result == 0 ? "نشر التصحيح" : "تم النشر"}
        </Button>
      ),
      enable: (
        <Switch
          size="md"
          defaultChecked={data?.enable == 0 ? true : false}
          onChange={() => {
            dispatch(enableExamRequest(data?.id));
          }}
        />
      ),
      restrict:
        userRole != "Teacher" ? (
          <Button
            onClick={() => dispatch(restrictExamRequest(data.id))}
            isLoading={data?.id == selected?.id ? restrictLoading : false}
            bg="main_1"
            color="white"
          >
            Restrict absent students
          </Button>
        ) : (
          <></>
        ),
      edit: (
        <IconButton
          variant="outline"
          border="0"
          color="gray.600"
          aria-label="Call Sage"
          fontSize="20px"
          icon={<RiFileEditLine />}
          onClick={() => {
            setAdd(false);
            dispatch(openCreateExamDataModel());
          }}
        />
      ),
      delete: (
        <IconButton
          variant="outline"
          border="0"
          color="gray.600"
          aria-label="Call Sage"
          fontSize="20px"
          icon={<RiDeleteBinLine color="red" />}
          onClick={() => dispatch(deleteExamRequest(data?.id))}
        />
      ),
    };
  });
  const columns = [
    {
      Header: " الاختبار",
      accessor: "title",
    },
    {
      Header: "المادة",
      accessor: "subject.name_ar",
    },
    {
      Header: "نشر التصحيح",
      accessor: "publish",
    },
    {
      Header: "نشر",

      accessor: "enable",
    },
    {
      Header: userRole != "Teacher" ? "  ايقاف الطلاب  " : "",
      accessor: "restrict",
    },
    {
      Header: "تعديل",
      accessor: "edit",
    },
    {
      Header: "حذف",
      accessor: "delete",
    },
  ];

  return (
    <CTable
      selectedData={viewData}
      Columns={columns}
      Data={Data}
      path={"openModel"}
      Actions
      Title={``}
      subTitle=""
      btnTitle=""
      placeHolder=""
      noSearchBar={true}
      noFilter={true}
      footerBtnTitle
      filterList
      addButton
      addButtonTitle=""
      setPage={setPage}
      setPerPage={setPerPage}
      currentpage={pageNumber}
      setPageNumber={setPageNumber}
      noSecondSearchBar={true}
      isLoading={isLoading}
      perPage={size}
      totalPage={Math.ceil(exams?.length / 10)}
    />
  );
};

export default ExamsTable;
