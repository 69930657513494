import React, { useEffect, useState } from "react";
import { Box, FormLabel, Heading, VStack } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  closePlaylistModel,
  closeTagModel,
} from "../../../../modules/admin-modules/models/Actions";
import MainModel from "../../../../components/Models/main-model";
import { useForm } from "react-hook-form";
import MainInput from "../../../../components/Form/input";
import {
  createPlaylistRequest,
  editPlaylistRequest,
} from "../../../../modules/admin-modules/playlists/Actions";
import { getAllTagsRequest } from "../../../../modules/admin-modules/tags/Actions";
import Select from "react-select";

const AddPlaylist = ({ isAdd, data, setSelectedData }) => {
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      width: "100%",
      background: "#f5f5f5",
      color: "#690000",
      borderRadius: "16px",
    }),
    option: (styles) => ({ ...styles, color: "#690000" }),
  };
  const openPlaylist = useSelector((state) => state.model.isOpen25);
  const tags = useSelector((state) => state.tags.tags);
  const isLoading = useSelector((state) => state.playlists.isLoading);

  const dispatch = useDispatch();

  const [defaultTag, setDefaultTag] = useState(null);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset,
    values,
  } = useForm({
    defaultValues: data ? data : {},
    shouldUnregister: true,
  });
  const tagsOptions = tags?.map((data) => {
    return { value: data.id, label: data.name };
  });
  const header = (
    <Box>
      <Heading textAlign="center" color="main_1" fontSize="24px">
        {isAdd ? "اضافة قائمة التشغيل " : "تعديل قائمة التشغيل"}
      </Heading>
    </Box>
  );
  const onSubmit = (values) => {
    let formData = new FormData();
    formData.append("name", values?.name);
    formData.append(
      "question_tag_id",
      values?.question_tag_id ? values?.question_tag_id : data?.question_tag?.id
    );

    const action = () => {
      dispatch(closePlaylistModel());
      setSelectedData({});
      reset();
      setDefaultTag(null);
    };
    if (isAdd) dispatch(createPlaylistRequest(formData, action));
    else {
      formData.append("_method", "PUT");

      dispatch(
        editPlaylistRequest({
          id: data?.id,
          formData: formData,
          action: action,
        })
      );
    }
  };
  const closeFunc = () => {
    setDefaultTag(null);

    dispatch(closePlaylistModel());
    setSelectedData({});
    reset();
  };

  useEffect(() => {
    if (openPlaylist) {
      dispatch(getAllTagsRequest());
    }
  }, [openPlaylist]);
  useEffect(() => {
    if (!isAdd) {
      const defautTagVal = tags.find((val) => val?.id == data?.question_tag_id);
      console.log(defautTagVal);
      setDefaultTag({ label: defautTagVal?.name, value: defautTagVal?.id });
    }
  }, [tags, isAdd, data]);
  console.log(isAdd);
  return (
    <MainModel
      isLoading={isLoading}
      header={header}
      closeFunc={closeFunc}
      openVar={openPlaylist}
      buttonTitle={" حفظ التغيرات"}
      buttonFunc={handleSubmit(onSubmit)}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <VStack dir="rtl" w="100%" alignItems="flex-start" spacing={4}>
          <MainInput
            label="اسم قائمة التشغيل"
            defaultValues={data?.name}
            error={errors?.name?.message}
            placeholder=" اكتب اسم قائمة التشغيل"
            register={register}
            name="name"
          />
          <Box w="100%">
            <FormLabel>العلامات : </FormLabel>

            <Select
              name="question_tag_id"
              options={tagsOptions}
              onChange={(data) => setValue("question_tag_id", data.value)}
              styles={colourStyles}
              placeholder="العلامات"
              defaultValue={{
                label: data?.question_tag?.name,
                value: data?.question_tag?.id,
              }}
            />
          </Box>
        </VStack>
      </form>
    </MainModel>
  );
};

export default AddPlaylist;
