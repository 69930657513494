import React, { useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Heading,
  Input,
  Text,
  Textarea,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  closeAddSectionkModel,
  closeCreateChapterModel,
} from "../../../../modules/admin-modules/models/Actions";
import MainModel from "../../../../components/Models/main-model";
import { useForm } from "react-hook-form";
import MainInput from "../../../../components/Form/input";
import Select from "react-select";
import { toast } from "react-toastify";
import { useState } from "react";
import {
  createChapterRequest,
  editChapterRequest,
  getAllChaptersRequest,
} from "../../../../modules/admin-modules/chapters/Actions";
import { getAllSubjectsRequest } from "../../../../modules/admin-modules/subjects/Actions";
import { getAllSectionsRequest } from "../../../../modules/students-modules/sections/Actions";
import {
  createAdminSectionRequest,
  editAdminSectionRequest,
} from "../../../../modules/admin-modules/sections/Actions";

const AddSection = ({ isAdd, data, setSelectedData }) => {
  const openChapter = useSelector((state) => state.model.isOpen29);
  const isLoading = useSelector((state) => state.adminSections.isLoading);

  const dispatch = useDispatch();
  useEffect(() => {}, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: !isAdd ? data : {},
    shouldUnregister: true,
  });

  const header = (
    <Box>
      <Heading textAlign="center" color="main_1" fontSize="24px">
        {isAdd ? " إضافة الفصل" : "تعديل  الفصل"}
      </Heading>
    </Box>
  );
  const onSubmit = async (values) => {
    const action = () => {
      dispatch(closeAddSectionkModel());
      setSelectedData({});
      reset();
    };
    if (isAdd) {
      let formData = await new FormData();
      formData.append("name", values?.name);
      console.log(values);

      await dispatch(createAdminSectionRequest(formData, action));
    } else {
      let formData = await new FormData();
      formData.append("_method", "PUT");
      const totel = { ...data, ...values };
      formData.append("name", totel?.name);

      dispatch(
        editAdminSectionRequest({
          id: data?.id,
          formData: formData,
          action: action,
        })
      );
    }
  };
  const closeFunc = () => {
    dispatch(closeAddSectionkModel());
    setSelectedData({});
    reset();
  };
  return (
    <MainModel
      isLoading={isLoading}
      header={header}
      closeFunc={closeFunc}
      openVar={openChapter}
      buttonTitle={" حفظ التغيرات"}
      buttonFunc={handleSubmit(onSubmit)}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex
          dir="rtl"
          w="100%"
          flexWrap="wrap"
          justifyContent="space-between"
          gap="4"
        >
          <Box w="45%">
            <MainInput
              label="اسم القسم "
              defaultValues={data?.name}
              error={errors?.name?.message}
              placeholder="ادخل اسم القسم "
              register={register}
              name="name"
            />
          </Box>
        </Flex>
      </form>
    </MainModel>
  );
};

export default AddSection;
