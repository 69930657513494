import axiosInstance from "../../../helpers/api";

export const getAllTeachers = (body) => {
    return axiosInstance.get('/admin/teachers', body);
};

export const editTeachers = (formData) => {

    return axiosInstance.put(`/admin/teachers/${formData.id}`, formData.formData);
};


export const createTeachers = (formData) => {
    return axiosInstance.post('/admin/teachers', formData);
};

export const deleteTeacher = (id) => {
    return axiosInstance.delete(`/admin/teachers/${id}`);
};
