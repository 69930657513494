import { toast } from 'react-toastify';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import * as actions from './Actions';
import * as api from './Api';
import Types from './Types';

function* getAllLectures({ payload }) {
    try {
        yield put(actions.addLectureLoading(true));
        const result = yield call(api.getAllLectures);
        yield put(actions.getAllLecturesSuccess(result.data));
    } catch (error) {
        const { response, message } = error;
        toast.error(error);
        yield put(actions.getAllLecturesFail());
    } finally {
        yield put(actions.addLectureLoading(false));
    }
}



//Edit Tables
function* editLecture({ payload }) {
    try {
        yield put(actions.addLectureLoading(true));
        const result = yield call(api.editLectures, payload);
        yield put(actions.editLectureSuccess(result.data));
        toast.success('Edit Successfully');
        payload?.action && payload?.action()
    } catch (error) {
        yield put(actions.editLectureFail());
    } finally {
        yield put(actions.addLectureLoading(false));
    }
}

// create
function* createLecture(formData) {
    try {
        yield put(actions.addLectureLoading(true));
        const result = yield call(api.createLectures, formData.payload);
        yield put(
            actions.createLectureSuccess({
                data: result.data
            })
        );
        toast.success('Created Successfully');
        formData?.action && formData?.action()
    } catch (error) {
        yield put(actions.createLectureFail());
    } finally {
        yield put(actions.addLectureLoading(false));
    }
}

function* deleteLecture({ payload }) {
    try {
        yield put(actions.addLectureLoading(true));
        const result = yield call(api.deleteLecture, payload);

        yield put(actions.deleteLectureSuccess(payload));

        toast.success('Deleted Successfully');
    } catch (error) {
        const { response, message } = error;
        toast.error(error.response);
        yield put(actions.deleteLectureFail());
    } finally {
        yield put(actions.addLectureLoading(false));
    }
}

export default function* LectureSaga() {
    yield takeLatest(Types.GET_LECTURES_REQUEST, getAllLectures);
    yield takeLatest(Types.EDIT_LECTURE_REQUEST, editLecture);
    yield takeLatest(Types.CREATE_LECTURE_REQUEST, createLecture);
    yield takeLatest(Types.DELETE_LECTURE_REQUEST, deleteLecture);
}
