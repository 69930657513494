import Types from "./Types";

const INITIAL_STATE = {
  allGroups: [],
  groups: [],
  performanceGroups: [],
  group: {},
  isLoading: false,
  count: "",
};

export default function groups(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_SYSTEM_GROUPS_SUCCESS: {
      return {
        ...state,
        allGroups: payload.data,
        count: payload.data.length,
      };
    }
    case Types.GET_GROUPS_SUCCESS: {
      return {
        ...state,
        groups: payload.data,
        count: payload.data.length,
      };
    }
    case Types.GET_PERFORMANCE_GROUPS_SUCCESS: {
      return {
        ...state,
        performanceGroups: payload.data,
        count: payload.data.length,
      };
    }
    case Types.GET_GROUP_SUCCESS: {
      return {
        ...state,
        group: payload,
      };
    }

    case Types.EDIT_GROUP_SUCCESS: {
      let data = payload.data;
      const groups = state.groups;
      let idx = 0;
      groups.forEach((table, index) => {
        if (table.id === data.id) {
          idx = index;
          return;
        }
      });

      groups[idx] = data;
      return {
        ...state,
        groups: groups,
      };
    }

    // create
    case Types.CREATE_GROUP_SUCCESS: {
      const data = payload.data.data;
      let newArray = state.groups.slice();
      let index = state.groups.length + 1;
      newArray.splice(index, 0, data);
      return {
        ...state,
        groups: newArray,
      };
    }
    case Types.ADD_GROUPS_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }

    case Types.DELETE_GROUP_SUCCESS: {
      const newgroups = state.groups.filter((item) => item.id !== payload);
      return {
        ...state,
        groups: newgroups,
      };
    }
    default: {
      return state;
    }
  }
}
