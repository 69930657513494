import { Box, Container } from "@chakra-ui/react";
import React from "react";
import Footer from "./footer";
import Navbar from "./navbar";
import MobileNavbar from "./mobileNavbar";
const PublicLayout = ({ children, color, bg, register = true }) => {
  return (
    <Box minH="100vh" h="100%" w="100%" className="public" overflowX="hidden">
      <Box h="7%">
        <Navbar color={color} bg={bg} register={register} />
      </Box>
      {children}
      <Footer />
    </Box>
  );
};

export default PublicLayout;
