import {
  Flex,
  Box,
  Image,
  Text,
  HStack,
  SkeletonText,
  Icon,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import Logo from "../../../assets/colorLogo.webp";
import student from "../../../assets/icons/student.svg";
import groups from "../../../assets/icons/groups.svg";
import list from "../../../assets/icons/list.svg";
import videos from "../../../assets/icons/videos.svg";
import events from "../../../assets/icons/events.svg";
import exams from "../../../assets/icons/exams.svg";
import questions from "../../../assets/icons/questions.svg";
import { useSelector } from "react-redux";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { RiFile2Fill } from "react-icons/ri";
const TeacherSidenavbar = () => {
  const isLoading = useSelector((state) => state.user.isLoading);

  let navTabs = [
    // { label: "لوحة التحكم", link: "/", icon: menu },
    { label: "الطلاب", link: "/dashboard/students", icon: student },
    { label: "المجموعات", link: "/dashboard/groups", icon: groups },
    { label: "المحتوى", link: "/dashboard/lessons", icon: list },
    { label: "اقسام", link: "/dashboard/sections", icon: list },

    // { label: "امتحانات الطلاب", link: "/dashboard/student-exams", icon: exams },
    { label: "الفيديوهات", link: "/dashboard/videos", icon: videos },
    { label: "الأحداث", link: "/dashboard/events", icon: events },
    { label: "الفصول", link: "/dashboard/chapters", icon: list },
    { label: "الواجبات", link: "/dashboard/homework", icon: exams },
    { label: "الحصص", link: "/dashboard/lectures", icon: events },
    { label: "الامتحانات", link: "/dashboard/exams", icon: exams },
    { label: "بنك الاسئلة", link: "/dashboard/questions-bank", icon: exams },
    {
      label: "طلبات الاختبار",
      link: "/dashboard/exam-redo-requests",
      icon: questions,
    },
    {
      label: "طلبات المشاهدة",
      link: "/dashboard/videos-more-view-requests",
      icon: videos,
    },
    {
      label: "  الحضور والدفع وتعويضات الحصص ",
      link: "/dashboard/payments",
      icon: questions,
    },
    { label: "العلامات", link: "/dashboard/tags", icon: questions },
    { label: "قوائم التشغيل", link: "/dashboard/playlists", icon: videos },
  ];

  let reportsTabs = [
    {
      label: "تقرير المجموعات",
      link: "/dashboard/groups-report",
      icon: RiFile2Fill,
    },
    {
      label: " الحضور",
      link: "/dashboard/attendence-report",
      icon: RiFile2Fill,
    },
    {
      label: " غياب الامتحانات",
      link: "/dashboard/exam-attendence-report",
      icon: RiFile2Fill,
    },
    {
      label: " حضور الامتحانات",
      link: "/dashboard/exam-report",
      icon: RiFile2Fill,
    },
    {
      label: " غياب الواجبات",
      link: "/dashboard/homework-absent-report",
      icon: RiFile2Fill,
    },
    {
      label: " حضور الواجبات",
      link: "/dashboard/homework-attendence-report",
      icon: RiFile2Fill,
    },
    {
      label: " تقرير مشاهدات الفيديوهات",
      link: "/dashboard/videos-report",
      icon: RiFile2Fill,
    },
    {
      label: "تقرير طباعة الاسئلة",
      link: "/dashboard/print-all-questions",
      icon: RiFile2Fill,
    },
    {
      label: " تقرير عدم الدفع",
      link: "/dashboard/unpaid-report",
      icon: RiFile2Fill,
    },
    {
      label: "تقرير مراجعة الواجبات",
      link: "/dashboard/uncomment-homework-report",
      icon: RiFile2Fill,
    },

    {
      label: " تقرير الدفع",
      link: "/dashboard/paid-report",
      icon: RiFile2Fill,
    },
  ];
  return (
    <Flex
      w={{ base: "10%", lg: "20%" }}
      p="0"
      h="100vh"
      flexDirection={"column"}
      zIndex="100"
      position="fixed"
      bg="#f5f5f5"
      borderLeft="1px solid #45424226"
      alignItems={{ base: "center", lg: "flex-start" }}
      className="scroll"
    >
      <Image
        alignSelf="center"
        my="20px"
        w="50%"
        loading="lazy"
        src={Logo}
        alt="Enough"
        onClick={() => window.location.replace("/")}
      />

      {isLoading ? (
        <HStack w="100%" bg="transparent" h="100%">
          <SkeletonText
            startColor="gray.100"
            endColor="gray.600"
            w="80%"
            mt="4"
            noOfLines={7}
            spacing="20"
          />
        </HStack>
      ) : (
        <Accordion defaultIndex={[0]} allowMultiple w="100%">
          <AccordionItem w="100%" border="0">
            <h2>
              <AccordionButton
                justifyContent="space-between"
                w="100%"
                fontSize="22px"
                color="main_1"
                _expanded={{ color: "white", bg: "main_1" }}
                _focus={{ outline: "none" }}
              >
                <Box display={{ base: "none", lg: "block" }}>
                  محتوي المعلمين
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              {navTabs?.map((data, index) => {
                return (
                  <Link to={data.link} key={index} style={{ width: "90%" }}>
                    <Flex
                      w="100%"
                      color="black"
                      alignItems="center"
                      justifyContent={{ base: "center", lg: "flex-start" }}
                      mt="10px"
                      p={{ base: "0", lg: "15px" }}
                      cursor={"pointer"}
                      h={{ base: "60px", lg: "auto" }}
                      bg={
                        window.location.pathname == data.link
                          ? "#e4e4e4"
                          : "transparent"
                      }
                      borderRadius="15px"
                      fontWeight={
                        window.location.pathname == data.link ? "600" : "500"
                      }
                      _hover={{
                        bg: "#e4e4e4",
                        fontWeight: "600",
                      }}
                    >
                      <Image src={data?.icon} w="30px" />
                      <Text
                        fontSize="18px"
                        mr="10px"
                        mt="2px"
                        display={{ base: "none", lg: "block" }}
                        color="main_1"
                      >
                        {data.label}
                      </Text>
                    </Flex>
                  </Link>
                );
              })}
            </AccordionPanel>
          </AccordionItem>

          <AccordionItem w="100%" border="0" mt="30px">
            <h2>
              <AccordionButton
                justifyContent="space-between"
                w="100%"
                color="main_1"
                fontSize="22px"
                _expanded={{ color: "white", bg: "main_1" }}
                _focus={{ outline: "none" }}
              >
                <Box display={{ base: "none", lg: "block" }}>التقارير</Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              {reportsTabs?.map((val, index) => {
                return (
                  <Link to={val?.link} style={{ width: "90%" }}>
                    <Flex
                      w="100%"
                      color="black"
                      alignItems="center"
                      justifyContent={{ base: "center", lg: "flex-start" }}
                      mt="10px"
                      p={{ base: "0", lg: "25px" }}
                      cursor={"pointer"}
                      h={{ base: "60px", lg: "" }}
                      bg={
                        window.location.pathname == val.link
                          ? "#e4e4e4"
                          : "transparent"
                      }
                      borderRadius="15px"
                      fontWeight={
                        window.location.pathname == val.link ? "600" : "500"
                      }
                      _hover={{
                        bg: "#e4e4e4",
                        fontWeight: "600",
                      }}
                    >
                      <Icon as={val?.icon} fontSize="22px" color="main_1" />

                      <Text
                        fontSize="14px"
                        mx="20px"
                        mt="2px"
                        display={{ base: "none", lg: "block" }}
                        color="main_1"
                      >
                        {val?.label}
                      </Text>
                    </Flex>
                  </Link>
                );
              })}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      )}
    </Flex>
  );
};

export default TeacherSidenavbar;
