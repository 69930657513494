export default {
    //get all VIDEOs
    GET_STUDENT_CHAPTERS_REQUEST: 'GET_STUDENT_CHAPTERS_REQUEST',
    GET_STUDENT_CHAPTERS_SUCCESS: 'GET_STUDENT_CHAPTERS_SUCCESS',
    GET_STUDENT_CHAPTERS_FAIL: 'GET_STUDENT_CHAPTERS_FAIL',
    GET_ALL_STUDENT_CHAPTERS_REQUEST: 'GET_ALL_STUDENT_CHAPTERS_REQUEST',
    GET_ALL_STUDENT_CHAPTERS_SUCCESS: 'GET_ALL_STUDENT_CHAPTERS_SUCCESS',
    GET_ALL_STUDENT_CHAPTERS_FAIL: 'GET_ALL_STUDENT_CHAPTERS_FAIL',



    //loading
    ADD_STUDENT_CHAPTERS_LOADING: 'ADD_STUDENT_CHAPTERS_LOADING'
};
