import axiosInstance from '../../../helpers/api';

export const createStudentExams = data => {
    return axiosInstance.post(`/students/student_type_exams`, data);
};



export const getStudentExams = () => {
    return axiosInstance.get(`/students/student_type_exams`);
};


export const getStudentExamQuestions = (id) => {
    return axiosInstance.get(`/students/student_type_exams/${id}`);
};


