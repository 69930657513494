import Types from './Types';


export const setUserAction = (isLoading) => ({
    type: Types.SET_USER_IS_LOADING,
    payload: isLoading
});

export const getUserDataRequest = () => ({
    type: Types.GET_USER_DATA_REQUEST,

});

export const getUserDataSuccess = (data) => ({
    type: Types.GET_USER_DATA_SUCCESS,
    payload: data
});

export const getUserDataFail = () => ({
    type: Types.GET_USER_DATA_FAIL
});