export default {
  CREATE_VIDEO_VOUCHERS_REQUEST: "CREATE_VIDEO_VOUCHERS_REQUEST",
  CREATE_VIDEO_VOUCHERS_SUCCESS: "CREATE_VIDEO_VOUCHERS_SUCCESS",
  CREATE_VIDEO_VOUCHERS_FAIL: "CREATE_VIDEO_VOUCHERS_FAIL",
  //get all VIDEOs
  GET_VIDEOS_REQUEST: "GET_VIDEOS_REQUEST",
  GET_VIDEOS_SUCCESS: "GET_VIDEOS_SUCCESS",
  GET_VIDEOS_FAIL: "GET_VIDEOS_FAIL",

  //edit VIDEOs
  EDIT_VIDEO_REQUEST: "EDIT_VIDEO_REQUEST",
  EDIT_VIDEO_SUCCESS: "EDIT_VIDEO_SUCCESS",
  EDIT_VIDEO_FAIL: "EDIT_VIDEO_FAIL",

  SUSPEND_VIDEO_REQUEST: "SUSPEND_VIDEO_REQUEST",
  SUSPEND_VIDEO_SUCCESS: "SUSPEND_VIDEO_SUCCESS",
  SUSPEND_VIDEO_FAIL: "SUSPEND_VIDEO_FAIL",

  //create VIDEO
  CREATE_VIDEO_REQUEST: "CREATE_VIDEO_REQUEST",
  CREATE_VIDEO_SUCCESS: "CREATE_VIDEO_SUCCESS",
  CREATE_VIDEO_FAIL: "CREATE_VIDEO_FAIL",
  //create VIDEO files
  ADD_VIDEO_FILES_REQUEST: "ADD_VIDEO_FILES_REQUEST",
  ADD_VIDEO_FILES_SUCCESS: "ADD_VIDEO_FILES_SUCCESS",
  ADD_VIDEO_FILES_FAIL: "ADD_VIDEO_FILES_FAIL",

  DELETE_VIDEO_FILES_REQUEST: "DELETE_VIDEO_FILES_REQUEST",
  DELETE_VIDEO_FILES_SUCCESS: "DELETE_VIDEO_FILES_SUCCESS",
  DELETE_VIDEO_FILES_FAIL: "DELETE_VIDEO_FILES_FAIL",

  DELETE_VIDEO_REQUEST: "DELETE_VIDEO_REQUEST",
  DELETE_VIDEO_SUCCESS: "DELETE_VIDEO_SUCCESS",
  DELETE_VIDEO_FAIL: "DELETE_VIDEO_FAIL",

  GET_VIDEO_REQUEST: "GET_VIDEO_REQUEST",
  GET_VIDEO_SUCCESS: "GET_VIDEO_SUCCESS",
  GET_VIDEO_FAIL: "GET_VIDEO_FAIL",

  GET_PAYMENT_SUBJECT_REQUEST: "GET_PAYMENT_SUBJECT_REQUEST",
  GET_PAYMENT_SUBJECT_SUCCESS: "GET_PAYMENT_SUBJECT_SUCCESS",
  GET_PAYMENT_SUBJECT_FAIL: "GET_PAYMENT_SUBJECT_FAIL",

  //loading
  ADD_VIDEOS_LOADING: "ADD_VIDEOS_LOADING",
};
