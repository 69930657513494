import axiosInstance from "../../../helpers/api";

export const getAllEvents = (body) => {
    return axiosInstance.get('/admin/events', body);
};

export const editEvents = (formData) => {

    return axiosInstance.put(`/admin/events/${formData.id}`, formData.formData);
};


export const createEvents = (formData) => {
    return axiosInstance.post('/admin/events', formData);
};

export const deleteEvent = (id) => {
    return axiosInstance.delete(`/admin/events/${id}`);
};
