import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../../components/dashboard/Layout";
import SearchBar from "../../../containers/admin/videos-requests/searchbar";
import VideosRequestTable from "../../../containers/admin/videos-requests/table";
import { getAllRequestsRequest } from "../../../modules/admin-modules/video-requests/Actions";

import UserQRCode from "../../../containers/admin/students/models/user-qr-code";

const VideosMoreViewRequests = () => {
  const dispatch = useDispatch();
  const model = useSelector((state) => state.model);
  const [isAdd, setAdd] = useState(false);
  const [selectedData, setSelectedData] = useState({});

  useEffect(() => {
    dispatch(getAllRequestsRequest());
  }, []);

  return (
    <Layout>
      <SearchBar setAdd={setAdd} setSelectedData={setSelectedData} />
      <VideosRequestTable setAdd={setAdd} setSelectedData={setSelectedData} />
      <UserQRCode data={selectedData?.student} setSelectedData={setSelectedData} />
    </Layout>
  );
};

export default VideosMoreViewRequests;
