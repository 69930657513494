import React, { useEffect } from "react";
import { Box, Heading, Spinner } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { closeStudentLessonsReportModel } from "../../../../modules/admin-modules/models/Actions";
import MainModel from "../../../../components/Models/main-model";

import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from "@chakra-ui/react";
import { videoFilterReportRequest } from "../../../../modules/admin-modules/reports/Actions";
const StudentLessonsReport = ({ isAdd, student_id }) => {
  const isLoading = useSelector((state) => state.reports.isLoading);
  const videosReport = useSelector((state) => state.reports.videosReport);

  const dispatch = useDispatch();

  useEffect(() => {
    if (student_id) dispatch(videoFilterReportRequest(student_id));
  }, [student_id]);

  const header = (
    <Box>
      <Heading textAlign="center" color="main_1" fontSize="24px">
        تقرير الطالب
      </Heading>
    </Box>
  );

  const openUserData = useSelector((state) => state.model.isOpen31);

  const closeFunc = () => {
    dispatch(closeStudentLessonsReportModel());
  };
  console.log(videosReport);
  return (
    <MainModel
      header={header}
      closeFunc={closeFunc}
      openVar={openUserData}
      buttonTitle={null}
      buttonFunc={null}
    >
      {isLoading ? (
        <Spinner />
      ) : (
        <TableContainer>
          <Table variant="striped">
            <Thead>
              {" "}
              <Tr>
                <Th>Lesson</Th>
                <Th>Total covered</Th>
                <Th>Total played</Th>
              </Tr>
            </Thead>
            <Tbody>
              {videosReport?.map((data, index) => (
                <Tr key={index}>
                  <Td
                    fontWeight="600"
                    style={{ maxWidth: "200px", textWrap: "wrap" }}
                  >
                    {data?.title}{" "}
                  </Td>
                  <Td>{data?.total_covered}</Td>

                  <Td> {data?.total_played}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </MainModel>
  );
};

export default StudentLessonsReport;
