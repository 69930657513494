import axiosInstance from "../../../helpers/api";

export const getAllPlaylists = (body) => {
  return axiosInstance.get("/admin/playlists", body);
};

export const editPlaylists = (formData) => {
  return axiosInstance.post(
    `/admin/playlists/${formData.id}`,
    formData.formData
  );
};

export const createPlaylists = (formData) => {
  return axiosInstance.post("/admin/playlists", formData);
};

export const deletePlaylist = (id) => {
  return axiosInstance.delete(`/admin/playlists/${id}`);
};
