import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../../components/dashboard/Layout";
import AddStreaming from "../../../containers/admin/streaming/models/add-streaming";
import SearchBar from "../../../containers/admin/streaming/searchbar";
import StudentsTable from "../../../containers/admin/students/table";
const Streaming = () => {
  const dispatch = useDispatch();
  const model = useSelector((state) => state.model);
  const [isAdd, setAdd] = useState(false);
  const [selectedData, setSelectedData] = useState({});

  useEffect(() => {
    // dispatch(getAllProductsRequest())
  }, []);

  return (
    <Layout>
      <SearchBar setAdd={setAdd} setSelectedData={setSelectedData} />
      <StudentsTable setAdd={setAdd} setSelectedData={setSelectedData} />
      <AddStreaming
        isAdd={isAdd}
        data={selectedData}
        setSelectedData={setSelectedData}
      />
    </Layout>
  );
};

export default Streaming;
