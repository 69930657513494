import axiosInstance from "../../../helpers/api";

export const getAllRequests = (body) => {
    return axiosInstance.get('/admin/video_requests', body);
};

export const editRequests = (formData) => {
    return axiosInstance.put(`/admin/video_requests/${formData.id}`, formData.formData);
};

export const acceptRequestWithoutVoucher = (id) => {
    return axiosInstance.put(`/admin/video_requests/without_voucher/${id}`);
};

export const deleteRequest = (id) => {
    return axiosInstance.delete(`/admin/video_requests/${id}`);
};
