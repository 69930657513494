import Types from './Types';


export const getAllEventsRequest = (body) => ({
    type: Types.GET_EVENTS_REQUEST,
    payload: body
});

export const getAllEventsSuccess = (data) => ({
    type: Types.GET_EVENTS_SUCCESS,
    payload: data
});

export const getAllEventsFail = () => ({
    type: Types.GET_EVENTS_FAIL
});


export const editEventRequest = (formData, action) => ({
    type: Types.EDIT_EVENT_REQUEST,
    payload: formData,
    action: action
});

export const editEventSuccess = (data) => ({
    type: Types.EDIT_EVENT_SUCCESS,
    payload: data
});

export const editEventFail = () => ({
    type: Types.EDIT_EVENT_FAIL
});

//LOADING
export const addEventLoading = (isLoading) => ({
    type: Types.ADD_EVENTS_LOADING,
    payload: isLoading
});

// Create Event
export const createEventRequest = (formData, action) => ({
    type: Types.CREATE_EVENT_REQUEST,
    payload: formData,
    action: action
});

export const createEventSuccess = ({ data }) => ({
    type: Types.CREATE_EVENT_SUCCESS,
    payload: {
        data
    }
});

export const createEventFail = () => ({
    type: Types.CREATE_EVENT_FAIL
});

export const deleteEventRequest = (id) => ({
    type: Types.DELETE_EVENT_REQUEST,
    payload: id
});

export const deleteEventSuccess = (id) => ({
    type: Types.DELETE_EVENT_SUCCESS,
    payload: id
});

export const deleteEventFail = () => ({
    type: Types.DELETE_EVENT_FAIL
});
