import Types from "./Types";

export const getAllTagsRequest = (body) => ({
  type: Types.GET_TAGS_REQUEST,
  payload: body,
});

export const getAllTagsSuccess = (data) => ({
  type: Types.GET_TAGS_SUCCESS,
  payload: data,
});

export const getAllTagsFail = () => ({
  type: Types.GET_TAGS_FAIL,
});

export const editTagRequest = (formData) => ({
  type: Types.EDIT_TAG_REQUEST,
  payload: formData,
});

export const editTagSuccess = (data) => ({
  type: Types.EDIT_TAG_SUCCESS,
  payload: data,
});

export const editTagFail = () => ({
  type: Types.EDIT_TAG_FAIL,
});

//LOADING
export const addTagLoading = (isLoading) => ({
  type: Types.ADD_TAGS_LOADING,
  payload: isLoading,
});

// Create Tag
export const createTagRequest = (formData, action) => ({
  type: Types.CREATE_TAG_REQUEST,
  payload: formData,
  action: action,
});

export const createTagSuccess = ({ data }) => ({
  type: Types.CREATE_TAG_SUCCESS,
  payload: {
    data,
  },
});

export const createTagFail = () => ({
  type: Types.CREATE_TAG_FAIL,
});

export const deleteTagRequest = (id) => ({
  type: Types.DELETE_TAG_REQUEST,
  payload: id,
});

export const deleteTagSuccess = (id) => ({
  type: Types.DELETE_TAG_SUCCESS,
  payload: id,
});

export const deleteTagFail = () => ({
  type: Types.DELETE_TAG_FAIL,
});

export const enableTagRequest = (formData) => ({
  type: Types.ENABLE_TAG_REQUEST,
  payload: formData,
});

export const enableTagSuccess = (data) => ({
  type: Types.ENABLE_TAG_SUCCESS,
  payload: data,
});

export const enableTagFail = () => ({
  type: Types.ENABLE_TAG_FAIL,
});
