import React, { useEffect } from "react";
import { Grid, Container, Heading, VStack } from "@chakra-ui/react";
import ChapterCard from "../../../components/student/Cards/chapterCard";
import Layout from "../../../components/student/Layout/layout";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Exam from "../../../assets/icons/exam.svg";
import { getSectionRequest } from "../../../modules/students-modules/sections/Actions";
const ChaptersExamsList = () => {
  const [getSearchParams] = useSearchParams();
  const section_name = getSearchParams.get("name");

  const { id } = useParams();
  const section = useSelector((state) => state.sections.section);
  const isLoading = useSelector((state) => state.sections.isLoading);

  const dispatch = useDispatch();
  useEffect(() => {
    if (id) dispatch(getSectionRequest(id));
  }, [id]);
  return (
    <Layout color="main_1" bg="white" isLoading={isLoading}>
      <Container maxW="container.2xl" h="100%" my="100px" mb="165px" w="100%">
        <VStack spacing={8} h="100%" w="100%" alignItems="flex-start">
          <Heading as="h3" color="main_1" textAlign="center" w="100%">
            {section_name} Exams
          </Heading>
          <Grid
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }}
            gap={6}
            w="100%"
          >
            {section?.map((chapter, idx) => {
              return (
                <Link
                  to={`/exam/${chapter?.id}?name=${chapter?.name}`}
                  key={idx}
                >
                  <ChapterCard
                    chapter={chapter}
                    onClick={() => {}}
                    list={[]}
                    content={0}
                    image={Exam}
                    type={"Exams"}
                    subTitle={"Solved"}
                  />
                </Link>
              );
            })}
          </Grid>
        </VStack>
      </Container>
    </Layout>
  );
};

export default ChaptersExamsList;
