import Types from './Types';


export const getLessonsByChapterIdRequest = (id) => ({
    type: Types.GET_LESSONS_BY_CHAPTER_ID_REQUEST,
    payload: id
});

export const getLessonsByChapterIdSuccess = (data) => ({
    type: Types.GET_LESSONS_BY_CHAPTER_ID_SUCCESS,
    payload: data
});

export const getLessonsByChapterIdFail = () => ({
    type: Types.GET_LESSONS_BY_CHAPTER_ID_FAIL
});





//LOADING
export const addLessonsLoading = (isLoading) => ({
    type: Types.ADD_LESSONS_LOADING,
    payload: isLoading
});

