import { Box, Button, Flex, HStack, InputGroup, Input, InputLeftElement, MenuItem, MenuList, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import { RiAddFill, RiSearch2Fill } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { openCreateVideos } from '../../../modules/admin-modules/models/Actions'
import LogoutMenu from '../../../components/dashboard/Layout/logout-menu'

const SearchBar = ({ setAdd, setSelectedData }) => {
    const dispatch = useDispatch()
    const count = useSelector((state) => state.videoRequests.count)

    const options = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
    ]
    const colourStyles = {
        control: (styles) => ({ ...styles, width: '100%', background: 'white', color: '#690000', borderRadius: '16px' }),
        option: (styles) => ({ ...styles, color: '#690000' }),

    };
    return (
        <VStack w='100%' my='30px'>

            <Flex alignItems='center' w='100%' justifyContent='space-between'>
                <Text fontSize='24px'
                    fontWeight='600' color='main_1'>
                    طلبات المشاهدة
                </Text>

                <Flex alignItems='center'  >


                    <LogoutMenu />
                </Flex>


            </Flex>
            <Text w='100%' fontSize='20px' textAlign='right'>   عدد الطلبات: {count}</Text>




        </VStack>



    )
}

export default SearchBar