export default {
  //get all TAGs
  GET_TAGS_REQUEST: "GET_TAGS_REQUEST",
  GET_TAGS_SUCCESS: "GET_TAGS_SUCCESS",
  GET_TAGS_FAIL: "GET_TAGS_FAIL",

  //edit TAGs
  EDIT_TAG_REQUEST: "EDIT_TAG_REQUEST",
  EDIT_TAG_SUCCESS: "EDIT_TAG_SUCCESS",
  EDIT_TAG_FAIL: "EDIT_TAG_FAIL",

  //create TAG
  CREATE_TAG_REQUEST: "CREATE_TAG_REQUEST",
  CREATE_TAG_SUCCESS: "CREATE_TAG_SUCCESS",
  CREATE_TAG_FAIL: "CREATE_TAG_FAIL",

  DELETE_TAG_REQUEST: "DELETE_TAG_REQUEST",
  DELETE_TAG_SUCCESS: "DELETE_TAG_SUCCESS",
  DELETE_TAG_FAIL: "DELETE_TAG_FAIL",

  GET_TAG_REQUEST: "GET_TAG_REQUEST",
  GET_TAG_SUCCESS: "GET_TAG_SUCCESS",
  GET_TAG_FAIL: "GET_TAG_FAIL",

  ENABLE_TAG_REQUEST: "ENABLE_TAG_REQUEST",
  ENABLE_TAG_SUCCESS: "ENABLE_TAG_SUCCESS",
  ENABLE_TAG_FAIL: "ENABLE_TAG_FAIL",
  //loading
  ADD_TAGS_LOADING: "ADD_TAGS_LOADING",
};
