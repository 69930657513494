import { toast } from 'react-toastify';
import { call, fork, put, takeLatest } from 'redux-saga/effects';
import * as actions from './Actions';
import * as api from './Api';
import Types from './Types';



function* getUserData() {
    try {
        yield put(actions.setUserAction(true));
        const result = yield call(api.getUserData);
        yield put(actions.getUserDataSuccess(result.data));

    } catch (error) {
        const { response, message } = error;
    } finally {
        yield put(actions.setUserAction(false));
    }
}

export default function* userSaga() {

    yield takeLatest(Types.GET_USER_DATA_REQUEST, getUserData);


}
