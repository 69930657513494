import { FormLabel, Input } from "@chakra-ui/react";
import React from "react";

const MainInput = ({
  label,
  error,
  placeholder,
  register,
  name,
  type = "text",
  defaultValues,
  required = true,
  ...rest
}) => {
  return (
    <>
      {label && (
        <FormLabel fontSize="16px" color="main_1">
          {label}
        </FormLabel>
      )}
      <Input
        type={type}
        borderColor={error ? "red" : "gray.200"}
        placeholder={placeholder}
        size="md"
        bg="#f5f5f5"
        defaultValue={defaultValues}
        {...register(
          name,
          required ? { required: "هذه المعلومات مطلوبة" } : {}
        )}
        {...rest}
      />
    </>
  );
};

export default MainInput;
