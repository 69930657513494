import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  Container,
  Flex,
  Heading,
  HStack,
  Icon,
  Square,
  Stack,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { HiArrowLeft, HiArrowRight } from "react-icons/hi";
import { RiDownload2Line } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Layout from "../../../components/student/Layout/layout";
import AddVoucher from "../../../containers/student/models/add-voucher";
import SolveHomework from "../../../containers/student/models/solve-homework";
import VideoCard from "../../../containers/student/video/video";
import VideoPreview from "../../../containers/student/video/videoPreview";
import axiosInstance from "../../../helpers/api";
import { openSolveHomeworkModel } from "../../../modules/admin-modules/models/Actions";
import { getVideosByChapterIdRequest } from "../../../modules/students-modules/videos/Actions";
const StudentVideo = (props) => {
  const [selectedData, setSelectedData] = useState({});
  const [selectedindex, setSelectedIndex] = useState(null);

  const [loading, setLoading] = useState(false);

  const [otp, setOtp] = useState("");
  const [playBack, setPlayBack] = useState("");
  const baseUrl = "https://enoughphysics.com/enoughapi";

  const [getSearchParams, setSearchParams] = useSearchParams();
  const video_id = getSearchParams.get("video");
  const chapter_name = getSearchParams.get("name");
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const chapterVideos = useSelector(
    (state) => state.chapterVideos.chapterVideos
  );
  const isLoading = useSelector((state) => state.chapterVideos.isLoading);
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <HiArrowRight
        className={className}
        style={{
          ...style,
          display: "block",
          color: "#010038",
          fontSize: "30px",
        }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <HiArrowLeft
        className={className}
        style={{
          ...style,
          display: "block",
          color: "#010038",
          fontSize: "30px",
        }}
        onClick={onClick}
      />
    );
  }
  useEffect(() => {
    if (params?.id) {
      dispatch(getVideosByChapterIdRequest(params?.id));
    }
  }, [params?.id]);

  const getVideoData = async (id) => {
    //console.log(id, "get");
    const res = await axiosInstance.get(`/students/videos/otp/${id}`);
    await setOtp(res?.data?.otp);
    await setPlayBack(res?.data?.playbackInfo);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  };
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  useEffect(() => {
    if (video_id && chapterVideos?.length > 0) {
      const filteredData = chapterVideos?.find((data) => data?.id == video_id);
      setSelectedData(filteredData);
    } else {
      setSelectedData({});
    }
  }, [video_id, chapterVideos]);

  useEffect(() => {
    if (selectedData?.id) {
      if (selectedData?.video_type == 1) getVideoData(selectedData?.id);
    }
  }, [selectedData]);
  return (
    <Layout color="main_1" bg="white" isLoading={isLoading}>
      <Container maxW="container.2xl" h="100%" my="100px" mb="165px" w="100%">
        <VStack spacing={8} h="100%" w="100%" alignItems="flex-start" mt={20}>
          <Breadcrumb>
            <BreadcrumbItem>
              <Link to="/videos/sections">
                <Text>Video</Text>
              </Link>
            </BreadcrumbItem>

            <BreadcrumbItem isCurrentPage>
              <Text textTransform="capitalize">{chapter_name}</Text>
            </BreadcrumbItem>
          </Breadcrumb>

          <Heading as="h3" color="main_1" textAlign="center" w="100%">
            {chapter_name}
          </Heading>

          {chapterVideos?.length == 0 && (
            <Heading
              as="h6"
              color="main_1"
              textAlign="center"
              w="100%"
              fontSize="20px"
            >
              You Didn't pay for this chapter
              <a
                href="https://wa.me/+201025830160"
                style={{ textDecoration: "underline" }}
                target="_blank"
                rel="noreferrer"
              >
                , Connect with the assistant!
              </a>
            </Heading>
          )}
          <Slider {...settings} className="slider">
            {chapterVideos?.map((data, idx) => {
              return (
                <VStack>
                  <VideoCard
                    key={idx}
                    data={data}
                    index={idx}
                    selectedindex={selectedindex}
                    handelClick={() => {
                      setSelectedIndex(idx);
                      if (
                        data?.views_count?.views_count <=
                          data?.max_allowed_views ||
                        (data?.suspended == 1 &&
                          data?.user_video_suspended != 0)
                      ) {
                        setSelectedData(data);
                        setSearchParams({ video: data?.id });
                      }
                    }}
                  />
                </VStack>
              );
            })}
          </Slider>
          <Stack
            flexDirection="column"
            w="100%"
            justifyContent="space-between"
            alignItems="flex-start"
            gap={{ base: 10, md: 4 }}
          >
            {/* right side */}

            {/* left side */}
            {selectedData?.url == "no" ? (
              <Heading>Comming Soon</Heading>
            ) : (
              chapterVideos?.length > 0 &&
              selectedData && (
                <VStack
                  alignItems="center"
                  justifyContent="center"
                  w="100%"
                  h="100%"
                  spacing="40px"
                  m="0 !important"
                  mt="10px !important"
                >
                  <VideoPreview
                    data={selectedData}
                    loading={loading}
                    otp={otp}
                    playBack={playBack}
                  />

                  {/* video materials  */}
                  <VStack
                    w={{ base: "100%", md: "80%" }}
                    alignItems="flex-start"
                    color="main_1"
                  >
                    {selectedData?.files?.length != 0 && (
                      <Text fontSize="26px" fontWeight="500">
                        Video materials
                      </Text>
                    )}
                    <Flex w="100%" flexWrap="wrap" gap="4">
                      {selectedData?.files?.map((val, idx) => {
                        return (
                          <HStack
                            key={idx}
                            w="23.5%"
                            h="70px"
                            bg="main_1_light"
                            justify="space-between"
                            borderRadius="8px"
                            p="10px"
                            position="relative"
                          >
                            <Text>File title</Text>

                            <Square
                              size="40px"
                              bg="main_1"
                              color="white"
                              borderRadius="8px"
                            >
                              <Icon as={RiDownload2Line} />
                            </Square>
                            <a
                              href={`${baseUrl}/${val?.file}`}
                              download
                              style={{
                                width: "100%",
                                height: "100%",
                                position: "absolute",
                              }}
                              target="_blank"
                              rel="noreferrer"
                            ></a>
                          </HStack>
                        );
                      })}
                    </Flex>
                  </VStack>

                  {/* video description */}

                  <VStack
                    w={{ base: "100%", md: "80%" }}
                    alignItems="flex-start"
                    color="main_1"
                  >
                    <Text fontSize="26px" fontWeight="500">
                      Video Description{" "}
                    </Text>
                    <Text fontSize="20px">{selectedData?.over_view} </Text>
                  </VStack>

                  {selectedData?.homework?.name && (
                    <VStack
                      w={{ base: "100%", md: "80%" }}
                      alignItems="flex-start"
                      color="main_1"
                    >
                      <Text fontSize="26px" fontWeight="500">
                        Video Related Homework{" "}
                      </Text>
                      <Box
                        onClick={() => dispatch(openSolveHomeworkModel())}
                        style={{
                          paddingLeft: "20px",
                          cursor: "pointer",
                          textDecoration: "underline",
                          fontWeight: "500",
                        }}
                      >
                        {selectedData?.homework?.name}
                      </Box>
                    </VStack>
                  )}

                  {selectedData?.exams?.length > 0 && (
                    <VStack
                      w={{ base: "100%", md: "80%" }}
                      alignItems="flex-start"
                      color="main_1"
                    >
                      <Text fontSize="26px" fontWeight="500">
                        Video Related Exams{" "}
                      </Text>
                      {selectedData?.exams?.map((val) => {
                        return (
                          <Box
                            style={{
                              paddingLeft: "20px",
                              cursor: "pointer",
                              textDecoration: "underline",
                              fontWeight: "500",
                            }}
                            onClick={() =>
                              navigate(
                                `/exam-guide/${val?.id}?name=${selectedData?.chapter?.name}`
                              )
                            }
                          >
                            {val?.name}
                          </Box>
                        );
                      })}
                    </VStack>
                  )}

                  {selectedData?.quiz_hw?.length > 0 && (
                    <VStack
                      w={{ base: "100%", md: "80%" }}
                      alignItems="flex-start"
                      color="main_1"
                    >
                      <Text fontSize="26px" fontWeight="500">
                        Video Related Exams{" "}
                      </Text>
                      {selectedData?.quiz_hw?.map((val) => {
                        return (
                          <Box
                            style={{
                              paddingLeft: "20px",
                              cursor: "pointer",
                              textDecoration: "underline",
                              fontWeight: "500",
                            }}
                            onClick={() =>
                              navigate(
                                `/solve-exam/${val?.id}?name=${selectedData?.chapter?.name}`
                              )
                            }
                          >
                            {val?.name}
                          </Box>
                        );
                      })}
                    </VStack>
                  )}
                </VStack>
              )
            )}
          </Stack>
        </VStack>
      </Container>
      <AddVoucher video_id={selectedData?.id} />

      <SolveHomework data={selectedData?.homework} setSelectedData={() => {}} />
    </Layout>
  );
};

export default StudentVideo;
