import { CircularProgress, Flex, Modal, ModalBody, ModalContent, ModalOverlay, Progress, Text, useDisclosure } from '@chakra-ui/react'
import React from 'react'
import { useSelector } from 'react-redux'

const ExamAnswersLoading = () => {
    const { onOpen, onClose } = useDisclosure()

    const isOpen = useSelector((state) => state.model.isOpen17)

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalBody>
                    <Flex w='100%' h='100%'
                        flexDirection='column'
                        alignItems='center'
                        gap='25px'
                        p='25px'>
                        <Text>جاري ارسال الاجابات</Text>
                        <Progress size='md' isIndeterminate w='100%' />
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>

    )
}

export default ExamAnswersLoading