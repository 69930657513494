import React, { useRef } from "react";
import { Heading, Box } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { closeDeleteHomeworkModel } from "../../../../modules/admin-modules/models/Actions";
import MainModel from "../../../../components/Models/main-model";
import { deleteHomeworkRequest } from "../../../../modules/admin-modules/homework/Actions";

const DeleteHomework = ({ data }) => {
  const dispatch = useDispatch();

  const openDeleteHomework = useSelector((state) => state.model.isOpen28);

  const closeFunc = () => {
    dispatch(closeDeleteHomeworkModel());
  };

  const handelDelete = () => {
    dispatch(deleteHomeworkRequest(data?.id));
    dispatch(closeDeleteHomeworkModel());
  };

  const header = (
    <Box>
      <Heading textAlign="center" color="main_1" fontSize="24px">
        هل انت متاكدة من ازالة هذا العنصر؟
      </Heading>
    </Box>
  );
  return (
    <MainModel
      header={header}
      closeFunc={closeFunc}
      openVar={openDeleteHomework}
      buttonTitle={"ازالة"}
      buttonFunc={handelDelete}
    ></MainModel>
  );
};

export default DeleteHomework;
