import { Flex, Image, Icon, Text, Container, Box, VStack } from '@chakra-ui/react'
import React from 'react'
import { RiFacebookBoxFill, RiTwitterLine, RiYoutubeFill, RiInstagramFill } from 'react-icons/ri'
const Footer = () => {
    return (
        <Box bg='main_1' py='15px' color='white' w='100%'>
            <Container maxWidth={'container.2xl'}>
                <Flex justifyContent={'space-between'} flexDirection={{ base: 'column', md: 'row' }}>
                    <Flex flexDirection={'column'} w={{ base: '100%', md: '50%' }}>
                        <Image src={require('../../../assets/whiteLogo.webp')} w='150px' />
                        <Flex my='15px'>
                            <Icon as={RiFacebookBoxFill} fontSize='25px' color='white' />
                            <Icon as={RiTwitterLine} fontSize='25px' color='white' />
                            <Icon as={RiYoutubeFill} fontSize='25px' color='white' />
                            <Icon as={RiInstagramFill} fontSize='25px' color='white' />

                        </Flex>
                        <Flex color='white' flexDirection={'column'}>
                            <Flex>
                                <Text>Email : </Text> <Text>info@enoughphysics.com</Text>
                            </Flex>
                            <Flex>
                                <Text>Phone : </Text> <Text>01223424243</Text>
                            </Flex>
                        </Flex>

                    </Flex>
                    <Flex w={{ base: '100%', md: '30%' }} mt={{ base: '10px', md: '0' }} justifyContent={'space-between'}>
                        <VStack
                            spacing={4}
                            align='stretch'
                        >
                            <Box h='40px' >
                                Reviews
                            </Box>
                            <Box h='40px' >
                                Contact us
                            </Box>
                            <Box h='40px' >
                                Courses
                            </Box>
                        </VStack>
                        <VStack
                            spacing={4}
                            align='stretch'
                        >
                            <Box h='40px'>
                                Download App
                            </Box>
                            <Box h='40px' >
                                Features
                            </Box>
                            <Box h='40px' >
                                Careers
                            </Box>
                        </VStack>
                        <VStack
                            spacing={4}
                            align='stretch'
                        >
                            <Box h='40px'>
                                services
                            </Box>
                            <Box h='40px' >
                                Summeries
                            </Box>
                            <Box h='40px' >
                                About us
                            </Box>
                        </VStack>
                    </Flex>
                </Flex>
            </Container>
        </Box >


    )
}

export default Footer