import Types from "./Types";

export const getAllPlaylistsRequest = (body) => ({
  type: Types.GET_PLAYLISTS_REQUEST,
  payload: body,
});

export const getAllPlaylistsSuccess = (data) => ({
  type: Types.GET_PLAYLISTS_SUCCESS,
  payload: data,
});

export const getAllPlaylistsFail = () => ({
  type: Types.GET_PLAYLISTS_FAIL,
});

export const editPlaylistRequest = (formData) => ({
  type: Types.EDIT_PLAYLIST_REQUEST,
  payload: formData,
});

export const editPlaylistSuccess = (data) => ({
  type: Types.EDIT_PLAYLIST_SUCCESS,
  payload: data,
});

export const editPlaylistFail = () => ({
  type: Types.EDIT_PLAYLIST_FAIL,
});

//LOADING
export const addPlaylistLoading = (isLoading) => ({
  type: Types.ADD_PLAYLISTS_LOADING,
  payload: isLoading,
});

// Create Playlist
export const createPlaylistRequest = (formData, action) => ({
  type: Types.CREATE_PLAYLIST_REQUEST,
  payload: formData,
  action: action,
});

export const createPlaylistSuccess = ({ data }) => ({
  type: Types.CREATE_PLAYLIST_SUCCESS,
  payload: {
    data,
  },
});

export const createPlaylistFail = () => ({
  type: Types.CREATE_PLAYLIST_FAIL,
});

export const deletePlaylistRequest = (id) => ({
  type: Types.DELETE_PLAYLIST_REQUEST,
  payload: id,
});

export const deletePlaylistSuccess = (id) => ({
  type: Types.DELETE_PLAYLIST_SUCCESS,
  payload: id,
});

export const deletePlaylistFail = () => ({
  type: Types.DELETE_PLAYLIST_FAIL,
});
