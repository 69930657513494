import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Layout from "../../../components/dashboard/Layout";
import AddChapter from "../../../containers/admin/chapters/models/add-chapter";
import SearchBar from "../../../containers/admin/chapters/searchbar";
import ChaptersTable from "../../../containers/admin/chapters/table";

import { getAllChaptersRequest } from "../../../modules/admin-modules/chapters/Actions";

const Chapters = () => {
  const dispatch = useDispatch();
  const model = useSelector((state) => state.model);
  const [isAdd, setAdd] = useState(false);
  const [selectedData, setSelectedData] = useState({});

  useEffect(() => {
    dispatch(getAllChaptersRequest());
  }, []);

  return (
    <Layout>
      <SearchBar setAdd={setAdd} setSelectedData={setSelectedData} />
      <ChaptersTable setAdd={setAdd} setSelectedData={setSelectedData} />
      <AddChapter
        isAdd={isAdd}
        data={selectedData}
        setSelectedData={setSelectedData}
      />
    </Layout>
  );
};

export default Chapters;
