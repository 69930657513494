import Types from "./Types";

export const getAllPaymentsRequest = (body) => ({
  type: Types.GET_PAYMENTS_REQUEST,
  payload: body,
});

export const getAllPaymentsSuccess = (data) => ({
  type: Types.GET_PAYMENTS_SUCCESS,
  payload: data,
});

export const getAllPaymentsFail = () => ({
  type: Types.GET_PAYMENTS_FAIL,
});

export const getPaymentExceptionRequest = (body) => ({
  type: Types.GET_PAYMENT_EXCEPTION_REQUEST,
  payload: body,
});

export const getPaymentExceptionSuccess = (data) => ({
  type: Types.GET_PAYMENT_EXCEPTION_SUCCESS,
  payload: data,
});

export const getPaymentExceptionFail = () => ({
  type: Types.GET_PAYMENT_EXCEPTION_FAIL,
});
export const editPaymentRequest = (formData, action) => ({
  type: Types.EDIT_PAYMENT_REQUEST,
  payload: formData,
  action: action,
});

export const editPaymentSuccess = (data) => ({
  type: Types.EDIT_PAYMENT_SUCCESS,
  payload: data,
});

export const editPaymentFail = () => ({
  type: Types.EDIT_PAYMENT_FAIL,
});

//LOADING
export const addPaymentLoading = (isLoading) => ({
  type: Types.ADD_PAYMENTS_LOADING,
  payload: isLoading,
});

// Create Payment
export const createPaymentRequest = (formData, action) => ({
  type: Types.CREATE_PAYMENT_REQUEST,
  payload: formData,
  action: action,
});

export const createPaymentSuccess = ({ data }) => ({
  type: Types.CREATE_PAYMENT_SUCCESS,
  payload: {
    data,
  },
});

export const createPaymentFail = () => ({
  type: Types.CREATE_PAYMENT_FAIL,
});

export const deletePaymentRequest = (id) => ({
  type: Types.DELETE_PAYMENT_REQUEST,
  payload: id,
});

export const deletePaymentSuccess = (id) => ({
  type: Types.DELETE_PAYMENT_SUCCESS,
  payload: id,
});

export const deletePaymentFail = () => ({
  type: Types.DELETE_PAYMENT_FAIL,
});

export const createVoucherRequest = (formData) => ({
  type: Types.CREATE_VOUCHER_REQUEST,
  payload: formData,
});

export const createVoucherSuccess = ({ data }) => ({
  type: Types.CREATE_VOUCHER_SUCCESS,
  payload: {
    data,
  },
});

export const createVoucherFail = () => ({
  type: Types.CREATE_VOUCHER_FAIL,
});
