import { toast } from "react-toastify";
import { call, put, takeLatest } from "redux-saga/effects";
import * as actions from "./Actions";
import * as api from "./Api";
import Types from "./Types";

// SIGNIN

function* signinUser(formData) {
  try {
    yield put(actions.setAuthIsLoadingAction(true));
    const result = yield call(api.Login, formData.payload);
    if (result.data.errors) {
      toast.error(result.data.errors[0]);
    } else {
      yield put(
        actions.loginUserSuccessAction({
          data: result.data,
        })
      );
      result.data.data.role[0].name == "Super Admin" &&
        window.location.replace("/dashboard/students");
      result.data.data.role[0].name == "Teacher" &&
        window.location.replace("/dashboard/students");
      result.data.data.role[0].name == "Student" &&
        window.location.replace("/home");
    }
  } catch (error) {
    toast.error(" خطاء في اسم المستخدم او كلمة المرور ");
  } finally {
    yield put(actions.setAuthIsLoadingAction(false));
  }
}

function* signoutUser() {
  try {
    yield put(actions.setAuthIsLoadingAction(true));
    const result = yield call(api.Logout);
    yield put(actions.logoutUserSuccessAction());
    window.localStorage.clear();

    window.location.href = "/login";
  } catch (error) {
    const { response, message } = error;
  } finally {
    yield put(actions.setAuthIsLoadingAction(false));
  }
}

function* userVoucher({ payload }) {
  try {
    yield put(actions.setAuthIsLoadingAction(true));
    const result = yield call(api.userVoucher, payload?.formData);
    yield put(actions.userVoucherSuccessAction());
    payload?.action && payload?.action();
  } catch (error) {
    toast.error("طلبك  مرفوض");
    const { response, message } = error;
  } finally {
    yield put(actions.setAuthIsLoadingAction(false));
  }
}

export default function* authSaga() {
  yield takeLatest(Types.LOGIN_USER, signinUser);
  yield takeLatest(Types.LOGOUT_USER, signoutUser);
  yield takeLatest(Types.USER_VOUCHER, userVoucher);
}
