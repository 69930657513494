import { Flex, Text, Image } from "@chakra-ui/react";
import React from "react";

const RegisterCard = ({ background, imageUrl, title, description, step }) => {
  return (
    <Flex flexDirection="column" position="relative" w="300px" h="300px">
      <Flex position="relative" zIndex={"5"} alignItems="center">
        <Text fontSize="65px" mx="8px" fontWeight="bold">
          {step}
        </Text>
        <Image
          src={require(`../../../assets/${imageUrl}.webp`)}
          alt=""
          w="100px"
          h="100px"
        />
      </Flex>
      <Flex flexDirection="column" position="relative" zIndex={"5"}>
        <Text fontSize="28px" fontWeight="600">
          {title}
        </Text>
        <Text>{description}</Text>
      </Flex>
      <Image
        src={require(`../../../assets/${background}.webp`)}
        position="absolute"
        w="100%"
        h="100%"
      />
    </Flex>
  );
};

export default RegisterCard;
