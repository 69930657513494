import { toast } from "react-toastify";
import { call, fork, put, takeLatest } from "redux-saga/effects";
import * as actions from "./Actions";
import * as api from "./Api";
import Types from "./Types";

function* getAllStudentChapters({ payload }) {
  try {
    yield put(actions.addStudentChapterLoading(true));
    const result = yield call(api.getAllStudentChapters);
    yield put(actions.getAllStudentChaptersSuccess(result.data));
  } catch (error) {
    const { response, message } = error;
    toast.error(error);
    //console.log(error, 'error');
    yield put(actions.getAllStudentChaptersFail());
  } finally {
    yield put(actions.addStudentChapterLoading(false));
  }
}

function* getStudentChapters({ payload }) {
  try {
    yield put(actions.addStudentChapterLoading(true));
    const result = yield call(api.getStudentChapters);
    yield put(actions.getStudentChaptersSuccess(result.data));
  } catch (error) {
    const { response, message } = error;
    toast.error(error);
    //console.log(error, 'error');
    yield put(actions.getStudentChaptersFail());
  } finally {
    yield put(actions.addStudentChapterLoading(false));
  }
}

export default function* StudentChapter() {
  yield takeLatest(Types.GET_STUDENT_CHAPTERS_REQUEST, getAllStudentChapters);
  yield takeLatest(Types.GET_ALL_STUDENT_CHAPTERS_REQUEST, getStudentChapters);
}
