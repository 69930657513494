import { HStack, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { set } from 'react-hook-form';
import DateTimeDisplay from './display-counter';
import { useCountdown } from './timer';

const ExpiredNotice = ({ handelSendAnswers }) => {
    useEffect(() => {
        handelSendAnswers()
    }, [])
    return (
        <div className="expired-notice">
            <p>time finshed.</p>
        </div>
    );
};

const ShowCounter = ({ days, hours, minutes, seconds }) => {
    const [isDanger, setDinger] = useState(false)
    return (
        <HStack>
            <DateTimeDisplay value={minutes} type={''} isDanger={isDanger} />
            <Text> : </Text>
            <DateTimeDisplay value={seconds} type={''} isDanger={isDanger} />

        </HStack>
    );
};

const CountdownTimer = ({ targetDate, handelSendAnswers }) => {

    const [days, hours, minutes, seconds] = useCountdown(targetDate);


    if (days + hours + minutes + seconds <= 0) {
        return <ExpiredNotice handelSendAnswers={handelSendAnswers} />
    } else {
        return (
            <ShowCounter
                days={days}
                hours={hours}
                minutes={minutes}
                seconds={seconds}
            />
        );
    }
};

export default CountdownTimer;
