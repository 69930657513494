import Types from "./Types";

const INITIAL_STATE = {
  chapters: [],
  chapter: {},
  isLoading: false,
  count: "",
};

export default function chapters(state = INITIAL_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case Types.GET_CHAPTERS_SUCCESS: {
      return {
        ...state,
        chapters: payload.data,
        count: payload.data.length,
      };
    }

    case Types.GET_CHAPTER_SUCCESS: {
      return {
        ...state,
        chapter: payload,
      };
    }

    case Types.EDIT_CHAPTER_SUCCESS: {
      let data = payload.data;
      const chapters = state.chapters;
      let idx = 0;
      chapters.forEach((table, index) => {
        if (table.id === data.id) {
          idx = index;
          return;
        }
      });

      chapters[idx] = data;
      return {
        ...state,
        chapters: chapters,
      };
    }

    case Types.RESTRICT_CHAPTER_SUCCESS: {
      let data = payload.data;
      const chapters = state.chapters;
      let idx = 0;
      chapters.forEach((table, index) => {
        if (table.id === data.id) {
          idx = index;
          return;
        }
      });

      chapters[idx] = data;
      return {
        ...state,
        chapters: chapters,
      };
    }
    case Types.ASQUARA_CHAPTER_SUCCESS: {
      let data = payload.data;
      const chapters = state.chapters;
      let idx = 0;
      chapters.forEach((table, index) => {
        if (table.id === data.id) {
          idx = index;
          return;
        }
      });

      chapters[idx] = data;
      return {
        ...state,
        chapters: chapters,
      };
    }

    case Types.ACTIVE_CHAPTER_SUCCESS: {
      let data = payload.data;
      const chapters = state.chapters;
      let idx = 0;
      chapters.forEach((table, index) => {
        if (table.id === data.id) {
          idx = index;
          return;
        }
      });

      chapters[idx] = data;
      return {
        ...state,
        chapters: chapters,
      };
    }

    // create
    case Types.CREATE_CHAPTER_SUCCESS: {
      const data = payload.data.data;
      let newArray = state.chapters.slice();
      let index = state.chapters.length + 1;
      newArray.splice(index, 0, data);
      return {
        ...state,
        chapters: newArray,
      };
    }
    case Types.ADD_CHAPTERS_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }

    case Types.DELETE_CHAPTER_SUCCESS: {
      const newchapters = state.chapters.filter((item) => item.id !== payload);
      return {
        ...state,
        chapters: newchapters,
      };
    }
    default: {
      return state;
    }
  }
}
