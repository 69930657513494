import axiosInstance from "../../../helpers/api";

export const getAllPayments = (body) => {
  return axiosInstance.get("/admin/payments", body);
};
export const getPaymentException = (body) => {
  return axiosInstance.get("/admin/payment_exceptions", body);
};

export const editPayments = (formData) => {
  return axiosInstance.post(
    `/admin/payments/${formData.id}`,
    formData.formData
  );
};

export const createPayments = (formData) => {
  return axiosInstance.post("/admin/payments", formData);
};

export const createVoucher = (formData) => {
  return axiosInstance.post("/admin/vouchers", formData);
};

export const deletePayment = (id) => {
  return axiosInstance.delete(`/admin/payments/${id}`);
};
