import { Button, IconButton, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { MdWarningAmber } from 'react-icons/md';
import { RiDeleteBinLine, RiFileEditLine } from 'react-icons/ri'
import { useDispatch, useSelector } from 'react-redux';
import CTable from '../../../components/Table/table'
import { deleteLessonRequest } from '../../../modules/admin-modules/lessons/Actions';
import { openCreateLsson } from '../../../modules/admin-modules/models/Actions';

const LessonsTable = ({ setAdd, setSelectedData }) => {
    const lessons = useSelector((state) => state.lessons.lessons)
    const dispatch = useDispatch()
    const isLoading = useSelector((state) => state.lessons.isLoading)
    const [offset, setPage] = useState(0);
    const [size, setPerPage] = useState(10);
    const [pageNumber, setPageNumber] = useState(0);
    const [selected, setSelected] = useState({
        id: ''
    })
    const viewData = (data) => {
        setSelectedData(data);
        setSelected(data)
    };
    const Data =
        lessons?.map((data) => {
            return {
                ...data,
                id: data?.id,
                title: data?.title,
                chapter_id: data?.chapter?.id,
                chapter_name: data?.chapter?.name,
                grade_name: data?.grade?.name,

                publish: (
                    <Button bg='main_1' color='white' borderRadius='16px'
                    >
                        نشر
                    </Button>
                ),
                edit: (
                    <IconButton
                        variant='outline'
                        border='0'
                        color='gray.600'
                        aria-label='Call Sage'
                        fontSize='20px'
                        icon={<RiFileEditLine />}
                        onClick={() => {
                            setAdd(false);
                            dispatch(openCreateLsson())
                        }}
                    />

                ),
                delete: (<IconButton
                    variant='outline'
                    border='0'
                    color='gray.600'
                    aria-label='Call Sage'
                    fontSize='20px'
                    icon={<RiDeleteBinLine color='red' />}
                    onClick={() => dispatch(deleteLessonRequest(data?.id))}
                />

                ),
            };
        })
    const columns = [
        {
            Header: 'عنوان الدرس',
            accessor: 'title'
        },
        {
            Header: 'الصف',
            accessor: 'grade_name'
        },

        {
            Header: 'الفصل التابع له',
            accessor: 'chapter_name'
        }
        , {
            Header: 'النشر',
            accessor: 'publish'
        },
        {
            Header: 'تعديل',
            accessor: 'edit'
        },
        {
            Header: 'حذف',
            accessor: 'delete'
        }
    ];


    return (
        <CTable
            selectedData={viewData}
            Columns={columns}
            Data={Data}
            path={'openModel'}
            Actions
            Title={``}
            subTitle=''
            btnTitle=""
            placeHolder=""
            noSearchBar={true}
            noFilter={true}
            footerBtnTitle
            filterList
            addButton
            addButtonTitle=""
            setPage={setPage}
            setPerPage={setPerPage}
            currentpage={pageNumber}
            setPageNumber={setPageNumber}
            noSecondSearchBar={true}
            isLoading={isLoading}
            perPage={size}
            totalPage={Math.ceil(lessons?.length / 10)}

        />
    )
}

export default LessonsTable